import React, { FC } from "react";

import { HeaderContainer, StyledHeader, StyledSubheader } from "../../styles";

type HeaderFieldProps = {
  fieldId: string;
  headerText: string;
  subheaderText?: string;
  collapsible: boolean;
  isCollapsed: boolean;
  handleToggleCollapse?: (fieldId: string) => void;
};

const HeaderField: FC<HeaderFieldProps> = ({
  fieldId,
  headerText,
  subheaderText,
  collapsible,
  isCollapsed,
  handleToggleCollapse,
}) => {
  return (
    <HeaderContainer>
      <StyledHeader style={{ margin: 0 }}>
        {headerText}
        {collapsible && handleToggleCollapse ? (
          isCollapsed ? (
            <i onClick={() => handleToggleCollapse(fieldId)} className="fa fa-caret-right"></i>
          ) : (
            <i onClick={() => handleToggleCollapse(fieldId)} className="fa fa-caret-down"></i>
          )
        ) : null}
      </StyledHeader>
      {isCollapsed ? null : <StyledSubheader>{subheaderText}</StyledSubheader>}
    </HeaderContainer>
  );
};

export { HeaderField };
