import styled from "styled-components";

import { defaultTheme } from "@src/themes";
import { Breakpoints } from "@src/customHooks";

const {
  colors: { alias: colorAlias, values: colorValues },
  spacing,
  borderRadius,
  font,
} = defaultTheme;

export type ButtonProps = {
  primary?: boolean;
  disabled?: boolean;
  screenSize?: Breakpoints;
  hoverColor?: string;
  backgroundColor?: string;
};

const Button = styled.button<ButtonProps>`
  ${({ primary, disabled, screenSize, hoverColor, backgroundColor }) => `
    border-radius: ${borderRadius};
    border: 0px solid transparent;
    padding: ${spacing.default} ${spacing.medium};
    font-family: ${font.default};
    background-color: ${
      disabled
        ? colorAlias.disabled
        : primary
        ? colorAlias.primary
        : backgroundColor
        ? backgroundColor
        : colorValues.white
    };
    color: ${primary ? colorValues.white : colorAlias.lightPrimary};
    cursor: ${disabled ? "default" : "pointer"};
    min-width: ${screenSize === "smallScreen" ? "100px" : "150px"};
    width: fit-content;
    height: 40px;

    &:hover {
      background-color: ${disabled ? "default" : hoverColor ?? colorAlias.lightPrimary};
    }

    &:active {
      outline: none;
    }
  `}
`;

const SmallButton = styled(Button)`
  ${({ screenSize }) => `
    padding: ${screenSize === "smallScreen" ? `${spacing.default} ${spacing.default}` : `${spacing.default} ${spacing.medium}`};
    min-width: ${screenSize === "smallScreen" ? "0" : "150px"};
  `}
`;

export { Button, SmallButton };
