import { i18next } from "@src/translations";

import { Answer, AnswerInput, Field, FieldType } from "@src/types";
import { InnerFields } from "./formValuesTypes";
import { getPlainLabel } from "./getters";

type RepeatableInnerFields = Record<string, Answer | Record<string, Answer>>;

/**
 * Gets the answers from the InnerFields of a RepeatableValue.
 * @param value InnerFields
 * @returns An array of Answer.
 */

const getInnerFieldAnswersFromRepeatableValue = (values: InnerFields): AnswerInput[] => {
  const innerFieldValues = values as RepeatableInnerFields;

  const innerFieldAnswers = Object.values(innerFieldValues);

  return innerFieldAnswers.flatMap((value) =>
    value.questionId && value.fieldId ? (value as Answer) : (Object.values(value) as Answer[])
  );
};

export type LabelValuePair = {
  label: string;
  value: string;
  fileType: string;
};

type GetLabelValuePairArgs = {
  field: Field;
  answers: AnswerInput[];
  overrideFieldId?: string | null;
};

const getLabelValuePair = ({
  field,
  answers,
  overrideFieldId,
}: GetLabelValuePairArgs): LabelValuePair => {
  const fieldId = overrideFieldId || field.id;

  const answer = answers.find(
    (answer) => answer.fieldId === fieldId && answer.questionId === field.question?.id
  );

  let value = answer?.value || i18next.t("labels.noAnswer") || "";

  if (
    (field.type === FieldType.Multiselect || field.type === FieldType.Selectmultiple) &&
    answer?.value
  ) {
    try {
      const selectedOptions = JSON.parse(answer.value);
      value =
        selectedOptions.length > 0
          ? selectedOptions.join(", ")
          : i18next.t("labels.noAnswer") || "";
    } catch {
      value = i18next.t("labels.noAnswer") || "";
    }
  }

  if (field.type === FieldType.Date && field.question?.format && answer?.value) {
  }

  let label = getPlainLabel(field);

  if (!label || label === "") label = i18next.t("labels.noLabel");

  return {
    label,
    value,
    fileType: answer?.fileType ?? "",
  };
};

export { getInnerFieldAnswersFromRepeatableValue, getLabelValuePair };
