import React, { FC, useEffect } from "react";

import { RepeatableAddButton, RepeatableFieldsWrapper } from "@src/components/styles";
import { Field } from "@src/types";
import { FieldSelector } from "../../FieldSelector";
import { Flex } from "@src/components/layout/Page";
import { useTranslation } from "react-i18next";

type RepeatableFormProps = {
  id: string;
  innerFields: Field[];
  isValid: boolean;
  isEditing: boolean;
  isDirty: boolean;
  uploadUrl?: string;
  viewFileUrl?: string;
  setIsDirty: (isDirty: boolean) => void;
  resetForm: () => void;
  submitForm: () => Promise<void>;
  fileSelected?: (fieldId: string, questionId: string, file: File) => void;
  fileRemoved?: (fieldId: string, questionId: string) => void;
};

const RepeatableForm: FC<RepeatableFormProps> = ({
  id,
  innerFields,
  isValid,
  isEditing,
  isDirty,
  viewFileUrl,
  setIsDirty,
  resetForm,
  submitForm,
  fileSelected,
  fileRemoved,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    setIsDirty(isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  return (
    <RepeatableFieldsWrapper>
      {innerFields.map((repeatableField) => (
        <FieldSelector
          key={repeatableField.id}
          field={repeatableField}
          viewFileUrl={viewFileUrl}
          fileSelected={fileSelected}
          fileRemoved={fileRemoved}
        />
      ))}
      <Flex justifyContent="space-between">
        <RepeatableAddButton
          data-testid={id + "-SUBMIT"}
          onClick={submitForm}
          disabled={!isValid}
          type="button"
        >
          {isEditing ? t("labels.editAnswers") : t("labels.addAnswers")}
        </RepeatableAddButton>
        <RepeatableAddButton
          data-testid={id + "-CLEAR"}
          onClick={resetForm}
          disabled={!isDirty}
          type="button"
        >
          {t("labels.clearForm")}
        </RepeatableAddButton>
      </Flex>
    </RepeatableFieldsWrapper>
  );
};

export { RepeatableForm };
