import { FormInfo } from "@src/customHooks/useFormInfo";
import {
  Field,
  ResponseVersion
} from "@src/types";
import { getFormInitialValues } from "@src/utils/formValues";
import { InitialValuesObject } from "@src/utils/formValuesTypes";
import { getSigneeAnswers, getSigneeFields } from "@src/utils/getters";
import { useMemo } from "react";
import { useCachedResponseVersion } from "@src/customHooks/useResponseVersion";

type UseSigneeQuestionsArgs = {
  formInfo: FormInfo;
  responseVersion: ResponseVersion;
};

type UseSigneeQuestionsReturnType = {
  fields: Field[];
  initialValues: InitialValuesObject;
};

const useSigneeQuestions = ({
  formInfo,
  responseVersion,
}: UseSigneeQuestionsArgs): UseSigneeQuestionsReturnType => {
  const { loading } = useCachedResponseVersion(responseVersion?.hash);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fields = useMemo(() => getSigneeFields(formInfo.form), [formInfo.loading]);
  const answers = useMemo(
    () =>
      getSigneeAnswers(responseVersion),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading]
  );

  const initialValues = useMemo(
    () =>
      responseVersion
        ? getFormInitialValues({
          fields,
          answers,
        })
        : {},
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formInfo.loading, loading, responseVersion]
  );

  return { fields, initialValues };
};

export { useSigneeQuestions };

