import { Icon } from "@src/components/atoms/Icon";
import styled from "styled-components";

const ErrorNavigatorWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #ff6c6c;
  width: 100vw;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  z-index: 1000;
`;

const ErrorNavigationMessage = styled.span`
  color: #43516f;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  margin: 0 10px;
`;

const ShowNextErrorButton = styled.button.attrs({ type: "button" })`
  background-color: #d24041;
  padding: 5px 10px;
  font-size: 0.9em;
  font-family: Roboto,sans-serif;
  color: ${({ theme }) => theme.colors.values.white};
  border-radius: 7px;
  border: none;
  font-weight: 500;
  cursor: pointer;
`;

const ErrorIcon = styled(Icon).attrs({
  icon: "exclamation-triangle",
  color: "#43516f",
  size: "12px",
  style: "solid",
})`
  margin-right: 5px;
`;

export { ErrorNavigatorWrapper, ShowNextErrorButton, ErrorIcon, ErrorNavigationMessage };
