const phoneDefaultFormat = "(111) 111-1111";

const cleanFormat = (value: string): string => {
  if (value) {
    value = value.replace(/(["{","}","_"])/g, "");

    return value.replace(/([0-9,a-z,A-Z])/g, "1");
  }

  return value;
};

const createMask = (format: string): (string | RegExp)[] => {
  const digitRegExp = /\d/;

  return format.split("").map((char) => (digitRegExp.test(char) ? digitRegExp : char));
};

export { phoneDefaultFormat, cleanFormat, createMask };
