import styled from "styled-components";
import { defaultTheme } from "@src/themes";

const TopLevelLink = styled.a`
  text-decoration: none;
`;

const LinkImage = styled.img`
  height: 45px;
  box-shadow: 0px 0px 5px 0 #777;
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 75%;
  margin-left: 15px;
`;

const Link = styled.p`
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${defaultTheme.colors.alias.primary};
  margin: 0;
`;

const LinkTitle = styled.p`
  font-family: ${defaultTheme.font.default};
  color: black;
  margin: 0;
`;

const LinkDescription = styled.p`
  font-family: ${defaultTheme.font.default};
  font-size: 12px;
  color: grey;
  margin: 0;
`;

export { TopLevelLink, LinkImage, LinkContainer, Link, LinkTitle, LinkDescription };
