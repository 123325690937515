import { Breakpoints } from "@src/customHooks";
import { defaultTheme } from "@src/themes";
import styled from "styled-components";

const StyledFooterBar = styled.div<{screenSize: Breakpoints}>`
  display: flex;
  justify-content: ${(props) => (props.screenSize === "desktop" ? `space-between` : `center`)};
  align-items: ${(props) => (props.screenSize === "desktop" ? `normal` : `center`)};
  height: 70px;
  width: 100%;
  background-color: ${defaultTheme.colors.alias.primary};
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: white;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
`;

const StyledCopyright = styled.p<{screenSize: Breakpoints}>`
  font-family: ${defaultTheme.font.default};
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  padding-top: 0;
  width: ${(props) => (props.screenSize === "desktop" ? `400px` : `300px`)};
  text-align: center;
`;

const StyledLogoContainer = styled.div<{screenSize: Breakpoints}>`
  display: flex;
  align-items: center;
  padding-left: ${(props) => (props.screenSize === "desktop" ? `20px` : `0px`)};
  width: ${(props) => (props.screenSize === "desktop" ? `400px` : `auto`)};
  justify-content: ${(props) => (props.screenSize === "desktop" ? `normal` : `center`)};
`;

const StyledLinkContainer = styled.div<{screenSize: Breakpoints}>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.screenSize === "desktop" ? `space-around` : `space-evenly`)};
  width: ${(props) => (props.screenSize === "desktop" ? `400px` : `300px`)};
  padding-top: 10px;
`;

export { StyledFooterBar, StyledLink, StyledCopyright, StyledLogoContainer, StyledLinkContainer };
