import { createGlobalStyle } from "styled-components";

const CssReset = createGlobalStyle`

html, body {

  margin: 0;
  padding: 0;
  border: none;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  height: 100%;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
  }
`;

export { CssReset };
