import { Flex } from "@src/components/layout/Page";
import { StyledLabel } from "@src/components/styles";
import { useFormInfo } from "@src/customHooks/useFormInfo";
import { PurchasedTicket } from "@src/types";
import { currencyFormatter } from "@src/utils/purchasedTickets";
import { useGoogleTranslate } from "@src/utils/translation";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

type PreviousTicketProps = {
  purchasedTickets: PurchasedTicket[];
};

const PreviousTicket: FC<PreviousTicketProps> = ({ purchasedTickets }) => {
  const { i18n } = useTranslation();
  const googleTranslate = useGoogleTranslate();
  const { form } = useFormInfo();

  const currencyCode = form?.document?.organization?.currencyCode;

  return (
    <Flex flexDirection="column">
      {purchasedTickets.map((purchasedTicket) => {
        return (
          <Flex
            key={`previous-${purchasedTicket.purchasedTicketIds[0]}`}
            justifyContent="space-between"
          >
            <StyledLabel>{googleTranslate(purchasedTicket.name ?? "")}</StyledLabel>
            <Flex>
              <StyledLabel style={{ marginRight: "10px" }}>
                ({purchasedTicket.quantity}){" "}
              </StyledLabel>
              <StyledLabel>
                {currencyFormatter(i18n.resolvedLanguage, currencyCode).format(
                  purchasedTicket.quantity * purchasedTicket.price
                )}
              </StyledLabel>
            </Flex>
          </Flex>
        );
      })}
    </Flex>
  );
};

export { PreviousTicket };
