import React, { FC } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { ValidationError } from "yup";

import { AggregateCell, Cell, Row, InvisibleCell } from "@src/components/atoms/Table";
import { AnswerInput, Question } from "@src/types";
import { EquationRow } from "@src/utils/formValuesTypes";
import { currencyFormatter } from "@src/utils/purchasedTickets";
import { getSchemaForEquationQuestion } from "@src/utils/validation";
import { EquationSection } from "./components";
import { DeleteRowIcon } from "./styles";
import { EditRowArgs } from "./hooks";
import { useFormInfo } from "@src/customHooks/useFormInfo";
import { TranslateFormErrors } from "@src/customHooks/useTranslateFormErrors";

type EditRowProps = {
  inputQuestions: Question[];
  calculatedQuestions: Question[];
  row: EquationRow;
  editRowValue: (args: EditRowArgs) => Promise<void>;
  deleteRow: (row: EquationRow) => Promise<void>;
};

const EditRow: FC<EditRowProps> = ({
  inputQuestions,
  calculatedQuestions,
  row,
  editRowValue,
  deleteRow,
}) => {
  const { t, i18n } = useTranslation();
  const { form } = useFormInfo();

  const currencyCode = form?.document?.organization?.currencyCode;

  const validate = (question: Question) => (value: string) => {
    try {
      const schema = getSchemaForEquationQuestion({ question, t });
      schema.validateSync(value);
    } catch (err) {
      const error = err as ValidationError;
      return error.message;
    }
  };

  const renderCalculatedValue = (question: Question) => {
    if (question.name && row.answers[question.name]) {
      return question.valueType === "currency" &&
        !isNaN(parseFloat(row.answers[question.name].value ?? ""))
        ? currencyFormatter(i18n.resolvedLanguage, currencyCode).format(
            parseFloat(row.answers[question.name].value ?? "")
          )
        : question.valueType === "decimal" &&
          !isNaN(parseFloat(row.answers[question.name].value ?? ""))
        ? parseFloat(row.answers[question.name].value ?? "0.00").toFixed(2)
        : question.valueType === "integer" &&
          !isNaN(parseInt(row.answers[question.name].value ?? "", 10))
        ? parseInt(row.answers[question.name].value ?? "", 10)
        : row.answers[question.name].value;
    }

    return "";
  };

  const onEditRowValue = (columnName?: string | null) => (newAnswer: AnswerInput) =>
    editRowValue({ rowId: row.answerId || "", columnName: columnName || "", newAnswer });

  // Remap the answer object keys to use the question id ranther than question name
  // This is necessary because the question name can contain special characters that seems to trips up Formik
  const transformAnswersToInitialValues = (answers: Record<string, AnswerInput>): Record<string, AnswerInput> => {
    const allQuestions = [...inputQuestions, ...calculatedQuestions];
    const initialValues: Record<string, AnswerInput> = {};

    Object.entries(answers).forEach(([key, answer]) => {
      const question = allQuestions.find(question => question.name === key);
      
      initialValues[question?.id ?? key] = answer;
    });

    return initialValues;
  }

  return (
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    <Formik initialValues={transformAnswersToInitialValues(row.answers)} onSubmit={() => {}}>
      <TranslateFormErrors>
        <Row key={row.answerId}>
          {inputQuestions.map((question, index) => (
            <Cell key={question.id || index} alignLeft={true}>
              <EquationSection
                key={question.id || ""}
                question={question}
                row={row}
                validate={validate(question)}
                editRowValue={onEditRowValue(question.name)}
              />
            </Cell>
          ))}
          {calculatedQuestions
            ? calculatedQuestions.map((question, index) => (
                <AggregateCell key={question.id || index}>
                  {renderCalculatedValue(question)}
                </AggregateCell>
              ))
            : null}
          <InvisibleCell key="actions">
            <DeleteRowIcon
              className="fa-solid fa-trash-can"
              onClick={() => deleteRow(row)}
            ></DeleteRowIcon>
          </InvisibleCell>
        </Row>
      </TranslateFormErrors>
    </Formik>
  );
};

export { EditRow };
