import React, { FC } from "react";
import { Order } from "@src/types";
import { StyledLabel, StyledLine } from "@src/components/styles";
import { Flex } from "@src/components/layout/Page";
import groupBy from "lodash/groupBy";
import { currencyFormatter } from "@src/utils/purchasedTickets";
import dayjs from "dayjs";
import { StyledOrderContainer } from "./styles";
import { PreviousTicket } from "./PreviousTicket";
import { useTranslation } from "react-i18next";
import { useFormInfo } from "@src/customHooks/useFormInfo";
import { useGoogleTranslate } from "@src/utils/translation";

export type PreviousOrderProps = {
  order: Order;
};

const PreviousOrder: FC<PreviousOrderProps> = ({ order }) => {
  const { i18n, t } = useTranslation();
  const attendeeTicketsDict = groupBy(order.purchasedTickets, "attendeeId");
  const { form } = useFormInfo();
  const googleTranslate = useGoogleTranslate();

  const orderDate = googleTranslate(dayjs(order.date).format("MMMM DD YYYY"));

  const currencyCode = form?.document?.organization?.currencyCode;
  return (
    <StyledOrderContainer>
      <Flex flexDirection="column" style={{ width: "100%" }}>
        <>
          <Flex justifyContent="space-between">
            <Flex flexDirection="column">
              <StyledLabel>
                <strong>{t("labels.orderNumber")}</strong> {order.orderNumber}{" "}
              </StyledLabel>
              <StyledLabel>
                <strong>{t("labels.purchasedBy")}</strong> {order.purchaserFirstName}{" "}
                {order.purchaserLastName}
              </StyledLabel>
            </Flex>
            <Flex flexDirection="column" alignItems="flex-end">
              <StyledLabel>
                <strong>{t("labels.date")}</strong> {orderDate}
              </StyledLabel>
              <StyledLabel>
                <strong>{t("labels.total")}</strong>{" "}
                {currencyFormatter(i18n.resolvedLanguage, currencyCode).format(
                  order.grandTotal || 0
                )}
              </StyledLabel>
            </Flex>
          </Flex>
          <StyledLine />
          {Object.keys(attendeeTicketsDict).map((attendeeId) => {
            return (
              <PreviousTicket
                key={`test-${attendeeId}-${order.orderNumber}`}
                purchasedTickets={attendeeTicketsDict[attendeeId]}
              />
            );
          })}
        </>
      </Flex>
    </StyledOrderContainer>
  );
};

export type PreviousOrdersProps = {
  orders: Order[];
};

const PreviousOrders: FC<PreviousOrdersProps> = ({ orders }) => {
  const { t } = useTranslation();
  return (
    <Flex flexDirection="column">
      <>
        <StyledLabel style={{ fontSize: "18px" }}>{t("labels.previousOrders")}</StyledLabel>
        {orders.map((order) => (
          <PreviousOrder key={order.orderId} order={order} />
        ))}
        <StyledLine />
      </>
    </Flex>
  );
};

export { PreviousOrders };
