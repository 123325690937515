import { getIn, useFormikContext } from "formik";
import React, { FC } from "react";
import { Icon } from "../Icon";
import { StyledErrorContainer, StyledFieldError } from "./style";

export type FieldErrorProps = {
  name: string;
  id: string;
  children?: (message: string) => JSX.Element;
};

const FieldError: FC<FieldErrorProps> = ({ name, id, children }) => {
  const { errors, touched } = useFormikContext();
  const error = getIn(errors, name);
  const touch = getIn(touched, name);
  const isString = typeof error === "string";

  if (touch && error && isString) {
    if (children) {
      return children(error);
    } else {
      return (
        <StyledErrorContainer id={id}>
          <Icon style="solid" icon="circle-exclamation" color="#c00109" />
          <StyledFieldError>{error}</StyledFieldError>
        </StyledErrorContainer>
      );
    }
  }

  return null;
};

export { FieldError };
