import { Button, ButtonProps } from "@src/components/atoms/Button";
import { Icon, IconProps } from "@src/components/atoms/Icon";
import React, { FunctionComponent } from "react";

type IconButtonProps = ButtonProps & IconProps & { children: React.ReactNode; onClick: () => void };

const IconButton: FunctionComponent<IconButtonProps> = ({
  primary,
  style,
  icon,
  children,
  onClick,
}: IconButtonProps) => {
  return (
    <Button type="button" primary={primary} onClick={onClick}>
      <Icon style={style} icon={icon} />
      {children}
    </Button>
  );
};

export { IconButton };
