import React, { ChangeEventHandler, FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import omit from "lodash/omit";

import {
  ClearIconSpacer,
  FieldWrapper,
  StyledLabel,
  StyledRadioButton,
  StyledRadioButtonLabel,
  StyledRequiredLabel,
} from "@src/components/styles";
import { CommonFieldProps } from "../types";
import { AnswerInput } from "@src/types";
import { Icon } from "@src/components/atoms/Icon";
import { FALSY_STRING, TRUTHY_STRING } from "@src/constants/validation";
import { FieldError } from "@src/components/atoms/FieldError";
import { Flex } from "@src/components/layout/Page";
import { Label } from "@src/components/atoms/Label";

type YesNoFieldProps = CommonFieldProps;

const YesNoField: FC<YesNoFieldProps> = ({
  label,
  id,
  value,
  isRequired,
  setValue,
  saveAnswer,
}) => {
  const { t } = useTranslation();
  const [answerId, setAnswerId] = useState<string>(value.answerId ?? "");

  const yesChecked = !!value?.value && value.value === TRUTHY_STRING;
  const noChecked = !!value?.value && value.value === FALSY_STRING;

  // Once we get an answerId, add it to the answer.
  // This id will be reused whenever a new answer is created, so this should run only once
  // after render.
  useEffect(() => {
    if (answerId) {
      setValue({ ...value, answerId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerId]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = async (e) => {
    const newAnswer: AnswerInput = omit(
      {
        ...value,
        value: e.target.value,
      },
      "__typename"
    );

    setValue(newAnswer);

    const newAnswerId = saveAnswer ? await saveAnswer(newAnswer) : "";

    // Triggers the useEffect that adds the answerId to the answer.
    newAnswerId !== answerId && setAnswerId(newAnswerId);
  };

  const handleClear = () => {
    const emptyAnswer: AnswerInput = omit(
      {
        ...value,
        value: "",
      },
      "__typename"
    );

    setValue(emptyAnswer);
    saveAnswer && saveAnswer(emptyAnswer);
  };

  return (
    <FieldWrapper id={id}>
      {isRequired ? <StyledRequiredLabel /> : null}
      <Label>{`${label} `}</Label>
      {value.value ? (
        <ClearIconSpacer>
          <Icon
            testId={id + "-REMOVE"}
            style="solid"
            icon="trash"
            size="12px"
            color="#bcbcbc"
            onClick={handleClear}
          />
        </ClearIconSpacer>
      ) : null}

      <Flex>
        <StyledLabel style={{ marginRight: "15px" }}>
          <StyledRadioButton
            value={TRUTHY_STRING}
            checked={yesChecked}
            onChange={handleChange}
            name={id}
            data-testid={id + "-YES"}
          />
          <StyledRadioButtonLabel selected={yesChecked}>{t("labels.yes")}</StyledRadioButtonLabel>
        </StyledLabel>
        <StyledLabel>
          <StyledRadioButton
            value={FALSY_STRING}
            checked={noChecked}
            onChange={handleChange}
            name={id}
            data-testid={id + "-NO"}
          />
          <StyledRadioButtonLabel selected={noChecked}>{t("labels.no")}</StyledRadioButtonLabel>
        </StyledLabel>
      </Flex>

      <FieldError id={id} name={id} />
    </FieldWrapper>
  );
};

export { YesNoField };
