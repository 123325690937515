import { useReactiveVar } from "@apollo/client";
import { Button } from "@src/components/atoms/Button";
import { Froala } from "@src/components/atoms/Froala";
import { ContentWrapper, Flex, PageWrapper, Push } from "@src/components/layout/Page";
import { PageFooter } from "@src/components/molecules/pageFooter";
import { PageHeader } from "@src/components/molecules/pageHeader";
import { StyledDivider, StyledHeader, StyledLabel } from "@src/components/styles";
import { useUrlParams } from "@src/customHooks";
import { SelectedPaymentType, IsPayNow, ResponseVersionHash, ResponseAlreadySubmitted } from "@src/localVariables";
import { ConfirmationRoute, PreviewProps } from "@src/Routes";
import { getPaymentMethods, PaymentMethodType } from "@src/utils/purchasedTickets";
import React, { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { Feedback } from "../../components/molecules/feedback/Feedback";
import { currencyFormatter } from "@src/utils/purchasedTickets";
import { useFormInfo } from "@src/customHooks/useFormInfo";
import { useCachedResponseVersion } from "@src/customHooks/useResponseVersion";
import { LoadingModal } from "@src/components/molecules/loadingModal/LoadingModal";
import { ErrorComponent } from "../Error";
import { isAuthenticatedResponse, isRostered } from "@src/utils/responseVersionGetters";
import { hasSafePay } from "@src/utils/getters";
import { useGoogleTranslate } from "@src/utils/translation";

const Confirmation: FC<PreviewProps> = ({ isPreview }) => {
  const { baseUrl, countryCode } = useUrlParams();
  const selectedPaymentType = useReactiveVar(SelectedPaymentType);
  const responseVersionHash = useReactiveVar(ResponseVersionHash);
  const responseAlreadySubmitted = useReactiveVar(ResponseAlreadySubmitted);
  const isPayNow = useReactiveVar(IsPayNow);

  const formInfo = useFormInfo();
  const responseVersionQuery = useCachedResponseVersion(responseVersionHash);
  const { t, i18n } = useTranslation();
  const googleTranslate = useGoogleTranslate();

  if (formInfo.loading || responseVersionQuery.loading) return <LoadingModal />;
  if (formInfo.error) return <ErrorComponent error={formInfo.error} />;
  if (responseVersionQuery.error)
    return <ErrorComponent error={responseVersionQuery.error ?? ""} />;

  const form = formInfo.form;
  const responseVersion = responseVersionQuery.responseVersion;
  const showFeedback = form && responseVersion && (form?.document?.showContactForm ?? false);
  const totalPayment = responseVersion?.total ?? 0;
  const manualPaymentText = form?.manualPaymentText
    ? googleTranslate(form.manualPaymentText)
    : t("pages.tickets.cashCheque");
  const allowOnlinePaymentsOnly = form?.allowOnlinePaymentsOnly ?? false;
  const orgHasSafePayEnabled = hasSafePay(form);
  const currencyCode = form?.document?.organization?.currencyCode;

  const paymentMethods = getPaymentMethods(manualPaymentText).filter(
    (x) =>
      //Remove offline payment options if isOnlinePaymentOnly is true or it's in PayNow mode
      ((isPayNow && x.isOnline) ||
        (!isPayNow && (!allowOnlinePaymentsOnly || (allowOnlinePaymentsOnly && x.isOnline)))) &&
      //Include safepay options if enabled
      (!x.isSafePay || (orgHasSafePayEnabled && x.isSafePay))
  );

  const selectedPaymentMethod = paymentMethods.find((pM) => pM.type === selectedPaymentType);

  const orgName = googleTranslate(form?.document.organization.name || "");
  const organizerText = showFeedback
    ? googleTranslate(form?.document.organization.roleLabels?.organizer || "")
    : "";

  if (!baseUrl || !responseVersion)
    return <Navigate to={`${window.location.pathname.replace(ConfirmationRoute, "")}`} />;

  return (
    <>
      <PageWrapper>
        <PageHeader isPreview={isPreview} hideNavigation={true} />
        <ContentWrapper>
          <Flex flexDirection="column" justifyContent="center" alignItems="center">
            {isPayNow ? (
              <StyledLabel>{t("pages.confirmation.paymentMade")}</StyledLabel>
            ) : !responseAlreadySubmitted ? (
              <>
                <StyledHeader style={{ fontWeight: 400, fontSize: "36px" }}>
                  {t("messages.youreAllSet")}
                </StyledHeader>
                <Flex flexDirection="column" alignItems="center" gap={15}>
                  <StyledLabel style={{ color: "#696974" }}>
                    {t("pages.confirmation.responseRecorded")}
                  </StyledLabel>
                  <StyledLabel style={{ color: "#696974" }}>
                    {t("pages.confirmation.confirmationMessage")}
                  </StyledLabel>
                  {form.hasApprovers ? (
                    <StyledLabel style={{ textAlign: "center", color: "#696974" }}>
                      <Trans i18nKey={"messages.approvalReminder"}>
                        {t("messages.approvalReminder")}
                      </Trans>
                    </StyledLabel>
                  ) : null}
                  {selectedPaymentMethod &&
                  selectedPaymentMethod.type === PaymentMethodType.MANUAL &&
                  totalPayment > 0 ? (
                    <StyledLabel style={{ color: "#696974" }}>
                      <Trans
                        i18nKey={"messages.manualPaymentReminder"}
                        components={{
                          strong: <strong />,
                        }}
                        values={{
                          method: selectedPaymentMethod.label,
                          organization: orgName,
                          amount: currencyFormatter(i18n.resolvedLanguage, currencyCode).format(
                            totalPayment
                          ),
                        }}
                      />
                    </StyledLabel>
                  ) : null}
                </Flex>
                {!isAuthenticatedResponse(responseVersion) && !isRostered(responseVersion) ? (
                  <Flex style={{ marginTop: "20px", marginBottom: "50px" }}>
                    <Button primary onClick={() => window.location.reload()}>
                      {t("labels.startNewResponse")}
                    </Button>
                  </Flex>
                ) : window.opener ? (
                  <Flex style={{ marginTop: "20px", marginBottom: "50px" }}>
                    <Button primary onClick={() => window.close()}>
                      {t("labels.finishAndClosePage")}
                    </Button>
                  </Flex>
                ) : null}

                {form?.parentMessage ? <Froala text={googleTranslate(form.parentMessage)} /> : null}
                {showFeedback && (
                  <>
                    <StyledDivider />
                    <Feedback
                      documentVersionId={form.id}
                      responseVersionId={responseVersion.responseVersionId}
                      countryCode={countryCode}
                      organizerText={organizerText}
                      isGeneralSignup={form.isGeneralSignup}
                      isPreview={isPreview}
                      signeeEmailAddress={responseVersion.signeeEmail || ""}
                      signeeFirstName={responseVersion.signeeFirstName || ""}
                      signeeLastName={responseVersion.signeeLastName || ""}
                    />
                    <StyledDivider />
                  </>
                )}
              </>
            ) : (
              <StyledLabel>{t("pages.confirmation.alreadySignedMessage")}</StyledLabel>
            )}
          </Flex>
        </ContentWrapper>
        <Push />
      </PageWrapper>
      <PageFooter />
    </>
  );
};

export { Confirmation };
