import omit from "lodash/omit";
import React, { ChangeEventHandler, FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { FieldError } from "@src/components/atoms/FieldError";
import { Icon } from "@src/components/atoms/Icon";
import { Flex } from "@src/components/layout/Page";
import {
  ClearIconSpacer,
  FieldWrapper,
  StyledLabel,
  StyledRadioButton,
  StyledRadioButtonLabel,
  StyledRequiredLabel,
} from "@src/components/styles";
import { FALSY_STRING, TRUTHY_STRING } from "@src/constants/validation";
import { AnswerInput, Field } from "@src/types";
import { ConditionalValue } from "@src/utils/formValuesTypes";
import { getYesOrNoFields } from "@src/utils/getters";
import { FieldSelector } from "../FieldSelector";
import { getFormInitialValues } from "@src/utils/formValues";
import { Label } from "@src/components/atoms/Label";
import { deleteNestedAnswers } from "@src/utils/answers";

type YesNoConditionalProps = {
  id: string;
  label: string;
  value: ConditionalValue;
  isRequired: boolean;
  fields: Field[];
  uploadUrl?: string;
  viewFileUrl?: string;
  error?: Record<string, string> | string;
  setValue: (value: ConditionalValue) => void;
  saveAnswer?: (answer: AnswerInput) => Promise<string>;
  deleteAnswer?: (answer: AnswerInput) => Promise<unknown>;
};

const YesNoConditionalField: FunctionComponent<YesNoConditionalProps> = ({
  id,
  label,
  value,
  isRequired,
  fields,
  uploadUrl,
  viewFileUrl,
  setValue,
  saveAnswer,
  deleteAnswer,
}: YesNoConditionalProps) => {
  const { t } = useTranslation();
  const [answerId, setAnswerId] = useState<string>(value.answer.answerId ?? "");

  const [yesFields, noFields] = getYesOrNoFields(fields);

  const yesChecked = value && !!value.answer && value.answer.value === TRUTHY_STRING;
  const noChecked = value && !!value.answer && value.answer.value === FALSY_STRING;

  // Once we get an answerId, add it to the answer.
  // This id will be reused whenever a new answer is created, so this should run only once
  // after render.
  useEffect(() => {
    if (answerId) {
      setValue({ ...value, answer: { ...value.answer, answerId } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerId]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = async (e) => {
    const newAnswer: AnswerInput = omit(
      {
        ...value.answer,
        value: e.target.value,
      },
      "__typename"
    );

    setValue({ ...value, answer: newAnswer });

    const newAnswerId = saveAnswer ? await saveAnswer(newAnswer) : "";

    newAnswerId !== answerId && setAnswerId(newAnswerId);
  };

  const handleClear = () => {
    const allFields = [...(yesFields ?? []), ...(noFields ?? [])];
    const clearedFields = getFormInitialValues({ fields: allFields });

    deleteNestedAnswers({ clearedFields, value, deleteAnswer });

    const newAnswer = {
      ...value.answer,
      value: "",
    };

    setValue({
      ...clearedFields,
      answer: newAnswer,
    });

    saveAnswer && saveAnswer(newAnswer);
  };

  return (
    <FieldWrapper id={id}>
      {isRequired ? <StyledRequiredLabel /> : null}
      <Label>{`${label} `}</Label>
      {value && value.answer && value.answer.value ? (
        <ClearIconSpacer>
          <Icon style="solid" icon="trash" size="12px" color="#bcbcbc" onClick={handleClear} />
        </ClearIconSpacer>
      ) : null}

      <Flex>
        <StyledLabel style={{ marginRight: "15px" }}>
          <StyledRadioButton
            value={TRUTHY_STRING}
            onChange={handleChange}
            checked={yesChecked}
            data-testid={id + "-YES"}
          />
          <StyledRadioButtonLabel selected={yesChecked}>{t("labels.yes")}</StyledRadioButtonLabel>
        </StyledLabel>

        <StyledLabel>
          <StyledRadioButton
            value={FALSY_STRING}
            onChange={handleChange}
            checked={noChecked}
            name={id}
            data-testid={id + "-NO"}
          />
          <StyledRadioButtonLabel selected={noChecked}>{t("labels.no")}</StyledRadioButtonLabel>
        </StyledLabel>
      </Flex>

      {yesChecked && yesFields ? (
        <div>
          {yesFields.map((field) => (
            <FieldSelector
              key={field.id}
              field={field}
              containerQuestionId={id}
              uploadUrl={uploadUrl}
              viewFileUrl={viewFileUrl}
              saveAnswer={saveAnswer}
              deleteAnswer={deleteAnswer}
            />
          ))}
        </div>
      ) : null}

      {noChecked && noFields ? (
        <div>
          {noFields.map((field) => (
            <FieldSelector
              key={field.id}
              field={field}
              containerQuestionId={id}
              uploadUrl={uploadUrl}
              viewFileUrl={viewFileUrl}
              saveAnswer={saveAnswer}
              deleteAnswer={deleteAnswer}
            />
          ))}
        </div>
      ) : null}
      <FieldError id={id} name={id} />
    </FieldWrapper>
  );
};

export { YesNoConditionalField };
