import React, { FunctionComponent } from "react";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";

import {
  StyledLabel,
  StyledOption,
  StyledRequiredLabel,
  StyledSelect,
  StyledTextArea,
  StyledTextInput,
} from "@src/components/styles";
import { useSigneeInfoValidation } from "./validation";
import { Flex } from "@src/components/layout/Page";
import { Button } from "@src/components/atoms/Button";
import { SigneeInfoValues } from "@src/localVariables";
import { useBreakPoints, useUrlParams } from "@src/customHooks";
import { FieldError } from "@src/components/atoms/FieldError";
import { UploadField } from "@src/components/fields";
import { getManualAttachmentUploadUrl, getViewFileUrl } from "@src/utils/urls";
import { useFormInfo } from "@src/customHooks/useFormInfo";
import { ResponseVersion, RoleLabels, Tag } from "@src/types";
import { Icon } from "@src/components/atoms/Icon";
import ReactTooltip from "react-tooltip";
import { isManual, isProcess, isRostered, isStaff } from "@src/utils/responseVersionGetters";
import { useGoogleTranslate } from "@src/utils/translation";
import { TranslateFormErrors } from "@src/customHooks/useTranslateFormErrors";

type SigneeInfoProps = {
  initialValues: SigneeInfoValues;
  orgRoleLabels: RoleLabels | null;
  responseVersion: ResponseVersion | null;
  documentId: string;
  eligibleTags: Tag[];
  isGeneralSignup: boolean;
  onSubmit: (values: SigneeInfoValues, helpers: FormikHelpers<SigneeInfoValues>) => void;
};

const SigneeInfoForm: FunctionComponent<SigneeInfoProps> = ({
  initialValues,
  orgRoleLabels,
  responseVersion,
  documentId,
  eligibleTags,
  isGeneralSignup,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const screenSize = useBreakPoints();
  const signeeInfoValidation = useSigneeInfoValidation(eligibleTags?.length > 1);
  const { countryCode } = useUrlParams();
  const { form } = useFormInfo();
  const manualAttachmentUploadUrl = getManualAttachmentUploadUrl(
    countryCode,
    documentId,
    responseVersion?.responseVersionId ?? ""
  );
  const viewFileUrl = getViewFileUrl(countryCode, documentId);
  const googleTranslate = useGoogleTranslate();

  const guardianRoleLabel = googleTranslate(orgRoleLabels?.guardian || "");

  const signeeFirstNameLabel = t(
    isGeneralSignup ? "placeholders.anyoneFirstName" : "placeholders.guardianFirstName",
    {
      guardian: guardianRoleLabel,
    }
  );

  const signeeFirstNamePlaceholder = t(
    isGeneralSignup ? "placeholders.anyoneFirstName" : "placeholders.guardianFirstName",
    {
      guardian: guardianRoleLabel,
    }
  );

  const signeeFirstNameAutoPopulatedMessage = t("pages.signeeInfo.firstNameIsPopulated");

  const signeeLastNameLabel = t(
    isGeneralSignup ? "placeholders.anyoneLastName" : "placeholders.guardianLastName",
    {
      guardian: guardianRoleLabel,
    }
  );

  const signeeLastNamePlaceholder = t(
    isGeneralSignup ? "placeholders.anyoneLastName" : "placeholders.guardianLastName",
    {
      guardian: guardianRoleLabel,
    }
  );

  const signeeLastNameAutoPopulatedMessage = t("pages.signeeInfo.lastNameIsPopulated");

  const emailLabel = t(
    isGeneralSignup ? "placeholders.anyoneEmailAddress" : "placeholders.guardianEmailAddress",
    {
      guardian: guardianRoleLabel,
    }
  );

  const emailPlaceholder = t(
    isGeneralSignup ? "placeholders.anyoneEmailAddress" : "placeholders.guardianEmailAddress",
    {
      guardian: guardianRoleLabel,
    }
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={signeeInfoValidation}
    >
      {({ isSubmitting, handleSubmit, isValid }) => (
        <TranslateFormErrors>
          <Form
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleSubmit();
              }
            }}
          >
            <Flex
              flexDirection="column"
              justifyContent="space-between"
              style={{ minHeight: "230px" }}
            >
              <Flex justifyContent="space-between">
                <Flex flexDirection="column" style={{ width: "48%", position: "relative" }}>
                  <StyledRequiredLabel />
                  <StyledLabel>{signeeFirstNameLabel}</StyledLabel>
                  <Field type="text" id="firstName" name="firstName">
                    {({ field, meta }: FieldProps) => (
                      <StyledTextInput
                        data-testid={"signeeFirstName"}
                        placeholder={signeeFirstNamePlaceholder}
                        hasError={!!meta.error && meta.touched}
                        {...field}
                        disabled={isStaff(responseVersion) || isProcess(responseVersion)}
                        title={
                          isStaff(responseVersion) || isProcess(responseVersion)
                            ? signeeFirstNameAutoPopulatedMessage
                            : ""
                        }
                      ></StyledTextInput>
                    )}
                  </Field>
                  <FieldError id="firstName" name="firstName" />
                </Flex>
                <Flex flexDirection="column" style={{ width: "48%", position: "relative" }}>
                  <StyledRequiredLabel />
                  <StyledLabel>{signeeLastNameLabel}</StyledLabel>
                  <Field type="text" id="lastName" name="lastName">
                    {({ field, meta }: FieldProps) => (
                      <StyledTextInput
                        data-testid={"signeeLastName"}
                        placeholder={signeeLastNamePlaceholder}
                        hasError={!!meta.error && meta.touched}
                        {...field}
                        disabled={isStaff(responseVersion) || isProcess(responseVersion)}
                        title={
                          isStaff(responseVersion) || isProcess(responseVersion)
                            ? signeeLastNameAutoPopulatedMessage
                            : ""
                        }
                      ></StyledTextInput>
                    )}
                  </Field>
                  <FieldError id="lastName" name="lastName" />
                </Flex>
              </Flex>
              {isManual(responseVersion) && form.isGeneralSignup ? (
                <>
                  <Flex>
                    <Field id={"guardian-manual-attachment"} name={"guardian-manual-attachment"}>
                      {({ field, form }: FieldProps) => (
                        <UploadField
                          id="manual-upload"
                          fieldId="manual-upload"
                          questionId="manual-upload"
                          label={t("labels.manualAttachment")}
                          answer={{
                            fieldId: "manual-upload",
                            answerId: "manual-upload",
                            questionId: "manual-upload",
                            value: field.value,
                            fileType: responseVersion?.signeeAttachmentFileType,
                          }}
                          isManualAttachment={true}
                          isRequired={false}
                          uploadUrl={manualAttachmentUploadUrl}
                          viewFileUrl={viewFileUrl}
                          setValue={(value) => form.setFieldValue("attachmentId", value)}
                        />
                      )}
                    </Field>
                  </Flex>

                  <Flex>
                    <StyledLabel>{t("labels.manualNotes")}</StyledLabel>
                  </Flex>
                  <Flex style={{ maxWidth: "600px", marginBottom: "25px" }}>
                    <Field type="textarea" id={"notes"} name={"notes"}>
                      {({ field, meta }: FieldProps) => (
                        <StyledTextArea
                          hasError={!!meta.error && meta.touched}
                          {...field}
                        ></StyledTextArea>
                      )}
                    </Field>
                  </Flex>
                </>
              ) : null}
              <Flex flexDirection="column" style={{ height: "115px", position: "relative" }}>
                <StyledRequiredLabel />
                <Flex gap={5}>
                  <StyledLabel>{emailLabel}</StyledLabel>
                  <div data-tip data-for="email-collection-info">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://permissionclick.com/privacy-policy/"
                    >
                      <Icon style="solid" icon="circle-question" color="#0283ff" />
                    </a>
                  </div>
                  <ReactTooltip html id="email-collection-info" effect="solid">
                    {t("messages.emailCollectionMessage")}
                  </ReactTooltip>
                </Flex>
                <Field type="email" id="email" name="email">
                  {({ field, meta }: FieldProps) => (
                    <StyledTextInput
                      data-testid={"signeeEmail"}
                      placeholder={emailPlaceholder}
                      hasError={!!meta.error && meta.touched}
                      {...field}
                      disabled={
                        isRostered(responseVersion) ||
                        isStaff(responseVersion) ||
                        isProcess(responseVersion)
                      }
                      title={
                        isRostered(responseVersion)
                          ? t("messages.emailNotModifiable")
                          : isStaff(responseVersion) || isProcess(responseVersion)
                          ? t("messages.emailIsPopulated")
                          : ""
                      }
                    ></StyledTextInput>
                  )}
                </Field>
                <FieldError id="email" name="email" />
              </Flex>
              {eligibleTags && eligibleTags.length > 1 ? (
                <Flex flexDirection="column" style={{ position: "relative" }}>
                  <StyledRequiredLabel />
                  <StyledLabel>{t("messages.pleaseSelectATag")}</StyledLabel>
                  <Field type="select" id="tagId" name="tagId">
                    {({ field, meta }: FieldProps) => (
                      <StyledSelect hasError={!!meta.error && meta.touched} {...field}>
                        <StyledOption value="">{t("labels.selectATag")}</StyledOption>
                        {eligibleTags.map((x) => (
                          <StyledOption key={x.id} value={x.id}>
                            {x.displayName}
                          </StyledOption>
                        ))}
                      </StyledSelect>
                    )}
                  </Field>
                </Flex>
              ) : null}
            </Flex>
            <div style={{ textAlign: "end", marginTop: "50px" }}>
              <Button
                data-testid={"signeeNextButton"}
                screenSize={screenSize}
                type="submit"
                primary
                disabled={isSubmitting || !isValid}
              >
                {t("labels.nextPage")}
              </Button>
            </div>
          </Form>
        </TranslateFormErrors>
      )}
    </Formik>
  );
};

export { SigneeInfoForm };
