import { ApolloError } from "@apollo/client";
import { useEligibleTagsQuery } from "@src/types";
import { EligibleTagsQuery, Tag } from "@src/types";
import { useUrlParams } from "./useUrlParams";

export type EligibleTagsInfo = {
    eligibleTags: Tag[];
    loading: boolean;
    error: ApolloError | undefined;
  };

  export const useCachedEligibleTags = (documentId: string, organizationId: string, email: string): EligibleTagsInfo => {

    const { countryCode } = useUrlParams();

    const query = useEligibleTagsQuery({
      variables: { documentId, organizationId, email, countryCode },
      fetchPolicy: "cache-first",
      skip: !documentId || !organizationId || !email || !countryCode
    });

    const tags = query?.data?.EligibleTags;

    return {
      eligibleTags: tags as NonNullable<EligibleTagsQuery["EligibleTags"]> as Tag[],
      loading: query.loading,
      error: query.error,
    };
  };