import React, { FC } from "react";
import { StyledIcon } from "./style";

export type faIconStyles = "solid" | "regular" | "light" | "thin" | "duotone";

export type IconProps = {
  style: faIconStyles;
  icon: string;
  color?: string;
  size?: string;
  clickable?: boolean;
  onClick?: () => void;
  testId?: string;
  overrideStyles?: React.CSSProperties;
};

const Icon: FC<IconProps> = ({
  style,
  icon,
  color,
  size,
  onClick,
  testId,
  overrideStyles,
  ...rest
}: IconProps) => {
  return (
    <StyledIcon
      className={`fa-${style} fa-${icon}`}
      data-testid={testId}
      color={color}
      size={size}
      onClick={onClick}
      clickable={!!onClick}
      style={overrideStyles}
      {...rest}
    />
  );
};

export { Icon };
