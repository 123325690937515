import { useReactiveVar } from "@apollo/client";
import { LoadingModal } from "@src/components/molecules/loadingModal/LoadingModal";
import { useCachedResponseVersion } from "@src/customHooks/useResponseVersion";
import { ResponseVersionHash, UrlParams } from "@src/localVariables";
import { AccessDataRoute } from "@src/Routes";
import React from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router-dom";
import { ErrorComponent } from "../Error/Error";
import { DefaultCountryCode } from "@src/utils/urls";

const RosterLanding: FC = () => {
  const { t } = useTranslation();
  const { hashedId } = useParams();
  const { visitedPages } = useReactiveVar(UrlParams);

  // rostered responses include responses created via the Staff Form Library
  const query = useCachedResponseVersion(hashedId || "");

  if (query.loading) return <LoadingModal message={t("messages.loadingYourExperience")} />;
  if (query.error) return <ErrorComponent error={query.error} />;
  const rosteredResponse = query.responseVersion;

  if (rosteredResponse) {
    ResponseVersionHash(rosteredResponse.hash);
    UrlParams({
      baseUrl: `/r/${hashedId}`,
      responseHashedId: hashedId,
      documentHashedId: rosteredResponse.documentHashId,
      countryCode: hashedId?.substring(0, 2) ?? DefaultCountryCode,
      visitedPages,
    });

    return <Navigate to={`/r/${hashedId}/${AccessDataRoute}`} />;
  }

  return <ErrorComponent error="Response not found." />;
};

export { RosterLanding };
