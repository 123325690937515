import styled from "styled-components";

import { StyledLabel } from "@src/components/styles";

export const SubtotalLabel = styled(StyledLabel)`
  color: #3260d3;
  font-size: 20px;
  line-height: 35px;
  font-weight: 400;
`;

export const StyledQuantity = styled.p`
  margin: 0;
  border-radius: 25px;
  background-color: #0283ff;
  width: fit-content;
  height: fit-content;
  min-width: 70px;
  padding: 2px 7px;
  color: white;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  margin: 5px 0px;
  margin-left: 10px;
`;
