// eslint-disable-next-line @typescript-eslint/triple-slash-reference
///<reference path="../../../../node_modules/react-froala-wysiwyg/lib/index.d.ts" />

import React from "react";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import { FroalaContainer } from "./styles";

export type FroalaProps = {
  isFieldLabel?: boolean;
  text?: string | null;
};

const Froala: React.FC<FroalaProps> = ({ text, isFieldLabel }) => {
  return text ? (
    isFieldLabel ? (
      <FroalaEditorView model={text} />
    ) : (
      <FroalaContainer>
        <FroalaEditorView model={text} />
      </FroalaContainer>
    )
  ) : (
    <></>
  );
};

export { Froala };
