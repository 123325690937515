import { useMediaQuery } from "react-responsive";

export type Breakpoints = 'desktop' | "smallScreen";

const useBreakPoints = (): Breakpoints => {
    const isSmallScreen = useMediaQuery({ query: "(max-width: 1224px)" });

    return isSmallScreen ? "smallScreen" : "desktop";
}

export {useBreakPoints}