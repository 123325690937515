import { useApolloClient, useReactiveVar } from "@apollo/client";
import React, { FC, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { ContentWrapper, PageWrapper } from "@src/components/layout";
import { Push } from "@src/components/layout/Page";
import { PageFooter } from "@src/components/molecules/pageFooter";
import { PageHeader } from "@src/components/molecules/pageHeader";
import { StyledSectionHeader } from "@src/components/styles";
import { useBreakPoints, useUrlParams } from "@src/customHooks";
import { useFormInfo } from "@src/customHooks/useFormInfo";
import { AccessDataAnswers, RequestError, ResponseVersionHash } from "@src/localVariables";
import { editAttendeesMutationUpdater } from "@src/mutations/updaterFunctions";
import {
  AttendeeFormRoute,
  AttendeeInformationRoute,
  PreviewProps,
  ReviewRoute,
  SigneeFormRoute,
} from "@src/Routes";
import { AttendeeInput, useEditAttendeesMutation } from "@src/types";
import { getFormAttendees } from "@src/utils/getters";
import { getPreviewAttendees } from "@src/utils/preview";
import { scrollToTop } from "@src/utils/scrollToTop";
import { StudentInfoForm } from "./form";
import { useCachedResponseVersion } from "@src/customHooks/useResponseVersion";
import { LoadingModal } from "@src/components/molecules/loadingModal/LoadingModal";
import { ErrorComponent } from "../Error";
import { attendeesAreUpdated, isRostered } from "@src/utils/responseVersionGetters";
import { hasAttendeeQuestions, hasSigneeQuestions, hasTickets } from "@src/utils/formGetters";
import { useTranslation } from "react-i18next";
import { useGoogleTranslate } from "@src/utils/translation";
import { Icon } from "@src/components/atoms/Icon";
import ReactTooltip from "react-tooltip";

const StudentInfo: FC<PreviewProps> = ({ isPreview }) => {
  const navigate = useNavigate();
  const { baseUrl, countryCode } = useUrlParams();
  const screenSize = useBreakPoints();
  const responseVersionHash = useReactiveVar(ResponseVersionHash);
  const accessData = useReactiveVar(AccessDataAnswers) || [];
  const client = useApolloClient();
  const { t } = useTranslation();
  const googleTranslate = useGoogleTranslate();

  useEffect(() => {
    scrollToTop();
  }, []);

  const { form } = useFormInfo();
  const responseVersionQuery = useCachedResponseVersion(responseVersionHash);
  const [editAttendees] = useEditAttendeesMutation({
    update: editAttendeesMutationUpdater(responseVersionHash),
  });

  if (responseVersionQuery.loading) return <LoadingModal />;
  if (responseVersionQuery.error)
    return <ErrorComponent error={responseVersionQuery.error ?? ""} />;

  const responseVersion = responseVersionQuery.responseVersion;
  const attendees = getFormAttendees(responseVersion);
  const orgRoleLabels = form?.document?.organization?.roleLabels ?? undefined;

  const attendeeLabel = orgRoleLabels?.attendee ? googleTranslate(orgRoleLabels?.attendee) : "";

  if (!baseUrl || !form || !responseVersion || responseVersion.isSigned)
    return <Navigate to={`${window.location.pathname.replace(AttendeeInformationRoute, "")}`} />;

  const submitForm = async (attendees: AttendeeInput[]) => {
    if (!isPreview) {
      if (attendeesAreUpdated(responseVersion, attendees)) {
        const result = await editAttendees({
          variables: {
            countryCode,
            documentId: form.documentId,
            responseVersionId: responseVersion.responseVersionId,
            attendees,
            accessData,
          },
        });
        if (result.errors && result.errors.length > 0) {
          RequestError(result.errors[0]);
          scrollToTop();
          return false;
        }
      }
    } else {
      // Update attendees in cache for preview
      if (editAttendeesMutationUpdater) {
        editAttendeesMutationUpdater(responseVersion.hash)(
          client.cache,
          { data: { EditAttendees: getPreviewAttendees(attendees, responseVersion.attendees) } },
          {
            variables: {
              countryCode,
              documentId: form.documentId,
              responseVersionId: responseVersion.responseVersionId,
              attendees,
              accessData,
            },
          }
        );
      }
    }

    if (hasSigneeQuestions(form)) navigate(`${baseUrl}/${SigneeFormRoute}`);
    else if (
      (hasAttendeeQuestions(form) || hasTickets(form)) &&
      attendees.some((attendee) => !form.requiresPermission || attendee.permitted)
    ) {
      navigate(`${baseUrl}/${AttendeeFormRoute}/0`);
    } else navigate(`${baseUrl}/${ReviewRoute}`);
  };

  const isRosteredResponse = isRostered(responseVersion);

  const disableAttendeeNameEditing =
    isRosteredResponse && (form?.document?.disableAttendeeNameEditing ?? false);

  const canAddMultipleAttendees = form.allowMultipleChildrenOnOneOrder && !isRosteredResponse;

  return (
    <>
      <PageWrapper>
        <PageHeader isPreview={isPreview} />
        <ContentWrapper screenSize={screenSize}>
          <StyledSectionHeader>
            {t("pages.studentInfo.enterAttendeeInfo", {
              attendee: attendeeLabel,
            })}{" "}
            {disableAttendeeNameEditing ? (
              <Icon
                style="solid"
                icon="circle-info"
                color="#0283ff"
                data-tip
                data-for="disableAttendeeNameEditingTooltip"
              />
            ) : null}
          </StyledSectionHeader>
          <StudentInfoForm
            disableAttendeeNameEditing={disableAttendeeNameEditing}
            handleSubmit={submitForm}
            attendees={attendees}
            attendeeLabel={attendeeLabel}
            documentId={form?.documentId}
            responseVersion={responseVersion}
            canAddMultipleAttendees={canAddMultipleAttendees}
          />
        </ContentWrapper>
        <Push />
        {disableAttendeeNameEditing ? (
          <ReactTooltip id="disableAttendeeNameEditingTooltip" effect="solid" place="right">
            {t("pages.studentInfo.disabledAttendeeNameEditing", {
              attendee: attendeeLabel,
            })}
          </ReactTooltip>
        ) : null}
      </PageWrapper>
      <PageFooter />
    </>
  );
};

export { StudentInfo };
