import { Flex } from "@src/components/layout/Page";
import styled from "styled-components";

const StyledErrorContainer = styled(Flex)`
  margin-top: 7px;
  margin-left: 3px;
`;

const StyledFieldError = styled.p`
  color: #c00109;
  margin: 0px 0px 24px 5px;
`;

export { StyledErrorContainer, StyledFieldError };
