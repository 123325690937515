import { DateFormats } from "@src/components/fields/types";
import { Flex } from "@src/components/layout/Page";
import { StyledLabel, StyledLine } from "@src/components/styles";
import { useGoogleTranslate } from "@src/utils/translation";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

type EventDatesProps = {
  startDate: string | null;
  endDate?: string | null;
  cutOffDate?: string | null;
};

const EventDates: FC<EventDatesProps> = ({ startDate, endDate, cutOffDate }) => {
  dayjs.extend(advancedFormat);
  const { t } = useTranslation();
  const googleTranslate = useGoogleTranslate();

  return startDate || endDate || cutOffDate ? (
    <Flex flexDirection="column">
      {startDate && endDate ? (
        <StyledLabel>
          {googleTranslate(
            `${dayjs(startDate).format(DateFormats.Long)} to ${dayjs(endDate).format(
              DateFormats.Long
            )}`
          )}
        </StyledLabel>
      ) : startDate || endDate ? (
        <StyledLabel>
          {googleTranslate(dayjs(startDate ?? endDate).format(DateFormats.Long))}
        </StyledLabel>
      ) : null}
      {cutOffDate && (
        <StyledLabel>
          {t("labels.respondBy")} {googleTranslate(dayjs(cutOffDate).format(DateFormats.Standard))}
        </StyledLabel>
      )}
      <StyledLine />
    </Flex>
  ) : null;
};

export { EventDates };
