import React, { FC, useState } from "react";
import { Flex } from "@src/components/layout/Page";
import styled from "styled-components";
import { StyledLabel } from "@src/components/styles";
import { StripeElementChangeEvent, Token, TokenResult } from "@stripe/stripe-js";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { stripePromise } from "@src/App";
import { LoadingModal } from "@src/components/molecules/loadingModal/LoadingModal";
import { useTranslation } from "react-i18next";
import { TranslationKey } from "@src/utils/validation";

const createOptions = {
  style: {
    base: {
      fontSize: "14px",
      color: "#788995",
      fontFamily: "Roboto, sans-serif",
      "::placeholder": {
        color: "#788995",
        fontWeight: 400,
      },
    },
    invalid: {
      color: "#f2594b",
    },
  },
};

const CreditCardLabel = styled(StyledLabel)`
  font-weight: 500;
  color: #d4d9dd;
`;

const CreditCardPaymentContainer = styled(Flex)`
  margin-top: 15px;
  flex-direction: column;
  width: 100%;

  .number {
    flex-grow: 1;
  }

  .card-details {
    display: flex;

    .stripe-input {
      margin-left: 10px;
    }

    .expiry {
      .StripeElement {
        width: 54px;
      }
    }

    .cvc {
      .StripeElement {
        width: 31px;
      }
    }
  }

  .StripeElement {
    border: solid 1px #dde3e8;
    border-radius: 3px;
    padding: 15px 20px;
  }
`;

type CreditCardPaymentComponentProps = {
  onTokenReceived: (token: Token | null) => void;
};

const CreditCardPaymentComponent: FC<CreditCardPaymentComponentProps> = ({ onTokenReceived }) => {
  const { t, i18n } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const [errorCode, setErrorCode] = useState<TranslationKey | null>(null);

  const onChange = async (status: StripeElementChangeEvent) => {
    if (elements == null || stripe == null) return;
    if (status.complete) {
      const card = elements.getElement(CardNumberElement);
      if (card) {
        const token = await stripe.createToken(card);
        onStripeTokenReceived(token);
      }
    } else {
      onTokenReceived(null);
    }
  };

  const onBlur = async () => {
    if (elements === null || stripe === null) return;
    const card = elements.getElement(CardNumberElement);
    if (card) {
      const token = await stripe.createToken(card);
      onStripeTokenReceived(token);
    }
  };

  const onStripeTokenReceived = (token: TokenResult) => {
    if (token?.error?.code) {
      const errorKey = `stripe.${token.error.code}`;

      if (i18n.exists(errorKey)) {
        setErrorCode(errorKey as TranslationKey);
      } else {
        setErrorCode("stripe.generic_error");
      }
    } else {
      setErrorCode(null);
      onTokenReceived(token.token ?? null);
    }
  };

  return (
    <CreditCardPaymentContainer>
      {stripe ? (
        <Flex className="credit-card">
          <div style={{ display: "contents" }}>
            <Flex data-testid="CARD-NUMBER" flexDirection="column" className="stripe-input number">
              <CreditCardLabel>{t("pages.tickets.cardNumber").toUpperCase()}</CreditCardLabel>
              <CardNumberElement
                options={createOptions}
                onChange={(status) => onChange(status)}
                onBlur={() => onBlur()}
              />
            </Flex>

            <Flex className="card-details">
              <Flex
                data-testid="EXPIRY-DATE"
                flexDirection="column"
                className="stripe-input expiry"
              >
                <CreditCardLabel>{t("pages.tickets.expiry").toUpperCase()}</CreditCardLabel>
                <CardExpiryElement
                  options={createOptions}
                  onChange={(status) => onChange(status)}
                  onBlur={() => onBlur()}
                />
              </Flex>

              <Flex data-testid="CVC" flexDirection="column" className="stripe-input cvc">
                <CreditCardLabel>CVC</CreditCardLabel>
                <CardCvcElement
                  onChange={(status) => onChange(status)}
                  onBlur={() => onBlur()}
                  options={createOptions}
                />
              </Flex>
            </Flex>
          </div>
        </Flex>
      ) : null}
      {errorCode && (
        <Flex justifyContent="center" style={{ marginTop: "10px" }}>
          <StyledLabel style={{ margin: 0, color: "red", fontWeight: "400" }}>
            {t(errorCode) as string}
          </StyledLabel>
        </Flex>
      )}
    </CreditCardPaymentContainer>
  );
};

export type OnlinePayProps = {
  onTokenReceived: (token: Token | null) => void;
};

const OnlinePay: FC<OnlinePayProps> = ({ onTokenReceived }) => {
  if (!stripePromise) return <LoadingModal />;

  return (
    <Elements stripe={stripePromise}>
      <CreditCardPaymentComponent onTokenReceived={onTokenReceived} />
    </Elements>
  );
};

export { OnlinePay };
