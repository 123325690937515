import { isNumber } from "mathjs";
import { NumberFormatValues } from "react-number-format";
import { NumericType } from "./types";

type NumericInputLogicArgs = {
  value?: string | null;
  disabled: boolean;
  min: number;
  max?: number;
  step: number;
  fixedDecimalPlaces?: number;
  type?: NumericType;
  onChange?: (value?: string | null) => void;
};

export type NumericInputLogic = {
  isDecrementDisabled: boolean;
  isIncrementDisabled: boolean;
  useDecimalScale: boolean;
  decimalScale: number | undefined;
  prefix?: string;
  isValid: (value: number) => boolean;
  onIncrease: () => void;
  onDecrease: () => void;
  handleChange: (numberFormatValue: NumberFormatValues) => void;
};

const getNumericInputLogic = ({
  value,
  disabled,
  min,
  max,
  step,
  type,
  fixedDecimalPlaces,
  onChange,
}: NumericInputLogicArgs): NumericInputLogic => {
  const isDecrementDisabled =
    disabled || (isNumber(min) && min >= 0 && Number(value) - Number(step) < min);

  const isIncrementDisabled =
    disabled || (isNumber(max) && max > 0 && Number(value) + Number(step) > max);

  const useDecimalScale = type === "currency" || type === "decimal";

  const decimalScale = fixedDecimalPlaces ?? (useDecimalScale ? 2 : 0);

  const prefix = type === "currency" ? "$ " : undefined;

  const isValid = (value: number) => {
    return (
      (!isNumber(max) || max < 0 || Number(value) <= max) &&
      (!isNumber(min) || min < 0 || Number(value) >= min)
    );
  };

  const onIncrease = () => {
    let newValue = Number(value) + Number(step);

    if (newValue < min) {
      newValue = min;
    }

    if (onChange) {
      onChange(`${newValue}`);
    }
  };

  const onDecrease = () => {
    const newValue = Number(value) - Number(step);
    if (onChange) {
      if (isValid(newValue)) {
      onChange(`${newValue}`);
      } else {
      onChange(`${value}`);
      }
    }
  };

  const handleChange = (numberFormatValue: NumberFormatValues) => {
    const { value } = numberFormatValue;

    if (onChange) onChange(`${value}` || "0");
  };

  return {
    isDecrementDisabled,
    isIncrementDisabled,
    useDecimalScale,
    decimalScale,
    prefix,
    isValid,
    onIncrease,
    onDecrease,
    handleChange,
  };
};

export { getNumericInputLogic };
