import gql from "graphql-tag";
import { OrganizationFragment } from "./fragments";

const ORGANIZATION_INFO_QUERY = gql`
  query OrganizationInfo($organizationId: ID!, $countryCode: String!) {
    Organization(organizationId: $organizationId, countryCode: $countryCode) {
      ...OrganizationFragment
    }
  }
  ${OrganizationFragment}
`;

const ORGANIZATION_HASH_QUERY = gql`
  query OrganizationByHash($hashedId: String!, $countryCode: String!) {
    OrganizationByHash(hash: $hashedId, countryCode: $countryCode) {
      ...OrganizationFragment
    }
  }
  ${OrganizationFragment}
`;

export { ORGANIZATION_INFO_QUERY, ORGANIZATION_HASH_QUERY };
