import React, { FC, useEffect, useState } from "react";
import { Flex } from "@src/components/layout/Page";
import { PaymentRequest, Token } from "@stripe/stripe-js";
import { Elements, PaymentRequestButtonElement, useStripe } from "@stripe/react-stripe-js";
import { stripePromise } from "@src/App";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export type WalletPayProps = {
  title: string;
  total: number;
  currency?: string | null;
  country?: string | null;
};

type WalletPayComponentProps = WalletPayProps & {
  onTokenReceived: (token: Token | null) => void;
};

export const isValidProps = (props: WalletPayProps): boolean => {
  return props.currency !== undefined && props.country != undefined;
};

export const WalletLabel = styled.span`
  margin-top: 25px;
`;

const WalletPaymentComponent: FC<WalletPayComponentProps> = ({
  total,
  title,
  currency,
  country,
  onTokenReceived,
}) => {
  const { t } = useTranslation();
  const stripe = useStripe();

  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null);
  const [walletUnavailable, setWalletUnavailable] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (stripe && country && currency) {
      const paymentRequest = stripe.paymentRequest({
        country: country.toUpperCase(),
        currency: currency.toLowerCase(),
        total: {
          label: title,
          amount: Math.round(total * 100), // Stripe expects the total in cents
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      paymentRequest.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(paymentRequest);
          setWalletUnavailable(false);
        } else {
          setWalletUnavailable(true);
        }
      });

      paymentRequest.on("token", async (event) => {
        onTokenReceived(event.token);
        event.complete("success");
        setSuccess(true);
      });
    }
    // For full documentation of the available paymentRequest options, see:
    // https://stripe.com/docs/stripe.js#the-payment-request-object
  }, [country, currency, onTokenReceived, stripe, title, total]);

  return (
    <Flex flex="0 0 100%">
      {success ? (
        <WalletLabel>{t("pages.tickets.youCanSignAndContinue")}</WalletLabel>
      ) : paymentRequest === null && !walletUnavailable ? (
        <WalletLabel>{t("pages.tickets.checkingYourWallet")}</WalletLabel>
      ) : walletUnavailable ? (
        <WalletLabel style={{ color: "red" }}>
          {t("pages.tickets.yourWalletIsNotReady")}
        </WalletLabel>
      ) : paymentRequest ? (
        <div style={{ width: "100%", margin: "20px 0" }}>
          <PaymentRequestButtonElement
            options={{ paymentRequest }}
            className="PaymentRequestButton"
          />
        </div>
      ) : null}
    </Flex>
  );
};

const WalletPay: FC<WalletPayComponentProps> = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <WalletPaymentComponent {...props} />
    </Elements>
  );
};

export { WalletPay };
