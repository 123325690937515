import { useFormInfo } from "@src/customHooks/useFormInfo";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useUrlParams } from "@src/customHooks";

import {
  TranslationLoaderParameters,
  pcTranslationLoader,
  googleTranslationLoader,
} from "./translationLoaders";
import { decodeHTMLEntities } from "./htmlCharcodes";

type TranslationDictionary = Record<string, Record<string, string>>;

/**
 * This hook returns a function that receives a string and calls the Google Translate
 * API to return a translated string. The returned function can be used on conditionals or rendering,
 * since it's not a hook itself.
 * @returns {function(text: string): string}
 */
export const useGoogleTranslate = () => {
  const { i18n } = useTranslation();
  const [translationDict, setTranslationDict] = useState<TranslationDictionary>({});
  const { form } = useFormInfo();
  const { countryCode } = useUrlParams();

  const { resolvedLanguage } = i18n;

  type TranslateParams = {
    text: string;
    languageISOCode: string;
    useLocalTranslations?: boolean;
    countryCode: string;
    preferredLanguage?: string;
  };

  async function translate({
    text,
    languageISOCode,
    countryCode,
    useLocalTranslations,
    preferredLanguage,
  }: TranslateParams) {
    if (!text || !languageISOCode || !process.env.GOOGLE_API_KEY) return text;

    const loaderParams: TranslationLoaderParameters = {
      text,
      target: languageISOCode,
      source: preferredLanguage || DefaultLanguage.code,
      countryCode,
    };

    return useLocalTranslations
      ? pcTranslationLoader.load(loaderParams)
      : googleTranslationLoader.load(loaderParams);
  }

  const addTranslation = (original: string, translated: string) =>
    setTranslationDict((dict) => ({
      ...dict,
      [resolvedLanguage]: {
        ...dict[resolvedLanguage],
        [original]: translated,
      },
    }));

  const executeTranslation = async (text: string, preferredLanguage?: string) => {
    const result = await translate({
      text,
      languageISOCode: i18n.resolvedLanguage,
      useLocalTranslations: form.useLocalTranslations,
      countryCode,
      preferredLanguage,
    });

    addTranslation(text, decodeHTMLEntities(result));
  };

  const translateFunction = (text: string, preferredLanguage?: string) => {
    const sourceLanguage = preferredLanguage || DefaultLanguage.code;
    const destinationLanguage = resolvedLanguage;

    if (sourceLanguage !== destinationLanguage) {
      const missingTranslation = typeof translationDict?.[resolvedLanguage]?.[text] === "undefined";

      if (!!text && missingTranslation) {
        //Adds a "fake" translation to the dictionary. This way the request won't fire again
        //for this text
        addTranslation(text, text);

        //Executes the actual translation
        executeTranslation(text, preferredLanguage);

        return "";
      }

      return translationDict?.[resolvedLanguage]?.[text] || "";
    } else {
      // Text is already in the default language.
      return text;
    }
  };

  return translateFunction;
};

export const getLanguageDisplayName = (languageISOCode: string) => {
  const nameGenerator = new Intl.DisplayNames(languageISOCode, { type: "language" });
  return nameGenerator.of(languageISOCode) || "";
};

export type Language = {
  code: string;
  display: string;
};

export const DefaultLanguage: Language = { display: "English", code: "en" };

export const Languages: Language[] = [
  { display: "Afrikaans", code: "af" },
  { display: "Albanian", code: "sq" },
  { display: "Amharic", code: "am" },
  { display: "Arabic", code: "ar" },
  { display: "Armenian", code: "hy" },
  { display: "Assamese", code: "as" },
  { display: "Aymara", code: "ay" },
  { display: "Azerbaijani", code: "az" },
  { display: "Bambara", code: "bm" },
  { display: "Basque", code: "eu" },
  { display: "Belarusian", code: "be" },
  { display: "Bengali", code: "bn" },
  { display: "Bhojpuri", code: "bho" },
  { display: "Bosnian", code: "bs" },
  { display: "Bulgarian", code: "bg" },
  { display: "Catalan", code: "ca" },
  { display: "Cebuano", code: "ceb" },
  { display: "Chinese (Simplified)", code: "zh-CN" },
  { display: "Chinese (Traditional)", code: "zh-TW" },
  { display: "Corsican", code: "co" },
  { display: "Croatian", code: "hr" },
  { display: "Czech", code: "cs" },
  { display: "Danish", code: "da" },
  { display: "Dhivehi", code: "dv" },
  { display: "Dogri", code: "doi" },
  { display: "Dutch", code: "nl" },
  { display: "English", code: "en" },
  { display: "Esperanto", code: "eo" },
  { display: "Estonian", code: "et" },
  { display: "Ewe", code: "ee" },
  { display: "Finnish", code: "fi" },
  { display: "French", code: "fr" },
  { display: "Frisian", code: "fy" },
  { display: "Galician", code: "gl" },
  { display: "Georgian", code: "ka" },
  { display: "German", code: "de" },
  { display: "Greek", code: "el" },
  { display: "Guarani", code: "gn" },
  { display: "Gujarati", code: "gu" },
  { display: "Haitian Creole", code: "ht" },
  { display: "Hausa", code: "ha" },
  { display: "Hawaiian", code: "haw" },
  { display: "Hebrew", code: "he" },
  { display: "Hindi", code: "hi" },
  { display: "Hmong", code: "hmn" },
  { display: "Hungarian", code: "hu" },
  { display: "Icelandic", code: "is" },
  { display: "Igbo", code: "ig" },
  { display: "Ilocano", code: "ilo" },
  { display: "Indonesian", code: "id" },
  { display: "Irish", code: "ga" },
  { display: "Italian", code: "it" },
  { display: "Japanese", code: "ja" },
  { display: "Javanese", code: "jv" },
  { display: "Kannada", code: "kn" },
  { display: "Kazakh", code: "kk" },
  { display: "Khmer", code: "km" },
  { display: "Kinyarwanda", code: "rw" },
  { display: "Konkani", code: "gom" },
  { display: "Korean", code: "ko" },
  { display: "Krio", code: "kri" },
  { display: "Kurdish", code: "ku" },
  { display: "Kurdish (Sorani)", code: "ckb" },
  { display: "Kyrgyz", code: "ky" },
  { display: "Lao", code: "lo" },
  { display: "Latin", code: "la" },
  { display: "Latvian", code: "lv" },
  { display: "Lingala", code: "ln" },
  { display: "Lithuanian", code: "lt" },
  { display: "Luganda", code: "lg" },
  { display: "Luxembourgish", code: "lb" },
  { display: "Macedonian", code: "mk" },
  { display: "Maithili", code: "mai" },
  { display: "Malagasy", code: "mg" },
  { display: "Malay", code: "ms" },
  { display: "Malayalam", code: "ml" },
  { display: "Maltese", code: "mt" },
  { display: "Maori", code: "mi" },
  { display: "Marathi", code: "mr" },
  { display: "Mizo", code: "lus" },
  { display: "Mongolian", code: "mn" },
  { display: "Myanmar (Burmese)", code: "my" },
  { display: "Nepali", code: "ne" },
  { display: "Norwegian", code: "no" },
  { display: "Nyanja (Chichewa)", code: "ny" },
  { display: "Odia (Oriya)", code: "or" },
  { display: "Oromo", code: "om" },
  { display: "Pashto", code: "ps" },
  { display: "Persian", code: "fa" },
  { display: "Polish", code: "pl" },
  { display: "Portuguese (Portugal, Brazil)", code: "pt" },
  { display: "Punjabi", code: "pa" },
  { display: "Quechua", code: "qu" },
  { display: "Romanian", code: "ro" },
  { display: "Russian", code: "ru" },
  { display: "Samoan", code: "sm" },
  { display: "Sanskrit", code: "sa" },
  { display: "Scots Gaelic", code: "gd" },
  { display: "Sepedi", code: "nso" },
  { display: "Serbian", code: "sr" },
  { display: "Sesotho", code: "st" },
  { display: "Shona", code: "sn" },
  { display: "Sindhi", code: "sd" },
  { display: "Sinhala (Sinhalese)", code: "si" },
  { display: "Slovak", code: "sk" },
  { display: "Slovenian", code: "sl" },
  { display: "Somali", code: "so" },
  { display: "Spanish, Castilian", code: "es" },
  { display: "Sundanese", code: "su" },
  { display: "Swahili", code: "sw" },
  { display: "Swedish", code: "sv" },
  { display: "Tagalog", code: "tl" },
  { display: "Tajik", code: "tg" },
  { display: "Tamil", code: "ta" },
  { display: "Tatar", code: "tt" },
  { display: "Telugu", code: "te" },
  { display: "Thai", code: "th" },
  { display: "Tigrinya", code: "ti" },
  { display: "Tsonga", code: "ts" },
  { display: "Turkish", code: "tr" },
  { display: "Turkmen", code: "tk" },
  { display: "Twi (Akan)", code: "ak" },
  { display: "Ukrainian", code: "uk" },
  { display: "Urdu", code: "ur" },
  { display: "Uyghur", code: "ug" },
  { display: "Uzbek", code: "uz" },
  { display: "Vietnamese", code: "vi" },
  { display: "Welsh", code: "cy" },
  { display: "Xhosa", code: "xh" },
  { display: "Yiddish", code: "yi" },
  { display: "Yoruba", code: "yo" },
  { display: "Zulu", code: "zu" }
]

