import { Breakpoints } from "@src/customHooks";
import styled from "styled-components";

type FlexProps = {
  flexDirection?: "row" | "row-reverse" | "column" | "column-reverse";
  flexWrap?: "nowrap" | "wrap" | "wrap-reverse";
  justifyContent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around"
    | "space-evenly"
    | "start"
    | "end"
    | "left"
    | "right";
  alignItems?: "stretch" | "flex-start" | "flex-end" | "center" | "baseline";
  alignContent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around"
    | "space-evenly"
    | "stretch"
    | "start"
    | "end"
    | "baseline";
  gap?: number;
  flex?: string;
};

const PageWrapper = styled.div`
  min-height: 100vh;
  background-color: #f1f2f4;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto -70px;
`;

const ContentWrapper = styled.div<{ screenSize?: Breakpoints }>`
  margin: 50px 15px 95px 15px;
  display: flex;
  width: ${(props) => (props.screenSize === "smallScreen" ? `90%` : `75%`)};
  max-width: 960px;
  min-height: 100%;
  flex-direction: column;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);
  padding: ${(props) =>
    props.screenSize === "smallScreen" ? `35px 50px 35px 50px` : `75px 50px 75px 50px`};
  position: relative;
`;

const Push = styled.div`
  height: 70px;
`;

const Flex = styled.div<FlexProps>`
  display: flex;
  flex-direction: ${(props) => props.flexDirection || "row"};
  flex-wrap: ${(props) => props.flexWrap || "nowrap"};
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "stretch"};
  align-content: ${(props) => props.alignContent || "flex-start"};
  gap: ${(props) => props.gap || "0"}px;
  ${(props) => (props.flex ? `flex: ${props.flex}` : "")};
`;

export { PageWrapper, ContentWrapper, Flex, Push };
