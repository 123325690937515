import { useReactiveVar } from "@apollo/client";
import { LoadingModal } from "@src/components/molecules/loadingModal/LoadingModal";
import { useCachedResponseVersion } from "@src/customHooks/useResponseVersion";
import { ResponseVersionHash, UrlParams } from "@src/localVariables";
import { SigneeInfoRoute, PreviewProps } from "@src/Routes";
import React from "react";
import { FC } from "react";
import { Navigate, useParams } from "react-router-dom";
import { ErrorComponent } from "../Error";
import { DefaultCountryCode } from "@src/utils/urls";

const PublicLanding: FC<PreviewProps> = ({ isPreview }) => {
  const { hashedId, countryCode, hashedOrgId } = useParams();
  const { visitedPages } = useReactiveVar(UrlParams);
  const baseUrl = `/${hashedId}/${countryCode ?? DefaultCountryCode}${
    hashedOrgId ? `/${hashedOrgId}` : ""
  }${isPreview ? "/preview" : ""}`;
  const responseVersionHash = useReactiveVar(ResponseVersionHash);
  const responseVersionQuery = useCachedResponseVersion(responseVersionHash);

  if (responseVersionQuery.loading) return <LoadingModal />;
  if (responseVersionQuery.error) return <ErrorComponent error={responseVersionQuery.error ?? ""} />;

  if (responseVersionQuery.responseVersion && responseVersionQuery.responseVersion.isSigned) {
    window.location.reload()
  }

  UrlParams({
    baseUrl,
    documentHashedId: hashedId,
    countryCode: countryCode ?? DefaultCountryCode,
    visitedPages,
    hashedOrgId
  });

  return <Navigate to={`${baseUrl}/${SigneeInfoRoute}`} />;
};

export { PublicLanding };
