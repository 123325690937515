import React, { FC } from "react";

import { CancelTicketAnswerResult } from "@src/mutations/mutationTypes";
import { Attendee, Field, FormInfoQuery, Ticket } from "@src/types";
import { CancelTicketArgs } from "@src/utils/purchasedTickets";
import { TicketMode } from "../TicketSection";
import { Flex } from "@src/components/layout/Page";
import { AttendeePreview } from "@src/components/molecules/preview/AttendeePreview";

type TicketsPreviewProps = {
  tickets: Ticket[];
  cart: Attendee[];
  fields?: Field[];
  mode: TicketMode;
  countryCode: string;
  form: NonNullable<FormInfoQuery["Form"]>;
  responseVersionId: string;
  onCancelTicket?: (args: CancelTicketArgs) => Promise<CancelTicketAnswerResult>;
};

const TicketsPreview: FC<TicketsPreviewProps> = ({
  tickets,
  cart,
  fields,
  mode,
  countryCode,
  form,
  responseVersionId,
  onCancelTicket,
}) => {
  const hasTicketsInCart = cart.some((attendee) => attendee?.tickets?.length > 0);
  return hasTicketsInCart ? (
    <Flex flexDirection="column" style={{ marginBottom: "10px" }}>
      {cart
        .filter((attendee) => attendee?.tickets?.length > 0)
        .map((attendee) => (
          <AttendeePreview
            key={`preview-${attendee.responseAttendeeId}`}
            tickets={tickets}
            attendee={attendee}
            attendees={cart}
            fields={fields}
            mode={mode}
            countryCode={countryCode}
            form={form}
            responseVersionId={responseVersionId}
            onCancelTicket={onCancelTicket}
          />
        ))}
    </Flex>
  ) : null;
};

export { TicketsPreview };
