import React, { FC } from "react";
import { PurchasedTicket, Ticket } from "@src/types";
import { StyledLabel } from "@src/components/styles";
import { Flex } from "@src/components/layout/Page";
import { CancelTicketArgs, currencyFormatter, TicketMode } from "@src/utils/purchasedTickets";
import { DisplayAnswers } from "@src/components/molecules/displayAnswers/DisplayAnswers";
import { TrashButton } from "../TicketSection/TrashButton";
import { StyledQuantity, SubtotalLabel } from "./styles";
import { useTranslation } from "react-i18next";
import { useBreakPoints } from "@src/customHooks";
import { TicketTypeCode } from "../TicketSection/TicketPrice";
import { useFormInfo } from "@src/customHooks/useFormInfo";
import { useGoogleTranslate } from "@src/utils/translation";

type PurchasedTicketProps = {
  ticket: Ticket;
  purchasedTicket: PurchasedTicket;
  responseAttendeeId: string;
  countryCode: string;
  documentId: string;
  mode: TicketMode;
  responseVersionId: string;
  onCancelTicket?: (args: CancelTicketArgs) => void;
};

const PurchasedTicketPreview: FC<PurchasedTicketProps> = ({
  ticket,
  purchasedTicket,
  responseAttendeeId,
  countryCode,
  documentId,
  mode,
  responseVersionId,
  onCancelTicket,
}) => {
  const { t, i18n } = useTranslation();
  const screenSize = useBreakPoints();
  const { form } = useFormInfo();
  const googleTranslate = useGoogleTranslate();
  const quantity = purchasedTicket.quantity ?? 0;
  const currencyCode = form?.document?.organization?.currencyCode;

  return (
    <Flex data-testid={ticket.id + "-TICKET"} flexDirection="column">
      <Flex alignItems="center" style={{ marginBottom: "5px" }}>
        <StyledLabel
          data-testid="TICKET-NAME"
          style={{
            fontSize:
              screenSize === "desktop" && mode !== TicketMode.CART_PREVIEW ? "18px" : "16px",
            margin: "0",
          }}
        >
          {googleTranslate(ticket.name || "")}
        </StyledLabel>
        {quantity > 0 ? (
          <StyledQuantity data-testid="QUANTITY">{`${t("pages.tickets.quantity")}: ${
            purchasedTicket.quantity ?? 0
          }`}</StyledQuantity>
        ) : null}

        {onCancelTicket ? (
          <TrashButton
            testId="DELETE-BUTTON"
            onClick={() =>
              onCancelTicket({
                ticketVersionId: purchasedTicket.ticketVersionId,
                responseAttendeeId,
                purchasedTicketIds: purchasedTicket.purchasedTicketIds,
              })
            }
          />
        ) : null}
      </Flex>
      {Number(ticket.typeCode) !== TicketTypeCode.Free ? (
        <SubtotalLabel style={{ lineHeight: "15px", fontSize: "14px" }}>
          {quantity > 1
            ? `${t("totals.subtotal")}: ${quantity} × ${currencyFormatter(
                i18n.resolvedLanguage,
                currencyCode
              ).format(purchasedTicket.price)} = ${currencyFormatter(
                i18n.resolvedLanguage,
                currencyCode
              ).format(quantity * purchasedTicket.price)}`
            : `${t("totals.subtotal")}: ${currencyFormatter(
                i18n.resolvedLanguage,
                currencyCode
              ).format(purchasedTicket.price)}`}
        </SubtotalLabel>
      ) : null}
      {mode != TicketMode.CART_PREVIEW ? (
        <DisplayAnswers
          fields={ticket.formQuestions || []}
          answers={purchasedTicket.answers}
          countryCode={countryCode}
          documentId={documentId}
          responseVersionId={responseVersionId}
        />
      ) : null}
    </Flex>
  );
};

export { PurchasedTicketPreview };
