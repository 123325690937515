import { ApolloCache, DefaultContext, MutationUpdaterFunction } from "@apollo/client";
import { RESPONSE_VERSION_QUERY } from "@src/queries/responseVersion";
import {
  AcceptTermsMutation,
  AcceptTermsMutationVariables,
  ResponseQuery,
  ResponseStatus,
} from "@src/types";

type AcceptTermsMutationUpdater = (
  responseHashedId: string
) => MutationUpdaterFunction<
  AcceptTermsMutation,
  AcceptTermsMutationVariables,
  DefaultContext,
  ApolloCache<unknown>
>;

// Signs the response version, used exclusively while in preview mode
const acceptTermsUpdater: AcceptTermsMutationUpdater =
  (responseHashedId) =>
  (cache, errors) => {
    if (!errors) {
      updateResponseQuery(cache, responseHashedId);
    }
  };

  const updateResponseQuery = (
    cache: ApolloCache<unknown>,
    responseHashedId: string,
  ) =>
    cache.updateQuery<ResponseQuery>(
      {
        query: RESPONSE_VERSION_QUERY,
        variables: {
          hash: responseHashedId
        },
        overwrite: true,
      },
      (cachedData) => {
        if (cachedData) {
        return {
            ...cachedData,
            Response: {
              ...cachedData.Response,
              isCheckedOut: true,
              isSigned: true,
              signedDate: new Date().toString(),
              status: ResponseStatus.Responded,
            }
          };
        }
      }
    );

export { acceptTermsUpdater };
