import React, { FC } from "react";
import { useTranslation, Trans } from "react-i18next";

import { Button } from "@src/components/atoms/Button";
import { Flex } from "@src/components/layout/Page";
import {
  StyledTermsMessage,
  StyledTermsLink,
  StyledLabel,
  StyledDivider,
  StyledHeader,
} from "@src/components/styles";
import { useFormInfo } from "@src/customHooks/useFormInfo";
import { useFormikContext } from "formik";
import { PaymentForm, PaymentFormKeys } from "../Review";
import styled from "styled-components";
import { PaymentMethodType } from "@src/utils/purchasedTickets";
import { useGoogleTranslate } from "@src/utils/translation";

const ErrorLabel = styled(StyledLabel)`
  ${({ theme }) => `
    color: ${theme.colors.values.red};
  `}
`;

const AcceptTermsAndSubmit: FC = () => {
  const { t } = useTranslation();
  const { values, errors, setFieldError, setFieldValue } = useFormikContext<PaymentForm>();
  const { form } = useFormInfo();
  const googleTranslate = useGoogleTranslate();

  const setPaymentFormValue = (fieldName: PaymentFormKeys, value: unknown) =>
    setFieldValue(fieldName, value, false);
  const setPaymentFormError = (fieldName: PaymentFormKeys, message: string | undefined) =>
    setFieldError(fieldName, message);

  const acceptTerms = () => {
    const isAccepted = !values.isTermsAccepted;
    setPaymentFormValue("isTermsAccepted", isAccepted);
    if (isAccepted) {
      setPaymentFormError("isTermsAccepted", undefined); // clear errors
    } else {
      setPaymentFormError("isTermsAccepted", t("pages.review.confirmError"));
    }
  };

  const isPaymentValid = (): boolean => {
    if (values.subtotal > 0) {
      if (values.selectedPaymentMethod) {
        if (
          (values.selectedPaymentMethod.type === PaymentMethodType.CREDITCARD ||
            values.selectedPaymentMethod.type === PaymentMethodType.WALLET) &&
          (values.stripeToken == null || !values.stripeToken.id)
        ) {
          return false;
        }
      } else {
        return false;
      }
    }
    return true;
  };

  const acceptOrgTerms = () =>
    setPaymentFormValue("isOrgTermsAccepted", !values.isOrgTermsAccepted);

  const orgDisclaimer = form.document.organization.disclaimer;

  const hasOrgTermsAndConditions = !!orgDisclaimer;

  const isSubmitButtonDisabled =
    !values.isTermsAccepted ||
    (hasOrgTermsAndConditions ? !values.isOrgTermsAccepted : false) ||
    !isPaymentValid();

  return (
    <Flex flexDirection="column">
      {hasOrgTermsAndConditions ? (
        <>
          <Flex flexDirection="column">
            <StyledHeader>
              {t("pages.review.termsAndConditionsHeader", {
                organization: form.document.organization.name,
              })}
            </StyledHeader>
            <StyledLabel style={{ fontWeight: "400", color: "#868686" }}>
              {orgDisclaimer ? googleTranslate(orgDisclaimer) : null}
            </StyledLabel>
            <Flex flexDirection="column" justifyContent="center" alignItems="center">
              <Flex justifyContent="center" style={{ marginTop: "25px", marginBottom: "5px" }}>
                <label onClick={() => acceptOrgTerms()} onKeyDown={() => acceptOrgTerms()}>
                  {values.isOrgTermsAccepted ? (
                    <i
                      className="fas fa-check-square checked"
                      style={{ fontSize: "24px", color: "#0283ff" }}
                      aria-label="I Agree to the Terms and Conditions, Checked"
                      tabIndex={0}
                    ></i>
                  ) : (
                    <i
                      className="far fa-square"
                      style={{ fontSize: "24px", color: "#0283ff" }}
                      aria-label="I Agree to the Terms and Conditions, Unchecked"
                      tabIndex={0}
                    ></i>
                  )}

                  <StyledLabel
                    style={{ fontSize: "18px", verticalAlign: "top", paddingLeft: "10px" }}
                  >
                    {t("pages.review.termsAndConditionsAgree", {
                      organization: form.document.organization.name,
                    })}
                  </StyledLabel>
                </label>
              </Flex>
              <StyledLabel style={{ fontWeight: "400", color: "#868686", fontSize: "12px" }}>
                {t("pages.review.termsAndConditionsNotice", {
                  organization: form.document.organization.name,
                })}
              </StyledLabel>
            </Flex>
          </Flex>
          <StyledDivider />
        </>
      ) : null}
      <Flex>
        <StyledTermsMessage>
          <Trans
            i18nKey="messages.termsOfService"
            components={{
              termsOfServiceLink: (
                <StyledTermsLink
                  href={"https://permissionclick.com/terms-of-service"}
                  target="_blank"
                  rel="noreferrer"
                />
              ),
              privacyPolicyLink: (
                <StyledTermsLink
                  href={"https://permissionclick.com/privacy-policy"}
                  target="_blank"
                  rel="noreferrer"
                />
              ),
            }}
          />
        </StyledTermsMessage>
      </Flex>
      <Flex justifyContent="center" style={{ margin: "25px 0px" }}>
        <label
          style={{ cursor: "pointer" }}
          onClick={() => acceptTerms()}
          onKeyDown={(e) => {
            if (e.key === " " || e.key === "Enter") {
              acceptTerms();
            }
          }}
        >
          {values.isTermsAccepted ? (
            <i
              className="fas fa-check-square checked"
              style={{ fontSize: "24px", color: "#0283ff" }}
              aria-label="I Agree, Checked"
              tabIndex={0}
              data-testid={"AGREE-CHECKED"}
            ></i>
          ) : (
            <i
              className="far fa-square"
              style={{ fontSize: "24px", color: "#0283ff" }}
              aria-label="I Agree, Unchecked"
              tabIndex={0}
              data-testid={"AGREE-UNCHECKED"}
            ></i>
          )}

          <StyledLabel
            style={{
              fontSize: "18px",
              verticalAlign: "top",
              paddingLeft: "10px",
              cursor: "pointer",
            }}
          >
            {t("pages.review.agree")}
          </StyledLabel>
        </label>
      </Flex>
      {errors.isTermsAccepted && (
        <Flex justifyContent="center">
          <ErrorLabel>{errors.isTermsAccepted}</ErrorLabel>
        </Flex>
      )}
      <Flex justifyContent="center">
        <Button
          data-testid={"SUBMIT-RESPONSE-BUTTON"}
          type="submit"
          primary
          disabled={isSubmitButtonDisabled}
        >
          {t("pages.review.submit")}
        </Button>
      </Flex>
    </Flex>
  );
};

export { AcceptTermsAndSubmit };
