import { useApolloClient, useReactiveVar } from "@apollo/client";
import React, { FC } from "react";

import { Flex } from "@src/components/layout/Page";
import { LoadingModal } from "@src/components/molecules/loadingModal/LoadingModal";
import { Totals } from "@src/components/molecules/totals";
import { StyledLine } from "@src/components/styles";
import { useUrlParams } from "@src/customHooks";
import { OrderId, RequestError } from "@src/localVariables";
import { CancelTicketAnswerResult, ReserveTicketAnswerResult } from "@src/mutations/mutationTypes";
import {
  cancelTicketMutationUpdater,
  reserveTicketMutationUpdater,
} from "@src/mutations/updaterFunctions/updateTickets";
import { ErrorComponent } from "@src/pages/Error";
import { RESPONSE_VERSIONS_QUERY } from "@src/queries/responseVersions";
import { PreviewProps } from "@src/Routes";
import {
  Attendee,
  DocumentVersion,
  ResponseVersion,
  useCancelTicketMutation,
  useReserveTicketMutation,
} from "@src/types";
import { getOrderInfo, getTickets } from "@src/utils/getters";
import { CancelTicketArgs, ReserveTicketArgs } from "@src/utils/purchasedTickets";
import { TicketsPreview } from "./Preview";
import { PreviousOrders } from "./PreviousOrders/PreviousOrders";
import { TicketMode } from "./TicketSection";
import { TicketsSection } from "./TicketsSection/TicketsSection";
import { useTicketInventory } from "@src/customHooks/useTicketInventory";
import { signeeInfo } from "@src/utils/responseVersionGetters";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

type TicketsProps = PreviewProps & {
  attendee: Attendee;
  attendeeIndex: number;
  form: DocumentVersion;
  responseVersion: ResponseVersion;
};

const Tickets: FC<TicketsProps> = ({
  attendee,
  attendeeIndex,
  isPreview,
  form,
  responseVersion,
}) => {
  const { t } = useTranslation();
  const { countryCode, markAsVisited } = useUrlParams();
  const orderId = useReactiveVar(OrderId) || "";
  const client = useApolloClient();

  const ticketInventoryQuery = useTicketInventory(form.tickets || [], form.documentId, isPreview);
  const tickets = getTickets(form);

  const responseVersionQueryVariables = {
    documentId: form.documentId,
    countryCode,
    email: signeeInfo(responseVersion).email,
  };

  const mutationOptions = {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: RESPONSE_VERSIONS_QUERY,
        variables: responseVersionQueryVariables,
      },
    ],
  };
  const [reserveTicket] = useReserveTicketMutation(mutationOptions);
  const [cancelTicket] = useCancelTicketMutation(mutationOptions);

  if (ticketInventoryQuery.loading) return <LoadingModal message={t("messages.loadingTickets")} />;
  if (ticketInventoryQuery.error) return <ErrorComponent error={ticketInventoryQuery.error} />;

  markAsVisited("attendee-form/0");

  const orderInfo = getOrderInfo(responseVersion, false);

  const onReserveTicket = async ({
    orderId,
    ticketVersionId,
    responseAttendeeId,
    answers,
    openValueAmount,
    quantity,
  }: ReserveTicketArgs): Promise<ReserveTicketAnswerResult> => {
    const result = await reserveTicket({
      variables: {
        countryCode,
        documentId: form.documentId,
        responseVersionId: responseVersion.responseVersionId,
        responseAttendeeId: responseAttendeeId,
        ticketVersionId: ticketVersionId,
        reserveTicketInput: {
          orderId,
          answers: answers,
          openValueAmount: openValueAmount,
          quantity: parseInt(quantity.toString()),
        },
      },
    });

    if (result.errors && result.errors.length > 0) {
      RequestError(result.errors[0]);
    }

    return result;
  };

  const onCancelTicket = async ({
    ticketVersionId,
    responseAttendeeId,
    purchasedTicketIds,
  }: CancelTicketArgs): Promise<CancelTicketAnswerResult> => {
    const result = await cancelTicket({
      variables: {
        countryCode,
        documentId: form.documentId,
        responseVersionId: responseVersion.responseVersionId,
        responseAttendeeId: responseAttendeeId,
        ticketVersionId: ticketVersionId,
        purchasedTicketIds: purchasedTicketIds,
        orderId,
      },
    });

    if (result.errors && result.errors.length > 0) {
      RequestError(result.errors[0]);
    }

    return result;
  };

  const previewOnReserveTicket = ({
    orderId,
    ticketVersionId,
    responseAttendeeId,
    answers,
    openValueAmount,
    quantity,
  }: ReserveTicketArgs): Promise<ReserveTicketAnswerResult> => {
    const result = { ReserveTicket: "" };
    const ticket = tickets.find((ticket) => ticket.id === ticketVersionId);
    // Assign the answerId so apollo's merge operation can track the answers in the cache
    answers?.forEach((answer) => { if (!answer.answerId) answer.answerId = uuidv4(); });

    const updater = ticket
      ? reserveTicketMutationUpdater(signeeInfo(responseVersion), ticket, attendee.attendeeId)
      : null;
    if (updater) {
      updater(
        client.cache,
        { data: result },
        {
          variables: {
            countryCode,
            documentId: form.documentId,
            responseVersionId: responseVersion.responseVersionId,
            responseAttendeeId: responseAttendeeId,
            ticketVersionId: ticketVersionId,
            reserveTicketInput: {
              orderId,
              answers: answers,
              openValueAmount: openValueAmount,
              quantity: quantity,
            },
          },
        }
      );
    }
    return Promise.resolve({ data: result, errors: undefined });
  };

  const previewOnCancelTicket = ({
    ticketVersionId,
    responseAttendeeId,
    purchasedTicketIds,
  }: CancelTicketArgs): Promise<CancelTicketAnswerResult> => {
    const result = { CancelTicket: true };
    const updater = cancelTicketMutationUpdater(signeeInfo(responseVersion));
    if (updater) {
      updater(
        client.cache,
        { data: result },
        {
          variables: {
            countryCode,
            documentId: form.documentId,
            responseVersionId: responseVersion.responseVersionId,
            responseAttendeeId: responseAttendeeId,
            ticketVersionId: ticketVersionId,
            purchasedTicketIds: purchasedTicketIds,
            orderId,
          },
        }
      );
    }
    return Promise.resolve({ data: result, errors: undefined });
  };

  const hasTicketsInCart =
    orderInfo && orderInfo?.cart.some((attendee) => attendee?.tickets?.length > 0);

  return (
    <Flex flexDirection="column">
      <TicketsSection
        tickets={tickets}
        inventories={ticketInventoryQuery.inventories}
        attendee={attendee}
        attendeeIndex={attendeeIndex}
        cart={orderInfo?.cart}
        mode={TicketMode.SHOPPING}
        isPreview={isPreview ?? false}
        onReserveTicket={isPreview ? previewOnReserveTicket : onReserveTicket}
      />
      {hasTicketsInCart ? (
        <>
          <TicketsPreview
            tickets={tickets}
            cart={orderInfo.cart}
            mode={TicketMode.PREVIEW}
            onCancelTicket={isPreview ? previewOnCancelTicket : onCancelTicket}
            countryCode={countryCode}
            form={form}
            responseVersionId={responseVersion.responseVersionId}
          />
          <>
            <StyledLine />
            <Totals subtotal={orderInfo.subtotal} showFees={false} showTotal={false} />
          </>
        </>
      ) : null}
      {orderInfo && orderInfo.previousOrders.length > 0 ? (
        <PreviousOrders orders={orderInfo.previousOrders} />
      ) : null}
    </Flex>
  );
};

export { Tickets };
