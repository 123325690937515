import { ApolloClient } from "@apollo/client";
import { SigneeInfoValues } from "@src/localVariables";
import { createResponseMutationUpdater } from "@src/mutations/updaterFunctions/createResponse";
import { RESPONSE_VERSION_QUERY } from "@src/queries/responseVersion";
import {
  Answer,
  AnswerInput,
  Attendee,
  AttendeeInput,
  CreateResponseMutation,
  DeleteAttendeeAnswerMutation,
  DeleteSigneeAnswerMutation,
  EditAttendeesMutation,
  PaidStatus,
  ResponseQuery,
  ResponseSource,
  ResponseStatus,
  SaveAttendeeAnswerMutation,
  SaveSigneeAnswerMutation,
  Ticket,
  TicketInventory,
  TicketInventoryQuery,
} from "@src/types";
import { v4 as uuidv4 } from "uuid";

export const PreviewHash = "PREVIEW";

export const getPreviewSignee = (
  signee: SigneeInfoValues | null | undefined
): SigneeInfoValues => ({
  firstName: signee?.firstName || "John",
  lastName: signee?.lastName || "Doe",
  email: signee?.email || "john.doe@me.com",
  responseSource: signee?.responseSource || ResponseSource.Organic,
  tagId: null,
});

export const getPreviewAttendees = (
  attendees: AttendeeInput[],
  currentAttendees: Attendee[] | undefined
): EditAttendeesMutation["EditAttendees"] =>
  attendees.map((attendee) => {
    const currentAttendee = currentAttendees?.find(
      (current) => current.responseAttendeeId === attendee.responseAttendeeId
    );
    return currentAttendee
      ? {
          ...currentAttendee,
          firstName: attendee.firstName,
          lastName: attendee.lastName,
          permitted: attendee.permitted,
          __typename: "Attendee",
        }
      : {
          ...attendee,
          responseAttendeeId: uuidv4(),
          attendeeId: uuidv4(),
          tickets: [],
          attendeeAnswers: [],
          attachmentFileType: null,
          integratedDataOnFile: [],
          __typename: "Attendee",
        };
  });

export const getPreviewResponseVersion = (
  signee: SigneeInfoValues,
  documentVersionId: string,
  isGeneralSignup: boolean
): CreateResponseMutation["CreateResponse"] => ({
  responseVersionId: uuidv4(),
  documentVersionId,
  documentHashId: "",
  tagId: null,
  hash: PreviewHash,
  responseOrganizationId: uuidv4(),
  isRostered: false,
  orderStatus: PaidStatus.NotApplicable,
  isPaymentRequired: false,
  status: ResponseStatus.Started,
  total: 0,
  subTotal: 0,
  signeeEmail: signee.email,
  signeeFirstName: signee.firstName,
  signeeLastName: signee.lastName,
  isSigned: false,
  isCheckedOut: false,
  createdDate: new Date().toString(),
  completedByOrganizationName: '',
  attendees: [
    {
      responseAttendeeId: uuidv4(),
      attendeeId: uuidv4(),
      firstName: isGeneralSignup ? signee.firstName : "Johnny",
      lastName: isGeneralSignup ? signee.lastName : "Doe",
      permitted: true,
      manualPermissionDate: null,
      tickets: [],
      attendeeAnswers: [],
      attachmentId: null,
      attachmentFileType: null,
      notes: null,
      integratedDataOnFile: null,
      __typename: "Attendee",
    },
  ],
  source: ResponseSource.Organic,
  sourceId: null,
  isInternal: null,
  isManual: false,
  signedDate: null,
  signeeNotes: null,
  signeeAttachmentId: null,
  signeeAttachmentFileType: null,
  originalValueUri: null,
  signeeAnswers: [],
  orders: [],
  previousOrders: [],
  __typename: "ResponseVersion",
});

export const getPreviewInventories = (tickets: Ticket[]): TicketInventoryQuery["TicketInventory"] =>
  tickets.map((ticket) => {
    const initial = ticket.inventory.draftInitial || 0;
    const remaining = ticket.inventory.draftRemaining || 0;
    return {
      id: uuidv4(),
      initial: initial,
      isLimited: ticket.inventory.isLimited,
      purchased: initial - remaining,
      remaining: remaining,
      ticketId: ticket.ticketId,
      __typename: "TicketInventory",
    } as TicketInventory;
  });

export const getSigneeAnswer = (
  answerInput: AnswerInput
): SaveSigneeAnswerMutation["SaveSigneeAnswer"] => mapAnswerInputsToAnswers([answerInput])[0];

export const getAttendeeAnswer = (
  answerInput: AnswerInput
): SaveAttendeeAnswerMutation["SaveAttendeeAnswer"] => mapAnswerInputsToAnswers([answerInput])[0];

export const mapAnswerInputsToAnswers = (answerInputs: AnswerInput[]): Answer[] => {
  return answerInputs.map((answerInput) => ({
    __typename: "Answer",
    fieldId: answerInput.fieldId,
    answerId: answerInput.answerId ?? uuidv4(),
    questionId: answerInput.questionId,
    fileType: answerInput.fileType || null,
    order: answerInput.order || null,
    value: answerInput.value,
  }));
};

export const getDeleteSigneeAnswerResponseForPreview =
  (): DeleteSigneeAnswerMutation["DeleteSigneeAnswer"] => true;

export const getDeleteAttendeeAnswerResponseForPreview =
  (): DeleteAttendeeAnswerMutation["DeleteAttendeeAnswer"] => true;

// Creates a "preview" response version in cache without saving to the backend
export const previewOnCreateResponse = (
  documentId: string,
  documentVersionId: string,
  isGeneralSignup: boolean,
  signee: SigneeInfoValues,
  countryCode: string,
  client: ApolloClient<unknown>
) => {
  // Fetch the current response data from cache (if any exists)
  const currentData = client.cache.readQuery<ResponseQuery>({
    query: RESPONSE_VERSION_QUERY,
    variables: {
      hash: PreviewHash,
    },
  });

  const previewResponseVersion = currentData?.Response
    ? // Continue with existing response data but with the new signee values
      ({
        ...currentData.Response,
        signeeFirstName: signee.firstName,
        signeeLastName: signee.lastName,
        signeeEmail: signee.email,
      } as CreateResponseMutation["CreateResponse"])
    : // Generate new stubbed preview response version
      getPreviewResponseVersion(signee, documentVersionId, isGeneralSignup);

  const updater = createResponseMutationUpdater();
  if (updater) {
    updater(
      client.cache,
      { data: { CreateResponse: previewResponseVersion } },
      {
        variables: {
          documentId,
          countryCode: countryCode,
          signee,
          accessData: [],
        },
      }
    );
  }

  return previewResponseVersion;
};
