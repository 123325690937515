import React, { Component, ErrorInfo } from "react";
import { Normalize } from "react-i18next";

import common_en from "@src/translations/en/common.json";
import { i18next } from "@src/translations";

interface Props {
  children: JSX.Element;
  name?: string;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error(`Uncaught error in ${this.props.name}:`, error, errorInfo);
  }

  private t(translationKey: Normalize<typeof common_en>, variables?: Record<string, unknown>) {
    return i18next.t(translationKey, variables);
  }

  public render(): JSX.Element {
    if (this.state.hasError) {
      // TODO: Redirect to full page error message with generic message
      return <h1>{this.t("errors.500")}</h1>;
    }

    return this.props.children;
  }
}

export { ErrorBoundary };
