import React, { FC } from "react";
import styled from "styled-components";
import { defaultTheme } from "@src/themes";

const TableWrapper = styled.table`
  border-collapse: collapse;
  margin: 15px auto 0;
  font-size: 1em;
  width: 100%;
`;

const HeaderRow = styled.tr`
  border-left: 2px solid ${defaultTheme.colors.fields.tablebordercolor};
`;

const Row = styled.tr<{ clickable?: boolean }>`
  cursor: ${(props) => (props.clickable ? "pointer" : "auto")};
  border-left: 2px solid ${defaultTheme.colors.fields.tablebordercolor};
`;

const Cell = styled.td<{ alignLeft?: boolean }>`
  border-right: dotted 2px ${defaultTheme.colors.fields.tablebordercolor};
  border-bottom: 2px solid ${defaultTheme.colors.fields.tablebordercolor};
  padding: 5px;
  font-size: 12px;
  text-align: ${(props) => (props.alignLeft ? "left" : "right")};
  font-weight: 300;
  vertical-align: top;

  &:last-of-type {
    border-right: solid 2px ${defaultTheme.colors.fields.tablebordercolor};
  }
`;

const InvisibleCell = styled.td<{ alignLeft?: boolean }>`
  border: none;
  border-left: solid 2px ${defaultTheme.colors.fields.tablebordercolor};
  padding: 5px;
  font-size: 12px;
  text-align: ${(props) => (props.alignLeft ? "left" : "right")};
  font-weight: 300;
  vertical-align: top;
  width: 52px;
`;

const AggregateCell = styled.td<{ alignLeft?: boolean }>`
  border-right: dotted 2px ${defaultTheme.colors.fields.tablebordercolor};
  border-bottom: 2px solid ${defaultTheme.colors.fields.tablebordercolor};
  padding: 15px 5px 5px;
  font-size: 12px;
  text-align: ${(props) => (props.alignLeft ? "left" : "right")};
  font-weight: 300;
  vertical-align: top;
`;

const Header = styled.th`
  border-right: solid 2px ${defaultTheme.colors.fields.tablebordercolor};
  padding: 4px 8px;
  background-color: ${defaultTheme.colors.fields.tablebordercolor};
  color: ${defaultTheme.colors.values.darkgrey};
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  min-width: 80px;
`;

const InvisibleHeader = styled.th`
  border: none;
  border-left: solid 2px ${defaultTheme.colors.fields.tablebordercolor};
  padding: 4px 8px;
  background-color: ${defaultTheme.colors.values.white};
  color: ${defaultTheme.colors.values.darkgrey};
  text-align: center;
  font-weight: 500;
  font-size: 12px;
`;

const StyledTableRowTextInput = styled.input.attrs({ type: "text" })<{ error?: boolean }>`
  &:focus {
    outline: none !important;
    border: 1px solid
      ${(props) =>
        props.error ? defaultTheme.colors.values.red : defaultTheme.colors.fields.selected};
  }

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid
    ${(props) =>
      props.error ? defaultTheme.colors.values.red : defaultTheme.colors.fields.bordercolor};
  border-radius: ${defaultTheme.borderRadius};
  padding: 5px;
  font-family: ${defaultTheme.font.default};
  font-size: 12px;
  font-weight: 300;
  width: 100%;
  min-width: 100px;
  box-sizing: border-box;
`;

const StyledTableRowLabel = styled.label`
  display: block;
  font-size: 10px;
  font-weight: 500;
  margin: 3px;
  max-width: 65px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type TableProps = { children: JSX.Element[] };

const Table: FC<TableProps> = ({ children }) => {
  return (
    <TableWrapper>
      <tbody>{children}</tbody>
    </TableWrapper>
  );
};

export {
  Table,
  Header,
  Row,
  Cell,
  TableWrapper,
  StyledTableRowTextInput,
  StyledTableRowLabel,
  AggregateCell,
  InvisibleCell,
  InvisibleHeader,
  HeaderRow,
};
