import { useEffect, useRef } from "react";

type useGoogleAddressProps = {
  onPlaceSelected: (place: google.maps.places.PlaceResult) => void;
};

const useGoogleAddress = ({ onPlaceSelected }: useGoogleAddressProps) => {
  const autoCompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, {
        types: ["address"],
      });

      autoCompleteRef.current.addListener("place_changed", async () => {
        if (autoCompleteRef.current) {
          const place = await autoCompleteRef.current.getPlace();
          onPlaceSelected(place);
        }
      });

      inputRef.current.addEventListener("blur", async () => {

        window.setTimeout(() => {
          // this is a complete hack. when the browser auto complete is used
          // the google autocomplete list can get detached from any event (it seems)
          // and is stuck visible on the screen. this at least hides it
          const containers = document.getElementsByClassName("pac-container");
          for (let index = 0; index < containers.length; index++)
            containers[index].setAttribute("style", "display: none");
      }, 500);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return inputRef;
};

export { useGoogleAddress };
