import { Button } from "@src/components/atoms/Button";
import { StyledNavigationButtonsContainer } from "@src/components/styles";
import { useBreakPoints } from "@src/customHooks";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

export type NavigationButtonsProps = {
  nextButtonDisabled?: boolean;
  displayBackButton?: boolean;
  displayNextButton?: boolean;
  backOnClick?: () => void;
  nextOnClick?: () => void;
};

const NavigationButtons: FunctionComponent<NavigationButtonsProps> = ({
  nextButtonDisabled,
  displayBackButton = true,
  displayNextButton = true,
  backOnClick,
  nextOnClick,
}: NavigationButtonsProps) => {
  const { t } = useTranslation();
  const screenSize = useBreakPoints();

  return (
    <StyledNavigationButtonsContainer screenSize={screenSize}>
      {displayBackButton ? (
        <Button
          data-testid={"pageBackButton"}
          screenSize={screenSize}
          type="button"
          primary
          disabled={nextButtonDisabled}
          onClick={backOnClick}
        >
          {t("labels.back")}
        </Button>
      ) : (
        <div></div>
      )}
      {displayNextButton ? (
        <Button
          data-testid={"pageNextButton"}
          screenSize={screenSize}
          primary
          type="submit"
          onClick={nextOnClick}
        >
          {t("labels.next")}
        </Button>
      ) : (
        <div></div>
      )}
    </StyledNavigationButtonsContainer>
  );
};

export { NavigationButtons };
