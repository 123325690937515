import React, { FunctionComponent } from "react";
import { ContentWrapper, Flex, PageWrapper } from "@src/components/layout/Page";
import { PageFooter } from "@src/components/molecules/pageFooter";
import {
  StyledHeader,
  StyledInformationContainer,
  StyledLabel,
} from "@src/components/styles";
import { FormInfoQuery } from "@src/types";
import { useBreakPoints } from "@src/customHooks";
import { Trans, useTranslation } from "react-i18next";
import { Language, useGoogleTranslate } from "@src/utils/translation";
import { SelectLanguage } from "@src/components/molecules/pageHeader/SelectLanguage";

type FormInactiveProps = { form: NonNullable<FormInfoQuery["Form"]> };

const FormInactive: FunctionComponent<FormInactiveProps> = ({ form }: FormInactiveProps) => {
  const screenSize = useBreakPoints();
  const { t, i18n } = useTranslation();
  const googleTranslate = useGoogleTranslate();

  const onLanguageChanged = (language: Language) => {
    i18n.changeLanguage(language.code);
  };

  const formName = googleTranslate(form.name || "");

  return (
    <>
      <PageWrapper>
        <ContentWrapper
          screenSize={screenSize}
          style={{ marginTop: screenSize === "desktop" ? "25px" : "0px" }}
        >
          <Flex flexDirection="column">
            <Flex justifyContent="space-between">
              <StyledHeader>{formName}</StyledHeader>
              <SelectLanguage onLanguageChanged={onLanguageChanged} />
            </Flex>
            <StyledInformationContainer>
              <StyledLabel style={{ fontWeight: "initial" }}>
                <Trans i18nKey="pages.formStatus.inactive" values={{ organization: form?.document?.organization?.name ?? "" }}>
                  {t("pages.formStatus.inactive")}
                </Trans>
              </StyledLabel>
            </StyledInformationContainer>
          </Flex>
        </ContentWrapper>
      </PageWrapper>
      <PageFooter />
    </>
  );
};

export { FormInactive };
