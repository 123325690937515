import React, { ChangeEventHandler, FC, FocusEventHandler } from "react";
import NumberFormat from "react-number-format";
import omit from "lodash/omit";

import { FieldWrapper, StyledRequiredLabel, StyledTextInput } from "@src/components/styles";
import { TextFieldProps } from "../types";
import { FieldError } from "@src/components/atoms/FieldError";
import styled from "styled-components";
import { defaultTheme } from "@src/themes";
import { Label } from "@src/components/atoms/Label";

type PhoneFieldProps = TextFieldProps & {
  phoneFormat: string;
};

const PhoneErrorContainer = styled.div<{ hasError?: boolean }>`
  > input {
    width: 100%;
    background-color: ${(props) => (props.hasError ? "#ffdfdf" : "inherit")};
    border: 1.25px solid
      ${(props) => (props.hasError ? "red" : defaultTheme.colors.fields.bordercolor)};
  }
`;

const PhoneField: FC<PhoneFieldProps> = ({
  id,
  label,
  error,
  touched,
  phoneFormat,
  value,
  isRequired,
  setValue,
  saveAnswer,
}) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    setValue({ ...value, value: e.target.value });

  const handleBlur: FocusEventHandler<HTMLInputElement> = async (e) => {
    const newAnswer = { ...value, value: e.target.value };

    const answerId = saveAnswer ? await saveAnswer(omit(newAnswer, "__typename")) : "";

    setValue({
      ...newAnswer,
      answerId,
    });
  };

  return (
    <FieldWrapper>
      {isRequired ? <StyledRequiredLabel /> : null}
      <Label>{label}</Label>

      <PhoneErrorContainer hasError={!!error && touched}>
        <NumberFormat
          data-testid={id}
          value={value?.value || ""}
          format={phoneFormat}
          customInput={StyledTextInput}
          type="tel"
          mask="_"
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={phoneFormat.replaceAll("#", "_")}
          name={id}
        />
      </PhoneErrorContainer>

      <FieldError id={id} name={id} />
    </FieldWrapper>
  );
};

export { PhoneField };
