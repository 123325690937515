import { Breakpoints, useBreakPoints } from "@src/customHooks/useBreakpoints";
import { useGoogleTranslate } from "@src/utils/translation";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const StyledSpan = styled.span<{ screenSize: Breakpoints }>`
  padding-top: 10px;
  padding-bottom: 10px;
  color: #868686;
  text-align: center;
  font-size: 11px;
  width: ${(props) => (props.screenSize === "desktop" ? `116px` : `85px`)};
`;

export type DateNoticeProps = {
  start: string;
  end: string;
};

const DateNotice: FC<DateNoticeProps> = ({ start, end }) => {
  const { t } = useTranslation();
  const googleTranslate = useGoogleTranslate();
  const screenSize = useBreakPoints();
  const startDate = new Date(start);
  const endDate = new Date(end);
  const now = new Date().getTime();

  const getNoticeMessage = () => {
    if (startDate.getTime() > now) {
      return {
        message: t("pages.tickets.dateNotice.start"),
        date: googleTranslate(startDate.toDateString()),
      };
    } else if (endDate.getTime() < now) {
      return {
        message: t("pages.tickets.dateNotice.ended"),
        date: googleTranslate(endDate.toDateString()),
      };
    } else if (startDate.getTime() < now && endDate.getTime() > now) {
      return {
        message: t("pages.tickets.dateNotice.inProgress"),
        date: googleTranslate(endDate.toDateString()),
      };
    }

    return null;
  };

  const noticeMessage = getNoticeMessage();

  return noticeMessage ? (
    <>
      <StyledSpan screenSize={screenSize} style={{ padding: "0" }}>
        {noticeMessage.message}
      </StyledSpan>
      <StyledSpan screenSize={screenSize}>{noticeMessage.date}</StyledSpan>
    </>
  ) : null;
};

export { DateNotice };
