import React, { FC } from "react";
import { Froala } from "@src/components/atoms/Froala";
import { useGoogleTranslate } from "@src/utils/translation";

type ParagraphFieldProps = {
  text: string;
};

const ParagraphField: FC<ParagraphFieldProps> = ({ text }) => {
  const googleTranslate = useGoogleTranslate();

  return <Froala text={googleTranslate(text)} />;
};

export { ParagraphField };
