import React, { FC } from "react";
import { isNumber } from "mathjs";
import NumberFormat from "react-number-format";

import { getNumericInputLogic } from "../getNumericInputLogic";
import { HorizontalButton, NumericInputWrapper, StyledHorizontalInput } from "../styles";
import { NumericInnerFieldProps } from "../types";
import { useTranslation } from "react-i18next";

const Horizontal: FC<NumericInnerFieldProps> = ({
  ticketId,
  value,
  type,
  min,
  max,
  width,
  minWidth,
  disabled,
  step,
  fixedDecimalPlaces,
  error,
  onChange,
}) => {
  const { t } = useTranslation();
  const {
    isDecrementDisabled,
    isIncrementDisabled,
    useDecimalScale,
    decimalScale,
    prefix,
    onDecrease,
    onIncrease,
    handleChange,
  } = getNumericInputLogic({
    disabled,
    min,
    max,
    value,
    step,
    type,
    fixedDecimalPlaces,
    onChange,
  });

  return (
    <NumericInputWrapper width={width} minWidth={minWidth} error={!!error}>
      <HorizontalButton
        disabled={isDecrementDisabled}
        onClick={onDecrease}
        title={isNumber(min) && min >= 0 ? t("messages.minimumAmount", { min }) : ""}
        data-testid={ticketId + "-DECREMENT"}
      >
        <i className={`fa-solid fa-minus`} style={{ color: "#0283ff" }} />
      </HorizontalButton>

      <NumberFormat
        disabled={disabled}
        fixedDecimalScale={useDecimalScale}
        decimalScale={decimalScale}
        value={value || 0}
        customInput={StyledHorizontalInput}
        allowEmptyFormatting={true}
        prefix={prefix}
        onValueChange={handleChange}
        name={ticketId + "-AMOUNT"}
        onKeyPress={
          !disabled
            ? (event: React.KeyboardEvent) => {
                if (event.which === 13) {
                  event.preventDefault();
                }
              }
            : null
        }
      />

      <HorizontalButton
        disabled={isIncrementDisabled}
        onClick={onIncrease}
        title={isNumber(max) && max >= 0 ? t("messages.maximumAmount", { max }) : ""}
        data-testid={ticketId + "-INCREMENT"}
      >
        <i className={`fa-solid fa-plus`} style={{ color: "#0283ff" }} />
      </HorizontalButton>
    </NumericInputWrapper>
  );
};
export { Horizontal };
