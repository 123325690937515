import React, { FC } from "react";

import { Flex } from "@src/components/layout/Page";
import { DisplayAnswers } from "@src/components/molecules/displayAnswers";
import { StyledLabel } from "@src/components/styles";
import { PreviewProps } from "@src/Routes";
import { Ticket, Attendee, Field, FormInfoQuery } from "@src/types";
import { CancelTicketArgs, TicketMode } from "@src/utils/purchasedTickets";
import { TicketPreview } from "@src/pages/Tickets/Preview";
import { useTranslation } from "react-i18next";
import { AttendeePreviewWrapper, PermissionLabel } from "./styles";
import { useGoogleTranslate } from "@src/utils/translation";

type AttendeePreviewProps = PreviewProps & {
  tickets: Ticket[];
  attendee: Attendee;
  attendees: Attendee[];
  fields?: Field[];
  mode: TicketMode;
  countryCode: string;
  form: NonNullable<FormInfoQuery["Form"]>;
  responseVersionId: string;
  onCancelTicket?: (args: CancelTicketArgs) => void;
};

const AttendeePreview: FC<AttendeePreviewProps> = ({
  tickets,
  attendee,
  attendees,
  fields,
  mode,
  countryCode,
  form,
  responseVersionId,
  onCancelTicket,
  isPreview,
}) => {
  const { t } = useTranslation();
  const googleTranslate = useGoogleTranslate();
  const attendeeIndex = attendees
    .filter((attendee) => !form.requiresPermission || attendee.permitted)
    .map((attendee) => attendee.responseAttendeeId)
    .indexOf(attendee.responseAttendeeId);
  const affirmativePermission = form.affirmativePermission
    ? googleTranslate(form.affirmativePermission)
    : form.affirmativePermission;
  const negativePermission = form.negativePermission
    ? googleTranslate(form.negativePermission)
    : form.negativePermission;

  const displayAnswers =
    fields && fields.length > 0 && (!form.requiresPermission || attendee.permitted);

  return (
    <AttendeePreviewWrapper data-testid={`${attendee.firstName} ${attendee.lastName}-ANSWERS`}>
      {mode === TicketMode.CHECKOUT || mode === TicketMode.CART_PREVIEW ? (
        <AttendeeHeader
          firstName={attendee.firstName || ""}
          lastName={attendee.lastName || ""}
          isPermitted={attendee.permitted ?? false}
          isGeneralSignup={form.isGeneralSignup}
          requiresPermission={form.requiresPermission}
          affirmativePermission={affirmativePermission}
          negativePermission={negativePermission}
          showPermission={mode === TicketMode.CHECKOUT}
        />
      ) : null}
      {displayAnswers ? (
        <Flex flexDirection="column">
          <DisplayAnswers
            fields={fields}
            answers={attendee.attendeeAnswers}
            integratedDataAnswers={attendee.integratedDataOnFile ?? []}
            countryCode={countryCode}
            documentId={form.documentId}
            responseVersionId={responseVersionId}
          />
        </Flex>
      ) : null}
      {tickets?.length > 0 && attendee.tickets?.length > 0 ? (
        <Flex flexDirection="column">
          {mode !== TicketMode.CART_PREVIEW ? (
            <StyledLabel style={{ fontSize: "18px" }}>
              {t("pages.tickets.order")} {attendee.firstName} {attendee.lastName}
            </StyledLabel>
          ) : null}
          {tickets.map((ticket) => {
            const purchasedTickets = attendee.tickets.filter(
              (purchasedTicket) => purchasedTicket.ticketId === ticket.ticketId
            );
            return purchasedTickets?.length > 0 ? (
              <TicketPreview
                key={`preview-${ticket.ticketId}`}
                ticket={ticket}
                purchasedTickets={purchasedTickets}
                attendee={attendee}
                attendeeIndex={attendeeIndex}
                mode={mode}
                countryCode={countryCode}
                documentId={form.documentId}
                responseVersionId={responseVersionId}
                onCancelTicket={onCancelTicket}
                isPreview={isPreview}
                cart={attendees}
              />
            ) : null;
          })}
        </Flex>
      ) : null}
    </AttendeePreviewWrapper>
  );
};

type AttendeeHeaderprops = {
  firstName: string;
  lastName: string;
  isPermitted: boolean;
  isGeneralSignup: boolean;
  showPermission: boolean;
  requiresPermission: boolean;
  affirmativePermission?: string | null;
  negativePermission?: string | null;
};

const AttendeeHeader: FC<AttendeeHeaderprops> = ({
  firstName,
  lastName,
  isPermitted,
  isGeneralSignup,
  showPermission,
  requiresPermission,
  affirmativePermission,
  negativePermission,
}) => {
  const { t } = useTranslation();
  const permissionLabel =
    showPermission && !isGeneralSignup && requiresPermission
      ? isPermitted
        ? affirmativePermission
          ? `${affirmativePermission} -`
          : t("pages.review.permissionGranted")
        : negativePermission
        ? `${negativePermission} -`
        : t("pages.review.permissionNotGranted")
      : null;
  return (
    <Flex flexDirection="column">
      <StyledLabel style={{ fontSize: "18px" }}>
        {firstName} {lastName}
      </StyledLabel>
      {permissionLabel && (
        <PermissionLabel isPermitted={isPermitted}>
          {permissionLabel} {firstName} {lastName}
        </PermissionLabel>
      )}
    </Flex>
  );
};

export { AttendeePreview };
