import React, { FunctionComponent } from "react";
import { Attachment } from "@src/types";
import { PrimaryAttachmentContainer } from "./styles";
import { PdfViewer } from "../PdfViewer/PdfViewer";
import { Flex } from "@src/components/layout/Page";

type PrimaryAttachmentProps = {
  attachment: Attachment;
};

const PrimaryAttachment: FunctionComponent<PrimaryAttachmentProps> = ({ attachment }) => {
  return (
    <Flex justifyContent="center">
      <PrimaryAttachmentContainer>
        <PdfViewer
          contentUri={attachment.contentUri}
          downloadUri={attachment.downloadUri}
          printUri={attachment.printUri}
        />
      </PrimaryAttachmentContainer>
    </Flex>
  );
};

export { PrimaryAttachment };
