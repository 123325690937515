import gql from "graphql-tag";
import {
  AnswerFragment,
  DocumentFragment,
  FieldFragment,
  FormAttachmentFragment,
  LinkAttachmentFragment,
  TicketFragment,
} from "./fragments";

const FORM_INFO_QUERY = gql`
  query FormInfo($hashedId: String!, $countryCode: String!, $hashedOrgId: String) {
    Form(hashedId: $hashedId, countryCode: $countryCode, hashedOrgId: $hashedOrgId) {
      id
      name
      status
      redirectUrl
      documentId
      redirectMessage
      allowMultipleChildrenOnOneOrder
      allowOnlinePaymentsOnly
      respondentMustSelectATicket
      manualPaymentText
      parentMessage
      information
      organizerName
      requiresPermission
      isGeneralSignup
      restrictResponses
      hasApprovers
      hasReachedResponseCutOffDate
      responseCutOffLocalDate
      startDate
      endDate
      affirmativePermission
      negativePermission
      useOrganizationBranding
      useLocalTranslations
      document {
        ...DocumentFragment
      }
      attachments {
        ...FormAttachmentFragment
      }
      linkAttachments {
        ...LinkAttachmentFragment
      }
      publicQuestions {
        ...FieldFragment
        equationSections {
          ...QuestionFragment
        }
        question {
          ...QuestionFragment
        }
        fields {
          ...FieldFragment
          equationSections {
            ...QuestionFragment
          }
          question {
            ...QuestionFragment
          }
          fields {
            ...FieldFragment
            equationSections {
              ...QuestionFragment
            }
            question {
              ...QuestionFragment
            }
            fields {
              ...FieldFragment
              equationSections {
                ...QuestionFragment
              }
              question {
                ...QuestionFragment
              }
            }
          }
        }
      }
      publicOrganizerAnswers {
        ...AnswerFragment
      }
      signeeQuestions {
        ...FieldFragment
        equationSections {
          ...QuestionFragment
        }
        question {
          ...QuestionFragment
        }
        fields {
          ...FieldFragment
          equationSections {
            ...QuestionFragment
          }
          question {
            ...QuestionFragment
          }
          fields {
            ...FieldFragment
            equationSections {
              ...QuestionFragment
            }
            question {
              ...QuestionFragment
            }
            fields {
              ...FieldFragment
              equationSections {
                ...QuestionFragment
              }
              question {
                ...QuestionFragment
              }
            }
          }
        }
      }
      attendeeQuestions {
        ...FieldFragment
        equationSections {
          ...QuestionFragment
        }
        question {
          ...QuestionFragment
        }
        fields {
          ...FieldFragment
          equationSections {
            ...QuestionFragment
          }
          question {
            ...QuestionFragment
          }
          fields {
            ...FieldFragment
            equationSections {
              ...QuestionFragment
            }
            question {
              ...QuestionFragment
            }
            fields {
              ...FieldFragment
              equationSections {
                ...QuestionFragment
              }
              question {
                ...QuestionFragment
              }
            }
          }
        }
      }
      tickets {
        ...TicketFragment
      }
      template {
        attachmentsPermissions
        ticketPermissions
        signeeFormPermissions
        attendeeFormPermissions
        document {
          ...DocumentFragment
        }
        attachments {
          ...FormAttachmentFragment
        }
        linkAttachments {
          ...LinkAttachmentFragment
        }
        publicQuestions {
          ...FieldFragment
          equationSections {
            ...QuestionFragment
          }
          question {
            ...QuestionFragment
          }
          fields {
            ...FieldFragment
            equationSections {
              ...QuestionFragment
            }
            question {
              ...QuestionFragment
            }
            fields {
              ...FieldFragment
              equationSections {
                ...QuestionFragment
              }
              question {
                ...QuestionFragment
              }
              fields {
                ...FieldFragment
                equationSections {
                  ...QuestionFragment
                }
                question {
                  ...QuestionFragment
                }
              }
            }
          }
        }
        signeeQuestions {
          ...FieldFragment
          equationSections {
            ...QuestionFragment
          }
          question {
            ...QuestionFragment
          }
          fields {
            ...FieldFragment
            equationSections {
              ...QuestionFragment
            }
            question {
              ...QuestionFragment
            }
            fields {
              ...FieldFragment
              equationSections {
                ...QuestionFragment
              }
              question {
                ...QuestionFragment
              }
              fields {
                ...FieldFragment
                equationSections {
                  ...QuestionFragment
                }
                question {
                  ...QuestionFragment
                }
              }
            }
          }
        }
        attendeeQuestions {
          ...FieldFragment
          equationSections {
            ...QuestionFragment
          }
          question {
            ...QuestionFragment
          }
          fields {
            ...FieldFragment
            equationSections {
              ...QuestionFragment
            }
            question {
              ...QuestionFragment
            }
            fields {
              ...FieldFragment
              equationSections {
                ...QuestionFragment
              }
              question {
                ...QuestionFragment
              }
              fields {
                ...FieldFragment
                equationSections {
                  ...QuestionFragment
                }
                question {
                  ...QuestionFragment
                }
              }
            }
          }
        }
        tickets {
          ...TicketFragment
        }
      }
    }
  }
  ${DocumentFragment}
  ${FieldFragment}
  ${TicketFragment}
  ${FormAttachmentFragment}
  ${LinkAttachmentFragment}
  ${AnswerFragment}
`;

export { FORM_INFO_QUERY };
