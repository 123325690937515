import { SigneeInfoValues } from "@src/localVariables"
import { AttendeeInput, ResponseSource, ResponseStatus, ResponseVersion } from "@src/types"
import { checkResponseVersionSource } from "./typeConverters"
import { i18next } from "@src/translations"

const signeeInfo = (responseVersion: ResponseVersion) : SigneeInfoValues => {

    return {
        firstName: responseVersion?.signeeFirstName ?? "",
        lastName: responseVersion?.signeeLastName ?? "",
        email: responseVersion?.signeeEmail ?? "",
        notes: responseVersion?.signeeNotes ?? "",
        attachmentId: responseVersion?.signeeAttachmentId ?? "",
        responseSource: responseVersion?.source ?? ResponseSource.Organic,
        tagId: responseVersion?.tagId ?? "",
    }
}

const isProcess = (responseVersion: ResponseVersion | null): boolean =>
    checkResponseVersionSource(responseVersion, ResponseSource.Process);

const isManual = (responseVersion: ResponseVersion | null): boolean =>
    checkResponseVersionSource(responseVersion, ResponseSource.Manual);

const isStaff = (responseVersion: ResponseVersion | null): boolean =>
    (responseVersion || false)
    && responseVersion.isInternal
        || checkResponseVersionSource(responseVersion, ResponseSource.Internal);

const isRostered = (responseVersion: ResponseVersion | null): boolean =>
    (responseVersion || false) && responseVersion.isRostered;

const isRosteredOrStaffResponse = (responseVersion: ResponseVersion | null): boolean =>
    isRostered(responseVersion) || isStaff(responseVersion);

const isAuthenticatedResponse = (responseVersion: ResponseVersion | null): boolean =>
    isProcess(responseVersion) || isStaff(responseVersion);

const isSigned = (responseVersion: ResponseVersion | null): boolean =>
    (responseVersion || false) && responseVersion.isSigned;

const signeeValuesAreUpdated = (responseVersion: ResponseVersion, signeeValues: SigneeInfoValues): boolean => {

    const currentSigneeValues = signeeInfo(responseVersion);

    return currentSigneeValues.email !== signeeValues.email
        || currentSigneeValues.firstName !== signeeValues.firstName
        || currentSigneeValues.lastName !== signeeValues.lastName
        || currentSigneeValues.attachmentId !== signeeValues.attachmentId
        || currentSigneeValues.notes !== signeeValues.notes;
}

const tagValueIsUpdated = (responseVersion: ResponseVersion, signeeValues: SigneeInfoValues): boolean => {

    const currentSigneeValues = signeeInfo(responseVersion);

    return currentSigneeValues.tagId !== signeeValues.tagId;
}

const attendeesAreUpdated = (responseVersion: ResponseVersion, attendees: AttendeeInput[]): boolean => {

    if (responseVersion.attendees?.length !== attendees?.length)
        return true;

    let isChanged = false;

    responseVersion.attendees.map((currentAttendee) => {

        const editedAttendee = attendees.find((x) => x.responseAttendeeId === currentAttendee.responseAttendeeId);

        if (!editedAttendee
            || editedAttendee.firstName !== currentAttendee.firstName
            || editedAttendee.lastName !== currentAttendee.lastName
            || editedAttendee.attachmentId !== currentAttendee.attachmentId
            || editedAttendee.manualPermissionDate !== currentAttendee.manualPermissionDate
            || editedAttendee.notes !== currentAttendee.notes
            || editedAttendee.permitted !== currentAttendee.permitted) {
            isChanged = true;
            return;
        }

    })

    return isChanged;
}

export type StartNewResponse = {
    disabled: boolean;
    disabledMessage: string;
  };

/**
 * Get the disabled status of the "Start new response" button, and a message for why it is disabled if so.
 * Currently there is only 3 reasons to disable the start new response button:
 * 1. The loaded response is rostered.
 * 2. The loaded response is from a process.
 * 3. The loaded response is a staff response, and there are other staff responses that are not complete.
 * @param responseVersion The currently loaded response. Only set if form is loaded via a direct response link.
 * @param responseVersions Any other response versions available besides the currently loaded response.
 */
const getStartNewResponseStatus = (responseVersion?: ResponseVersion, responseVersions?: ResponseVersion[]): StartNewResponse => {

    const result: StartNewResponse = {disabled: false, disabledMessage: ""};

    if (!responseVersions) {
        responseVersions = [];
    }

    if (responseVersion) {
        if (isRostered(responseVersion)) {
            result.disabled = true;
            result.disabledMessage = i18next.t("pages.selectResponse.startNewResponse.RosteredDisabled");
        } else if (isProcess(responseVersion)) {
            result.disabled = true;
            result.disabledMessage = i18next.t("pages.selectResponse.startNewResponse.ProcessDisabled");
        } else if (isStaff(responseVersion) && 
            !responseVersions.every(
            (x) =>
                x.status === ResponseStatus.Rejected ||
                x.status === ResponseStatus.Responded ||
                x.status === ResponseStatus.PendingApproval ||
                x.status === ResponseStatus.Cancelled
            )
        ) {
            result.disabled = true;
            result.disabledMessage = i18next.t("pages.selectResponse.startNewResponse.StaffDisabled");
        }
    }

    return result;
  };

export {
    signeeInfo,
    isProcess,
    isManual,
    isStaff,
    isRostered,
    isSigned,
    isRosteredOrStaffResponse,
    signeeValuesAreUpdated,
    tagValueIsUpdated,
    attendeesAreUpdated,
    isAuthenticatedResponse,
    getStartNewResponseStatus
}