import React, { FC } from "react";

import { useFormInfo } from "@src/customHooks/useFormInfo";
import { Field } from "@src/types";
import { isComplexField, isSimpleField } from "@src/utils/field";
import {
  getAttendeeFields,
  getAttendeesList,
  getOrderInfo,
  getSigneeAnswers,
  getSigneeFields,
  getTickets,
} from "@src/utils/getters";
import {
  CompleteText,
  CompletionProgressBar,
  Percentage,
  ProgressTrackerContainer,
  TimeEstimate,
} from "./styles";
import { getFlattenedFieldList, isFieldAnswered } from "@src/utils/progressTracker";
import { useCachedResponseVersion } from "@src/customHooks/useResponseVersion";
import { useReactiveVar } from "@apollo/client";
import { ResponseVersionHash } from "@src/localVariables";
import { useTranslation } from "react-i18next";

const ProgressTracker: FC = () => {
  //milliseconds
  const simpleFieldEntryTimeEstimation = 30000;
  const complexFieldEntryTimeEstimation = 60000;
  const ticketEntryTimeEstimation = 45000;
  const responseVersionHash = useReactiveVar(ResponseVersionHash);
  const { t } = useTranslation();

  const { form } = useFormInfo();
  const responseVersionQuery = useCachedResponseVersion(responseVersionHash);

  const attendees = getAttendeesList(responseVersionQuery.responseVersion).filter(
    (attendee) => !form.requiresPermission || attendee.permitted
  );

  const calculateTimeEstimation = (fields: Field[], numberOfAttendees = 1) => {
    return Math.ceil(
      fields.filter((x) => isComplexField(x)).length *
        numberOfAttendees *
        complexFieldEntryTimeEstimation +
        fields.filter((x) => isSimpleField(x)).length *
          numberOfAttendees *
          simpleFieldEntryTimeEstimation
    );
  };

  const millisecondsTommss = (millis: number) => {
    const sign = millis < 0 ? "-" : "";
    const minutes = Math.floor(millis / 60000);
    const seconds = (millis % 60000) / 1000;
    return (
      sign +
      (minutes < 10 ? "0" : "") +
      minutes +
      ":" +
      (seconds < 10 ? "0" : "") +
      seconds.toFixed(0)
    );
  };

  const signeeFields = getSigneeFields(form);
  const attendeeFields = getAttendeeFields(form);
  const tickets = getTickets(form);
  const isGeneralSignup = form.isGeneralSignup;
  const signeeAnswers = getSigneeAnswers(responseVersionQuery.responseVersion);
  const orderInfo = getOrderInfo(
    responseVersionQuery.responseVersion,
    false);
  const numberOfAttendees = isGeneralSignup ? 1 : attendees.length;

  const flattenedSigneeFields = getFlattenedFieldList(signeeFields, signeeAnswers);

  let numberOfFields = 0;
  let numberOfFieldsCompleted = 0;
  let signeeFormTimeEstimation = 0;
  let attendeeFormTimeEstimation = 0;
  let ticketTimeEstimation = 0;

  if (flattenedSigneeFields && flattenedSigneeFields.length > 0) {
    signeeFormTimeEstimation = calculateTimeEstimation(flattenedSigneeFields);
    numberOfFields += flattenedSigneeFields.filter(
      (x) => isComplexField(x) || isSimpleField(x)
    ).length;
    numberOfFieldsCompleted += flattenedSigneeFields.filter((x) =>
      isFieldAnswered(x, signeeAnswers)
    ).length;
  }

  if (!isGeneralSignup && attendeeFields && attendeeFields.length > 0) {
    attendees.map((attendee) => {
      const flattenedAttendeeFields = getFlattenedFieldList(
        attendeeFields,
        attendee.attendeeAnswers
      );

      attendeeFormTimeEstimation = calculateTimeEstimation(
        flattenedAttendeeFields,
        attendees.length
      );

      numberOfFields += flattenedAttendeeFields.filter(
        (x) => isComplexField(x) || isSimpleField(x)
      ).length;

      numberOfFieldsCompleted += flattenedAttendeeFields.filter((x) =>
        isFieldAnswered(x, attendee.attendeeAnswers)
      ).length;
    });
  }

  // if there are no signee or attendee questions, we skip checking the tickets.
  // this is because we're dead set on having business rules designed to complicate things
  // and to cause our user to believe things are broken. mind numbing stuff
  if (numberOfFields > 0 && tickets && tickets.length > 0) {
    ticketTimeEstimation = tickets.length * ticketEntryTimeEstimation * numberOfAttendees;
    numberOfFields += tickets.length * numberOfAttendees;
    numberOfFieldsCompleted +=
      orderInfo?.cart?.reduce((ticketIdsInCartCount, attendee) => {
        const uniqueTicketIds = new Set(attendee.tickets?.map((ticket) => ticket.ticketId) || []);
        return ticketIdsInCartCount + uniqueTicketIds.size || 0;
      }, 0) ?? 0;

    tickets
      .filter((x) => x.formQuestions && x.formQuestions.length > 0)
      .map((ticket) => {
        const ticketAnswerValues =
          orderInfo?.latestOrder?.purchasedTickets?.find((x) => x.ticketId === ticket.ticketId)
            ?.answers ?? [];

        if (ticket.formQuestions) {
          ticketTimeEstimation += calculateTimeEstimation(ticket.formQuestions, numberOfAttendees);
          numberOfFields +=
            ticket.formQuestions?.filter((x) => isComplexField(x) || isSimpleField(x)).length ||
            0 * numberOfAttendees;

          numberOfFieldsCompleted +=
            ticket.formQuestions?.filter((x) => isFieldAnswered(x, ticketAnswerValues)).length ||
            0 * numberOfAttendees;
        }
      });
  }

  const completionPercentage = numberOfFieldsCompleted / numberOfFields;
  const totalEstimationTime =
    signeeFormTimeEstimation + attendeeFormTimeEstimation + ticketTimeEstimation;
  const timeComplete = totalEstimationTime * completionPercentage;
  const timeEstimate =
    completionPercentage > 0 ? totalEstimationTime - timeComplete : totalEstimationTime;

  return numberOfFields > 0 ? (
    <ProgressTrackerContainer>
      <Percentage>{(completionPercentage * 100).toFixed(0)}%</Percentage>{" "}
      <CompleteText>{t("labels.complete")}</CompleteText>
      <TimeEstimate>{t("labels.timeRemaining", { timeEstimate: millisecondsTommss(timeEstimate)})}</TimeEstimate>
      <CompletionProgressBar style={{ width: `${completionPercentage * 100}%` }} />
    </ProgressTrackerContainer>
  ) : null;
};

export { ProgressTracker };
