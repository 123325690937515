import React from "react";

interface Props {
  id: string;
  label: string;
  placeholder: string;
  selectedOption?: string | null;
  options: string[];
  class?: string;
  disabled?: boolean;

  onChange?(value: string): void;
}

export class DateOption extends React.Component<Props> {
  public render(): JSX.Element {
    const classNames = ["date-option"];

    if (this.props.class) {
      classNames.push(this.props.class);
    }

    return (
      <div className={classNames.join(" ")}>
        <label
          className={
            !this.props.options || this.props.options.length === 0
              ? "date-label disabled"
              : "date-label"
          }
        >
          {this.props.label}
          {this.renderList()}
        </label>
      </div>
    );
  }

  private renderList() {
    return (
      <select
        data-testid={this.props.id + "-" + this.props.label}
        className="date-fill-input"
        value={this.props.selectedOption ?? ""}
        onClick={(e) => this.onClick(e)}
        onChange={(event) => this.onSelected(event.target.value)}
        disabled={!this.props.options || this.props.options.length === 0 || this.props.disabled}
      >
        <option value="-1"></option>
        {this.props.options.map((x, index) => (
          <option key={index}>{x}</option>
        ))}
      </select>
    );
  }

  private onSelected(value: string) {
    this.props.onChange && value !== "-1" && this.props.onChange(value);
    this.setState({ openList: false });
  }

  private onClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    event.stopPropagation();
  }
}
