import { Breakpoints } from "@src/customHooks";
import { Ticket } from "@src/types";
import { TicketMode } from "@src/utils/purchasedTickets";
import _ from "lodash";
import React, { FC } from "react";
import styled from "styled-components";

import DefaultImage from "./default.jpg";

const StyledImg = styled.img<{ screenSize: Breakpoints; mode: TicketMode }>`
  box-sizing: border-box;
  width: ${(props) =>
    props.screenSize === "desktop" && props.mode !== TicketMode.CART_PREVIEW ? `140px` : `85px`};
  height: ${(props) =>
    props.screenSize === "desktop" && props.mode !== TicketMode.CART_PREVIEW ? `140px` : `85px`};
  object-fit: cover;
  border: solid ${({ theme }) => theme.colors.values.white}};
  border-radius: 7px;
`;

export type ThumbnailProps = {
  ticket: Ticket;
  src?: string;
  alt: string;
  screenSize: Breakpoints;
  mode: TicketMode;
};

const Thumbnail: FC<ThumbnailProps> = ({ ticket, src, alt, mode, screenSize }) => (
  <StyledImg
    data-testid={ticket.id + "-THUMBNAIL"}
    mode={mode}
    screenSize={screenSize}
    src={!_.isEmpty(src) ? src : DefaultImage}
    alt={alt}
  />
);

export { Thumbnail };
