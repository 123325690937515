import { StyledIcon } from "@src/components/atoms/Icon";
import { DefaultTheme } from "@src/themes/default";
import React, { FC } from "react";
import styled, { useTheme } from "styled-components";

const TrashIcon = styled(StyledIcon)`
  margin-top: 1px;
  margin-left: 15px;
`;

export type TrashButtonProps = {
  testId?: string;
  disabled?: boolean;
  onClick?: () => void;
  style?: React.CSSProperties;
};

const TrashButton: FC<TrashButtonProps> = ({ testId, disabled, onClick, style }) => {
  const theme = useTheme() as DefaultTheme;
  return (
    <TrashIcon
      data-testid={testId}
      size={"18px"}
      hoverColor={theme.colors.values.red}
      clickable
      disabled={disabled}
      hoverEnabled
      className={`fa-light fa-trash-can-xmark`}
      onClick={onClick}
      style={style}
    />
  );
};

export { TrashButton };
