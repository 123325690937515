import React, { ChangeEventHandler, FC, FocusEventHandler } from "react";
import omit from "lodash/omit";

import {
  FieldWrapper,
  MaxLengthMessage,
  StyledRequiredLabel,
  StyledTextArea,
} from "@src/components/styles";
import { TextFieldProps } from "../types";
import { FieldError } from "@src/components/atoms/FieldError";
import { Label } from "@src/components/atoms/Label";
import { useTranslation } from "react-i18next";

type TextAreaFieldProps = TextFieldProps;

const TextAreaField: FC<TextAreaFieldProps> = ({
  label,
  id,
  value,
  isRequired,
  error,
  touched,
  maxLength,
  placeHolderText,
  setValue,
  saveAnswer,
}) => {
  const { t } = useTranslation();
  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (event) =>
    setValue({ ...value, value: event.target.value });

  const handleBlur: FocusEventHandler<HTMLTextAreaElement> = async (event) => {
    const newValue = { ...value, value: event.target.value };

    const answerId = saveAnswer ? await saveAnswer(omit(newValue, "__typename")) : "";

    setValue({
      ...newValue,
      answerId,
    });
  };

  return (
    <FieldWrapper id={id}>
      {isRequired ? <StyledRequiredLabel /> : null}
      <Label>{label}</Label>

      {maxLength && maxLength > 0 ? (
        <MaxLengthMessage>{t("labels.characterLimit", { length: maxLength })}</MaxLengthMessage>
      ) : null}
      <StyledTextArea
        data-testid={id}
        name={id}
        hasError={!!error && touched}
        value={value?.value || ""}
        maxLength={maxLength && maxLength > 0 ? maxLength : undefined}
        placeholder={placeHolderText ? placeHolderText : undefined}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <FieldError id={id} name={id} />
    </FieldWrapper>
  );
};

export { TextAreaField };
