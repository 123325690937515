import styled from "styled-components";

import { Flex } from "@src/components/layout/Page";

const StyledInput = styled.input`
  ${({ theme }) => `
    &:focus {
      outline: none !important;
      border: 2px solid ${theme.colors.fields.selected};
    }

    width: 100%;
    height: 100%;
    padding: 0 30px;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 300;

    position: absolute;
    border: 1px solid ${theme.colors.values.lightgrey};
  `}
`;

const StyledVerticalInput = styled.input`
  ${({ theme }) => `
    &:focus {
      outline: none !important;
      border: 2px solid ${theme.colors.fields.selected};
    }

    width: 100px;
    height: 100%;
    text-align: center;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 300;
    border: 1px solid ${theme.colors.values.lightgrey};
  `}
`;

const StyledHorizontalInput = styled.input<{ error?: string }>`
  ${({ theme, error }) => `
    &:focus {
      outline: none !important;
      border: 2px solid ${error ? theme.colors.values.red : theme.colors.fields.selected};
    }

    text-align: center;
    width: 100%;
    height: 100%;
    padding: 0 30px;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 300;

    position: absolute;
    border: 1px solid ${theme.colors.values.lightgrey};
  `}
`;

const HorizontalButton = styled.button.attrs({ type: "button" })`
  ${({ theme, disabled }) => `
    z-index: 1;
    top: 4px;
    width: 26px;
    height: 20px;
    text-align: center;
    position: absolute;
    border: 1px solid ${theme.colors.values.lightgrey};
    background-color: ${theme.colors.values.lightgrey};
    cursor: pointer;
    opacity: ${disabled ? "0.4" : "1"};

    ${NumericInputWrapper} &:first-child {
      left: 4px;
    }

    ${NumericInputWrapper} &:last-child {
      right: 4px;
    }
  `}
`;

type NumericInputWrapperProps = { width?: string; minWidth?: string; error?: boolean };

const NumericInputWrapper = styled(Flex)<NumericInputWrapperProps>`
  ${({ theme, width, minWidth, error }) => `
    position: relative;
    display: block;
    max-width: 400px;
    width: ${width || "100px"};
    min-width: ${minWidth || "100px"};
    height: 28px;
    border: 1px solid ${error ? theme.colors.values.red : "transparent"};

    b {
      font-size: 13px;
    }

    b:nth-child(2) {
      position: absolute;
      top: 2px;
      right: 2px;
      bottom: 50%;

      width: 2.26ex;
      border-color: ${theme.colors.values.lightgrey};
      border-style: solid;
      text-align: center;
      cursor: default;
      transition: all 0.1s;
      background: ${theme.colors.values.lightgrey};
      box-shadow: ${
        theme.colors.values.lightgrey
      } -1px -1px 3px inset, rgba(255, 255, 255, 0.701961) 1px 1px 3px inset;

      border-radius: 2px 2px 0px 0px;
      border-width: 1px 1px 0px;
    }

    b:nth-child(2) > i {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0px;
      height: 0px;
      border-width: 0px 0.6ex 0.6ex;
      border-color: transparent transparent rgba(0, 0, 0, 0.701961);
      border-style: solid;
      margin: -0.3ex 0px 0px -0.56ex;
    }

    b:nth-child(3) {
      position: absolute;
      right: 2px;
      bottom: 2px;
      width: 2.26ex;
      border-color: ${theme.colors.values.lightgrey};
      border-style: solid;
      text-align: center;
      cursor: default;
      transition: all 0.1s;
      background: ${theme.colors.values.lightgrey};
      box-shadow: ${
        theme.colors.values.lightgrey
      } -1px -1px 3px inset, rgba(255, 255, 255, 0.701961) 1px 1px 3px inset;
      top: 50%;
      border-radius: 0px 0px 2px 2px;
      border-width: 0px 1px 1px;
    }

    b:nth-child(3) > i {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0px;
      height: 0px;
      border-width: 0.6ex 0.6ex 0px;
      border-color: rgba(0, 0, 0, 0.701961) transparent transparent;
      border-style: solid;
      margin: -0.3ex 0px 0px -0.56ex;
    }
  `}
`;

const VerticalButton = styled.button.attrs({ type: "button" })`
  opacity: ${(props) => (props.disabled ? "0.4" : "1")};
`;

export {
  StyledInput,
  StyledVerticalInput,
  StyledHorizontalInput,
  HorizontalButton,
  NumericInputWrapper,
  VerticalButton,
};
