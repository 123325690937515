import React, { FC } from "react";

import { ControlType, NumericInputProps } from "./types";
import { Vertical } from "./components/Vertical";
import { Horizontal } from "./components/Horizontal";
import { Simple } from "./components/Simple";

const NumericInput: FC<NumericInputProps> = ({ controlType, type = "integer", ...rest }) => {
  switch (controlType) {
    case ControlType.Horizontal:
      return <Horizontal type={type} {...rest} />;

    case ControlType.Vertical:
      return <Vertical type={type} {...rest} />;

    default:
      return <Simple type={type} {...rest} />;
  }
};

export { NumericInput };
