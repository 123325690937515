import gql from "graphql-tag";

import { ResponseVersionFragment } from "./fragments";

const RESPONSE_VERSION_QUERY = gql`
  query Response($hash: String!) {
    Response(hash: $hash) {
      ...ResponseVersionFragment
    }
  }
  ${ResponseVersionFragment}
`;

export { RESPONSE_VERSION_QUERY };
