import omit from "lodash/omit";
import snakeCase from "lodash/snakeCase";

import { ModifiableSection } from "@src/constants/editingPermission";
import { DEFAULT_PHONE_MASK } from "@src/constants/validation";
import {
  Answer,
  AnswerInput,
  Attachment,
  Attendee,
  Field,
  FieldType,
  FormInfoQuery,
  LinkAttachment,
  Order,
  Question,
  ResponseVersion,
  Ticket,
} from "@src/types";
import { decodeHtmlCharCodes } from "./htmlCharcodes";
import { answerToAnswerInput, FormAttendee } from "./typeConverters";
import { getUnpaidOrderInfo } from "./purchasedTickets";

type GetBoolean = (form: FormInfoQuery["Form"] | null | undefined) => boolean;

type GetFields = (form: FormInfoQuery["Form"]) => Field[];

type GetAnswers = (form: FormInfoQuery["Form"]) => Answer[];

type GetTickets = (form: FormInfoQuery["Form"]) => Ticket[];

type GetAttachments = (form: FormInfoQuery["Form"]) => Attachment[];

type GetLinkAttachments = (form: FormInfoQuery["Form"]) => LinkAttachment[];

const getSigneeFields: GetFields = (form) =>
  form
    ? form.template &&
      form.template.signeeQuestions &&
      form.template.signeeFormPermissions !== ModifiableSection
      ? [...form.template.signeeQuestions, ...(form.signeeQuestions || [])]
      : form.signeeQuestions || []
    : [];

const getAttendeeFields: GetFields = (form) =>
  form
    ? form.template &&
      form.template.attendeeQuestions &&
      form.template.attendeeFormPermissions !== ModifiableSection
      ? [...form.template.attendeeQuestions, ...(form.attendeeQuestions || [])]
      : form.attendeeQuestions || []
    : [];

const getTickets: GetTickets = (form) =>
  form
    ? form.template &&
      form.template.ticketPermissions !== ModifiableSection &&
      form.template.tickets
      ? form.template.tickets.concat(form.tickets ?? [])
      : form.tickets ?? []
    : [];

const getAttachments: GetAttachments = (form) =>
  form
    ? form.template &&
      form.template.attachments &&
      form.template.attachmentsPermissions !== ModifiableSection
      ? [...form.template.attachments, ...(form.attachments || [])]
      : form.attachments || []
    : [];

const getLinkAttachments: GetLinkAttachments = (form) =>
  form
    ? form.template &&
      form.template.linkAttachments &&
      form.template.attachmentsPermissions !== ModifiableSection
      ? [...form.template.linkAttachments, ...(form.linkAttachments || [])]
      : form.linkAttachments || []
    : [];

const getPublicOrganizerFields: GetFields = (form) =>
  form?.template?.publicQuestions ?? form?.publicQuestions ?? [];

const getPublicOrganizerAnswers: GetAnswers = (form) => form?.publicOrganizerAnswers ?? [];

const hasSafePay: GetBoolean = (form) => form?.document?.organization?.hasSafePay ?? false;

/**
 * Returns the label with HTML tags and formatting
 * @param field
 * @returns
 */
const getLabel = (field: Field): string => {
  switch (field.type) {
    case FieldType.Equations:
      return getEquationFielLabel(field);
    case FieldType.IntegratedData:
      return getIntegratedDataFieldLabel(field);
    default:
      return getSimpleFieldLabel(field);
  }
};

/**
 * Returns the equation label with HTML tags and formatting
 * @param field
 * @returns
 */
const getEquationFielLabel = (field: Field): string =>
  field.equationSections && field.equationSections.length
    ? field.equationSections[0].labelText || ""
    : "";

/**
 * Returns the Integrated Date label with HTML tags and formatting
 * @param field
 * @returns
 */
const getIntegratedDataFieldLabel = (field: Field): string =>
  getSimpleFieldPlainLabel(field.fields ? field.fields[0] : field);

/**
 * Returns the field's label with HTML tags and formatting
 * @param field
 * @returns
 */
const getSimpleFieldLabel = (field: Field): string =>
  field.question && field.question.labelText
    ? field.question.labelText
    : field.question?.plainLabel || "";

/**
 * Returns the label without HTML tags and formatting
 * @param field
 * @returns
 */
const getPlainLabel = (field: Field): string => {
  switch (field.type) {
    case FieldType.Equations:
      return getEquationFieldName(field);
    case FieldType.IntegratedData:
      return getIntegratedDataFieldName(field);
    default:
      return getSimpleFieldPlainLabel(field);
  }
};

/**
 * Returns the Integrated Date label without HTML tags and formatting
 * @param field
 * @returns
 */
const getIntegratedDataFieldName = (field: Field): string =>
  getSimpleFieldPlainLabel(field.fields ? field.fields[0] : field);

/**
 * Returns the field's label without HTML tags and formatting
 * @param field
 * @returns
 */
const getSimpleFieldPlainLabel = (field: Field): string =>
  field.question && field.question.plainLabel
    ? decodeHtmlCharCodes(field.question.plainLabel)
    : field.question?.labelText
    ? decodeHtmlCharCodes(field.question.labelText)
    : "";

/**
 * Returns the equation label without HTML tags and formatting
 * @param field
 * @returns
 */
const getEquationFieldName = (field: Field): string =>
  field.equationSections && field.equationSections.length
    ? decodeHtmlCharCodes(field.equationSections[0].plainLabel || "")
    : "";

const getValueOptions = (field: Field): string[] =>
  field.question && field.question.valueOptions
    ? field.question.valueOptions.map(decodeHtmlCharCodes)
    : [];

const getPinnedValueOptions = (field: Field): string[] =>
  field.question && field.question.pinnedValueOptions
    ? field.question.pinnedValueOptions.map(decodeHtmlCharCodes)
    : [];

const getDateFormat = (field: Field): string =>
  field.question && field.question.format ? field.question.format : "";

const getPhoneFormat = (field: Field): string =>
  field.question && field.question.format
    ? field.question.format.replaceAll(/([0-9,a-z,A-Z])/g, "#")
    : DEFAULT_PHONE_MASK;

const getYesOrNoFields = (fields: Field[]): (Field[] | undefined)[] => {
  const [yesFieldContainer, noFieldContainer] = fields && fields.length === 2 ? fields : [];

  const yesFields =
    yesFieldContainer && yesFieldContainer.fields && yesFieldContainer.fields.length
      ? yesFieldContainer.fields
      : undefined;

  const noFields =
    noFieldContainer && noFieldContainer.fields && noFieldContainer.fields.length
      ? noFieldContainer.fields
      : undefined;

  return [yesFields, noFields];
};

const getFieldId = (field: Field, prefix?: string | null): string => {
  return prefix ? `${prefix}_${field.id}` : field.id;
};

/**
 * Generates a key to access the values in Formik's state.
 *
 * @param {Field} field Field object
 * @param {string} containerQuestionId ID of the container questions, e.g. an Address or Repeatable Field
 * @returns {string} A string with the ID of the container question and the prefixed ID of the provided field's question.
 */

const getFieldKey = (field: Field, containerQuestionId?: string): string => {
  const questionId = getFieldId(field, field.type);

  return containerQuestionId ? `${containerQuestionId}.${questionId}` : questionId;
};

const getEquationSectionId = (question: Question): string => {
  const questionName = question.id || "";

  return questionName;
};

const getCharacterLimit = (field: Field): number =>
  field.question && field.question.characterLimit ? field.question.characterLimit : -1;

const getPlaceholderText = (field: Field): string =>
  field.question && field.question.placeHolderText
    ? decodeHtmlCharCodes(field.question.placeHolderText || "")
    : "";

const getHeaderText = (field: Field): string => {
  const header = field.fields && field.fields.length ? field.fields[0] : undefined;

  return header ? getPlaceholderText(header) : "";
};

const getSubheaderText = (field: Field): string => {
  const subHeader = field.fields && field.fields.length === 2 ? field.fields[1] : undefined;

  return subHeader ? getPlaceholderText(subHeader) : "";
};

const getQuestionSnakeCaseName = (question: Question): string =>
  question.name ? snakeCase(question.name) : "";

const getQuestionDataValue = (question: Question): string => question.dataValue || "";

const getFormAttendees = (
  selectedResponseVersion: ResponseVersion | null | undefined
): FormAttendee[] => {
  const YES = "yes" as const;
  const NO = "no" as const;

  if (selectedResponseVersion) {
    const formAttendees = selectedResponseVersion?.attendees
      ? selectedResponseVersion.attendees.map((attendee) =>
          omit(
            {
              ...attendee,
              firstName: attendee.firstName || "",
              lastName: attendee.lastName || "",
              permitted:
                attendee.permitted === true ? YES : attendee.permitted === false ? NO : null,
              manualPermissionDate: attendee.manualPermissionDate ?? null,
              attachmentId: attendee.attachmentId ?? null,
              attachmentFileType: attendee.attachmentFileType ?? null,
              notes: attendee.notes ?? null,
            },
            ["__typename", "attendeeAnswers", "tickets", "integratedDataOnFile"]
          )
        )
      : [];

    return formAttendees;
  } else {
    return [];
  }
};

export type OrderInfo = {
  total: number;
  subtotal: number;
  cart: Attendee[];
  latestOrder?: Order;
  previousOrders: Order[];
  attendees?: Attendee[];
};

const getOrderInfo = (
  selectedResponseVersion: ResponseVersion | null | undefined,
  isPayNow: boolean
): OrderInfo | null => {
  if (selectedResponseVersion) {
    const order = !selectedResponseVersion.isCheckedOut
      ? selectedResponseVersion.orders.find((order) => !order.isCheckedOut)
      : null;

    return !order && isPayNow
      ? getUnpaidOrderInfo(selectedResponseVersion, [])
      : {
          total: selectedResponseVersion.total,
          subtotal: selectedResponseVersion.subTotal,
          cart: selectedResponseVersion.attendees ?? [],
          latestOrder: order as Order,
          previousOrders: selectedResponseVersion.previousOrders ?? [],
        };
  }

  return null;
};

const getAttendeesList = (
  selectedResponseVersion: ResponseVersion | undefined | null
): Attendee[] => {
  if (selectedResponseVersion) {
    return selectedResponseVersion.attendees || [];
  } else {
    return [];
  }
};

const getSigneeAnswers = (
  selectedResponseVersion: ResponseVersion | undefined | null
): AnswerInput[] => {
  if (selectedResponseVersion) {
    const signeeAnswers = selectedResponseVersion.signeeAnswers || [];

    return signeeAnswers.map(answerToAnswerInput);
  } else {
    return [];
  }
};

export type AttendeeAnswersDictionary = Record<string, AnswerInput[]>;

const getAttendeeAnswers = (
  selectedResponseVersion: ResponseVersion | undefined | null
): AttendeeAnswersDictionary => {
  if (selectedResponseVersion) {
    const attendeeAnswers: Record<string, AnswerInput[]> =
      selectedResponseVersion.attendees.reduce((acc, attendee) => {
        acc[attendee.responseAttendeeId] = attendee.attendeeAnswers.map(answerToAnswerInput);
        return acc;
      }, {} as AttendeeAnswersDictionary) || {};

    return attendeeAnswers;
  } else {
    return {};
  }
};

/**
 * Given an array of objects that have the "order" property, this function iterates
 * through them and returns the greatest order among them plus one.
 *
 * @param rows An array of objects that contain an "order" property.
 * @returns {Number} A number that represents the index of the next row.
 */

const getNextRowOrder = (rows: { order?: number | null }[]): number =>
  rows.length ? Math.max(...rows.map((row) => row.order || 0)) + 1 : 0;

type FindAnswerForQuestionsArgs = {
  answers: Answer[];
  questionId: string;
  fieldId: string;
  order?: number | null;
};

const findAnswerForQuestion = ({
  answers,
  questionId,
  fieldId,
  order = null,
}: FindAnswerForQuestionsArgs): Answer | undefined => {
  answers = answers ? answers : [];

  // check both the field id and question id b/c of old bad data where question ids are duplicated,
  // we can't just rely on the field id either. I mostly just cry quietly to myself at night.
  return order
    ? answers.find((x) => x.questionId === questionId && x.fieldId === fieldId && x.order === order)
    : answers.find((x) => x.questionId === questionId && x.fieldId === fieldId);
};

const isQuestionRequired = (question?: Question): boolean => question?.isRequired || false;

const getInnerFieldsFromConditional = (field: Field): Field[] => {
  if (
    field.type === FieldType.Conditional ||
    field.type === FieldType.SelectConditional ||
    field.type === FieldType.MultiFieldConditional
  ) {
    const fieldContainers = field.fields || [];
    return fieldContainers.flatMap((container) => container.fields || []);
  } else {
    console.error("This field is not a conditional field.");
    return [];
  }
};

export {
  getSigneeFields,
  getAttendeeFields,
  getPlainLabel,
  getLabel,
  getValueOptions,
  getPinnedValueOptions,
  getDateFormat,
  getPhoneFormat,
  getYesOrNoFields,
  getSimpleFieldPlainLabel,
  getEquationFieldName,
  getFieldKey,
  getCharacterLimit,
  getFieldId,
  getPlaceholderText,
  getHeaderText,
  getSubheaderText,
  getQuestionSnakeCaseName,
  getQuestionDataValue,
  getEquationSectionId,
  getFormAttendees,
  getAttendeesList,
  getSigneeAnswers,
  getAttendeeAnswers,
  getNextRowOrder,
  getOrderInfo,
  getTickets,
  hasSafePay,
  getAttachments,
  getLinkAttachments,
  findAnswerForQuestion,
  isQuestionRequired,
  getInnerFieldsFromConditional,
  getPublicOrganizerAnswers,
  getPublicOrganizerFields,

  //Testing exports
  getIntegratedDataFieldName as _getIntegratedDataFieldName,
};
