import { ApolloCache, DefaultContext, MutationUpdaterFunction } from "@apollo/client";

import { RESPONSE_VERSION_QUERY } from "@src/queries/responseVersion";
import {
  DeleteSigneeAnswerMutation,
  DeleteSigneeAnswerMutationVariables,
  ResponseQuery,
} from "@src/types";

type DeleteAnswerForRosteredResponseArgs = {
  cache: ApolloCache<unknown>;
  responseHashedId?: string;
  variables?: DeleteSigneeAnswerMutationVariables;
};

const deleteAnswerForResponse = ({
  cache,
  responseHashedId,
  variables,
}: DeleteAnswerForRosteredResponseArgs) => {
  cache.updateQuery<ResponseQuery>(
    {
      query: RESPONSE_VERSION_QUERY,
      variables: {
        hash: responseHashedId,
      },
      overwrite: true,
    },
    (cacheData: ResponseQuery | null) => {
      if (cacheData) {
        const responseVersion = cacheData.Response;

        if (responseVersion) {
          const newSigneeAnswers = responseVersion.signeeAnswers.filter((x) => {
            const variableOrder = variables?.answerInput.order || null;
            const answerOrder = x.order || null;

            return !(
              x.fieldId === variables?.answerInput?.fieldId &&
              x.questionId === variables?.answerInput?.questionId &&
              answerOrder === variableOrder
            );
          });

          return {
            ...cacheData,
            Response: {
              ...responseVersion,
              signeeAnswers: newSigneeAnswers,
            },
          };
        }
      }
    }
  );
};

type DeleteSigneeAnswerMutationUpdaterArgs = {
  responseHashedId: string;
};

type DeleteSigneeAnswerMutationUpdater = (
  args: DeleteSigneeAnswerMutationUpdaterArgs
) => MutationUpdaterFunction<
  DeleteSigneeAnswerMutation,
  DeleteSigneeAnswerMutationVariables,
  DefaultContext,
  ApolloCache<unknown>
>;

const deleteSigneeAnswerMutationUpdater: DeleteSigneeAnswerMutationUpdater =
  ({ responseHashedId }) =>
  (cache, _result, { variables }) => {
    deleteAnswerForResponse({ cache, responseHashedId, variables });
  };

export { deleteSigneeAnswerMutationUpdater };
