import React, { FC, useEffect } from "react";
import {
  FileViewerModalBackground,
  FileViewerModalClose,
  FileViewerModalContent,
  ImageView,
  PdfContainer,
} from "./style";
import { MimeType } from "@src/constants/mimeTypes";
import { PdfViewer } from "../PdfViewer/PdfViewer";

export type FileViewerProps = {
  viewUrl: string;
  mimeType: string;
  dismiss: () => void;
};

const FileViewer: FC<FileViewerProps> = ({ viewUrl, mimeType, dismiss }) => {

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        dismiss();
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FileViewerModalContent>
        <FileViewerModalClose className="fa fa-times-circle" aria-hidden="true" onClick={dismiss} />
        {mimeType === MimeType.PDF ? (
          <PdfContainer>
            <PdfViewer contentUri={viewUrl} />
          </PdfContainer>
        ) : (
          <ImageView src={viewUrl} />
        )}
      </FileViewerModalContent>
      <FileViewerModalBackground onClick={dismiss} />
    </>
  );
};

export { FileViewer };
