import { ModifiableSection } from "@src/constants/editingPermission";
import { DocumentVersion, Field, FieldType, FormInfoQuery } from "@src/types";

const hasAttendeeQuestions = (documentVersion: DocumentVersion | FormInfoQuery["Form"]): boolean =>
    ((documentVersion?.attendeeQuestions || false) && documentVersion.attendeeQuestions.length > 0) ||
    ((documentVersion.template?.attendeeQuestions || false) && documentVersion.template.attendeeQuestions.length > 0);

const hasAttendeeIntegratedDataQuestions = (documentVersion: DocumentVersion | FormInfoQuery["Form"]): boolean =>
    ((documentVersion?.attendeeQuestions || false) && documentVersion.attendeeQuestions.filter((x) => x.type === FieldType.IntegratedData).length > 0) ||
    ((documentVersion.template?.attendeeQuestions || false) && documentVersion.template.attendeeQuestions.filter((x) => x.type === FieldType.IntegratedData).length > 0);

const hasSigneeQuestions = (documentVersion: DocumentVersion | FormInfoQuery["Form"]): boolean =>
    ((documentVersion?.signeeQuestions || false) && documentVersion.signeeQuestions.length > 0) ||
    ((documentVersion.template?.signeeQuestions || false) && documentVersion.template.signeeQuestions.length > 0);

const hasTickets = (documentVersion: DocumentVersion | FormInfoQuery["Form"]): boolean =>
    ((documentVersion?.tickets || false) && documentVersion.tickets?.length > 0) ||
    ((documentVersion.template?.tickets || false) && documentVersion.template.tickets.length > 0 && documentVersion.template.ticketPermissions !== ModifiableSection);

const isGeneralSignup = (documentVersion: DocumentVersion | FormInfoQuery["Form"]): boolean =>
    (documentVersion || false) && (documentVersion.isGeneralSignup);

const getIntegratedDataFields = (documentVersion: DocumentVersion | FormInfoQuery["Form"]): Field[] =>
    hasAttendeeQuestions(documentVersion) ? documentVersion?.attendeeQuestions.filter((x) => x.type === FieldType.IntegratedData) : []

export {
    hasAttendeeQuestions,
    hasSigneeQuestions,
    hasTickets,
    isGeneralSignup,
    hasAttendeeIntegratedDataQuestions,
    getIntegratedDataFields
}