import React, {
  ChangeEventHandler,
  DragEventHandler,
  FC,
  useEffect,
  useRef,
  useState,
} from "react";
import PdfIcon from "@src/assets/pdf.png";
import FileIcon from "@src/assets/any.png";
import UploadIcon from "@src/assets/upload.png";
import { MimeType } from "@src/constants/mimeTypes";
import omit from "lodash/omit";
import { FieldHelperProps } from "formik";
import { v4 as uuidv4 } from "uuid";

import {
  FieldWrapper,
  FileThumbnail,
  PdfThumbnail,
  PreviewImage,
  ProgressMessage,
  StyledRequiredLabel,
  StyledUploadContainer,
  StyledUploadField,
  FieldInformationLabel,
  UploadButton,
  UploadImage,
  UploadPreview,
} from "@src/components/styles";
import { TrashButton } from "./TrashButton";
import { AnswerInput } from "@src/types";
import axios, { AxiosProgressEvent } from "axios";
import { FileViewer } from "@src/components/atoms/FileViewer";
import {
  FieldError,
  StyledErrorContainer,
  StyledFieldError,
} from "@src/components/atoms/FieldError";
import { useTranslation } from "react-i18next";
import { Flex } from "@src/components/layout/Page";
import { Icon } from "@src/components/atoms/Icon";
import { Label } from "@src/components/atoms/Label";
import { TranslationKey } from "@src/utils/validation";

type UploadFieldProps = {
  label: string;
  id: string;
  questionId: string;
  fieldId: string;
  answer: AnswerInput;
  error?: string;
  isRequired: boolean;
  uploadUrl?: string;
  viewFileUrl?: string;
  isManualAttachment?: boolean;
  saveAnswer?: (answer: AnswerInput) => Promise<string>;
  deleteAnswer?: (answer: AnswerInput) => Promise<unknown>;
  setValue: FieldHelperProps<AnswerInput>["setValue"];

  // used when the upload field is nested and the upload needs to be delayed
  // for example in repeatable fields
  fileSelected?: (fieldId: string, questionId: string, file: File) => void;
  fileRemoved?: (fieldId: string, questionId: string) => void;
};

export type SelectedFile = {
  data: string;
  name: string;
  mimeType: string;
};

const isImage = (type: string) => {
  return (
    type === MimeType.BMP || type === MimeType.PNG || type === MimeType.JPG || type === MimeType.GIF
  );
};

type UploadFieldError = {
  errorKey: TranslationKey | null;
  fileName: string;
};

const UploadField: FC<UploadFieldProps> = ({
  id,
  fieldId,
  questionId,
  label,
  answer,
  isRequired,
  uploadUrl,
  viewFileUrl,
  isManualAttachment,
  saveAnswer,
  deleteAnswer,
  setValue,
  fileSelected,
  fileRemoved,
}) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setFile] = useState<SelectedFile | null>(null);
  const [progress, setProgress] = useState<number>(100);
  const [showFile, setShowFile] = useState<boolean>(false);
  const [error, setError] = useState<UploadFieldError>({ errorKey: null, fileName: "" });
  const [userUploadedAFile, setUserUploadedAFile] = useState(false);

  const allowedFileTypes: string[] = [
    MimeType.BMP,
    MimeType.GIF,
    MimeType.JPG,
    MimeType.PNG,
    MimeType.PDF,
  ];

  useEffect(() => {
    const isInsideRepeatable = !!(fileSelected && fileRemoved);

    if (!answer?.answerId || isInsideRepeatable) {
      setUserUploadedAFile(false);
      setShowFile(false);
      setFile(null);
      if (inputRef?.current) inputRef.current.value = "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer.answerId]);

  const imageUrl =
    !userUploadedAFile && viewFileUrl && answer?.value && answer?.value !== "repeatable-upload"
      ? `${viewFileUrl}/${answer.value}`
      : selectedFile?.data;

  const canUpload = (file: File) => {
    let fileIsValid = false;

    if (file) {
      if (file.size > 10000000) {
        setError({ errorKey: "validation.fileOverMaxSize", fileName: file.name });
      } else if (!allowedFileTypes.includes(file.type)) {
        setError({ errorKey: "validation.fileIsOfWrongType", fileName: "" });
      } else {
        setError({ errorKey: null, fileName: "" });
        fileIsValid = true;
      }
    }

    return fileIsValid;
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      // TODO: show a validation error if the file can't be uploaded
      if (canUpload(file)) {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onloadend = () => {
          if (reader.result) {
            setFile({
              data: reader.result.toString(),
              name: file.name,
              mimeType: file.type,
            });
          }
        };

        await upload(file);
      }
    }
  };

  const onDropped: DragEventHandler<HTMLDivElement> = async (event) => {
    event.stopPropagation();
    event.preventDefault();

    if (event.dataTransfer.files && event.dataTransfer.files.length === 1) {
      const file = event.dataTransfer.files[0];

      // TODO: show a validation error if the file can't be uploaded
      if (canUpload(file)) {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onloadend = () => {
          if (reader.result) {
            setFile({
              data: reader.result.toString(),
              name: file.name,
              mimeType: file.type,
            });
          }
        };

        await upload(file);
      }
    }
  };

  const onDragOver: DragEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const upload = async (file: File) => {
    // User has uplaoded a file. From that point on
    // use only the local copy of that file to display the thumbnail.
    setUserUploadedAFile(true);

    if (canUpload(file) && uploadUrl) {
      const form = new FormData();
      form.append("file", file, file.name);
      form.append("fieldId", fieldId);
      form.append("questionId", questionId);

      const result = await axios.post(uploadUrl, form, {
        onUploadProgress: (p: AxiosProgressEvent) => setProgress(Math.ceil((p.loaded / (p.total ?? 1)) * 100)),
      });

      // only assign use the properties of an Answer that will pass validation
      const newAnswer = {
        answerId: result.data.answerId,
        fieldId: result.data.fieldId,
        questionId: result.data.questionId,
        value: result.data.value,
        fileType: result.data.fileType,
        order: result.data.order,
      };

      setValue(!isManualAttachment ? newAnswer : result.data);
      if (saveAnswer) {
        saveAnswer(newAnswer);
      }
    } else if (fileSelected) {
      // We're in a repeatable.
      // So do a fake upload until the answer is added.
      // At which point the real upload will be performed.
      const uploadAnswer = {
        answerId: uuidv4(),
        fieldId: fieldId,
        questionId: questionId,
        value: "repeatable-upload",
        fileType: file.type,
        order: 0,
      };

      setValue(uploadAnswer);

      fileSelected(fieldId, questionId, file);
    } else {
      // We're in preview mode.
      const uploadAnswer = {
        answerId: uuidv4(),
        fieldId: fieldId,
        questionId: questionId,
        value: "preview",
        fileType: file.type,
        order: 0,
      };

      setValue(uploadAnswer);

      if (saveAnswer) {
        saveAnswer(uploadAnswer);
      }
    }
  };

  const handleUploadClick = () => {
    if (inputRef.current && progress === 100) inputRef.current.click();
  };

  const handleTrashClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    setFile(null);

    if (inputRef.current) inputRef.current.value = "";
    const newAnswer: AnswerInput = omit(
      {
        ...answer,
        value: "",
      },
      "__typename"
    );

    setValue(newAnswer);

    if (deleteAnswer) {
      deleteAnswer(newAnswer);
    }

    if (fileRemoved) {
      fileRemoved(fieldId, questionId);
    }
  };

  const handleFileClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();

    if (selectedFile || answer) setShowFile(true);
  };

  return (
    <FieldWrapper>
      {showFile && imageUrl ? (
        <FileViewer
          viewUrl={imageUrl}
          mimeType={answer?.fileType ?? selectedFile?.mimeType ?? ""}
          dismiss={() => setShowFile(false)}
        />
      ) : null}
      {isRequired ? <StyledRequiredLabel /> : null}
      <Label>{label}</Label>

      <Flex flexDirection="column" alignItems="flex-end">
        <StyledUploadContainer
          flexDirection="column"
          alignItems="center"
          onClick={handleUploadClick}
          onDrop={onDropped}
          onDragOver={onDragOver}
        >
          {selectedFile || imageUrl ? (
            <UploadPreview onClick={handleFileClick}>
              <TrashButton
                testId={id + "-REMOVE"}
                onClick={handleTrashClick}
                title={t("labels.removeFile")}
              />
              {selectedFile ? (
                isImage(selectedFile.mimeType) ? (
                  <PreviewImage src={selectedFile.data} />
                ) : selectedFile.mimeType === MimeType.PDF ? (
                  <PdfThumbnail title={selectedFile.name} src={PdfIcon} />
                ) : (
                  <FileThumbnail title={selectedFile.name} src={FileIcon} />
                )
              ) : answer.fileType ? (
                isImage(answer.fileType) ? (
                  <PreviewImage src={imageUrl} />
                ) : answer.fileType === MimeType.PDF ? (
                  <PdfThumbnail src={PdfIcon} />
                ) : (
                  <FileThumbnail src={FileIcon} />
                )
              ) : null}
            </UploadPreview>
          ) : (
            <UploadImage src={UploadIcon} />
          )}
          <UploadButton
            data-testid={id + "-BUTTON"}
            completionPercentage={progress < 100 ? progress : 0}
          >
            {progress < 100 ? (
              <ProgressMessage>{progress}%</ProgressMessage>
            ) : selectedFile || imageUrl ? (
              t("labels.replaceFile")
            ) : (
              t("labels.addFile")
            )}
          </UploadButton>

          <StyledUploadField
            data-testid={id + "-UPLOAD"}
            ref={inputRef}
            name={id}
            onChange={handleChange}
            accept={allowedFileTypes.join(",")}
          />
        </StyledUploadContainer>
        <FieldInformationLabel>{t("validation.maxFileSize")}</FieldInformationLabel>
      </Flex>

      {error.errorKey && error.fileName ? (
        <StyledErrorContainer>
          <Icon style="solid" icon="circle-exclamation" color="#c00109" />
          <StyledFieldError>
            {t(error.errorKey, { fileName: error.fileName }) as string}
          </StyledFieldError>
        </StyledErrorContainer>
      ) : null}
      <FieldError id={id} name={id} />
    </FieldWrapper>
  );
};

export { UploadField };
