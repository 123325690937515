import React, { FunctionComponent } from "react";
import { ContentWrapper, Flex, PageWrapper } from "@src/components/layout/Page";
import { PageFooter } from "@src/components/molecules/pageFooter";
import {
  StyledHeader,
  StyledInformationContainer,
  StyledLabel,
  StyledSectionHeader,
  StyledTextInput,
} from "@src/components/styles";
import { FormInfoQuery, useSignUpForNotificationMutation } from "@src/types";
import { Button } from "@src/components/atoms/Button";
import { Field, Form, Formik } from "formik";
import { useBreakPoints, useUrlParams } from "@src/customHooks";
import * as Yup from "yup";
import { TranslateFormErrors } from "@src/customHooks/useTranslateFormErrors";
import { Trans, useTranslation } from "react-i18next";
import { Language, useGoogleTranslate } from "@src/utils/translation";
import { SelectLanguage } from "@src/components/molecules/pageHeader/SelectLanguage";

type FormInDraftProps = { form: NonNullable<FormInfoQuery["Form"]> };

const FormInDraft: FunctionComponent<FormInDraftProps> = ({ form }: FormInDraftProps) => {
  const { countryCode } = useUrlParams();
  const screenSize = useBreakPoints();
  const { t, i18n } = useTranslation();
  const googleTranslate = useGoogleTranslate();

  const onLanguageChanged = (language: Language) => {
    i18n.changeLanguage(language.code);
  };

  const [signUp, { data }] = useSignUpForNotificationMutation();

  const yupValidation = Yup.object().shape({ email: Yup.string().email() });
  const formName = googleTranslate(form.name || "");

  return (
    <>
      <PageWrapper>
        <ContentWrapper
          screenSize={screenSize}
          style={{ marginTop: screenSize === "desktop" ? "25px" : "0px" }}
        >
          <Flex flexDirection="column">
            <Flex justifyContent="space-between">
              <StyledHeader>{formName}</StyledHeader>
              <SelectLanguage onLanguageChanged={onLanguageChanged} />
            </Flex>

            {data?.SignUpForNotification ? (
              <StyledInformationContainer>
                <StyledSectionHeader>{t("pages.formInDraft.gotIt")}</StyledSectionHeader>
                <StyledLabel>{t("pages.formInDraft.youWillBeNotified", { formName })}</StyledLabel>
              </StyledInformationContainer>
            ) : (
              <StyledInformationContainer>
                <StyledSectionHeader>{t("pages.formInDraft.holdOn")}</StyledSectionHeader>
                <br />
                <StyledLabel style={{ fontWeight: "initial" }}>
                  {t("labels.organizer")} {form.organizerName}
                </StyledLabel>
                <br />
                <br />
                <StyledLabel style={{ fontWeight: "initial" }}>
                  <Trans i18nKey="pages.formInDraft.formIsBeingEdited" values={{ formName }}>
                    {t("pages.formInDraft.formIsBeingEdited")}
                  </Trans>
                </StyledLabel>
                <br />
                <br />
                <StyledLabel style={{ fontWeight: "initial" }}>
                  {t("pages.formInDraft.wouldYouLikeToBeNotified")}
                </StyledLabel>
                <br />
                <Formik
                  initialValues={{ email: "" }}
                  onSubmit={async (values) =>
                    signUp({
                      variables: {
                        email: values.email,
                        documentVersionId: form.id,
                        countryCode,
                      },
                    })
                  }
                  validationSchema={yupValidation}
                >
                  {({ isValid }) => (
                    <TranslateFormErrors>
                      <Form>
                        <Flex flexDirection="column">
                          <Field
                            name="email"
                            placeholder={t("pages.formInDraft.emailAddress")}
                            as={StyledTextInput}
                            style={{ margin: "15px 0px 15px 0px" }}
                          />
                          <Button primary disabled={!isValid} type="submit">
                            {t("pages.formInDraft.notifyMe")}
                          </Button>
                        </Flex>
                      </Form>
                    </TranslateFormErrors>
                  )}
                </Formik>
              </StyledInformationContainer>
            )}
          </Flex>
        </ContentWrapper>
      </PageWrapper>
      <PageFooter />
    </>
  );
};

export { FormInDraft };
