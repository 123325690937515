import styled from "styled-components";
import { Breakpoints } from "@src/customHooks";
import { Flex } from "@src/components/layout/Page";

export const StyledModal = styled.div<{ screenSize: Breakpoints }>`
  ${({ theme, screenSize }) => `
    display: flex;
    flex-direction: column;
    background: ${theme.colors.values.white};
    position: absolute;
    right: 85px;
    top: 100px;
    z-index: 999;
    width: ${screenSize === "desktop" ? "400px" : "250px"};

    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    border: 1px solid #e9e9e9;
    padding: 15px;
    border-radius: 10px;
  `}
`;

export const StyledModalContent = styled.div`
  max-height: 400px;
  overflow-y: auto;
`;

export const CartModalBoundaries = styled.div`
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const StyledCartContainer = styled(Flex)<{ screenSize: Breakpoints }>`
  margin-right: ${(props) => (props.screenSize === "desktop" ? `50px` : `0px`)};
`;
