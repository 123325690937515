import React, { FC } from "react";
import { Tax } from "@src/types";
import { CurrencyLabel } from "@src/components/styles";
import { Flex } from "@src/components/layout/Page";
import { currencyFormatter } from "@src/utils/purchasedTickets";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { useFormInfo } from "@src/customHooks/useFormInfo";

type TotalsProps = {
  subtotal: number;
  taxes?: Tax;
  baseFee?: number;
  serviceFee?: number;
  showFees?: boolean;
  isFeesAssigned?: boolean;
  total?: number;
  showTotal?: boolean;
};

const Totals: FC<TotalsProps> = ({
  subtotal,
  taxes,
  serviceFee,
  baseFee,
  showFees,
  isFeesAssigned,
  total,
  showTotal,
}) => {
  const { t, i18n } = useTranslation();
  const { form } = useFormInfo();

  const currencyCode = form?.document?.organization?.currencyCode;

  return (
    <Flex flexDirection="column" gap={10} style={{ margin: "10px" }}>
      <Flex justifyContent="space-between">
        <CurrencyLabel>{t("totals.subtotal")}:</CurrencyLabel>
        <CurrencyLabel data-testid={"SUBTOTAL-AMOUNT"}>
          {currencyFormatter(i18n.resolvedLanguage, currencyCode).format(subtotal)}
        </CurrencyLabel>
      </Flex>
      {showFees && (
        <>
          <Flex justifyContent="space-between">
            <CurrencyLabel>{t("totals.serviceFee")}:</CurrencyLabel>
            <CurrencyLabel data-testid={"SERVICEFEE-AMOUNT"}>
              {isFeesAssigned && serviceFee !== undefined
                ? currencyFormatter(i18n.resolvedLanguage, currencyCode).format(serviceFee)
                : t("totals.tbd")}
            </CurrencyLabel>
          </Flex>
          {baseFee && baseFee > 0 && (
            <Flex justifyContent="space-between">
              <CurrencyLabel>{t("totals.transactionFee")}:</CurrencyLabel>
              <CurrencyLabel data-testid={"TRANSACTIONFEE-AMOUNT"}>
                {currencyFormatter(i18n.resolvedLanguage, currencyCode).format(baseFee)}
              </CurrencyLabel>
            </Flex>
          )}
          {taxes && taxes.gst > 0 && (
            <Flex justifyContent="space-between">
              <CurrencyLabel>{t("totals.gst")}:</CurrencyLabel>
              <CurrencyLabel data-testid={"GST-AMOUNT"}>
                {currencyFormatter(i18n.resolvedLanguage, currencyCode).format(taxes.gst)}
              </CurrencyLabel>
            </Flex>
          )}
          {taxes && taxes.pst > 0 && (
            <Flex justifyContent="space-between">
              <CurrencyLabel>{t("totals.pst")}:</CurrencyLabel>
              <CurrencyLabel data-testid={"PST-AMOUNT"}>
                {currencyFormatter(i18n.resolvedLanguage, currencyCode).format(taxes.pst)}
              </CurrencyLabel>
            </Flex>
          )}
        </>
      )}
      {showTotal && (
        <Flex justifyContent="space-between">
          <CurrencyLabel style={{ fontWeight: "600" }}>{t("totals.total")}:</CurrencyLabel>
          <CurrencyLabel data-testid={"TOTAL-AMOUNT"} style={{ fontWeight: "600" }}>
            {total !== undefined ? (
              currencyFormatter(i18n.resolvedLanguage, currencyCode).format(total)
            ) : showFees ? (
              <span
                data-tip
                data-for="fee-calculation-explanation"
                style={{ textDecorationStyle: "dotted", textDecorationLine: "underline" }}
              >
                {`${currencyFormatter(i18n.resolvedLanguage, currencyCode).format(subtotal)} + ${t(
                  "totals.fees"
                )}`}
              </span>
            ) : (
              t("totals.tbd")
            )}
          </CurrencyLabel>
          <ReactTooltip id="fee-calculation-explanation" effect="solid">
            {t("totals.feeCalculationExplanation")}
          </ReactTooltip>
        </Flex>
      )}
    </Flex>
  );
};

export { Totals };
