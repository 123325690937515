import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { ObjectShape } from "yup/lib/object";
import { MAX_LENGTH, MIN_LENGTH } from "@src/constants/validation";

const useSigneeInfoValidation = (hasMultipleTags: boolean): Yup.ObjectSchema<ObjectShape> => {
  const { t } = useTranslation();

  let schema = Yup.object().shape({
    firstName: Yup.string()
      .min(
        MIN_LENGTH,
        t("validation.minCharacters", {
          field: "First Name",
          length: MIN_LENGTH,
        })
      )
      .max(
        MAX_LENGTH,
        t("validation.maxCharacters", {
          field: "First Name",
          length: MAX_LENGTH,
        })
      )
      .required(t("validation.required", { field: "First Name" })),

    lastName: Yup.string()
      .min(
        MIN_LENGTH,
        t("validation.minCharacters", {
          field: "Last Name",
          length: MIN_LENGTH,
        })
      )
      .max(
        MAX_LENGTH,
        t("validation.maxCharacters", {
          field: "Last Name",
          length: MAX_LENGTH,
        })
      )
      .required(t("validation.required", { field: "Last Name" })),

    email: Yup.string()
      .email(t("validation.validEmail"))
      .required(t("validation.required", { field: "E-mail" })),
  });

  if (hasMultipleTags) {
    schema = schema.concat(
      Yup.object().shape({
        tagId: Yup.string().required(t("validation.required", { field: "Tag" })),
      })
    );
  }

  return schema;
};

export { useSigneeInfoValidation };
