import { ResponseResumeAction } from "@src/constants/responseResumeActions";
import { ResponseState } from "@src/constants/responseStates";
import { PaidStatus, ResponseSource, ResponseStatus, ResponseVersion } from "@src/types";
import { i18next } from "../translations";

export class ResumeListItem {
  public responseVersion: ResponseVersion;
  public currentFormId: string;
  public responseEditsArePermitted: boolean;

  constructor(
    responseVersion: ResponseVersion,
    currentFormId: string,
    responseEditsArePermitted: boolean
  ) {
    this.responseVersion = responseVersion;
    this.currentFormId = currentFormId;
    this.responseEditsArePermitted = responseEditsArePermitted;
  }

  responseCreatedOnLatestFormVersion = () => {
    return this.responseVersion.documentVersionId === this.currentFormId;
  };

  canResume = () => {
    if (this.responseVersion.isRostered) {
      return (
        !this.responseVersion.isSigned &&
        this.isStarted() &&
        this.responseCreatedOnLatestFormVersion()
      );
    }

    return this.isStarted() && this.responseCreatedOnLatestFormVersion();
  };

  canPay = () => {
    const checkedOutOrders = this.responseVersion.orders?.filter((x) => x.isCheckedOut);

    return (
      checkedOutOrders.length > 0 && this.responseVersion.orderStatus == PaidStatus.PaymentRequired
    );
  };

  canAddNewVersion = () => {
    return this.responseEditsArePermitted &&
      this.responseVersion.isSigned &&
      this.responseVersion.source !== ResponseSource.Process &&
      !this.isCancelled();
  };

  isCancelled = () => {
    return this.responseVersion.status === ResponseStatus.Cancelled;
  }

  canUpdateFormVersion = () => {
    return this.isStarted() && !this.responseCreatedOnLatestFormVersion();
  }

  isRejected = () => {
    return this.responseVersion.status === ResponseStatus.Rejected;
  };

  isStarted = () => {
    return this.responseVersion.status === ResponseStatus.Started;
  };

  isPendingApproval = () => {
    return (
      this.responseVersion.status === ResponseStatus.PendingApproval ||
      this.responseVersion.status === ResponseStatus.Pending
    );
  };

  canRestart = () => {
    if (this.responseVersion.isRostered || this.responseVersion.source === ResponseSource.Process || this.isCancelled()) {
      return false;
    }

    return (
      (!this.isRejected() &&
        ((this.responseVersion.isSigned && !this.canAddNewVersion()) ||
          (!this.responseVersion.isSigned && !this.responseCreatedOnLatestFormVersion()))) ||
      (this.isRejected() && !this.canAddNewVersion())
    );
  };

  canStart = () => {
    return !this.responseVersion.isSigned && !this.isStarted();
  };

  state = () => {
    return this.canStart()
      ? ResponseState.StartResponse
      : this.isStarted()
      ? ResponseState.ResponseInProgress
      : this.isPendingApproval()
      ? ResponseState.ResponsePendingApproval
      : this.isRejected()
      ? ResponseState.ResponseRejected 
      : this.isCancelled() 
      ? ResponseState.ResponseCancelled 
      : ResponseState.ResponseComplete;
  };

  title = () => {
    return i18next.t(`pages.selectResponse.responseStates.${this.state().toString()}`);
  };

  buttonText = () => {
    if (this.isCancelled()) 
      return "";

    return this.canStart()
      ? i18next.t("pages.selectResponse.startResponse")
      : this.canResume()
      ? i18next.t("pages.selectResponse.resumeResponse")
      : this.isStarted() && !this.responseCreatedOnLatestFormVersion()
      ? i18next.t("pages.selectResponse.continueResponse")
      : this.canAddNewVersion()
      ? i18next.t("pages.selectResponse.editResponse")
      : this.canRestart()
      ? i18next.t("pages.selectResponse.restartResponse")
      : "";
  };

  body = () => {
    if (this.isCancelled()) 
      return i18next.t("pages.selectResponse.responseCancelled")

    if (this.responseVersion.isRostered) {
      return this.canResume()
        ? i18next.t("pages.selectResponse.resumeAndSubmit")
        : this.canUpdateFormVersion()
        ? i18next.t("pages.selectResponse.formHasBeenUpdated")
        : this.canAddNewVersion() && this.responseVersion.isSigned
        ? i18next.t("pages.selectResponse.resumeAndSubmit")
        : this.canPay() && this.responseVersion.isSigned
        ? i18next.t("pages.selectResponse.responseSubmittedButNotPaid")
        : this.canStart()
        ? i18next.t("pages.selectResponse.startAndSubmit")
        : null;
    }

    return this.canStart()
      ? i18next.t("pages.selectResponse.startAndSubmit")
      : this.canResume()
      ? i18next.t("pages.selectResponse.resumeAndSubmit")
      : this.canUpdateFormVersion()
      ? i18next.t("pages.selectResponse.formHasBeenUpdated")
      : this.canRestart() && this.isRejected()
      ? i18next.t("pages.selectResponse.responseCompleteButNotApproved")
      : this.canRestart() && this.responseVersion.isSigned
      ? i18next.t("pages.selectResponse.responseCompletePleaseRestart")
      : this.canRestart()
      ? i18next.t("pages.selectResponse.pleaseClickRestart")
      : this.canAddNewVersion()
      ? i18next.t("pages.selectResponse.editsMustBeFilled")
      : !this.canAddNewVersion() && this.responseVersion.source === ResponseSource.Process
      ? i18next.t("pages.selectResponse.inProcessEditsMustBeFilled")
      : this.canPay()
      ? i18next.t("pages.selectResponse.responseSubmittedButNotPaid")
      : null;
  };

  resumeAction = () => {
    if (this.isCancelled()) 
      return ResponseResumeAction.None;

    if (this.responseVersion.isRostered) {
      return this.canResume() || this.canStart()
        ? ResponseResumeAction.Resume
        : this.canUpdateFormVersion()
        ? ResponseResumeAction.Update
        : this.canAddNewVersion() && this.responseVersion.isSigned
        ? ResponseResumeAction.Edit
        : ResponseResumeAction.None;
    }

    return this.canStart() || this.canResume()
      ? ResponseResumeAction.Resume
      : this.canUpdateFormVersion()
      ? ResponseResumeAction.Update
      : this.canAddNewVersion() && this.responseVersion.isSigned
      ? ResponseResumeAction.Edit
      : this.canRestart()
      ? ResponseResumeAction.Restart
      : ResponseResumeAction.None;
  };
}
