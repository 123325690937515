import { useApolloClient, useReactiveVar } from "@apollo/client";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";
import { AttachmentComponent } from "@src/components/atoms/Attachment";
import { Button } from "@src/components/atoms/Button";
import { Froala } from "@src/components/atoms/Froala";
import { LinkAttachmentComponent } from "@src/components/atoms/LinkAttachment";
import { PrimaryAttachment } from "@src/components/atoms/PrimaryAttachment";
import { ContentWrapper, PageWrapper } from "@src/components/layout";
import { Flex, Push } from "@src/components/layout/Page";
import { DisplayAnswers } from "@src/components/molecules/displayAnswers/DisplayAnswers";
import { LoadingModal } from "@src/components/molecules/loadingModal/LoadingModal";
import { PageFooter } from "@src/components/molecules/pageFooter";
import { PageHeader } from "@src/components/molecules/pageHeader";
import { AttendeesPreview } from "@src/components/molecules/preview/AttendeesPreview";
import { Totals } from "@src/components/molecules/totals";
import {
  StyledDivider,
  StyledErrorContainer,
  StyledErrorText,
  StyledLabel,
  StyledReviewHeader,
  StyledReviewMessage,
} from "@src/components/styles";
import { useBreakPoints, useUrlParams } from "@src/customHooks";
import { useFormInfo } from "@src/customHooks/useFormInfo";
import {
  AccessDataAnswers,
  IsPayNow,
  OrderId,
  RequestError,
  ResponseAlreadySubmitted,
  ResponseVersionHash,
} from "@src/localVariables";
import { CancelTicketAnswerResult } from "@src/mutations/mutationTypes";
import { acceptTermsUpdater } from "@src/mutations/updaterFunctions/acceptTerms";
import { cancelTicketMutationUpdater } from "@src/mutations/updaterFunctions/updateTickets";
import { RESPONSE_VERSIONS_QUERY } from "@src/queries/responseVersions";
import { ConfirmationRoute, PreviewProps, ReviewRoute, SigneeInfoRoute } from "@src/Routes";
import { PaidStatus, Tax, useAcceptTermsMutation, useCancelTicketMutation } from "@src/types";
import {
  getAttachments,
  getAttendeeFields,
  getLinkAttachments,
  getOrderInfo,
  getPublicOrganizerAnswers,
  getPublicOrganizerFields,
  getSigneeAnswers,
  getSigneeFields,
  getTickets,
} from "@src/utils/getters";
import {
  CancelTicketArgs,
  PaymentMethod,
  PaymentMethodType,
  TicketMode,
} from "@src/utils/purchasedTickets";
import { scrollToTop, useScrollToTop } from "@src/utils/scrollToTop";
import { Token } from "@stripe/stripe-js";
import { Form, Formik } from "formik";
import { ErrorComponent } from "../Error/Error";
import { AcceptTermsAndSubmit } from "./components/AcceptTermsAndSubmit";
import { PaymentSection } from "./Payment/PaymentSection";
import { useValidateAnswers } from "@src/utils/validation";
import { Icon } from "@src/components/atoms/Icon";
import { useCachedResponseVersion } from "@src/customHooks/useResponseVersion";
import { isSigned, signeeInfo } from "@src/utils/responseVersionGetters";
import { hasTickets, isGeneralSignup } from "@src/utils/formGetters";
import { useGoogleTranslate } from "@src/utils/translation";
import { TranslateFormErrors } from "@src/customHooks/useTranslateFormErrors";

export type PaymentForm = {
  selectedPaymentMethod: PaymentMethod | null;
  serviceFee?: number;
  stripeToken?: Token | null;
  subtotal: number;
  tax?: Tax;
  total?: number;
  isTermsAccepted: boolean;
  isOrgTermsAccepted: boolean;
  isServiceFeeAssigned: boolean;
};
export type PaymentFormKeys = keyof PaymentForm;

const Review: FC<PreviewProps> = ({ isPreview }) => {
  const { t, i18n } = useTranslation();
  const googleTranslate = useGoogleTranslate();
  const navigate = useNavigate();
  const screenSize = useBreakPoints();
  const { baseUrl, countryCode, markAsVisited } = useUrlParams();
  const responseVersionHash = useReactiveVar(ResponseVersionHash);
  const accessData = useReactiveVar(AccessDataAnswers) || [];
  const client = useApolloClient();
  const [isUpdatingCart, setUpdatingCart] = useState(false);
  const formInfo = useFormInfo();
  const isPayNow = useReactiveVar(IsPayNow) || false;
  const orderId = useReactiveVar(OrderId) || "";
  const selectedLanguage = i18n.resolvedLanguage;

  useScrollToTop();

  const responseVersionQuery = useCachedResponseVersion(responseVersionHash);

  const [acceptTerms] = useAcceptTermsMutation({
    update: acceptTermsUpdater(responseVersionHash),
  });

  const [cancelTicket] = useCancelTicketMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: RESPONSE_VERSIONS_QUERY,
        variables: {
          documentId: formInfo.form?.documentId,
          countryCode,
          email: signeeInfo(responseVersionQuery.responseVersion).email,
        },
      },
    ],
  });

  const signeeFields = getSigneeFields(formInfo.form);
  const signeeAnswers = getSigneeAnswers(responseVersionQuery.responseVersion);
  const attendeeFields = getAttendeeFields(formInfo.form);
  const tickets = getTickets(formInfo.form);
  const orderInfo = getOrderInfo(responseVersionQuery.responseVersion, isPayNow);

  const listOfValidationErrors = useValidateAnswers(
    formInfo.form?.requiresPermission,
    formInfo.form?.respondentMustSelectATicket && hasTickets(formInfo.form),
    formInfo.form?.isGeneralSignup,
    orderInfo,
    signeeFields,
    signeeAnswers,
    attendeeFields,
    responseVersionQuery.responseVersion?.attendees,
    tickets,
    responseVersionQuery.responseVersion?.orders
  );

  if (formInfo.loading || responseVersionQuery.loading) return <LoadingModal />;
  if (formInfo.error) return <ErrorComponent error={formInfo.error} />;
  if (responseVersionQuery.error)
    return <ErrorComponent error={responseVersionQuery.error ?? ""} />;

  const form = formInfo.form;
  const responseVersion = responseVersionQuery.responseVersion;
  const passAlongFees = tickets?.some((ticket) => ticket.passAlongFees) ?? false;
  const hasSafePay = formInfo.form?.document?.organization?.hasSafePay ?? false;
  const formInformation = googleTranslate(form?.information || "");
  const attachments = getAttachments(form);
  const linkAttachments = getLinkAttachments(form);
  const primaryAttachment = attachments.find((x) => x.isPrimary);
  const hasTicketsInCart =
    orderInfo && orderInfo?.cart.some((attendee) => attendee?.tickets?.length > 0);
  const publicOrganizerFields = getPublicOrganizerFields(formInfo.form);
  const publicOrganizerAnswers = getPublicOrganizerAnswers(formInfo.form);
  const guardianRole = googleTranslate(form?.document?.organization?.roleLabels?.guardian || "");

  if (
    !baseUrl ||
    !responseVersion ||
    (isSigned(responseVersion) && responseVersion.orderStatus !== PaidStatus.PaymentRequired)
  )
    return <Navigate to={`${window.location.pathname.replace(`/${ReviewRoute}`, "")}`} />;

  markAsVisited("review");

  const onAcceptTerms = async (stripeToken?: string) => {
    if (!isPreview) {
      const result = await acceptTerms({
        variables: {
          countryCode,
          documentId: form.documentId,
          responseVersionId: responseVersion.responseVersionId,
          accessData,
          paymentToken: stripeToken,
          orderId,
          languageCode: selectedLanguage,
        },
      });

      if (result) {
        if (!result.errors && result.data && result.data.AcceptTerms) {
          navigate(`${baseUrl}/${ConfirmationRoute}`);
        } else if (result.errors && result.errors.length > 0) {
          const errorCode = result.errors[0].message.match(/\d+/);
          if (errorCode && errorCode[0]) {
            switch (parseInt(errorCode[0])) {
              case 100: // Submitted responses cannot be modified
                navigate(`${baseUrl}/${ConfirmationRoute}`);
                ResponseAlreadySubmitted(true);
                break;
              default:
                RequestError(result.errors[0]);
                scrollToTop();
                break;
            }
          } else {
            RequestError(result.errors[0]);
            scrollToTop();
          }
        } else {
          RequestError({
            message:
              "An unrecoverable error occurred, please refresh your page and try to submit again",
            nodes: undefined,
            source: undefined,
            positions: undefined,
            path: undefined,
            originalError: undefined,
            locations: undefined,
            name: "failed",
            extensions: {},
          });
          scrollToTop();
        }
      }
    } else {
      acceptTermsUpdater(responseVersionHash)(
        client.cache,
        {},
        {
          variables: {
            countryCode,
            documentId: form.documentId,
            responseVersionId: responseVersion.responseVersionId,
            languageCode: selectedLanguage
          },
        }
      );
      navigate(`${baseUrl}/${ConfirmationRoute}`);
    }
  };

  const onCancelTicket = async ({
    ticketVersionId,
    responseAttendeeId,
    purchasedTicketIds,
  }: CancelTicketArgs) => {
    setUpdatingCart(true);

    await cancelTicket({
      variables: {
        countryCode,
        documentId: form.documentId,
        responseVersionId: responseVersion.responseVersionId,
        responseAttendeeId: responseAttendeeId,
        ticketVersionId: ticketVersionId,
        purchasedTicketIds: purchasedTicketIds,
        orderId,
      },
    }).then(() => {
      setUpdatingCart(false);
    });
  };

  const previewOnCancelTicket = ({
    ticketVersionId,
    responseAttendeeId,
    purchasedTicketIds,
  }: CancelTicketArgs): Promise<CancelTicketAnswerResult> => {
    const result = { CancelTicket: true };
    const updater = cancelTicketMutationUpdater(signeeInfo(responseVersion));
    if (updater) {
      updater(
        client.cache,
        { data: result },
        {
          variables: {
            countryCode,
            documentId: form.documentId,
            responseVersionId: responseVersion.responseVersionId,
            responseAttendeeId: responseAttendeeId,
            ticketVersionId: ticketVersionId,
            purchasedTicketIds: purchasedTicketIds,
            orderId,
          },
        }
      );
    }
    return Promise.resolve({ data: result, errors: undefined });
  };

  const initialValues: PaymentForm = {
    stripeToken: null,
    subtotal: orderInfo?.subtotal ?? 0,
    total: orderInfo?.subtotal ?? 0,
    tax: { gstLabel: "0.00", pstLabel: "0.00", gst: 0, pst: 0, total: 0 },
    serviceFee: 0,
    selectedPaymentMethod: null,
    isTermsAccepted: false,
    isOrgTermsAccepted: false,
    isServiceFeeAssigned: false,
  };

  return (
    <>
      <PageWrapper>
        <PageHeader isPreview={isPreview} />
        <ContentWrapper screenSize={screenSize}>
          {isUpdatingCart && <LoadingModal message={t("pages.review.updatingCart")} />}
          {!isSigned && (
            <Flex flexDirection="column">
              <StyledReviewHeader>{t("pages.review.almostDone")}</StyledReviewHeader>
              <StyledReviewMessage>{t("pages.review.reviewMessage")}</StyledReviewMessage>
              <StyledDivider />
            </Flex>
          )}
          <Flex>
            <Flex flexDirection="column" style={{ width: "100%" }}>
              <Flex alignItems="center" justifyContent="space-between" style={{ width: "100%" }}>
                <StyledLabel style={{ fontSize: "18px" }}>{t("pages.review.summary")}</StyledLabel>
                <Button
                  primary
                  disabled={isPayNow}
                  onClick={() => navigate(`${baseUrl}/${SigneeInfoRoute}`)}
                >
                  {t("labels.edit")}
                </Button>
              </Flex>
              <Flex>
                <DisplayAnswers
                  shouldTranslateAnswers
                  fields={publicOrganizerFields}
                  answers={publicOrganizerAnswers}
                  countryCode={countryCode}
                  documentId={form.documentId}
                  responseVersionId={responseVersion.responseVersionId}
                  uploadedFileViewBaseUrl={`${
                    process.env.DOCUMENTS_URL
                  }media/content/${countryCode.toLowerCase()}/get`}
                />
              </Flex>
              <Froala text={formInformation} />
              {primaryAttachment ? <PrimaryAttachment attachment={primaryAttachment} /> : null}
              {attachments && attachments.length > 0 ? (
                <Flex flexDirection="column" style={{ marginBottom: "15px" }}>
                  <StyledLabel>{t("pages.review.imagesAndDocuments")}</StyledLabel>
                  {attachments.map((attachment) => (
                    <AttachmentComponent key={attachment.id} attachment={attachment} />
                  ))}
                </Flex>
              ) : null}
              {linkAttachments && linkAttachments.length > 0 ? (
                <Flex flexDirection="column" style={{ marginBottom: "15px" }}>
                  <StyledLabel>{t("pages.review.externalLinks")}</StyledLabel>
                  {linkAttachments.map((linkAttachment) => (
                    <LinkAttachmentComponent key={linkAttachment.id} attachment={linkAttachment} />
                  ))}
                </Flex>
              ) : null}
            </Flex>
          </Flex>
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => onAcceptTerms(values.stripeToken?.id)}
            enableReinitialize
          >
            {({ values, isSubmitting }) => {
              return (
                <TranslateFormErrors>
                  <>
                    {isSubmitting ? (
                      <LoadingModal message={t("messages.submittingResponse")} />
                    ) : null}
                    <Flex flexDirection="column">
                      <StyledLabel style={{ fontSize: "18px", marginTop: "25px" }}>
                        {t("pages.review.form", {
                          form: isGeneralSignup(form) ? "" : guardianRole,
                        }).trim()}
                      </StyledLabel>
                      {signeeFields && signeeFields.length ? (
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                          style={{ width: "100%" }}
                        >
                          <DisplayAnswers
                            fields={signeeFields}
                            answers={signeeAnswers}
                            countryCode={countryCode}
                            documentId={form.documentId}
                            responseVersionId={responseVersion.responseVersionId}
                          />
                        </Flex>
                      ) : null}
                      <>
                        <AttendeesPreview
                          tickets={tickets}
                          attendees={orderInfo?.cart ?? []}
                          mode={TicketMode.CHECKOUT}
                          fields={attendeeFields || []}
                          countryCode={countryCode}
                          form={formInfo.form}
                          responseVersionId={responseVersion.responseVersionId}
                          onCancelTicket={
                            !isPreview
                              ? !isPayNow
                                ? onCancelTicket
                                : undefined
                              : previewOnCancelTicket
                          }
                        />
                        {listOfValidationErrors.length > 0 ? (
                          <Flex flexDirection="column">
                            <StyledLabel style={{ fontSize: "18px", marginTop: "25px" }}>
                              {t("labels.errors")}
                            </StyledLabel>
                            <Flex flexDirection="column" gap={10} style={{ width: "fit-content" }}>
                              {listOfValidationErrors.map((validationError) => (
                                <StyledErrorContainer key={validationError.message}>
                                  <Flex gap={10}>
                                    <Icon style="solid" icon="circle-exclamation" color="#e01313" />
                                    <StyledErrorText>{validationError.message}</StyledErrorText>
                                  </Flex>
                                  <Button
                                    onClick={() => {
                                      navigate(validationError.route);
                                    }}
                                    style={{ height: "fit-content", minWidth: "auto" }}
                                    primary
                                  >
                                    {t("labels.fixNow")}
                                  </Button>
                                </StyledErrorContainer>
                              ))}
                            </Flex>
                          </Flex>
                        ) : null}
                        {hasTicketsInCart && (
                          <>
                            {values?.total &&
                            values.total > 0 &&
                            listOfValidationErrors.length === 0 ? (
                              <>
                                <StyledDivider />
                                <PaymentSection
                                  passAlongFees={passAlongFees}
                                  responseOrganizationId={responseVersion.responseOrganizationId}
                                />
                              </>
                            ) : null}
                            <StyledDivider />
                            <Totals
                              subtotal={values.subtotal ?? 0}
                              total={values.total}
                              taxes={values.tax}
                              serviceFee={values.serviceFee}
                              showTotal={true}
                              showFees={hasSafePay && passAlongFees}
                              isFeesAssigned={
                                values.isServiceFeeAssigned ||
                                values.selectedPaymentMethod?.type === PaymentMethodType.MANUAL
                              }
                            />
                          </>
                        )}
                      </>
                    </Flex>
                    <StyledDivider />
                    {listOfValidationErrors.length === 0 ? (
                      <Form>
                        <AcceptTermsAndSubmit />
                      </Form>
                    ) : null}
                  </>
                </TranslateFormErrors>
              );
            }}
          </Formik>
        </ContentWrapper>
        <Push />
      </PageWrapper>
      <PageFooter />
    </>
  );
};

export { Review };
