import { useApolloClient, useReactiveVar } from "@apollo/client";
import { cloneDeep } from "@apollo/client/utilities";
import { Form, Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";

import {
  AttendeeFormRoute,
  AttendeeInformationRoute,
  PreviewProps,
  ReviewRoute,
  SigneeFormRoute,
} from "@src/Routes";
import { FieldSelector } from "@src/components/fields";
import { ContentWrapper, PageWrapper } from "@src/components/layout";
import { ProgressTracker } from "@src/components/molecules/ProgressTracker";
import { ErrorNavigator } from "@src/components/molecules/errorNavigator";
import { LoadingModal } from "@src/components/molecules/loadingModal/LoadingModal";
import { NavigationButtons } from "@src/components/molecules/navigationButtons";
import { PageFooter } from "@src/components/molecules/pageFooter";
import { PageHeader } from "@src/components/molecules/pageHeader/PageHeader";
import { StyledDivider, StyledLabel } from "@src/components/styles";
import { useBreakPoints, useUrlParams } from "@src/customHooks";
import { useFormInfo } from "@src/customHooks/useFormInfo";
import { useCachedResponseVersion } from "@src/customHooks/useResponseVersion";
import { useSafeSaveSigneeAnswerMutation } from "@src/customHooks/useSafeMutations";
import { TranslateFormErrors } from "@src/customHooks/useTranslateFormErrors";
import { AccessDataAnswers, RequestError, ResponseVersionHash } from "@src/localVariables";
import { DeleteSigneeAnswersResult } from "@src/mutations/mutationTypes";
import { saveSigneeAnswerMutationUpdater } from "@src/mutations/updaterFunctions";
import { deleteSigneeAnswerMutationUpdater } from "@src/mutations/updaterFunctions/deleteSigneeAnswer";
import {
  AnswerInput,
  useDeleteSigneeAnswerMutation,
} from "@src/types";
import { checkUnsubmittedAnswers } from "@src/utils/answers";
import { isCollapsed } from "@src/utils/field";
import { hasAttendeeQuestions, hasTickets, isGeneralSignup } from "@src/utils/formGetters";
import { InitialValuesObject } from "@src/utils/formValuesTypes";
import { getFormAttendees } from "@src/utils/getters";
import { getDeleteSigneeAnswerResponseForPreview, getSigneeAnswer } from "@src/utils/preview";
import { isStaff } from "@src/utils/responseVersionGetters";
import { scrollToTop } from "@src/utils/scrollToTop";
import { ErrorComponent } from "../Error";
import { useSigneeQuestions } from "./hooks/useSigneeQuestions";

const SigneeForm: FC<PreviewProps> = ({ isPreview }) => {
  const { baseUrl, countryCode, markAsVisited } = useUrlParams();
  const navigate = useNavigate();
  const accessData = useReactiveVar(AccessDataAnswers) || [];
  const responseVersionHash = useReactiveVar(ResponseVersionHash);
  const { t } = useTranslation();
  const screenSize = useBreakPoints();
  const client = useApolloClient();
  const [disableNextButton, setDisableNextButton] = useState(false);
  const requestError = useReactiveVar(RequestError);

  useEffect(() => {
    scrollToTop();
  }, []);

  const formInfo = useFormInfo();
  const responseVersionQuery = useCachedResponseVersion(responseVersionHash);
  const initialAnswers = responseVersionQuery?.responseVersion?.signeeAnswers || [];
  const saveSigneeAnswer = useSafeSaveSigneeAnswerMutation({
    update: saveSigneeAnswerMutationUpdater({ responseHashedId: responseVersionHash }),
  }, initialAnswers);

  const [deleteSigneeAnswer] = useDeleteSigneeAnswerMutation({
    update: deleteSigneeAnswerMutationUpdater({ responseHashedId: responseVersionHash }),
  });
  const [collapsedHeaders, setCollapsedHeaders] = useState<string[]>([]);

  const { fields, initialValues } = useSigneeQuestions({
    formInfo: formInfo,
    responseVersion: responseVersionQuery?.responseVersion,
  });

  if (formInfo.loading || responseVersionQuery.loading) return <LoadingModal />;
  if (formInfo.error) return <ErrorComponent error={formInfo.error} />;
  if (responseVersionQuery.error)
    return <ErrorComponent error={responseVersionQuery.error ?? ""} />;

  const form = formInfo.form;
  const responseVersion = responseVersionQuery.responseVersion;

  if (!baseUrl || !responseVersion || responseVersion?.isSigned)
    return <Navigate to={`${window.location.pathname.replace(SigneeFormRoute, "")}`} />;

  const attendees = getFormAttendees(responseVersion).filter(
    (attendee) => !formInfo.form.requiresPermission || attendee.permitted === "yes"
  );

  markAsVisited("signee-form");

  const uploadUrl = `${process.env.RESPONSES_URL}response/${countryCode.toLowerCase()}/${form.documentId
    }/${responseVersion.responseVersionId}/signee-answer/upload`;

  const viewFileUrl = `${process.env.RESPONSES_URL}response/${countryCode.toLowerCase()}/${form.documentId
    }/media`;

  const saveAnswer = async (answer: AnswerInput): Promise<string> => {
    if (isPreview) {
      const previewAnswer = getSigneeAnswer(answer);
      const updater = saveSigneeAnswerMutationUpdater({ responseHashedId: responseVersionHash });
      if (updater) {
        updater(
          client.cache,
          { data: { SaveSigneeAnswer: previewAnswer } },
          {
            variables: {
              countryCode,
              documentId: form.documentId,
              responseVersionId: responseVersion.responseVersionId,
              answer,
              accessData,
            },
          }
        );
      }
      return previewAnswer.answerId;
    } else {
      const answerId = await saveSigneeAnswer({
        variables: {
          countryCode,
          documentId: form.documentId,
          responseVersionId: responseVersion.responseVersionId,
          answer,
          accessData,
        },
      });

      return answerId;
    }
  };

  const deleteAnswer = async (answerInput: AnswerInput): Promise<DeleteSigneeAnswersResult> => {
    if (isPreview) {
      const response = { DeleteSigneeAnswer: getDeleteSigneeAnswerResponseForPreview() };
      const updater = deleteSigneeAnswerMutationUpdater({ responseHashedId: responseVersionHash });
      if (updater) {
        updater(
          client.cache,
          { data: response },
          {
            variables: {
              countryCode,
              documentId: form.documentId,
              responseVersionId: responseVersion.responseVersionId,
              answerInput,
              accessData,
            },
          }
        );
      }
      return Promise.resolve({ data: response, errors: undefined });
    } else {
      return await deleteSigneeAnswer({
        variables: {
          countryCode,
          documentId: form.documentId,
          responseVersionId: responseVersion.responseVersionId,
          accessData,
          answerInput: {
            fieldId: answerInput.fieldId,
            questionId: answerInput.questionId,
            order: answerInput.order,
          },
        },
      });
    }
  };

  const nextPage = () => {
    if ((hasAttendeeQuestions(form) || hasTickets(form)) && attendees.length > 0) {
      navigate(`${baseUrl}/${AttendeeFormRoute}/0`);
    } else navigate(`${baseUrl}/${ReviewRoute}`);
  };

  const backPage = () => {
    if (isGeneralSignup(form)) navigate(`${baseUrl}`);
    else navigate(`${baseUrl}/${AttendeeInformationRoute}`);
  };

  const handleToggleCollapse = (fieldId: string) => {
    const existing = collapsedHeaders.find((x) => x === fieldId);

    if (existing) {
      setCollapsedHeaders(collapsedHeaders.filter((x) => x !== existing));
    } else {
      const headers = cloneDeep(collapsedHeaders);
      headers.push(fieldId);

      setCollapsedHeaders(headers);
    }
  };

  const handleSubmit = async (values: InitialValuesObject) => {
    setDisableNextButton(true);

    const needsSaving = checkUnsubmittedAnswers(values);
    await Promise.all(needsSaving.map((x) => saveAnswer(x as AnswerInput)));

    setDisableNextButton(false);
    nextPage();
  };

  return (
    <>
      <PageWrapper>
        <PageHeader isPreview={isPreview}>{requestError ? null : <ProgressTracker />}</PageHeader>
        <ContentWrapper screenSize={screenSize}>
          <StyledLabel>{t("messages.signeeInstructionMessage")}</StyledLabel>
          <StyledDivider />
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            <TranslateFormErrors>
              <Form>
                <ErrorNavigator />
                {fields.map((field) => (
                  <FieldSelector
                    key={field.id}
                    field={field}
                    isCollapsed={isCollapsed(field, fields, collapsedHeaders)}
                    uploadUrl={!isPreview ? uploadUrl : undefined}
                    viewFileUrl={viewFileUrl}
                    saveAnswer={saveAnswer}
                    deleteAnswer={deleteAnswer}
                    handleToggleCollapse={handleToggleCollapse}
                  />
                ))}

                <NavigationButtons
                  displayBackButton={!isStaff(responseVersion)}
                  nextButtonDisabled={disableNextButton}
                  backOnClick={backPage}
                />
              </Form>
            </TranslateFormErrors>
          </Formik>
        </ContentWrapper>
      </PageWrapper>
      <PageFooter />
    </>
  );
};

export { SigneeForm };
