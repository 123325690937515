export type NumericType = "currency" | "decimal" | "integer";

export enum ControlType {
  None,
  Vertical,
  Horizontal,
}

export type NumericInnerFieldProps = {
  ticketId?: string;
  value?: string | null;
  max?: number;
  min: number;
  step: number;
  disabled: boolean;
  type?: NumericType;
  fixedDecimalPlaces?: number;
  width?: string;
  minWidth?: string;
  error?: string;
  onChange?: (value?: string | null) => void;
};

export type NumericInputProps = NumericInnerFieldProps & {
  controlType: ControlType;
};
