import { useState } from "react";

import {
  Answer,
  AnswerInput,
  SaveAttendeeAnswerMutationOptions,
  SaveSigneeAnswerMutationOptions,
  useSaveAttendeeAnswerMutation,
  useSaveSigneeAnswerMutation,
} from "@src/types";
import { getAnswerKey, getAnswersDictionary } from "@src/utils/formValues";
import { RequestError } from "@src/localVariables";

const shouldSaveAnswer = (answer: AnswerInput, previousValue: string) => {
  const newValue = answer?.value || "";

  /**
   * Equation fields need to save empty answers to act as rows. They are indicated
   * by a questionId of "row".
   */
  return answer.questionId === "row" || newValue !== previousValue;
};

type SaveSigneeAnswerFunction = (args: SaveSigneeAnswerMutationOptions) => Promise<string>;

type UseSafeSigneeAnswerMutation = (
  mutationOptions: SaveSigneeAnswerMutationOptions,
  answers: Answer[]
) => SaveSigneeAnswerFunction;

/**
 * This hook wraps the saveSigneeAnswer mutation to prevent sending the same value
 * to the backend multiple times. It receives the mutation options and an array of
 * answers. It returns a safe mutation function that will only send the mutation if
 * the value has changed since the last call.
 *
 * @param {SaveSigneeAnswerMutationOptions} mutationOptions
 * @param {Answer[]} answers
 * @returns
 */
const useSafeSaveSigneeAnswerMutation: UseSafeSigneeAnswerMutation = (mutationOptions, answers) => {
  const [saveSigneeAnswerFunction] = useSaveSigneeAnswerMutation(mutationOptions);
  const [answersDictionary, setAnswersDictionary] = useState(getAnswersDictionary(answers));

  const safeSaveAnswer: SaveSigneeAnswerFunction = async (args) => {
    const answer = args?.variables?.answer as AnswerInput;
    const answerKey = getAnswerKey(answer);
    const previousValue = answersDictionary[answerKey]?.value || "";
    const newValue = answer?.value || "";

    if (shouldSaveAnswer(answer, previousValue)) {
      setAnswersDictionary((prev) => ({
        ...prev,
        [answerKey]: { ...prev[answerKey], value: newValue },
      }));

      const result = await saveSigneeAnswerFunction(args);

      if (result.errors && result.errors.length > 0) {
        RequestError(result.errors[0]);
      }

      return result.data?.SaveSigneeAnswer.answerId || "";
    }

    return answer?.answerId || "";
  };

  return safeSaveAnswer;
};

type SaveAttendeeAnswerFunction = (args: SaveAttendeeAnswerMutationOptions) => Promise<string>;

type UseSafeAttendeeAnswerMutation = (args: {
  mutationOptions?: SaveAttendeeAnswerMutationOptions;
  answers: Answer[];
}) => SaveAttendeeAnswerFunction;

/**
 * This hook wraps the saveAttendeeAnswer mutation to prevent sending the same value
 * to the backend multiple times. It receives the mutation options and an array of
 * answers. It returns a safe mutation function that will only send the mutation if
 * the value has changed since the last call.
 *
 * @param {Object} args
 * @property {SaveAttendeeAnswerMutationOptions} mutationOptions
 * @property {Answer[]} answers
 *
 * @returns
 */
const useSafeSaveAttendeeAnswerMutation: UseSafeAttendeeAnswerMutation = ({
  mutationOptions,
  answers,
}) => {
  const [saveAttendeeAnswerFunction] = useSaveAttendeeAnswerMutation(mutationOptions);

  const safeSaveAnswer: SaveAttendeeAnswerFunction = async (args) => {
    const answer = args?.variables?.answer as AnswerInput;
    const answerKey = getAnswerKey(answer);
    const answersDictionary = getAnswersDictionary(answers);
    const previousValue = answersDictionary[answerKey]?.value || "";

    if (shouldSaveAnswer(answer, previousValue)) {
      const result = await saveAttendeeAnswerFunction(args);

      if (result.errors && result.errors.length > 0) {
        RequestError(result.errors[0]);
      }

      return result.data?.SaveAttendeeAnswer.answerId || "";
    }

    return answer?.answerId || "";
  };

  return safeSaveAnswer;
};

export { useSafeSaveSigneeAnswerMutation, useSafeSaveAttendeeAnswerMutation };
