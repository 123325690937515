import { useReactiveVar } from "@apollo/client";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import {
  AttendeeFormRoute,
  AttendeeInformationRoute,
  ReviewRoute,
  SelectResponseRoute,
  SigneeFormRoute,
  SigneeInfoRoute,
} from "@src/Routes";
import { Button } from "@src/components/atoms/Button";
import { ContentWrapper, PageWrapper } from "@src/components/layout";
import { Flex, Push } from "@src/components/layout/Page";
import { InfoModal } from "@src/components/molecules/infoModal/InfoModal";
import { LoadingModal } from "@src/components/molecules/loadingModal/LoadingModal";
import { PageFooter } from "@src/components/molecules/pageFooter";
import { PageHeader } from "@src/components/molecules/pageHeader";
import { StyledSectionHeader } from "@src/components/styles";
import { ResponseResumeAction } from "@src/constants/responseResumeActions";
import { useBreakPoints, useUrlParams } from "@src/customHooks";
import { useCachedEligibleTags } from "@src/customHooks/useEligibleTags";
import { useFormInfo } from "@src/customHooks/useFormInfo";
import { useCachedResponseVersion } from "@src/customHooks/useResponseVersion";
import { useResponseVersions } from "@src/customHooks/useResponseVersions";
import {
  AccessDataAnswers,
  IsPayNow,
  OrderId,
  ResponseVersionHash,
  SigneeInfoValues,
} from "@src/localVariables";
import { createResponseMutationUpdater } from "@src/mutations/updaterFunctions";
import { addResponseVersionMutationUpdater } from "@src/mutations/updaterFunctions/addResponseVersion";
import { restartInProcessResponseVersionMutationUpdater, restartResponseVersionMutationUpdater } from "@src/mutations/updaterFunctions/restartResponse";
import { saveAttendeeAnswerMutationUpdater } from "@src/mutations/updaterFunctions/updateAttendeeAnswers";
import {
  FieldType,
  ResponseSource,
  SigneeInput,
  useAddResponseVersionMutation,
  useCreateResponseMutation,
  useResponseLazyQuery,
  useRestartInProcessResponseMutation,
  useRestartResponseMutation,
  useSaveAttendeeAnswerMutation,
  useUpdateResponseVersionMutation,
} from "@src/types";
import { ResumeListItem } from "@src/utils/ResumeListItem";
import { scrollToTop } from "@src/utils/scrollToTop";
import { ResponseCard } from "./components/ResponseCard";
import { ErrorComponent } from "../Error";
import {
  isProcess,
  signeeInfo,
  isStaff,
  isRostered,
  getStartNewResponseStatus,
} from "@src/utils/responseVersionGetters";
import {
  hasAttendeeIntegratedDataQuestions,
  hasSigneeQuestions,
  hasTickets,
} from "@src/utils/formGetters";
import omit from "lodash/omit";
import { updateResponseVersionMutationUpdater } from "@src/mutations/updaterFunctions/updateResponseVersion";
import ReactTooltip from "react-tooltip";
import { Icon } from "@src/components/atoms/Icon";

export type SelectResponseState = SigneeInfoValues & {
  documentId: string;
};

const SelectResponse: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { baseUrl, countryCode, hashedOrgId } = useUrlParams();
  const screenSize = useBreakPoints();
  const accessData = useReactiveVar(AccessDataAnswers) || [];
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const responseVersionHash = useReactiveVar(ResponseVersionHash);
  const { state } = useLocation();
  const params = (state as SelectResponseState) || null;

  useEffect(() => {
    scrollToTop();
  }, []);

  const formInfo = useFormInfo();
  const responseVersionQuery = useCachedResponseVersion(responseVersionHash);

  const [createResponse, { loading: createResponseLoading }] = useCreateResponseMutation({
    update: createResponseMutationUpdater(),
  });

  const [addResponseVersion, { loading: addResponseLoading }] = useAddResponseVersionMutation({
    update: addResponseVersionMutationUpdater(),
  });

  const [restartResponse, { loading: restartResponseLoading }] = useRestartResponseMutation({
    update: restartResponseVersionMutationUpdater(),
  });

  const [updateResponseVersion, { loading: updateResponseLoading }] = useUpdateResponseVersionMutation({
    update: updateResponseVersionMutationUpdater(),
  });

  const [restartInProcessResponse, { loading: restartInProcessResponseLoading }] = useRestartInProcessResponseMutation({
    update: restartInProcessResponseVersionMutationUpdater(),
  });

  const [saveAttendeeAnswer, { loading: saveAttendeeAnswerLoading }] =
    useSaveAttendeeAnswerMutation();

  const [setResponse, { loading: setResponseLoading }] = useResponseLazyQuery();

  const responseVersionsQuery = useResponseVersions(
    formInfo.form?.documentId ?? params?.documentId,
    responseVersionQuery.responseVersion
      ? signeeInfo(responseVersionQuery.responseVersion)
      : {
        firstName: params?.firstName ?? "",
        lastName: params?.lastName ?? "",
        email: params?.email ?? "",
        responseSource: params?.responseSource ?? ResponseSource.Organic,
        attachmentId: params?.attachmentId ?? "",
        notes: params?.notes ?? "",
        tagId: params?.tagId,
      },
    isStaff(responseVersionQuery.responseVersion),
    isProcess(responseVersionQuery.responseVersion),
    isRostered(responseVersionQuery.responseVersion)
  );

  const eligibleTagsQuery = useCachedEligibleTags(
    formInfo.form?.documentId || "",
    responseVersionQuery.responseVersion?.responseOrganizationId || "",
    responseVersionQuery.responseVersion?.signeeEmail || ""
  );

  if (formInfo.loading || responseVersionsQuery.loading || responseVersionQuery.loading)
    return <LoadingModal message={t("messages.loadingYourExperience")} />;
  if (formInfo.error) return <ErrorComponent error={formInfo.error} />;
  if (responseVersionQuery.error)
    return <ErrorComponent error={responseVersionQuery.error ?? ""} />;
  if (responseVersionsQuery.error)
    return <ErrorComponent error={responseVersionsQuery.error ?? ""} />;
  if (eligibleTagsQuery.loading) return <LoadingModal />;
  if (eligibleTagsQuery.error) return <ErrorComponent error={eligibleTagsQuery.error ?? ""} />;

  const form = formInfo.form;
  const responseVersion = responseVersionQuery.responseVersion;
  const isProcessResponse = isProcess(responseVersion);
  const isStaffResponse = isStaff(responseVersion);
  let responseVersions =
    isProcess(responseVersion) || isRostered(responseVersion)
      ? [responseVersion]
      : responseVersionsQuery.responseVersions;
  const eligibleTags = eligibleTagsQuery.eligibleTags ?? [];

  if (responseVersion && isProcessResponse) {
    responseVersions = responseVersions.filter(
      (x) => x.responseVersionId === responseVersion.responseVersionId
    );
  }

  if (responseVersion && isStaffResponse) {
    responseVersions = responseVersions.length === 0 
      ? [responseVersion] 
      : responseVersions.filter((x) => x.responseOrganizationId === responseVersion.responseOrganizationId);
  }

  if (!baseUrl || !form?.documentId)
    return <Navigate to={window.location.pathname.replace(SelectResponseRoute, "")} />;

  const handlePayNow = (resumeListItem: ResumeListItem) => {
    if (resumeListItem.canPay()) {
      ResponseVersionHash(resumeListItem.responseVersion.hash);
      OrderId(
        resumeListItem.responseVersion.orders.find((x) => x.isCheckedOut && !x.hasBeenPaid)?.orderId
      );
      IsPayNow(true);
      navigate(`${baseUrl}/${ReviewRoute}`);
    }
  };

  const navigateToNextStep = () => {
    // rostered responses need to go to the signee info page, the email should not be editable
    // public responses have already entered signee info, no need to go back to that page
    // staff and process responses should only hit the signee info page if there are multiple eligible tags,
    // otherwise the signee info isn't editable anyway and its populated by user info and
    // should be changed via the user management page or in AD
    if (isRostered(responseVersion) || (eligibleTags && eligibleTags.length > 1)) {
      navigate(`${baseUrl}/${SigneeInfoRoute}`);
    } else {
      if (form?.isGeneralSignup) {
        if (hasSigneeQuestions(form)) navigate(`${baseUrl}/${SigneeFormRoute}`);
        else if (hasTickets(form)) {
          navigate(`${baseUrl}/${AttendeeFormRoute}/0`);
        } else navigate(`${baseUrl}/${ReviewRoute}`);
      } else navigate(`${baseUrl}/${AttendeeInformationRoute}`);
    }
  };

  const handleSelectResponse = (resumeListItem: ResumeListItem) => async () => {
    switch (resumeListItem.resumeAction()) {
      case ResponseResumeAction.Edit:
        if (!isProcessResponse)
          await addNewVersion(resumeListItem.responseVersion.responseVersionId);
        break;

      case ResponseResumeAction.Restart:
        if (!isProcessResponse)
          await restart(resumeListItem.responseVersion.responseVersionId);
        else
          await restartInProcess(resumeListItem.responseVersion.responseVersionId);
        break;

      case ResponseResumeAction.Update:
        await updateResponseVersionForm(resumeListItem.responseVersion.responseVersionId);
        break;

      case ResponseResumeAction.Resume:
        // load the response immediately
        await setResponse({ variables: { hash: resumeListItem.responseVersion.hash } });
        ResponseVersionHash(resumeListItem.responseVersion.hash);
        OrderId(resumeListItem.responseVersion.orders.find((x) => !x.hasBeenPaid)?.orderId);

        if (
          resumeListItem.responseVersion.isRostered &&
          hasAttendeeIntegratedDataQuestions(form) &&
          resumeListItem.responseVersion.attendees &&
          resumeListItem.responseVersion.attendees[0].integratedDataOnFile &&
          resumeListItem.responseVersion.attendees[0].integratedDataOnFile.length > 0
        ) {
          // rostered responses have 1 attendee
          const attendee = resumeListItem.responseVersion.attendees[0];
          const integratedDataFields = form.attendeeQuestions.filter(
            (x) => x.type === FieldType.IntegratedData
          );

          for (let index = 0; index < integratedDataFields.length; index++) {
            const integratedDataField = integratedDataFields[index];
            let dataValue = integratedDataField.question?.dataValue ?? "";
            dataValue = dataValue.substring(0, dataValue.indexOf(":"));
            const answer = attendee.integratedDataOnFile?.find((x) => x.fieldId === dataValue);

            if (answer) {
              const newAnswer = omit(answer, "__typename");
              newAnswer.fieldId = integratedDataField.id;
              newAnswer.questionId = integratedDataField.question?.id ?? "";

              await saveAttendeeAnswer({
                update: saveAttendeeAnswerMutationUpdater({
                  attendeeId: attendee.attendeeId,
                  responseHashedId: resumeListItem.responseVersion.hash,
                }),
                variables: {
                  countryCode,
                  documentId: form.documentId,
                  responseVersionId: resumeListItem.responseVersion.responseVersionId,
                  attendeeId: attendee.responseAttendeeId,
                  answer: newAnswer,
                  accessData,
                },
              });
            }
          }
        }

        navigateToNextStep();
        break;

      default:
        setInfoModalOpen(true);
        break;
    }
  };

  const restart = async (responseVersionId: string) => {
    if (responseVersionId && !isProcessResponse) {
      const result = await restartResponse({
        variables: {
          countryCode,
          documentId: form.documentId,
          responseVersionId,
        },
      });

      if (result.data?.RestartResponse) {
        ResponseVersionHash(result.data?.RestartResponse.hash);
        navigateToNextStep();
      }
    }
  };

  // TODO: This is disabled for now, user should use the Process Editor to cancel and start a new version.
  const restartInProcess = async (responseVersionId: string) => {
    if (responseVersionId && isProcessResponse) {
      const result = await restartInProcessResponse({
        variables: {
          countryCode,
          documentId: form.documentId,
          responseVersionId,
        },
      });

      if (result.data?.RestartInProcessResponse) {
        ResponseVersionHash(result.data?.RestartInProcessResponse.hash);
        navigateToNextStep();
      }
    }
  };

  const addNewVersion = async (responseVersionId: string) => {
    if (signeeInfo(responseVersion) && !isProcessResponse) {
      const result = await addResponseVersion({
        variables: {
          countryCode,
          documentId: form.documentId,
          responseVersionId,
        },
      });

      if (result.data?.AddResponseVersion) {
        ResponseVersionHash(result.data?.AddResponseVersion.hash);
        navigateToNextStep();
      }
    }
  };

  const updateResponseVersionForm = async (responseVersionId: string) => {
    const result = await updateResponseVersion({
      variables: {
        countryCode,
        documentId: form.documentId,
        responseVersionId,
      },
    });

    if (result.data?.UpdateResponseVersion) {
      ResponseVersionHash(result.data?.UpdateResponseVersion.hash);
      OrderId(result.data?.UpdateResponseVersion.orders.find((x) => !x.hasBeenPaid)?.orderId);
      navigateToNextStep();
    }

  };

  const handleCreateNewResponse = async () => {
    if (!isProcessResponse) {
      const signee: SigneeInput | null = responseVersions.length
        ? {
          firstName: responseVersions[0].signeeFirstName ?? "",
          lastName: responseVersions[0].signeeLastName ?? "",
          email: responseVersions[0].signeeEmail ?? "",
          responseSource: isStaffResponse ? ResponseSource.Internal : ResponseSource.Organic,
        }
        : null;

      if (signee) {
        const result = await createResponse({
          variables: {
            countryCode,
            documentId: form.documentId,
            signee,
            accessData,
            organizationHash: hashedOrgId || null,
            organizationId: isStaffResponse ? responseVersion?.responseOrganizationId : null,
          },
        });

        ResponseVersionHash(result.data?.CreateResponse.hash);
        navigateToNextStep();
      }
    }
  };

  const getLoadingMessage = () => {
    if (createResponseLoading || addResponseLoading) {
      return t("messages.creatingResponse");
    } else if (restartResponseLoading || restartInProcessResponseLoading) {
      return t("messages.restartingResponse");
    } else {
      return t("messages.loadingResponse");
    }
  };

  const isLoading =
    createResponseLoading ||
    addResponseLoading ||
    updateResponseLoading ||
    restartResponseLoading ||
    restartInProcessResponseLoading ||
    setResponseLoading ||
    saveAttendeeAnswerLoading;

  const startNewResponseStatus = getStartNewResponseStatus(responseVersion, responseVersions);

  return (
    <>
      <PageWrapper>
        <PageHeader />
        {isLoading ? <LoadingModal message={getLoadingMessage()} /> : null}
        <ContentWrapper screenSize={screenSize}>
          <>
            <StyledSectionHeader style={{ marginBottom: "10px" }}>
              {t("labels.startNewResponse")}
            </StyledSectionHeader>
            <div style={{ width: "fit-content" }} data-tip data-for="disabledMessage">
              <Button
                data-testid={"START-NEW-RESPONSE"}
                primary
                onClick={startNewResponseStatus.disabled ? () => {} : handleCreateNewResponse}
                disabled={startNewResponseStatus.disabled}
                style={{ cursor: startNewResponseStatus.disabled ? "not-allowed" : "default" }}
              >
                {t("labels.start")}
              </Button>
              {startNewResponseStatus.disabled && startNewResponseStatus.disabledMessage ? (
                <Icon
                  style="solid"
                  icon="circle-info"
                  color="#0283ff"
                  overrideStyles={{
                    backgroundColor: "white",
                    borderRadius: "50%",
                    position: "relative",
                    right: "10px",
                    bottom: "15px",
                  }}
                />
              ) : null}
            </div>
            {startNewResponseStatus.disabled && startNewResponseStatus.disabledMessage ? (
              <Flex alignItems="center" style={{ paddingTop: "10px" }} gap={5}>
                <ReactTooltip id="disabledMessage" effect="solid" place="right">
                  {startNewResponseStatus.disabledMessage}
                </ReactTooltip>
              </Flex>
            ) : null}
          </>

          <StyledSectionHeader style={{ margin: "25px 0 0" }}>
            {t("labels.selectExistingResponse")}
          </StyledSectionHeader>
          <Flex flexWrap="wrap" style={{ margin: "15px 0px" }}>
            {responseVersions.map((responseVersion) => (
              <ResponseCard
                key={responseVersion.responseVersionId}
                responseVersion={responseVersion}
                handlePayNow={handlePayNow}
                handleSelectResponse={handleSelectResponse}
              />
            ))}
          </Flex>
        </ContentWrapper>
        <Push />
      </PageWrapper>
      <PageFooter />
      {isInfoModalOpen ? (
        <InfoModal
          title="Attention"
          message="No action is permitted on this response."
          onConfirm={() => setInfoModalOpen(false)}
          onCancel={() => setInfoModalOpen(false)}
          confirmButtonText="Ok"
        />
      ) : null}
    </>
  );
};

export { SelectResponse };
