import { Answer, Field } from "@src/types";

const AddressFieldNames = [
  "streetAddress",
  "streetAddressLine2",
  "city",
  "stateProv",
  "postalZip",
] as const;

export type AddressFieldName = typeof AddressFieldNames[number];

const getStreetNumber = (place: google.maps.places.PlaceResult): string | null =>
  place.address_components?.find((x: { types: string[] }) => x.types.indexOf("street_number") > -1)
    ?.long_name ?? null;

const getStreet = (place: google.maps.places.PlaceResult): string | null =>
  place.address_components?.find((x: { types: string[] }) => x.types.indexOf("route") > -1)
    ?.long_name ?? null;

const getProvince = (place: google.maps.places.PlaceResult): string | null =>
  place.address_components?.find(
    (x: { types: string[] }) => x.types.indexOf("administrative_area_level_1") > -1
  )?.long_name ?? null;

const getCity = (place: google.maps.places.PlaceResult): string | null =>
  place.address_components?.find((x: { types: string[] }) => x.types.indexOf("locality") > -1)
    ?.long_name ??
  place.address_components?.find(
    (x: { types: string[] }) => x.types.indexOf("administrative_area_level_2") > -1
  )?.long_name ??
  null;

const getPostalCode = (place: google.maps.places.PlaceResult): string | null =>
  place.address_components?.find((x: { types: string[] }) => x.types.indexOf("postal_code") > -1)
    ?.long_name ?? null;

const getStreetAddress = (place: google.maps.places.PlaceResult): string =>
  `${getStreetNumber(place) || ""} ${getStreet(place) || ""}`.trim();

type GetAddressInnerFieldValueArgs = {
  place: google.maps.places.PlaceResult;
  fieldName?: AddressFieldName | null;
};

const getAddressInnerFieldValue = ({
  place,
  fieldName,
}: GetAddressInnerFieldValueArgs): string | null => {
  switch (fieldName) {
    case "streetAddress":
      return getStreetAddress(place);

    case "city":
      return getCity(place);

    case "stateProv":
      return getProvince(place);

    case "postalZip":
      return getPostalCode(place);

    default:
      return "";
  }
};

type GetAddressInnerFieldValuesArgs = {
  fieldsDictionary: Record<string, Field>;
  value: Record<string, Answer>;
  place: google.maps.places.PlaceResult;
};

const getAddressInnerFieldValues = ({
  fieldsDictionary,
  value,
  place,
}: GetAddressInnerFieldValuesArgs): Record<string, Answer> => {
  const newValue = Object.keys(fieldsDictionary).reduce((acc, fieldKey) => {
    const fieldName = fieldsDictionary[fieldKey].question?.name as
      | AddressFieldName
      | null
      | undefined;

    const fieldValue = getAddressInnerFieldValue({ place, fieldName });

    acc[fieldKey] = {
      ...value[fieldKey],
      value: fieldValue,
    };

    return acc;
  }, {} as Record<string, Answer>);

  return newValue;
};

const getAddressInnerFields = (addressField: Field): Field[] =>
  addressField.fields?.filter((x) => !x.question?.hidden) || [];

export {
  getAddressInnerFieldValues,
  AddressFieldNames,
  getAddressInnerFields,

  // Testing exports
  getStreetNumber as _getStreetNumber,
  getStreet as _getStreet,
  getProvince as _getProvince,
  getCity as _getCity,
  getPostalCode as _getPostalCode,
  getStreetAddress as _getStreetAddress,
  getAddressInnerFieldValue as _getAddressInnerFieldValue,
};
