import React, { FC } from "react";

import { FieldError } from "@src/components/atoms/FieldError";
import { FieldWrapper, StyledRequiredLabel } from "@src/components/styles";
import { AnswerInput, Field } from "@src/types";
import { FieldSelector } from "../FieldSelector";
import { Label } from "@src/components/atoms/Label";
import { useTranslation } from "react-i18next";

type IntegratedDataFieldProps = {
  id: string;
  label: string;
  field: Field;
  isRequired: boolean;
  readOnly: boolean;
  originalValue: AnswerInput;
  error?: string;
  saveAnswer?: (answer: AnswerInput) => Promise<string>;
};

const IntegratedDataField: FC<IntegratedDataFieldProps> = ({
  id,
  label,
  field,
  isRequired,
  readOnly,
  originalValue,
  saveAnswer,
}) => {
  const { t } = useTranslation();

  return (
    <FieldWrapper>
      {isRequired ? <StyledRequiredLabel /> : null}
      {label ? <Label>{label}</Label> : null}
      <label>{t("labels.valueOnRecord")}</label>{" "}
      <span className={!originalValue?.value ? "no-value" : ""}>
        {originalValue?.value ? originalValue?.value : t("messages.noValueOnRecord")}
      </span>
      {!readOnly ? (
        <FieldSelector
          key={field.id}
          field={field}
          containerQuestionId={id}
          saveAnswer={saveAnswer}
          hideLabel={true}
        />
      ) : null}
      <FieldError id={id} name={id} />
    </FieldWrapper>
  );
};

export { IntegratedDataField };
