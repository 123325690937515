import { i18next } from "@src/translations";
import common_en from "@src/translations/en/common.json";

import {
  Answer,
  AnswerInput,
  Attendee,
  FeeMethod,
  Order,
  PurchasedTicket,
  ResponseVersion,
  Ticket,
} from "@src/types";
import AmexIcon from "../assets/payment/americanexp.png";
import ApIcon from "../assets/payment/ap.png";
import CashCheckIcon from "../assets/payment/cashcheck.png";
import GpIcon from "../assets/payment/gp.png";
import McIcon from "../assets/payment/mastercard.png";
import MpIcon from "../assets/payment/mp.png";
import VisaIcon from "../assets/payment/visa.png";
import { Token } from "@stripe/stripe-js";
import { OrderInfo } from "./getters";
import cloneDeep from "lodash/cloneDeep";
import { checkTicketSellingDates } from "./tickets";
import { Normalize } from "react-i18next";

export type Image = {
  src: string;
  alt: string;
};

export enum TicketMode {
  SHOPPING,
  PREVIEW,
  CART_PREVIEW,
  CHECKOUT,
}

export enum PaymentMethodType {
  CREDITCARD,
  WALLET,
  MANUAL,
}

export enum FeeMethodType {
  Unknown = 0,
  DomesticCreditCard = 1,
  InternationalCreditCard = 2,
  DomesticDebitCard = 3,
  InternationalDebitCard = 4,
  BankTransfer = 5,
  DomesticPrepaidCard = 6,
  InternationalPrepaidCard = 7,
}

export type PaymentMethod = {
  label: string;
  type: PaymentMethodType;
  icons: Image[];
  isOnline: boolean;
  isSafePay: boolean;
};

const t = (translationkey: Normalize<typeof common_en>, options?: Record<string, unknown>) =>
  i18next.t(translationkey, options);

export const getPurchasedTicketKey = (purchasedTicket: PurchasedTicket): string =>
  `${purchasedTicket.ticketId || ""}_${JSON.stringify(purchasedTicket.answers) || ""}_${
    purchasedTicket.price || ""
  }`;

export const currencyFormatter: (
  locale: string,
  currencyCode?: string | null
) => Intl.NumberFormat = (locale, currencyCode) =>
  new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currencyCode || "CAD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

export const currencyParseLocaleNumber = (stringNumber: string): number => {
  const dollarSign = "$";
  const thousandSeparator = Intl.NumberFormat("en-US")
    .format(11111)
    .replace(/\p{Number}/gu, "");
  const decimalSeparator = Intl.NumberFormat("en-US")
    .format(1.1)
    .replace(/\p{Number}/gu, "");

  return parseFloat(
    stringNumber
      .replace(new RegExp("\\" + dollarSign, "g"), "")
      .replace(new RegExp("\\" + thousandSeparator, "g"), "")
      .replace(new RegExp("\\" + decimalSeparator), ".")
  );
};

export const getPaymentMethods = (manualPaymentText: string): PaymentMethod[] => {
  return [
    {
      label: t("pages.tickets.creditCard"),
      type: PaymentMethodType.CREDITCARD,
      isOnline: true,
      isSafePay: false,
      icons: [
        { src: McIcon, alt: t("pages.tickets.masterCardMasterCardDebit") },
        { src: VisaIcon, alt: t("pages.tickets.visaVisaDebit") },
        { src: AmexIcon, alt: "American Express" },
      ],
    },
    {
      label: t("pages.tickets.walletPay"),
      type: PaymentMethodType.WALLET,
      isOnline: true,
      isSafePay: true,
      icons: [
        { src: ApIcon, alt: "Apple Pay" },
        { src: GpIcon, alt: "Google Pay" },
        { src: MpIcon, alt: "Microsoft Pay" },
      ],
    },
    {
      label: manualPaymentText ?? t("pages.tickets.cashCheque"),
      type: PaymentMethodType.MANUAL,
      isOnline: false,
      isSafePay: false,
      icons: [{ src: CashCheckIcon, alt: t("pages.tickets.manualPayment") }],
    },
  ];
};

export const hasPassAlongFees = (tickets: Ticket[]): boolean =>
  tickets?.some((ticket) => ticket.passAlongFees) ?? false;

export const getStripeTokenFeeMethod = (token: Token, country: string): FeeMethod | null => {
  if (token == null || token.type !== "card" || !token.card) {
    return null;
  }

  const credit = token.card?.funding === "credit";
  const debit = token.card?.funding === "debit";

  if (country.toUpperCase() === token.card?.country?.toUpperCase()) {
    return credit ? FeeMethod.DomesticCreditCard : debit ? FeeMethod.DomesticDebitCard : null;
  }

  return credit
    ? FeeMethod.InternationalCreditCard
    : debit
    ? FeeMethod.InternationalDebitCard
    : null;
};

export const getHashCode = (text: string): number => {
  return text.split("").reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
};

export const getAnswerHash = (answers: Answer[]): number =>
  getHashCode(
    answers
      .map((x) => x.value)
      .sort((a, b) => (a || "").localeCompare(b || ""))
      .join("")
  );

export type GetQuantityInCartArgs = {
  cart?: Attendee[];
  attendeeId: string | null;
  ticketVersionId: string;
};

export const getQuantityInCart = ({
  cart,
  attendeeId,
  ticketVersionId,
}: GetQuantityInCartArgs): number => {
  if (!cart) return 0;

  return cart
    .filter((cartAttendee) => !attendeeId || cartAttendee.attendeeId === attendeeId)
    .map((cartAttendee) => cartAttendee.tickets)
    .reduce((a, b) => a.concat(b), [])
    .filter((purchasedTicket) => purchasedTicket.ticketVersionId === ticketVersionId)
    .map((purchasedTicket) => purchasedTicket.quantity)
    .reduce((a, b) => a + b, 0);
};

export type ReserveTicketArgs = {
  orderId: string | null;
  ticketVersionId: string;
  responseAttendeeId: string;
  answers: AnswerInput[];
  openValueAmount?: number;
  quantity: number;
};

export type CancelTicketArgs = {
  ticketVersionId: string;
  responseAttendeeId: string;
  purchasedTicketIds: string[];
};

export const getUnpaidOrderInfo = (
  selectedResponseVersion: ResponseVersion,
  attendees: Attendee[]
): OrderInfo | null => {
  if (selectedResponseVersion) {
    const unpaidOrder = selectedResponseVersion.isCheckedOut
      ? selectedResponseVersion.orders.find(
          (order) => order.isCheckedOut && (order.amountOutstanding || 0) > 0
        )
      : null;

    if (unpaidOrder) {
      const responseAttendees = cloneDeep(selectedResponseVersion.attendees);

      responseAttendees.map((attendee) => {
        attendee.tickets = unpaidOrder.purchasedTickets.filter(
          (pt) => pt.attendeeId === attendee.attendeeId
        );
      });

      return {
        total: unpaidOrder.grandTotal || 0,
        subtotal: unpaidOrder.subTotal || 0,
        cart: responseAttendees,
        latestOrder: unpaidOrder as Order,
        previousOrders: [],
        attendees: attendees,
      };
    }
  }

  return null;
};

// ticket minimums include amounts ordered on any order for this signee on this form
export const ticketMinimumsAreMet = (tickets: Ticket[], orders: Order[]): boolean => {
  let result = true;

  if (tickets && tickets.length > 0) {
    for (const ticket of tickets.filter((x) => x.minimumQuantity && x.minimumQuantity > 0)) {
      // Are the tickets buyable?
      if (
        !checkTicketSellingDates(ticket) ||
        (ticket.inventory.isLimited && ticket.inventory.remaining == 0)
      ) {
        continue;
      }

      const purchasedTickets = orders
        .flatMap((x) => x.purchasedTickets)
        .filter((x) => x.ticketId === ticket.ticketId);

      const quantityOrdered = purchasedTickets.reduce((acc, curr) => (acc += curr.quantity), 0);

      if (quantityOrdered < ticket.minimumQuantity!) {
        result = false;
        break;
      }
    }
  }

  return result;
};

// ticket maximums include amounts ordered on any order for this signee on this form
export const ticketMaximumsAreNotExceeded = (tickets: Ticket[], orders: Order[]): boolean => {
  let result = true;

  if (tickets && tickets.length > 0) {
    for (const ticket of tickets.filter((x) => x.maximumQuantity && x.maximumQuantity > 0)) {
      // Are the tickets buyable?
      if (
        !checkTicketSellingDates(ticket) ||
        (ticket.inventory.isLimited && ticket.inventory.remaining == 0)
      ) {
        continue;
      }

      const purchasedTickets = orders
        .flatMap((x) => x.purchasedTickets)
        .filter((x) => x.ticketId === ticket.ticketId);

      const quantityOrdered = purchasedTickets.reduce((acc, curr) => (acc += curr.quantity), 0);

      if (quantityOrdered > ticket.maximumQuantity!) {
        result = false;
        break;
      }
    }
  }

  return result;
};
