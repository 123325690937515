import React, { FunctionComponent } from "react";
import { LinkAttachment } from "@src/types";
import { Flex } from "@src/components/layout/Page";
import LinkIcon from "@src/assets/link.png";
import { Link, LinkContainer, LinkDescription, LinkImage, LinkTitle, TopLevelLink } from "./styles";

type LinkAttachmentProps = {
  attachment: LinkAttachment;
};

const LinkAttachmentComponent: FunctionComponent<LinkAttachmentProps> = ({ attachment }) => {
  let uri = attachment.uri;

  if (!uri?.startsWith("http")) {
    uri = "http://" + uri;
  }

  return (
    <TopLevelLink target="_blank" href={uri as string}>
      <Flex>
        <LinkImage src={LinkIcon} />
        <LinkContainer>
          <LinkTitle style={{ fontFamily: "'Roboto'" }}>{attachment.title}</LinkTitle>
          <Link>{attachment.uri}</Link>
          <LinkDescription style={{ fontFamily: "'Roboto'" }}>
            {attachment.description}
          </LinkDescription>
        </LinkContainer>
      </Flex>
    </TopLevelLink>
  );
};

export { LinkAttachmentComponent };
