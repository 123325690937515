import styled from "styled-components";

const ProgressTrackerContainer = styled.div`
  ${({ theme }) => `
    background: ${theme.colors.values.white};
    box-shadow: 2px 3px 8px 0 #ccc;
    border-radius: 8px 8px 0 0;
    position: relative;
    top: 30px;
    margin: 0 auto;
    z-index: 10;
    padding: 10px 15px;
    font-size: 12px;
    font-weight: 400;
    border-bottom: solid 5px #daedff;
  `}
`;

const CompleteText = styled.span`
  color: ${(props) => props.theme.colors.values.lightblack};
`;

const Percentage = styled.span`
  display: inline-block;
  color: #3260d3;
  font-weight: 500;
`;

const TimeEstimate = styled.span`
  display: inline-block;
  margin-left: 15px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.alias.darkPrimary};
`;

const CompletionProgressBar = styled.div`
  position: absolute;
  left: 0;
  bottom: -5px;
  height: 5px;
  background: ${(props) => props.theme.colors.alias.primary};
`;

export { ProgressTrackerContainer, CompleteText, Percentage, TimeEstimate, CompletionProgressBar };
