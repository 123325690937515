import React, { FC, Fragment, useState } from "react";
import groupBy from "lodash/groupBy";

import { AnswerInput, Field, FieldType } from "@src/types";
import { AnswerBackground, ButtonRow } from "./styles";
import { Button } from "@src/components/atoms/Button";
import { RepeatableAddButton } from "@src/components/styles";
import { FileViewer } from "@src/components/atoms/FileViewer";
import { DisplayAnswers } from "@src/components/molecules/displayAnswers";
import { useTranslation } from "react-i18next";

type RepeatableAnswersProps = {
  innerFields: Field[];
  answers: AnswerInput[];
  uploadedFileViewBaseUrl: string;
  handleSelectRow?: (order: string) => void;
  handleDeleteRow?: (order: string) => void;
};

const RepeatableAnswers: FC<RepeatableAnswersProps> = ({
  innerFields,
  answers,
  uploadedFileViewBaseUrl,
  handleSelectRow,
  handleDeleteRow,
}) => {
  const { t } = useTranslation();
  const [showFile, setShowFile] = useState<AnswerInput | null>(null);
  const answersByOrder = groupBy(answers, "order");
  const rows = Object.keys(answersByOrder).map((rowOrder) => ({
    order: rowOrder,
    values: innerFields.flatMap((innerField) => {
      if (innerField.type === FieldType.Address) {
        const addressFields = innerField.fields || [];
        return addressFields.filter((x) => !x.question?.hidden);
      } else {
        return innerField;
      }
    }),
    answers: answersByOrder[rowOrder],
  }));

  return (
    <Fragment>
      {showFile ? (
        <FileViewer
          viewUrl={`${uploadedFileViewBaseUrl}/${showFile.value}`}
          mimeType={showFile.fileType ?? ""}
          dismiss={() => setShowFile(null)}
        />
      ) : null}
      {rows.map((row, index) =>
        row.answers.length > 0 ? (
          <AnswerBackground key={index}>
            <DisplayAnswers
              fields={innerFields}
              answers={row.answers}
              uploadedFileViewBaseUrl={uploadedFileViewBaseUrl}
            />
            {handleSelectRow && handleDeleteRow ? (
              <ButtonRow>
                <RepeatableAddButton
                  data-testid={row.order + "-EDIT"}
                  type="button"
                  onClick={() => handleSelectRow(row.order)}
                >
                  {t("labels.edit")}
                </RepeatableAddButton>
                <Button
                  data-testid={row.order + "-REMOVE"}
                  style={{ color: "white" }}
                  backgroundColor="#ee475b"
                  hoverColor="#a6081b"
                  type="button"
                  onClick={() => handleDeleteRow(row.order)}
                >
                  {t("labels.remove")}
                </Button>
              </ButtonRow>
            ) : null}
          </AnswerBackground>
        ) : null
      )}
    </Fragment>
  );
};

export { RepeatableAnswers };
