import { AnswerInput, Ticket } from "@src/types";
import { PossibleFieldValue } from "./formValuesTypes";
import { getAnswersFromPossibleFieldValue } from "./answers";

const getTicketAnswers = (possibleFieldValues: PossibleFieldValue[]): AnswerInput[] =>
  possibleFieldValues
    .flatMap((value) => getAnswersFromPossibleFieldValue(value))
    .filter((x) => x !== undefined);

const checkTicketSellingDates = (ticket: Ticket) => {
  let canBuyTicket = true;

  if (ticket.startSellingDate) {
    canBuyTicket = new Date(ticket.startSellingDate) <= new Date();
  }

  if (ticket.endSellingDate && canBuyTicket) {
    canBuyTicket = new Date(ticket.endSellingDate) >= new Date();
  }

  return canBuyTicket;
};

export { getTicketAnswers, checkTicketSellingDates };
