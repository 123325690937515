import React, { FC } from "react";
import NumberFormat from "react-number-format";

import { getNumericInputLogic } from "../getNumericInputLogic";
import { NumericInputWrapper, StyledInput } from "../styles";
import { NumericInnerFieldProps } from "../types";

const Simple: FC<NumericInnerFieldProps> = ({
  value,
  type,
  min,
  max,
  width,
  minWidth,
  disabled,
  step,
  fixedDecimalPlaces,
  error,
  onChange,
}) => {
  const { useDecimalScale, decimalScale, prefix, handleChange } = getNumericInputLogic({
    value,
    type,
    disabled,
    min,
    max,
    step,
    fixedDecimalPlaces,
    onChange,
  });

  return (
    <NumericInputWrapper width={width} minWidth={minWidth} error={!!error}>
      <NumberFormat
        fixedDecimalScale={useDecimalScale}
        decimalScale={decimalScale}
        value={value || ""}
        customInput={StyledInput}
        allowEmptyFormatting={true}
        prefix={prefix}
        onValueChange={handleChange}
        onKeyPress={
          !disabled
            ? (event: React.KeyboardEvent) => {
                if (event.which === 13) {
                  event.preventDefault();
                }
              }
            : null
        }
      />
    </NumericInputWrapper>
  );
};

export { Simple };
