import React, { FC } from "react";
import { useField } from "formik";

import { StyledRequiredLabel, StyledTextInput } from "@src/components/styles";
import { FieldError } from "@src/components/atoms/FieldError";
import { Flex } from "@src/components/layout/Page";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";

type AttendeeFieldProps = {
  name: string;
  placeholder: string;
  required?: boolean;
  disabled: boolean;
  attendeeLabel: string;
};

const AttendeeField: FC<AttendeeFieldProps> = ({
  name,
  placeholder,
  required,
  disabled,
  attendeeLabel,
}) => {
  const [input, meta] = useField(name);
  const { t } = useTranslation();

  const tooltipId = `disabled-${name}`;

  const disableInput = disabled && !!input.value;

  return (
    <Flex flexDirection="column" style={{ width: "48%", position: "relative" }}>
      {required && <StyledRequiredLabel />}
      <StyledTextInput
        data-testid={name}
        placeholder={placeholder}
        hasError={!!meta.error && meta.touched}
        disabled={disableInput}
        data-tip
        data-for={tooltipId}
        {...input}
      />
      <FieldError id={name} name={name} />
      {disableInput ? (
        <ReactTooltip id={tooltipId} effect="solid" place="top" multiline>
          {t("pages.studentInfo.disabledAttendeeNameEditing", {
            attendee: attendeeLabel,
          })}
        </ReactTooltip>
      ) : null}
    </Flex>
  );
};

export { AttendeeField };
