import styled from "styled-components";
import { defaultTheme } from "@src/themes";

const AttachmentContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 15px;
`;

const AttachmentInformationContainer = styled.div`
  display: flex;
  margin-left: 15px;
  cursor: pointer;
`;

const AttachmentActionsContainer = styled.div`
  display: flex;
  margin-left: 15px;
  margin-top: 2px;
  justify-content: space-between;
  width: 50px;
  align-items: center;
`;

const AttachmentName = styled.p`
  font-family: ${defaultTheme.font.default};
`;

const AttachmentImage = styled.img`
  height: 45px;
  cursor: pointer;
  box-shadow: 0px 0px 5px 0 #777;
`;

export {
  AttachmentContainer,
  AttachmentInformationContainer,
  AttachmentActionsContainer,
  AttachmentName,
  AttachmentImage,
};
