import React, { FC, useMemo } from "react";
import keyBy from "lodash/keyBy";

import { Answer, AnswerInput, Field } from "@src/types";
import { PossibleFieldValue } from "@src/utils/formValuesTypes";
import { getFieldKey } from "@src/utils/getters";
import { getAddressInnerFieldValues } from "@src/utils/address";
import { AddressInnerField } from "./AddressInnerField";
import { useGoogleAddress } from "@src/customHooks/useGoogleAddress";
import { Label } from "@src/components/atoms/Label";

export type PlaceSelectedResult = {
  province: string | null;
  city: string | null;
  postalCode: string | null;
  streetAddress: string | null;
};

type AddressFieldProps = {
  id: string;
  label: string;
  fields: Field[];
  value: Record<string, Answer>;
  error?: Record<string, string>;
  setValue: (value: PossibleFieldValue) => void;
  saveAnswer?: (answer: AnswerInput) => Promise<string>;
};

const AddressField: FC<AddressFieldProps> = ({
  id,
  label,
  fields,
  value,
  setValue,
  saveAnswer,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fieldsDictionary = useMemo(() => keyBy(fields, (field) => getFieldKey(field)), []);

  const onPlaceSelected = (place: google.maps.places.PlaceResult) => {
    const newValue = getAddressInnerFieldValues({ fieldsDictionary, value, place });

    setValue(newValue);
  };

  const inputRef = useGoogleAddress({ onPlaceSelected });

  return (
    <div id={id}>
      <Label>{label}</Label>

      {Object.keys(fieldsDictionary).map((fieldKey) => {
        const addressField = fieldsDictionary[fieldKey];
        const questionName = addressField.question?.name || "";
        const isMainAddressField = questionName === "streetAddress";

        return (
          <AddressInnerField
            key={fieldKey}
            ref={isMainAddressField ? inputRef : undefined}
            parentFieldId={id}
            field={addressField}
            saveAnswer={saveAnswer}
          />
        );
      })}
    </div>
  );
};

export { AddressField };
