import { ApolloCache, DefaultContext, MutationUpdaterFunction } from "@apollo/client";

import { RESPONSE_VERSION_QUERY } from "@src/queries/responseVersion";
import {
  Answer,
  ResponseQuery,
  SaveSigneeAnswerMutation,
  SaveSigneeAnswerMutationVariables,
} from "@src/types";

type UpdaterArgs = {
  responseHashedId: string;
};

type UpdateResponseArgs = {
  cache: ApolloCache<unknown>;
  answer?: Answer;
  responseHashedId: string;
};

const saveAnswerForResponse = ({
  cache,
  answer,
  responseHashedId,
}: UpdateResponseArgs) => {
  cache.updateQuery<ResponseQuery>(
    {
      query: RESPONSE_VERSION_QUERY,
      variables: {
        hash: responseHashedId,
      },
      overwrite: true,
    },
    (data: ResponseQuery | null) => {
      if (data) {
        const responseVersion = data.Response;

        if (responseVersion) {
          const newSigneeAnswers = responseVersion.signeeAnswers.filter(
            (x) => x.answerId !== answer?.answerId
          );

          if (answer) {
            newSigneeAnswers.push(answer);
          }

          return {
            ...data,
            Response: {
              ...responseVersion,
              signeeAnswers: newSigneeAnswers,
            },
          };
        }
      }
    }
  );
};

type SaveSigneeAnswerMutationUpdater = (
  args: UpdaterArgs
) => MutationUpdaterFunction<
  SaveSigneeAnswerMutation,
  SaveSigneeAnswerMutationVariables,
  DefaultContext,
  ApolloCache<unknown>
>;

const saveSigneeAnswerMutationUpdater: SaveSigneeAnswerMutationUpdater =
  ({ responseHashedId }) =>
  (cache, { data }) => {
    const answer = data?.SaveSigneeAnswer;
    saveAnswerForResponse({ cache, answer, responseHashedId });
  };

export { saveSigneeAnswerMutationUpdater };
