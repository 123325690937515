import { Button } from "@src/components/atoms/Button";
import { Flex } from "@src/components/layout/Page";
import { LoadingModal } from "@src/components/molecules/loadingModal/LoadingModal";
import { StyledLabel } from "@src/components/styles";
import { TranslateFormErrors } from "@src/customHooks/useTranslateFormErrors";
import { PreviewProps } from "@src/Routes";
import { useSubmitFeedbackMutation } from "@src/types";
import { Field, Form, Formik } from "formik";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FeedbackContainer,
  SentFeedbackContainer,
  StartFeedbackButton,
  StartFeedbackContainer,
} from "./styles";

type FeedbackFormProps = {
  onSubmit: (feedback: string) => Promise<boolean>;
};

const FeedbackForm: FC<FeedbackFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation();
  return (
    <Formik
      initialValues={{ feedback: "" }}
      onSubmit={async ({ feedback }, { resetForm }) => {
        const success = await onSubmit(feedback);
        if (success) {
          resetForm();
        }
      }}
    >
      {({ isSubmitting, dirty, resetForm }) => {
        return (
          <TranslateFormErrors>
            <Form>
              {isSubmitting && (
                <LoadingModal message={t("pages.confirmation.feedbackSubmitting")} />
              )}
              <FeedbackContainer flexDirection="column">
                <Field name="feedback" as="textarea" />
                <StyledLabel>{t("pages.confirmation.feedbackInfo")}</StyledLabel>
                <Flex justifyContent="end">
                  <Button primary disabled={!dirty} type="submit">
                    {t("labels.send")}
                  </Button>
                  <Button primary disabled={!dirty} onClick={() => resetForm()}>
                    {t("labels.cancel")}
                  </Button>
                </Flex>
              </FeedbackContainer>
            </Form>
          </TranslateFormErrors>
        );
      }}
    </Formik>
  );
};

type FeedbackProps = PreviewProps & {
  documentVersionId: string;
  responseVersionId: string;
  countryCode: string;
  organizerText?: string;
  isGeneralSignup: boolean;
  signeeEmailAddress: string;
  signeeFirstName: string;
  signeeLastName: string;
};

const Feedback: FC<FeedbackProps> = (props: FeedbackProps) => {
  const { t } = useTranslation();
  const [isSendingFeedback, setIsSendingFeedback] = useState(false);
  const [isFeedbackSent, setIsFeedbackSent] = useState(false);

  const [submitFeedback] = useSubmitFeedbackMutation();

  const onSubmitFeedback = async (feedback: string) => {
    if (!props.isPreview) {
      const result = await submitFeedback({
        variables: {
          countryCode: props.countryCode,
          documentVersionId: props.documentVersionId,
          responseVersionId: props.responseVersionId,
          feedback,
          signeeEmailAddress: props.signeeEmailAddress,
          signeeFirstName: props.signeeFirstName,
          signeeLastName: props.signeeLastName,
        },
      });

      if (!result.errors) {
        setIsFeedbackSent(true);
        return true;
      }
    } else {
      setIsFeedbackSent(true);
      return true;
    }

    return false;
  };

  let organizerLabel = props.organizerText;

  if (!organizerLabel || props.isGeneralSignup) {
    organizerLabel = "Organizer";
  }

  return isFeedbackSent === false ? (
    <StartFeedbackContainer>
      <Flex className="startFeedback">
        <StyledLabel>{t("pages.confirmation.feedback", { organizer: organizerLabel })}</StyledLabel>
        <StartFeedbackButton primary onClick={() => setIsSendingFeedback(true)}>
          {t("labels.yes")}
        </StartFeedbackButton>
      </Flex>
      {isSendingFeedback ? <FeedbackForm onSubmit={onSubmitFeedback} /> : null}
    </StartFeedbackContainer>
  ) : (
    <SentFeedbackContainer>
      <i className="fa-light fa-circle-check"></i>
      <StyledLabel>{t("pages.confirmation.feedbackSent")}</StyledLabel>
    </SentFeedbackContainer>
  );
};

export { Feedback };
