import { useReactiveVar } from "@apollo/client";
import { useParams } from "react-router-dom";

import { NavSlug, UrlParams } from "@src/localVariables";
import { parseStringToNumber } from "@src/utils/typeConverters";
import { useMemo } from "react";
import { DefaultCountryCode } from "@src/utils/urls";

type UrlParams = {
  baseUrl: string;
  responseHashedId?: string;
  documentHashedId?: string;
  countryCode: string;
  hashedOrgId?: string;
  visitedPages: NavSlug[];
  attendeeIndex?: number;
  markAsVisited: (navSlug: NavSlug) => void;
};

const useUrlParams = (): UrlParams => {
  const { attendeeIndex } = useParams();

  const { baseUrl, documentHashedId, responseHashedId, countryCode, hashedOrgId, visitedPages } =
    useReactiveVar(UrlParams);

  const parsedAttendeeIndex = useMemo(() => parseStringToNumber(attendeeIndex), [attendeeIndex]);

  const shardCode = countryCode
    ? countryCode.toLocaleUpperCase()
    : DefaultCountryCode;

  const markAsVisited = (navSlug: NavSlug) => {
    const wasVisited = visitedPages.includes(navSlug);
    if (!wasVisited) {
      UrlParams({
        baseUrl,
        documentHashedId,
        responseHashedId,
        countryCode,
        hashedOrgId,
        visitedPages: [...visitedPages, navSlug],
      });
    }
  };

  return {
    baseUrl,
    documentHashedId,
    responseHashedId,
    countryCode: shardCode,
    hashedOrgId,
    visitedPages,
    attendeeIndex: parsedAttendeeIndex,
    markAsVisited,
  };
};

export { useUrlParams };
