const decodeHtmlCharCodes = (str: string): string =>
  new DOMParser().parseFromString(str, "text/html").body.textContent || "";

/**
 * Determines wheter or not a string has one or more valid HTML tags
 * @param label
 */
const isHTMLlabel = (label: string): boolean => {
  const regexForHTML = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
  return regexForHTML.test(label);
};

const decodeHTMLEntities = (rawStr: string) =>
  rawStr.replace(/&#(\d+);/g, (_match, dec) => `${String.fromCharCode(dec)}`);

export { decodeHtmlCharCodes, isHTMLlabel, decodeHTMLEntities };
