import { defaultTheme } from "@src/themes";
import styled from "styled-components";

const FileViewerModalBackground = styled.div`
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${defaultTheme.colors.values.darkgrey};
  opacity: 0.8;
  z-index: 999;
`;

const FileViewerModalContent = styled.div`
  position: fixed;
  z-index: 1000;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 95%;
  height: 95%;
`;

const ImageView = styled.img`
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 8px;
`;

const PdfContainer = styled.div`
  position: absolute;
  max-width: 630px;
  max-height: 100%;
  min-height: 75%;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const FileViewerModalClose = styled.i`
  position: absolute;
  right: 0;
  top: 0;
  font-size: 22px;
  cursor: pointer;
  color: ${defaultTheme.colors.values.white};
  z-index: 5;
`;

export { FileViewerModalBackground, FileViewerModalContent, FileViewerModalClose, PdfContainer, ImageView };