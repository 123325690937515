import isEmpty from "lodash/isEmpty";
import * as Yup from "yup";
import { ObjectShape } from "yup/lib/object";
import { useTranslation, TFunction, Normalize } from "react-i18next";

import {
  Answer,
  AnswerInput,
  Attendee,
  Field,
  FieldType,
  Order,
  Question,
  Ticket,
} from "@src/types";
import { phoneDefaultFormat } from "./phoneUtilities";
import { OrderInfo, getFieldKey, isQuestionRequired } from "./getters";
import { isEquationRequired } from "./equations";
import { ResponseStep } from "@src/constants/responseSteps";
import { useUrlParams } from "@src/customHooks";
import { AttendeeFormRoute, SigneeFormRoute } from "@src/Routes";
import { ticketMaximumsAreNotExceeded, ticketMinimumsAreMet } from "./purchasedTickets";
import {
  canHaveChildFields,
  getConditionalNestedFields,
  isConditional,
  isNoInputField,
} from "./field";
import common_en from "@src/translations/en/common.json";

type ValidationSchema = Yup.ObjectSchema<ObjectShape>;
export type TranslationKey = Normalize<typeof common_en>;

const parseMultiselectJSON = (value: string) => {
  try {
    return JSON.parse(value);
  } catch {
    return null;
  }
};

const validateValueFormat = (field: Field, value?: string | null) => {
  const question = field.question;

  if (question) {
    const phoneFormat = question.format ?? phoneDefaultFormat;
    const valueNumbers = value?.match(/[A-Za-z\d]/g);
    const questionNumbers = phoneFormat.match(/[A-Za-z\d]/g);

    return !!(
      (!question.isRequired && isEmpty(value)) ||
      (!isEmpty(value) &&
        valueNumbers &&
        questionNumbers &&
        valueNumbers.length === questionNumbers.length)
    );
  } else {
    return false;
  }
};

const validateValues = (field: Field, question: Question, values: string[]) =>
  !question.isRequired ||
  (question.isRequired &&
    values &&
    values.length > 0 &&
    field.type !== FieldType.Header &&
    field.type !== FieldType.Divider &&
    field.type !== FieldType.Section);

type ValidateSingleFieldArgs = {
  question?: Question | null;
  t: TFunction;
};

const validateString = ({ question, t }: ValidateSingleFieldArgs): ValidationSchema => {
  const errorMessage = t("validation.required");

  return Yup.object().shape({
    value:
      question && !question.hidden && isQuestionRequired(question)
        ? Yup.string().trim().required(errorMessage)
        : Yup.string().nullable(),
  });
};

const getSchemaForEquationQuestion = ({
  question,
  t,
}: ValidateSingleFieldArgs): ValidationSchema => {
  const shouldValidate = question && !question.hidden;
  const isRequired = shouldValidate ? isQuestionRequired(question) : false;

  const min = question && question.minEntries ? question.minEntries : 0;
  const max = question && question.maxEntries ? question.maxEntries : -1;

  const minErrorMessage = t("validation.minValue", { min });
  const maxErrorMessage = t("validation.maxValue", { max });
  const requiredErrorMessage = t("validation.required");

  if (question?.valueType === "text") {
    return validateString({ question, t });
  }

  const minSchema = Yup.number().min(min, minErrorMessage);

  const maxSchema = max > -1 ? minSchema.max(max, maxErrorMessage) : minSchema;

  const finalSchema = isRequired ? maxSchema.required(requiredErrorMessage) : maxSchema;

  return Yup.object().shape({
    value: shouldValidate ? finalSchema : Yup.number(),
  });
};

type ValidateRepeatableValues = {
  repeatableAnswers?: Answer[];
  question?: Question | null;
};

const validateRepeatableMinEntries = ({
  repeatableAnswers,
  question,
}: ValidateRepeatableValues): boolean => {
  if (question && !question.hidden) {
    const order = repeatableAnswers ? repeatableAnswers.flatMap((x) => x.order || 0) : [];
    const numberOfEntries = new Set(order).size;

    const isValid = !question.minEntries || numberOfEntries >= question.minEntries;

    return isValid;
  } else {
    return true;
  }
};

const validateRepeatableMaxEntries = ({
  repeatableAnswers,
  question,
}: ValidateRepeatableValues): boolean => {
  if (question && !question.hidden) {
    const order = repeatableAnswers ? repeatableAnswers.flatMap((x) => x.order || 0) : [];
    const numberOfEntries = new Set(order).size;

    const isValid =
      !question.maxEntries || question.maxEntries < 1 || numberOfEntries <= question.maxEntries;

    return isValid;
  } else {
    return true;
  }
};

const validateCheckbox = (field: Field, checkboxValue?: string | null) => {
  let value: string[] = [];
  const question = field.question;

  if (question && value) {
    if (checkboxValue) {
      value = parseMultiselectJSON(checkboxValue || "") as string[];

      // if the answer came straight from SIS it will be a string and would not have parsed
      if (value === null) {
        value = [checkboxValue || ""];
      }
    }

    return validateValues(field, question, value.length > 0 ? value : []);
  } else {
    return false;
  }
};

const validateAddressField = (field: Field) => {
  const fieldKey = getFieldKey(field);

  return Yup.object().shape({
    [fieldKey]: Yup.object(),
  });
};

const validateTextField = (field: Field, t: TFunction) => {
  const fieldKey = getFieldKey(field);

  return Yup.object().shape({
    [fieldKey]: validateString({ question: field.question, t }),
  });
};

const validateRepeatableField = (field: Field, t: TFunction) => {
  const fieldKey = getFieldKey(field);

  const minEntriesMessage = t("validation.minEntries", {
    length: field.question?.minEntries,
  });

  const maxEntriesMessage = t("validation.maxEntries", {
    length: field.question?.maxEntries,
  });

  const shouldNotContainValuesMessage = t("validation.shouldNotContainValues");

  const newSchema = Yup.object().shape({
    [fieldKey]: Yup.object().shape({
      answers: Yup.array()
        .test("TestMinEntries", minEntriesMessage, (repeatableAnswers) =>
          validateRepeatableMinEntries({ repeatableAnswers, question: field.question })
        )
        .test("TestMaxEntries", maxEntriesMessage, (repeatableAnswers) =>
          validateRepeatableMaxEntries({ repeatableAnswers, question: field.question })
        ),
      isDirty: Yup.boolean().test(
        "ShouldNotContainValues",
        shouldNotContainValuesMessage,
        (isDirty) => !isDirty
      ),
    }),
  });

  return newSchema;
};

const validateEquationField = (field: Field, t: TFunction) => {
  const fieldKey = getFieldKey(field);
  const isRequired = isEquationRequired(field);

  const minEntriesErrorMessage = t("validation.minEntries", { length: 1 });

  const shouldNotContainValuesMessage = t("validation.shouldNotContainValues");

  const newSchema = Yup.object().shape({
    [fieldKey]: Yup.object().shape({
      rows: isRequired ? Yup.array().min(1, minEntriesErrorMessage) : Yup.array(),
      isDirty: Yup.boolean().test(
        "ShouldNotContainValues",
        shouldNotContainValuesMessage,
        (isDirty) => !isDirty
      ),
    }),
  });

  return newSchema;
};

const validateYesNoConditionalField = (field: Field, t: TFunction) => {
  const fieldKey = getFieldKey(field);

  const conditionalSchema = Yup.object().shape({
    [fieldKey]: Yup.object().shape({
      answer: validateString({ question: field.question, t }),
    }),
  });

  return conditionalSchema;
};

const validatePhoneField = (field: Field, t: TFunction) => {
  const fieldKey = getFieldKey(field);

  const errorMessage = t("validation.required");

  const phoneSchema = Yup.object().shape({
    [fieldKey]: validateString({ question: field.question, t }).test(
      "TestValueIsDifferentThanMask",
      errorMessage,
      (value) => validateValueFormat(field, value.value)
    ),
  });

  return phoneSchema;
};

const validateCheckboxField = (field: Field, t: TFunction) => {
  const fieldKey = getFieldKey(field);

  const errorMessage = t("validation.required");

  const checkboxSchema = Yup.object().shape({
    [fieldKey]: validateString({ question: field.question, t }).test(
      "TestCheckBox",
      errorMessage,
      (value) => validateCheckbox(field, value.value)
    ),
  });

  return checkboxSchema;
};

type GetValidationForSingleField = {
  field: Field;
  t: TFunction;
};

const getSchemaforSingleField = ({ field, t }: GetValidationForSingleField): ValidationSchema => {
  switch (field.type) {
    case FieldType.Address:
      return validateAddressField(field);

    case FieldType.RepeatableQuestion:
      return validateRepeatableField(field, t);

    case FieldType.Phonenumber:
      return validatePhoneField(field, t);

    case FieldType.Multiselect:
    case FieldType.Selectmultiple:
      return validateCheckboxField(field, t);

    case FieldType.MultiFieldConditional:
    case FieldType.SelectConditional:
    case FieldType.Conditional:
      return validateYesNoConditionalField(field, t);

    case FieldType.Equations:
      return validateEquationField(field, t);

    default:
      return validateTextField(field, t);
  }
};

const useFieldValidation = (
  field: Field
): ((fieldValue: Record<string, unknown>) => string | void) => {
  const { t } = useTranslation();

  return (fieldValue) => {
    const fieldKey = getFieldKey(field);
    const schema = getSchemaforSingleField({ field, t });

    const formattedValue = {
      [fieldKey]: fieldValue,
    };

    try {
      schema.validateSync(formattedValue);
      return;
    } catch (err) {
      const error = err as Yup.ValidationError;
      return error.message;
    }
  };
};

export type FormError = {
  message: string;
  step: ResponseStep;
  route: string;
};

const useValidateAnswers = (
  requiresPermission: boolean,
  mustSelectAtLeastOneTicket: boolean,
  isGeneralSignup: boolean,
  cartOrder?: OrderInfo | null,
  signeeFields?: Field[],
  signeeAnswers?: AnswerInput[],
  attendeeFields?: Field[],
  attendees?: Attendee[],
  tickets?: Ticket[],
  orders?: Order[]
): FormError[] => {
  const { t } = useTranslation();
  const { baseUrl } = useUrlParams();

  const formErrors: FormError[] = [];

  if (signeeFields && signeeAnswers) {
    // Validating Signee
    const signeeErrorFields = checkAnswers(signeeFields, signeeAnswers, t);

    let message = "";

    if (signeeErrorFields.length > 1) {
      message = `${
        isGeneralSignup
          ? t("validation.questionsSectionErrorPlural", { length: signeeErrorFields.length })
          : t("validation.signeeFormErrorPlural", { length: signeeErrorFields.length })
      } `;
    } else if (signeeErrorFields.length > 0) {
      message = `${
        isGeneralSignup
          ? t("validation.questionsSectionErrorSingular")
          : t("validation.signeeFormErrorSingular")
      }`;
    }

    if (signeeErrorFields.length > 0) {
      formErrors.push({
        message,
        step: ResponseStep.SigneeForm,
        route: `${baseUrl}/${SigneeFormRoute}`,
      });
    }
  }

  if (attendeeFields && attendees) {
    // Validating Attendees
    attendees.forEach((attendee, index) => {
      if (attendee.permitted || !requiresPermission) {
        const attendeeErrorFields = checkAnswers(attendeeFields, attendee.attendeeAnswers, t);

        if (attendeeErrorFields.length > 0) {
          formErrors.push({
            message:
              attendeeErrorFields.length > 1
                ? t("messages.attendeeHasErrorsPlural", {
                    firstName: attendee.firstName,
                    lastName: attendee.lastName,
                    length: attendeeErrorFields.length,
                  })
                : t("messages.attendeeHasErrorSingular", {
                    firstName: attendee.firstName,
                    lastName: attendee.lastName,
                  }),
            step: ResponseStep.AttendeeForm,
            route: `${baseUrl}/${AttendeeFormRoute}/${index}`,
          });
        }
      }
    });
  }

  if (tickets) {
    if (
      mustSelectAtLeastOneTicket &&
      (!cartOrder || cartOrder.cart.some((attendee) => attendee?.tickets?.length === 0))
    ) {
      formErrors.push({
        message: t("validation.noTicketsWhenRequired"),
        step: ResponseStep.Tickets,
        route: `${baseUrl}/${AttendeeFormRoute}/0`,
      });
    }

    const ticketMinimumsAreValid = ticketMinimumsAreMet(tickets ?? [], orders ?? []);
    const ticketMaximumsAreValid = ticketMaximumsAreNotExceeded(tickets ?? [], orders ?? []);

    if (!ticketMinimumsAreValid)
      formErrors.push({
        message: t("validation.ticketMinimumsAreNotMet"),
        step: ResponseStep.Tickets,
        route: `${baseUrl}/${AttendeeFormRoute}/0`,
      });

    if (!ticketMaximumsAreValid)
      formErrors.push({
        message: t("validation.ticketMaximumsAreExceeded"),
        step: ResponseStep.Tickets,
        route: `${baseUrl}/${AttendeeFormRoute}/0`,
      });
  }

  return formErrors;
};

const checkAnswers = (fields: Field[], answers: AnswerInput[], t: TFunction): Field[] => {
  const failedFields: Field[] = [];
  let fieldsToCheck: Field[] = [];

  if (fields && answers) {
    fields
      .filter((x) => !isNoInputField(x))
      .forEach((field) => {
        fieldsToCheck.push(field);

        // Get the relevant nested fields of a conditional
        if (isConditional(field)) {
          fieldsToCheck = fieldsToCheck.concat(getConditionalNestedFields(field, answers));
        } else if (canHaveChildFields(field) && field.fields) {
          if (
            field.type !== FieldType.RepeatableQuestion ||
            (field.type === FieldType.RepeatableQuestion && (field.question?.minEntries ?? -1) > 0)
          ) {
            fieldsToCheck = fieldsToCheck.concat(field.fields);
          }
        }
      });

    fieldsToCheck.forEach(async (field) => {
      const answer = answers.find(
        (x) => x.fieldId === field.id || x.questionId === field.question?.id
      );
      const fieldSchema = getSchemaforSingleField({ field, t });
      const fieldKey = getFieldKey(field);

      let fieldAnswer = {};

      switch (field.type) {
        case FieldType.SelectConditional:
        case FieldType.MultiFieldConditional:
        case FieldType.Conditional:
          fieldAnswer = {
            [fieldKey]: { answer: answer },
          };
          break;

        case FieldType.RepeatableQuestion:
          const fieldIds = field.fields?.map((x) => x.id) ?? [];
          const repeatableAnswers = answers.filter((x) => fieldIds.indexOf(x.fieldId) > -1);

          fieldAnswer = {
            [fieldKey]: { answers: repeatableAnswers },
          };
          break;

        case FieldType.Equations:
          const equationAnswer = answers.find(
            (x) => x.fieldId === field.id && x.questionId === "row"
          );
          fieldAnswer = {
            [fieldKey]: { rows: equationAnswer ? [equationAnswer] : [] },
          };
          break;

        default:
          fieldAnswer = {
            [fieldKey]: answer,
          };
          break;
      }

      try {
        await fieldSchema.validateSync(fieldAnswer);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          failedFields.push(field);
        }
      }
    });
  }

  return failedFields;
};

type Errors = Record<string, string | Record<string, string | Record<string, string>>>;
type ErrorList = { key: string; message: string };

const getErrorListFromErrorObjects = (errors: Errors, parentKey?: string): ErrorList[] =>
  Object.entries(errors)
    .map(([key, message]) => {
      if (typeof message === "string") {
        const newKey = parentKey ? `${parentKey}.${key}` : key;
        return { key: newKey, message };
      } else {
        return getErrorListFromErrorObjects(message, key);
      }
    })
    .flat();

export {
  useFieldValidation,
  validateString,
  useValidateAnswers,
  getSchemaForEquationQuestion,
  getErrorListFromErrorObjects,
};
