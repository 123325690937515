import { Field, FieldArray, FieldProps, Form, Formik } from "formik";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { StyledLabel, StyledRadioButton, StyledTextArea } from "@src/components/styles";
import { useStudentInfoValidation } from "./validation";
import { AttendeeInput, ResponseVersion } from "@src/types";
import { FormAttendee, formAttendeeToAttendeeInput } from "@src/utils/typeConverters";
import { Flex } from "@src/components/layout/Page";
import { IconButton } from "@src/components/molecules/iconButton";
import { NavigationButtons } from "@src/components/molecules/navigationButtons";
import { Icon, StyledIcon } from "@src/components/atoms/Icon";
import { useUrlParams } from "@src/customHooks";
import { FieldError } from "@src/components/atoms/FieldError";
import { DateField, UploadField } from "@src/components/fields";
import { DateFormats } from "@src/components/fields/types";
import { useFormInfo } from "@src/customHooks/useFormInfo";
import { LoadingModal } from "@src/components/molecules/loadingModal/LoadingModal";
import { getManualAttachmentUploadUrl, getViewFileUrl } from "@src/utils/urls";
import { SigneeInfoRoute } from "@src/Routes";
import { TrashButton } from "@src/components/fields/UploadField/TrashButton";
import { isManual, isRostered } from "@src/utils/responseVersionGetters";
import { TranslateFormErrors } from "@src/customHooks/useTranslateFormErrors";
import { AttendeeField } from "./fields/AttendeeField";
import { useGoogleTranslate } from "@src/utils/translation";

export type AttendeeFormValues = {
  attendees: FormAttendee[];
  requiresPermission: boolean;
};

type StudentInfoFormProps = {
  handleSubmit: (attendees: AttendeeInput[]) => void;
  attendees?: FormAttendee[];
  attendeeLabel: string;
  documentId: string;
  responseVersion: ResponseVersion | null;
  canAddMultipleAttendees: boolean;
  disableAttendeeNameEditing: boolean;
};

const StudentInfoForm: FunctionComponent<StudentInfoFormProps> = ({
  handleSubmit,
  attendees,
  attendeeLabel,
  documentId,
  responseVersion,
  canAddMultipleAttendees,
  disableAttendeeNameEditing,
}) => {
  const { t } = useTranslation();
  const googleTranslate = useGoogleTranslate();
  const { countryCode } = useUrlParams();
  const navigate = useNavigate();
  const validationSchema = useStudentInfoValidation();
  const { baseUrl } = useUrlParams();
  const { form } = useFormInfo();
  const isResponseManual = isManual(responseVersion);

  const canAddAttendees = canAddMultipleAttendees && !isRostered(responseVersion);

  const manualAttachmentUploadUrl = getManualAttachmentUploadUrl(
    countryCode,
    documentId,
    responseVersion?.responseVersionId ?? ""
  );
  const viewFileUrl = getViewFileUrl(countryCode, documentId);

  const initialValues: AttendeeFormValues = {
    requiresPermission: form.requiresPermission,
    attendees:
      attendees && attendees?.length
        ? attendees
        : [
            {
              firstName: "",
              lastName: "",
              permitted: null,
              responseAttendeeId: uuidv4(),
              manualPermissionDate: null,
              attachmentId: null,
              attachmentFileType: null,
              notes: "",
            },
          ],
  };

  const handleBackClick = () => navigate(`${baseUrl}/${SigneeInfoRoute}`);

  const submitForm = (values: AttendeeFormValues) => {
    return handleSubmit(values.attendees.map((student) => formAttendeeToAttendeeInput(student)));
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submitForm}>
      {({ values, isSubmitting }) => (
        <TranslateFormErrors>
          <Form>
            <>
              {isSubmitting && <LoadingModal />}
              <FieldArray
                name="attendees"
                render={({ push, remove }) => (
                  <div>
                    {values.attendees && values.attendees.length > 0
                      ? values.attendees.map((attendee, index) => {
                          const firstNameKey = `attendees.${index}.firstName`;
                          const lastNameKey = `attendees.${index}.lastName`;
                          const permittedKey = `attendees.${index}.permitted`;
                          const manualPermissionDateKey = `attendees.${index}.manualPermissionDate`;
                          const manualAttachmentIdKey = `attendees.${index}.attachmentId`;
                          const manualNotesKey = `attendees.${index}.notes`;

                          const canDelete =
                            values.attendees.length > 1 &&
                            !disableAttendeeNameEditing &&
                            !isRostered(responseVersion);

                          return (
                            <div style={{ marginBottom: "50px" }} key={attendee.responseAttendeeId}>
                              <Flex gap={10} style={{ paddingBottom: "5px" }}>
                                <Icon style="solid" icon="user" color="#0283ff" />

                                <StyledLabel>
                                  {t("labels.studentFirstAndLastName", {
                                    student: attendeeLabel,
                                    number: index + 1,
                                  })}
                                </StyledLabel>

                                {canDelete ? (
                                  <TrashButton
                                    onClick={() => remove(index)}
                                    title={t("pages.studentInfo.deleteAttendee", {
                                      attendee: attendeeLabel,
                                    })}
                                    style={{ padding: "0px" }}
                                  />
                                ) : null}
                              </Flex>

                              <Flex justifyContent="space-between">
                                <AttendeeField
                                  required
                                  disabled={disableAttendeeNameEditing}
                                  name={firstNameKey}
                                  attendeeLabel={attendeeLabel}
                                  placeholder={t("placeholders.studentsFirstName", {
                                    student: attendeeLabel,
                                  })}
                                />

                                <AttendeeField
                                  required
                                  disabled={disableAttendeeNameEditing}
                                  name={lastNameKey}
                                  attendeeLabel={attendeeLabel}
                                  placeholder={t("placeholders.studentsLastName", {
                                    student: attendeeLabel,
                                  })}
                                />
                              </Flex>
                              {isResponseManual ? (
                                <>
                                  <Flex>
                                    <Field
                                      id={manualPermissionDateKey}
                                      name={manualPermissionDateKey}
                                    >
                                      {({ field, form }: FieldProps) => (
                                        <DateField
                                          id="manual-date"
                                          label={t("labels.manualPermissionDate")}
                                          answer={{
                                            fieldId: "manual-date",
                                            answerId: "manual-date",
                                            questionId: "manual-date",
                                            value: field.value,
                                          }}
                                          format={DateFormats.YearMonthDay}
                                          setValue={(value) => {
                                            form.setFieldValue(
                                              manualPermissionDateKey,
                                              value.value
                                            );
                                          }}
                                        />
                                      )}
                                    </Field>
                                  </Flex>

                                  <Flex>
                                    <Field id={manualAttachmentIdKey} name={manualAttachmentIdKey}>
                                      {({ field, form }: FieldProps) => (
                                        <UploadField
                                          id="manual-upload"
                                          fieldId="manual-upload"
                                          questionId="manual-upload"
                                          label={t("labels.manualAttachment")}
                                          answer={{
                                            fieldId: "manual-upload",
                                            answerId: "manual-upload",
                                            questionId: "manual-upload",
                                            value: field.value,
                                            fileType: attendee.attachmentFileType,
                                          }}
                                          isRequired={false}
                                          isManualAttachment={true}
                                          uploadUrl={manualAttachmentUploadUrl}
                                          viewFileUrl={viewFileUrl}
                                          setValue={(value) =>
                                            form.setFieldValue(manualAttachmentIdKey, value)
                                          }
                                        />
                                      )}
                                    </Field>
                                  </Flex>

                                  <Flex>
                                    <StyledLabel>{t("labels.manualNotes")}</StyledLabel>
                                  </Flex>
                                  <Flex style={{ maxWidth: "600px" }}>
                                    <Field
                                      type="textarea"
                                      id={manualNotesKey}
                                      name={manualNotesKey}
                                    >
                                      {({ field, meta }: FieldProps) => (
                                        <StyledTextArea
                                          hasError={!!meta.error && meta.touched}
                                          {...field}
                                        ></StyledTextArea>
                                      )}
                                    </Field>
                                  </Flex>
                                </>
                              ) : null}

                              {form.requiresPermission ? (
                                <Flex
                                  flexWrap="wrap"
                                  gap={20}
                                  style={{ paddingTop: "10px" }}
                                  role="group"
                                >
                                  <StyledLabel style={{ cursor: "pointer" }}>
                                    {attendee.permitted === "yes" ? (
                                      <StyledIcon
                                        className="fa-solid fa-circle-check"
                                        color="#77cb1b"
                                        size="16px"
                                        style={{ marginRight: "7px" }}
                                      />
                                    ) : (
                                      <Field
                                        type="radio"
                                        name={permittedKey}
                                        data-testid={permittedKey}
                                        value="yes"
                                        as={StyledRadioButton}
                                      />
                                    )}

                                    {!form.affirmativePermission ||
                                    form.affirmativePermission === "null"
                                      ? t("labels.IGivePermission")
                                      : googleTranslate(form.affirmativePermission)}
                                  </StyledLabel>

                                  <StyledLabel style={{ cursor: "pointer" }}>
                                    {attendee.permitted === "no" ? (
                                      <StyledIcon
                                        className="fa-solid fa-times-circle"
                                        color="#d64f57"
                                        size="16px"
                                        style={{ marginRight: "7px" }}
                                      />
                                    ) : (
                                      <Field
                                        type="radio"
                                        name={permittedKey}
                                        data-testid={permittedKey}
                                        value="no"
                                        as={StyledRadioButton}
                                      />
                                    )}
                                    {!form.negativePermission || form.negativePermission === "null"
                                      ? t("labels.IDoNotGivePermission")
                                      : googleTranslate(form.negativePermission)}
                                  </StyledLabel>
                                </Flex>
                              ) : null}

                              <FieldError id={permittedKey} name={permittedKey} />
                            </div>
                          );
                        })
                      : null}
                    {canAddAttendees ? (
                      <IconButton
                        primary
                        style="solid"
                        icon="user-plus"
                        onClick={() => {
                          const newAttendee: FormAttendee = {
                            responseAttendeeId: uuidv4(),
                            firstName: "",
                            lastName: "",
                            permitted: null,
                            manualPermissionDate: null,
                            attachmentId: null,
                            attachmentFileType: null,
                            notes: "",
                          };

                          return push(newAttendee);
                        }}
                      >
                        {t("labels.addStudent", { student: attendeeLabel })}
                      </IconButton>
                    ) : null}
                  </div>
                )}
              />
              <FieldError id="attendees" name="attendees" />
              <NavigationButtons nextButtonDisabled={false} backOnClick={handleBackClick} />
            </>
          </Form>
        </TranslateFormErrors>
      )}
    </Formik>
  );
};

export { StudentInfoForm };
