import React, { FC } from "react";
import { Formik } from "formik";
import {
  Table,
  Row,
  Header,
  Cell,
  InvisibleCell,
  InvisibleHeader,
  HeaderRow,
} from "@src/components/atoms/Table";
import { AggregatesTable, AggregatesWrapper } from "./styles";
import { AnswerInput, Field, Question } from "@src/types";
import { EquationValue } from "@src/utils/formValuesTypes";
import { useEquationsLogic } from "./hooks";
import { EquationsWrapper, FieldWrapper, StyledRequiredLabel } from "@src/components/styles";
import { EquationsForm } from "./components/EquationsForm";
import { isEquationRequired } from "@src/utils/equations";
import { FieldError } from "@src/components/atoms/FieldError";
import { EditRow } from "./EditRow";
import { currencyFormatter } from "@src/utils/purchasedTickets";
import { useGoogleTranslate } from "@src/utils/translation";
import { Label } from "@src/components/atoms/Label";
import { useTranslation } from "react-i18next";
import { useFormInfo } from "@src/customHooks/useFormInfo";
import { TranslateFormErrors } from "@src/customHooks/useTranslateFormErrors";

type EquationsFieldProps = {
  id: string;
  label: string;
  value: EquationValue;
  field: Field;
  inputQuestions: Question[];
  calculatedQuestions: Question[];
  aggregateQuestions: Question[];
  error?: string;
  setValue: (value: EquationValue) => void;
  saveAnswer: (answer: AnswerInput) => Promise<string>;
  deleteAnswer: (answer: AnswerInput) => Promise<unknown>;
};

const EquationsField: FC<EquationsFieldProps> = ({
  id,
  label,
  value,
  inputQuestions,
  calculatedQuestions,
  aggregateQuestions,
  field,
  setValue,
  saveAnswer,
  deleteAnswer,
}) => {
  const googleTranslate = useGoogleTranslate();
  const { i18n } = useTranslation();
  const { rows, aggregates } = value;
  const required = isEquationRequired(field);
  const { form } = useFormInfo();

  const currencyCode = form?.document?.organization?.currencyCode;

  type HeaderInfo = {
    name: string;
    type: string;
  };

  const tableHeaders: HeaderInfo[] = [...inputQuestions, ...calculatedQuestions].map((question) => {
    return { name: question.name || "", type: question.valueType || "text" };
  });

  const hasRows = rows.length > 0;

  const { initialValues, loading, addRow, editRowValue, deleteRow, setIsDirty } = useEquationsLogic(
    {
      inputQuestions,
      calculatedQuestions,
      aggregateQuestions,
      value,
      field,
      setValue,
      saveAnswer,
      deleteAnswer,
    }
  );

  return (
    <FieldWrapper fullWidth={true}>
      {required ? <StyledRequiredLabel /> : null}
      <Label>{label}</Label>

      <EquationsWrapper>
        <Table>
          <HeaderRow>
            {tableHeaders.map((header) => (
              <Header key={header.name}>{googleTranslate(header.name)}</Header>
            ))}
            <InvisibleHeader key="actions"></InvisibleHeader>
          </HeaderRow>
          {hasRows ? (
            <>
              {rows.map((row) => (
                <EditRow
                  key={row.answerId}
                  inputQuestions={inputQuestions}
                  calculatedQuestions={calculatedQuestions}
                  row={row}
                  editRowValue={editRowValue}
                  deleteRow={deleteRow}
                />
              ))}
            </>
          ) : (
            <Row>
              {tableHeaders.map((header, index) => {
                if (index < tableHeaders.length) return <Cell key={header.name}>&nbsp;</Cell>;
              })}
              <InvisibleCell key={tableHeaders.length}>&nbsp;</InvisibleCell>
            </Row>
          )}
          {/*eslint-disable-next-line @typescript-eslint/no-empty-function*/}
          <Formik initialValues={initialValues} onSubmit={() => {}}>
            <TranslateFormErrors>
              <EquationsForm
                inputQuestions={inputQuestions}
                calculatedQuestions={calculatedQuestions}
                isEditing={false}
                loading={loading}
                addRow={addRow}
                setIsDirty={setIsDirty}
              />
            </TranslateFormErrors>
          </Formik>
        </Table>
      </EquationsWrapper>

      <AggregatesWrapper>
        <AggregatesTable isEditing={true}>
          <tbody>
            {aggregateQuestions.length > 0
              ? aggregateQuestions.map((question, index) => (
                  <Row key={question.name || index}>
                    <Cell>{question.name ? googleTranslate(question.name) : ""}</Cell>
                    <Cell>
                      {!isNaN(parseFloat(aggregates[question.id]?.value || ""))
                        ? question.valueType === "currency"
                          ? currencyFormatter(i18n.resolvedLanguage, currencyCode).format(
                              parseFloat(aggregates[question.id]?.value || "")
                            )
                          : parseFloat(aggregates[question.id]?.value || "").toFixed(2)
                        : aggregates[question.id]?.value || " "}
                    </Cell>
                  </Row>
                ))
              : null}
          </tbody>
        </AggregatesTable>
      </AggregatesWrapper>
      <FieldError id={id} name={id} />
    </FieldWrapper>
  );
};

export { EquationsField };
