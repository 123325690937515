/* eslint-disable @typescript-eslint/no-explicit-any */
import { useFormikContext } from "formik";
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import isPlainObject from "lodash/isPlainObject";

type TouchErrorFieldsArgs = {
  errors: Record<string, any>;
  touched: Record<string, any>;
  prefix?: string;
  setFieldTouched: (fieldName: string) => void;
};

/**
 * Touches fields with errors. Error fields can be a string, object or array of fields
 * @param {TouchErrorFieldsArgs} args
 */
const touchErrorFields = ({ errors, touched, prefix, setFieldTouched }: TouchErrorFieldsArgs) => {
  if (!isPlainObject(errors)) return;

  Object.keys(errors).forEach((fieldName) => {
    if (isPlainObject(errors[fieldName])) {
      if (touched && touched[fieldName]) {
        touchErrorFields({
          prefix: fieldName,
          errors: errors[fieldName],
          touched: touched[fieldName],
          setFieldTouched,
        });
      }
    } else if (Array.isArray(errors[fieldName])) {
      const errorsArray: Record<string, any>[] = errors[fieldName];

      errorsArray.forEach((innerErrors, idx) => {
        const fieldTouched = touched && touched[fieldName] ? touched[fieldName][idx] : undefined;
        if (fieldTouched) {
          touchErrorFields({
            errors: innerErrors,
            touched: fieldTouched,
            prefix: `touched[${idx}]`,
            setFieldTouched,
          });
        }
      });
    } else {
      setFieldTouched(`${prefix}.${fieldName}`);
    }
  });
};

const useTranslateFormErrors = () => {
  const { errors, touched, setFieldTouched, validateForm } = useFormikContext();
  const { i18n } = useTranslation();

  useEffect(() => {
    const runValidation = async () => {
      if (Object.keys(errors).length) {
        // Re-validates the form
        await validateForm();

        // Touches all fields with errors, so the validation messages are updated
        touchErrorFields({ errors, touched, setFieldTouched });
      }
    };

    i18n.on("languageChanged", () => {
      runValidation();
    });

    return () => {
      i18n.off("languageChanged", () => {
        ("");
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, i18n.resolvedLanguage]);
};

type TranslateFormErrorsProps = {
  children: JSX.Element[] | JSX.Element;
};

/**
 * This components runs a hook that listens to the `languageChanged` i18next event and re-runs the
 * form's validation whenever the user changes the language. This is done to get the correct error message
 * strings in the current language selected by the user.
 *
 * It should wrap all contents of a Formik tag:
 * @example
 * <Formik>
 *  <TranslateFormErrors>
 *    <Field name="fieldOne" />
 *    <Field name="fieldTwo" />
 *  </TranslateFormErrors>
 * </Formik>
 *
 */
const TranslateFormErrors: FC<TranslateFormErrorsProps> = ({ children }) => {
  useTranslateFormErrors();
  return <>{children}</>;
};

export { TranslateFormErrors };
