import { DEFAULT_PHONE_MASK, FALSY_STRING, TRUTHY_STRING } from "@src/constants/validation";
import { Field, AnswerInput, FieldType } from "@src/types";
import { getAddressInnerFields } from "./address";
import { isSimpleField } from "./field";
import { getYesOrNoFields, getValueOptions } from "./getters";

const getMultiFieldConditionalInnerFields = (field: Field, answers: AnswerInput[]): Field[] => {
  const mainAnswer = answers.find(
    (answer) => answer.fieldId === field.id && answer.questionId === field.question?.id
  );

  if (mainAnswer) {
    const [yesFields, noFields] = getYesOrNoFields(field.fields || []);

    const selectedFields =
      mainAnswer.value === TRUTHY_STRING
        ? yesFields
        : mainAnswer.value === FALSY_STRING
        ? noFields
        : [];

    return selectedFields ? [...selectedFields] : [];
  }

  return [];
};

const getSelectConditionalInnerFields = (field: Field, answers: AnswerInput[]): Field[] => {
  const mainAnswer = answers.find(
    (answer) => answer.fieldId === field.id && answer.questionId === field.question?.id
  );

  if (mainAnswer) {
    const options = getValueOptions(field);
    const selectedIndex = options.findIndex((option) => option === mainAnswer.value);
    const selectedOptionFields =
      selectedIndex >= 0 && field.fields && field.fields[selectedIndex] ? field.fields[selectedIndex].fields || [] : [];

    return [...selectedOptionFields];
  }

  return [];
};

const isSimpleFieldAnswered = (field: Field, answers: AnswerInput[]) =>
  answers.some(
    (x) =>
      x.fieldId === field.id &&
      x.questionId === field.question?.id &&
      x.value !== "" &&
      x.value !== "[]" && // SelectMultiple
      x.value !== DEFAULT_PHONE_MASK.replace("#", "_") // Phone
  );

const isFieldAnswered = (field: Field, answers: AnswerInput[]): boolean => {
  if (field && answers && answers.length > 0) {
    if (isSimpleField(field)) {
      return isSimpleFieldAnswered(field, answers);
    } else if (field.type === FieldType.Equations) {
      return answers.some((x) => x.fieldId === field.id && x.questionId === "row");
    } else if (
      field.type === FieldType.Conditional ||
      field.type === FieldType.SelectConditional ||
      field.type === FieldType.MultiFieldConditional
    ) {
      return isSimpleFieldAnswered(field, answers);
    } else if (field.type === FieldType.RepeatableQuestion) {
      return !!field.fields?.some((x) => isFieldAnswered(x, answers));
    } else if (field.type === FieldType.Upload) {
      return isSimpleFieldAnswered(field, answers);
    }
  }

  return false;
};

const getFlattenedFieldList = (fields: Field[], answers: AnswerInput[]): Field[] => {
  const flattenedFields = fields.flatMap((field) => {
    if (
      isSimpleField(field) ||
      field.type === FieldType.Equations ||
      field.type === FieldType.Upload ||
      field.type === FieldType.RepeatableQuestion
    ) {
      return field;
    }

    if (field.type === FieldType.Address) {
      // Address inner fields have the same field Id as the parent address field
      return getAddressInnerFields(field).map((innerField) => ({
        ...innerField,
        id: field.id,
      }));
    }

    if (field.type === FieldType.MultiFieldConditional || field.type === FieldType.Conditional) {
      const selectedFields = getMultiFieldConditionalInnerFields(field, answers);

      return [field, ...selectedFields];
    }

    if (field.type === FieldType.SelectConditional) {
      const selectedFields = getSelectConditionalInnerFields(field, answers);

      return [field, ...selectedFields];
    }
  });

  return flattenedFields.filter(Boolean) as Field[];
};

export { isFieldAnswered, getFlattenedFieldList };
