import { ApolloCache, ApolloError, useApolloClient, useReactiveVar } from "@apollo/client";
import { IsPreviewInventoryLoaded } from "@src/localVariables";
import { ticketInventoryMutationUpdater } from "@src/mutations/updaterFunctions/updateTickets";
import { FormInfoQuery, Ticket, TicketInventory, useTicketInventoryQuery } from "@src/types";
import { getPreviewInventories } from "@src/utils/preview";
import { useUrlParams } from "./useUrlParams";
import { FORM_INFO_QUERY } from "@src/queries";

export type TicketInventoryQuery = {
  inventories: TicketInventory[];
  loading: boolean;
  error: ApolloError | undefined;
};

export const useTicketInventory = (
  tickets: Ticket[],
  documentId: string,
  isPreview?: boolean
): TicketInventoryQuery => {
  const { countryCode, documentHashedId, hashedOrgId } = useUrlParams();
  const isPreviewInventoryLoaded = useReactiveVar(IsPreviewInventoryLoaded);
  const client = useApolloClient();

  const ticketIds = tickets.map((x) => x.ticketId);

  if (isPreview && !isPreviewInventoryLoaded) {
    injectPreviewDataIntoCache(client.cache, tickets, documentId || "", countryCode);
    IsPreviewInventoryLoaded(true);
  }

  const ticketInventoryQuery = useTicketInventoryQuery({
    variables: { documentId: documentId || "", countryCode, ticketIds },
    fetchPolicy: "cache-first",
    skip: !documentId || !countryCode,
  });

  const inventories = ticketInventoryQuery?.data?.TicketInventory;

  client.cache.updateQuery<FormInfoQuery>(
    {
      query: FORM_INFO_QUERY,
      variables: { 
        hashedId: documentHashedId || "", 
        countryCode, 
        hashedOrgId 
      },
      overwrite: true,
    },
    (cachedData) => {
      if (cachedData) {

        if (cachedData?.Form?.tickets) {

          const updatedTickets = cachedData.Form.tickets.map((ticket) => {
            const matchingInventory = inventories?.find((inv) => inv.ticketId === ticket.ticketId);
            if (matchingInventory) {
              return {
                ...ticket,
                inventory: {
                  ...ticket.inventory,
                  remaining: matchingInventory.remaining,
                },
              };
            }
            return ticket;
          });

          return {
            ...cachedData,
            Form: {
              ...cachedData.Form,
              tickets: updatedTickets,
            }
          };

        }

        return {...cachedData};
      }
    }
  );

  return {
    inventories: inventories as TicketInventory[],
    loading: ticketInventoryQuery.loading,
    error: ticketInventoryQuery.error,
  };
};

const injectPreviewDataIntoCache = (
  cache: ApolloCache<unknown>,
  tickets: Ticket[],
  documentId: string,
  countryCode: string
) => {
  const inventoryUpdater = ticketInventoryMutationUpdater(tickets);
  if (inventoryUpdater) {
    inventoryUpdater(
      cache,
      { data: { TicketInventory: getPreviewInventories(tickets) } },
      {
        variables: {
          documentId: documentId || "",
          countryCode,
          ticketIds: tickets.map((x) => x.ticketId),
        },
      }
    );
  }
};
