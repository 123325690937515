import { Spinner } from "@src/components/atoms/Spinner";
import {
  LoadingMessage,
  LoadingModalBackground,
  LoadingModalContent,
} from "@src/components/styles";
import React, { FC } from "react";

export type LoadingProps = {
  message?: string;
};

const LoadingModal: FC<LoadingProps> = ({ message }) => {
  return (
    <>
      <LoadingModalContent>
        <Spinner />
        {message ? <LoadingMessage>{message}</LoadingMessage> : null}
      </LoadingModalContent>
      <LoadingModalBackground />
    </>
  );
};

export { LoadingModal };
