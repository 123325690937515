import { NavItem, SubMenu, SubMenuBackground } from "@src/components/layout/Header";
import { useBreakPoints } from "@src/customHooks";
import React, { FunctionComponent, useState } from "react";
import { NavigationItem } from "./NavigationItem";

type NavigationListItemProps = {
  item: NavigationItem;
  id: string;
  number?: number;
  linkIsActive: (item: NavigationItem) => boolean;
  onClick: (slug: string) => void;
};

const NavigationListItem: FunctionComponent<NavigationListItemProps> = ({
  item,
  id,
  number,
  linkIsActive,
  onClick,
}) => {
  const [openItem, setOpenMenu] = useState("");
  const screenSize = useBreakPoints();

  let activeName = "";

  const isEnabled = linkIsActive(item) && !item.isDisabled;

  if (item.subMenuItems) {
    item.subMenuItems.forEach((subMenuItem) => {
      if (subMenuItem.isCurrent) {
        activeName = subMenuItem.displayText;
      }
    });
  }

  return (
    <NavItem
      key={id}
      onClick={
        !item.subMenuItems
          ? () =>
              isEnabled &&
              !item.isCurrent &&
              onClick(item.slug && !item.subMenuItems ? item.slug : "")
          : () => setOpenMenu(!openItem ? item.slug ?? "" : "")
      }
      isCurrent={item.isCurrent || (activeName != "" && screenSize === "smallScreen")}
      isEnabled={isEnabled || !!item.subMenuItems}
    >
      {number ? number : null}
      <i className="fa fa-circle" aria-hidden="true" />
      <span style={{ userSelect: "none" }}>
        {screenSize === "smallScreen" && activeName != "" ? activeName : item.displayText}
      </span>
      {item.subMenuItems ? <i className="fa fa-caret-down" aria-hidden="true"></i> : null}

      {item.subMenuItems && openItem === item.slug ? (
        <>
          <SubMenuBackground onClick={() => setOpenMenu("")} />
          <SubMenu>
            {item.subMenuItems.map((subMenuItem, subIndex) => (
              <NavigationListItem
                key={`${id}-${subIndex}`}
                id={`${id}-${subIndex}`}
                item={subMenuItem}
                linkIsActive={linkIsActive}
                number={subIndex + 1}
                onClick={() => onClick(subMenuItem.slug ?? "")}
              />
            ))}
          </SubMenu>
        </>
      ) : null}
    </NavItem>
  );
};

export { NavigationListItem };
