import React, { FunctionComponent, useState } from "react";
import { Attachment } from "@src/types";

import {
  AttachmentInformationContainer,
  AttachmentContainer,
  AttachmentImage,
  AttachmentName,
  AttachmentActionsContainer,
} from "./styles";
import AnyIcon from "@src/assets/any.png";
import ImageIcon from "@src/assets/image.png";
import PDFIcon from "@src/assets/pdf.png";
import { Icon } from "../Icon";
import { FileViewer } from "../FileViewer";

type AttachmentProps = {
  attachment: Attachment;
};

const AttachmentComponent: FunctionComponent<AttachmentProps> = ({ attachment }) => {

  const [showFile, setShowFile] = useState<boolean>(false);

  const GetLogoImage = (fileType: string) => {
    if (fileType.includes("image/")) {
      return ImageIcon;
    } else if (fileType.includes("application/pdf")) {
      return PDFIcon;
    } else {
      return AnyIcon;
    }
  };

  return (<AttachmentContainer>
    {
      showFile
        ? <FileViewer
          viewUrl={attachment.contentUri}
          mimeType={attachment.mimeType}
          dismiss={() => setShowFile(false)}
        />
        : null
    }
    <AttachmentImage onClick={() => setShowFile(true)} src={GetLogoImage(attachment.fileType)} />
    <AttachmentInformationContainer>
      <AttachmentName onClick={() => setShowFile(true)}>{attachment.friendlyName}</AttachmentName>
      <AttachmentActionsContainer>
        {attachment.printUri ? (
          <a href={attachment.printUri} target="_blank" title="Print" rel="noreferrer">
            <Icon style="solid" icon="print" color="#cbcbcb" />
          </a>
        ) : null}

        {attachment.downloadUri ? (
          <a href={attachment.downloadUri} title="Download">
            <Icon style="solid" icon="download" color="#cbcbcb" />
          </a>
        ) : null}
      </AttachmentActionsContainer>
    </AttachmentInformationContainer>
  </AttachmentContainer>);
};

export { AttachmentComponent };
