import React, { FC } from "react";
import { Attendee, ReserveTicketInput, Ticket, TicketInventory } from "@src/types";
import { TicketMode, TicketSection } from "../TicketSection/TicketSection";
import { StyledLabel, StyledLine } from "@src/components/styles";
import { Flex } from "@src/components/layout/Page";
import { ReserveTicketAnswerResult } from "@src/mutations/mutationTypes";
import { ReserveTicketArgs } from "@src/utils/purchasedTickets";
import { PreviewProps } from "@src/Routes";
import { useFormikContext } from "formik";
import { RespondentMustSelectATicket } from "@src/pages/TicketsAndAttendeeForm";
import { useTranslation } from "react-i18next";

export type TicketInput = ReserveTicketInput & {
  responseAttendeeId: string;
  responseVersionId: string;
  ticketId: string;
  ticket: Ticket;
};

export type TicketsSectionProps = PreviewProps & {
  tickets: Ticket[];
  inventories: TicketInventory[];
  attendee: Attendee;
  attendeeIndex: number;
  cart?: Attendee[];
  mode: TicketMode;
  onReserveTicket?: (args: ReserveTicketArgs) => Promise<ReserveTicketAnswerResult>;
};

const TicketsSection: FC<TicketsSectionProps> = ({
  tickets,
  inventories,
  attendee,
  attendeeIndex,
  cart,
  mode,
  isPreview,
  onReserveTicket,
}) => {
  const { t } = useTranslation();
  const { errors } = useFormikContext<{ RespondentMustSelectATicket: string | undefined }>();

  const requiresATicket = errors[RespondentMustSelectATicket] ?? null;

  return tickets.length > 0 ? (
    <Flex flexDirection="column">
      <StyledLine />
      <StyledLabel style={{ fontSize: "18px" }}>
        {t("pages.tickets.selectItems")} {attendee.firstName} {attendee.lastName}
      </StyledLabel>
      <div style={{ border: requiresATicket ? "1px solid red" : "none" }}>
        {tickets.map((ticket, index) => {
          const inventory = inventories.find((x) => x.ticketId == ticket.ticketId);

          return inventory ? (
            <React.Fragment key={`${ticket.ticketId}-${index}`}>
              <TicketSection
                ticket={ticket}
                inventory={inventory}
                attendee={attendee}
                attendeeIndex={attendeeIndex}
                cart={cart}
                mode={mode}
                isPreview={isPreview}
                onReserveTicket={onReserveTicket}
              />
              <StyledLine />
            </React.Fragment>
          ) : null;
        })}
        {requiresATicket ? (
          <p style={{ color: "red", marginLeft: "15px" }}>* {requiresATicket}</p>
        ) : null}
      </div>
    </Flex>
  ) : null;
};

export { TicketsSection };
