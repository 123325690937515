import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

type GetReadableDateArgs = {
  dateString: string;
  options?: Intl.DateTimeFormatOptions;
  locale?: string | string[];
};

const getReadableDate = ({ dateString, options, locale }: GetReadableDateArgs): string => {
  try {
    const timezonePattern = /\+\d{2}:\d{2}$|-\d{2}:\d{2}$/;

    if (!timezonePattern.test(dateString) && !dateString.endsWith("Z")) {
      dateString += "Z";
    }
    const dateObject = new Date(dateString);

    return dateObject.toLocaleDateString(locale, options);
  } catch (e) {
    console.error("Unable to parse date with these options: ", { dateString, options, locale }, e);

    return "";
  }
};

type FormatDateArgs = {
  date: Date;
  format: string;
};

const formatDate = ({ date, format }: FormatDateArgs): string => {
  const dateObject = dayjs(date);

  return dateObject.isValid() ? dateObject.format(format) : "";
};

type GetDateObjectArgs = {
  dateString: string | number | Date;
  format: string;
};

const getDateObject = (args?: GetDateObjectArgs): Date => {
  try {
    const { dateString, format } = args || {
      dateString: undefined,
      format: undefined,
    };

    const dateObject = dayjs(dateString, format);

    if (dateObject.isValid()) {
      return dateObject.toDate();
    } else {
      throw new Error("Provided dateString and format are not valid");
    }
  } catch (error) {
    console.error("Unable to parse time in this format: ", { args }, error);

    return new Date();
  }
};

const getYears = (startYear = 1900, yearsInTheFuture = 5): string[] => {
  const years: string[] = [];
  const today = dayjs();
  const thisYear = today.year();

  for (let index = thisYear + yearsInTheFuture; index >= startYear; index--) {
    years.push(index.toString());
  }

  return years;
};

export { formatDate, getDateObject, getReadableDate, getYears };

