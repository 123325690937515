import { useEffect, useMemo, useState } from "react";
import omit from "lodash/omit";
import { useDebouncedCallback } from "use-debounce";

import { AnswerInput, Field, Question } from "@src/types";
import { EquationRow, EquationValue, InnerFields } from "@src/utils/formValuesTypes";
import { getNextRowOrder } from "@src/utils/getters";
import { getEquationInnerFields, getEquationValue } from "@src/utils/formValues";
import {
  getAggregateAnswers,
  getCalculatedAnswers,
  getEquationQuestionNameForExpression,
  getInputAnswersAndExpressionValues,
} from "@src/utils/equations";

export type EditRowArgs = {
  rowId: string;
  columnName: string;
  newAnswer: AnswerInput;
};

type UseEquationsLogicArgs = {
  inputQuestions: Question[];
  calculatedQuestions: Question[];
  aggregateQuestions: Question[];
  value: EquationValue;
  field: Field;
  setValue: (value: EquationValue) => void;
  saveAnswer: (answer: AnswerInput) => Promise<string>;
  deleteAnswer: (answer: AnswerInput) => Promise<unknown>;
};

type EquationsLogic = {
  initialValues: InnerFields;
  loading: boolean;
  addRow: (innerFields: InnerFields) => Promise<void>;
  editRowValue: (args: EditRowArgs) => Promise<void>;
  deleteRow: (row: EquationRow) => Promise<void>;
  setIsDirty: (isDirty: boolean) => void;
};

const useEquationsLogic = ({
  inputQuestions,
  calculatedQuestions,
  aggregateQuestions,
  value,
  field,
  setValue,
  saveAnswer,
  deleteAnswer,
}: UseEquationsLogicArgs): EquationsLogic => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialValues = useMemo(() => getEquationInnerFields(field), [field.id]);
  const [loading, setLoading] = useState(false);

  const { rows } = value;

  type DebouncedSaveAnswer = {
    oldAnswers: AnswerInput[];
    newAnswers: AnswerInput[];
    newRow?: AnswerInput;
  };

  const debouncedSaveAnswer = useDebouncedCallback(
    async ({ oldAnswers, newAnswers, newRow }: DebouncedSaveAnswer) => {
      const savedAnswers = await Promise.all(
        newAnswers.map(async (answer) => {
          const answerId = await saveAnswer(omit(answer, "__typename"));
          return {
            ...answer,
            answerId,
          };
        })
      );

      const allAnswers = newRow
        ? [...oldAnswers, newRow, ...savedAnswers]
        : [...oldAnswers, ...savedAnswers];

      // Calculating the new value with the saved answers
      const newValue = getEquationValue({
        field,
        answers: allAnswers,
      });

      setValue(newValue);
    },
    500
  );

  // Flushes the debounced saveAnswer function on unmount
  useEffect(() => () => debouncedSaveAnswer.flush(), [debouncedSaveAnswer]);

  const addRow = async (innerFields: InnerFields) => {
    setLoading(true);

    const rowInput: AnswerInput = {
      fieldId: field.id || "",
      questionId: "row",
      value: null,
      fileType: null,
      order: getNextRowOrder(rows),
    };

    try {
      const newRowId = await saveAnswer(rowInput);

      const { inputAnswers, expressionValues } = getInputAnswersAndExpressionValues({
        innerFields,
        inputQuestions,
        rowId: newRowId || "",
      });

      const calculatedAnswers = getCalculatedAnswers({
        calculatedQuestions,
        expressionValues,
        rowId: newRowId || "",
      });

      const allAnswers = [rowInput, ...value.allAnswers, ...inputAnswers, ...calculatedAnswers];

      const aggregateAnswers = getAggregateAnswers({
        fieldId: field.id || "",
        aggregateQuestions,
        allAnswers,
      });

      const newAnswerInputs = [...inputAnswers, ...calculatedAnswers, ...aggregateAnswers];

      const oldAnswers = value.allAnswers.filter(
        (answer) =>
          aggregateAnswers.findIndex((aggregate) => aggregate.questionId === answer.questionId) ===
          -1
      );

      const newRow = { ...rowInput, answerId: newRowId };

      const newValue = getEquationValue({
        field,
        answers: [...oldAnswers, newRow, ...newAnswerInputs],
      });

      await debouncedSaveAnswer({ oldAnswers, newAnswers: newAnswerInputs, newRow });

      setValue(newValue);
    } catch (e) {
      console.error("Something went wrong:", e);
    }

    setLoading(false);
  };

  const editRowValue = async ({ rowId, columnName, newAnswer }: EditRowArgs) => {
    const row = rows.find((row) => row.answerId === rowId);

    if (row) {
      // Removes all answer belonging to the row being edited AND all aggregate Answers
      // from the allAnswers array.
      const oldAnswers = value.allAnswers.filter(
        (answer) =>
          answer.questionId === "row" || !(answer.fieldId === rowId || answer.fieldId === field.id)
      );

      const { inputAnswers, expressionValues } = inputQuestions.reduce(
        (acc, question) => {
          const expressionName = getEquationQuestionNameForExpression(question);
          const inputAnswer =
            question.name === columnName ? newAnswer : row.answers[question.name || ""];

          acc.inputAnswers.push(inputAnswer);
          acc.expressionValues[expressionName] = inputAnswer?.value || "";

          return acc;
        },
        { inputAnswers: [], expressionValues: {} } as {
          inputAnswers: AnswerInput[];
          expressionValues: Record<string, string | string[]>;
        }
      );

      const calculatedAnswers = getCalculatedAnswers({
        calculatedQuestions,
        expressionValues,
        rowId,
      });

      const aggregateAnswers = getAggregateAnswers({
        aggregateQuestions,
        fieldId: field.id || "",
        allAnswers: [...oldAnswers, ...inputAnswers, ...calculatedAnswers],
      });

      const newAnswerInputs = [...inputAnswers, ...calculatedAnswers, ...aggregateAnswers];

      // Calculating and setting the value temporarily to avoid the user seeing the
      // old value while the new value is being saved.
      const tempValue = getEquationValue({
        field,
        answers: [...oldAnswers, ...newAnswerInputs],
      });

      setValue(tempValue);

      // Saving the answers to get the answerIds
      // debouncedSaveAnswer.cancel();
      debouncedSaveAnswer({ oldAnswers, newAnswers: newAnswerInputs });
    }
  };

  const deleteRow = async (row: EquationRow) => {
    setLoading(true);

    const selectedAnswers = Object.values(row.answers);
    const selectedRow = omit(row, "answers");

    const answersToDelete: AnswerInput[] = [selectedRow, ...selectedAnswers];

    const currentRows: AnswerInput[] = value.rows.map((row) => omit(row, "answers"));
    const currentInputs: AnswerInput[] = value.rows.flatMap((row: EquationRow) =>
      Object.values(row.answers)
    );

    const currentAnswers = [...currentRows, ...currentInputs];

    const newAnswers = currentAnswers.filter((currentAnswer) => {
      const index = answersToDelete.findIndex(
        (answerToDelete) =>
          answerToDelete.fieldId === currentAnswer.fieldId &&
          answerToDelete.questionId === currentAnswer.questionId &&
          answerToDelete.order === currentAnswer.order
      );

      return index === -1;
    });

    const aggregateAnswers = getAggregateAnswers({
      aggregateQuestions,
      fieldId: field.id || "",
      allAnswers: newAnswers,
    });

    const newValue = getEquationValue({
      field,
      answers: [...newAnswers, ...aggregateAnswers],
    });

    await Promise.all([
      ...answersToDelete.map((answer) => deleteAnswer(omit(answer, "__typename"))),
      ...aggregateAnswers.map((answer) => saveAnswer(omit(answer, "__typename"))),
    ]);

    setLoading(false);
    setValue(newValue);
  };

  const setIsDirty = (isDirty: boolean) =>
    setValue({
      ...value,
      isDirty,
    });

  return {
    initialValues,
    loading,
    addRow,
    editRowValue,
    deleteRow,
    setIsDirty,
  };
};

export { useEquationsLogic };
