import { Flex } from "@src/components/layout/Page";
import { StyledLabel } from "@src/components/styles";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const StyledPreviewBar = styled(Flex)`
  ${({ theme }) => `
    width: 100%;
    box-sizing: border-box;
    padding: 8px;
    background: ${theme.colors.values.yellow};
    color: ${theme.colors.values.darkgrey};
    align-items: baseline;
    justify-content: center;
    max-height: 50px;
    overflow-y: auto;
    z-index: 2;

    & > * {
      margin-left: 5px;
      margin-bottom: 0;
    }

    .bold {
      font-weight: 600;
    }
  `}
`;

type PreviewBarProps = {
  guardianRoleLabel: string;
};

const PreviewBar: FC<PreviewBarProps> = ({ guardianRoleLabel }) => {
  const { t } = useTranslation();
  const signee = guardianRoleLabel ?? "Guardian";
  return (
    <StyledPreviewBar>
      <i className="fa fa-exclamation-triangle"></i>
      <StyledLabel>{t("previewBar.mode")}: </StyledLabel>
      <StyledLabel>{t("previewBar.message", { signee })}</StyledLabel>
    </StyledPreviewBar>
  );
};

export { PreviewBar };
