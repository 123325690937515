import { ApolloCache, DefaultContext, MutationUpdaterFunction } from "@apollo/client";
import { SigneeInfoValues } from "@src/localVariables";
import { TicketTypeCode } from "@src/pages/Tickets/TicketSection/TicketPrice";
import {
  CancelTicketMutation,
  CancelTicketMutationVariables,
  PurchasedTicket,
  ReserveTicketMutation,
  ReserveTicketMutationVariables,
  ResponseQuery,
  Ticket,
  TicketInventoryQuery,
  TicketInventoryQueryVariables,
} from "@src/types";
import { PreviewHash, mapAnswerInputsToAnswers } from "@src/utils/preview";
import { getAnswerHash } from "@src/utils/purchasedTickets";
import { v4 as uuidv4 } from "uuid";
import cloneDeep from "lodash/cloneDeep";
import { TICKET_INVENTORY_QUERY } from "@src/queries/ticketInventories";
import { RESPONSE_VERSION_QUERY } from "@src/queries/responseVersion";

type ReserveTicketMutationUpdater = (
  signee: SigneeInfoValues,
  ticket: Ticket,
  attendeeId: string
) => MutationUpdaterFunction<
  ReserveTicketMutation,
  ReserveTicketMutationVariables,
  DefaultContext,
  ApolloCache<unknown>
>;

// Adds ticket data to Apollo cache, used exclusively while in preview mode
const reserveTicketMutationUpdater: ReserveTicketMutationUpdater =
  (signee, ticket, attendeeId) =>
  (cache, _result, { variables }) => {
    const answers = mapAnswerInputsToAnswers(variables?.reserveTicketInput.answers || []);
    const answerHash = getAnswerHash(answers);

    cache.updateQuery<ResponseQuery>(
      {
        query: RESPONSE_VERSION_QUERY,
        variables: {
          hash: PreviewHash
        },
        overwrite: true,
      },
      (data: ResponseQuery | null) => {

        if (data && data.Response) {
          const responseVersion = data.Response;
          
          let orders = cloneDeep(responseVersion.orders);
          const attendees = cloneDeep(responseVersion.attendees);
          const attendee = attendees.find((x) => x.attendeeId === attendeeId);
          const attendeeTickets = attendee?.tickets ?? [];
          const ticketPrice = ticket.typeCode
            ? (ticket.typeCode as TicketTypeCode) == TicketTypeCode.Donation
              ? variables?.reserveTicketInput.openValueAmount || 0
              : (ticket.typeCode as TicketTypeCode) == TicketTypeCode.Free
              ? 0
              : ticket.price || 0
            : ticket.price || 0;
          const existingTicket = attendeeTickets?.find(
            (x) =>
              x.ticketVersionId === variables?.ticketVersionId &&
              x.price === ticketPrice &&
              getAnswerHash(x.answers) === answerHash
          );

          if (!orders || orders.length == 0) {
            orders = [
              {
                __typename: "Order",
                date: new Date().toString(),
                canRefund: false,
                baseFee: 0,
                baseFeeString: "0.00",
                totalFees: 0,
                totalFeesString: "0.00",
                grandTotal: 0,
                grandTotalOriginal: 0,
                hasBeenPaid: false,
                isCheckedOut: false,
                isRefunded: false,
                orderId: uuidv4(),
                orderNumber: "PREVIEW",
                paymentTypes: ["Cheque", "Cash", "Other"],
                purchasedTickets: [],
                purchaserFirstName: signee.firstName,
                purchaserLastName: signee.lastName,
                refundedAmount: 0,
                subTotal: 0,
                wasManuallyPaid: false,
                amountOutstanding: 0,
              },
            ];
          }

          if (attendee) {
            if (existingTicket) {
              existingTicket.quantity += variables?.reserveTicketInput.quantity || 0;
            } else {
              // Create new purchased ticket
              const purchasedTicket: PurchasedTicket = {
                __typename: "PurchasedTicket" as const,
                answers: answers,
                attendeeId: attendeeId,
                createdDate: new Date().toString(),
                description: ticket.description,
                name: ticket.name,
                orderNumber: "PREVIEW",
                price: ((ticket.typeCode as TicketTypeCode) != TicketTypeCode.Donation ? ticket.price : variables?.reserveTicketInput.openValueAmount) ?? 0,
                purchasedTicketIds: Array(variables?.reserveTicketInput.quantity || 0).fill(
                  uuidv4()
                ),
                quantity: variables?.reserveTicketInput.quantity || 0,
                sortOrder: ticket.sortOrder ?? 0,
                ticketId: ticket.ticketId,
                ticketVersionId: variables?.ticketVersionId || "",
                totalPrice: variables?.reserveTicketInput.quantity || 0 * ticketPrice,
                type: {
                  __typename: "TicketTypeInfo" as const,
                  code: "",
                  defaultPrice: 0,
                  id: 0,
                  name: "",
                  sortOrder: 0,
                },
              };

              attendeeTickets?.push(purchasedTicket);
              attendeeTickets?.sort((a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0));
              attendee.tickets = attendeeTickets;
              orders[0].purchasedTickets = attendeeTickets;
            }
          }

          // Recalculate total
          const newTotal = attendees.reduce(
            (acc, a) =>
              (acc += a.tickets?.reduce((acc2, t) => (acc2 += t.price * t.quantity), 0) ?? 0),
            0
          );
          orders[0].subTotal = newTotal;
          orders[0].grandTotal = newTotal;

          const newResponse = {...responseVersion,
            attendees: attendees,
            subTotal: newTotal,
            total: newTotal,
            orders: orders};

          return {
            ...data,
            Response: newResponse,
          };
        }
      }
    );
  };

type CancelTicketMutationUpdater = (
  signee: SigneeInfoValues
) => MutationUpdaterFunction<
  CancelTicketMutation,
  CancelTicketMutationVariables,
  DefaultContext,
  ApolloCache<unknown>
>;

// Removes ticket data from Apollo cache, used exclusively while in preview mode
const cancelTicketMutationUpdater: CancelTicketMutationUpdater =
  () =>
  (cache, _result, { variables }) => {

    cache.updateQuery<ResponseQuery>(
      {
        query: RESPONSE_VERSION_QUERY,
        variables: {
          hash: PreviewHash
        },
        overwrite: true,
      },
      (data: ResponseQuery | null) => {
        if (data && data.Response) {

          const responseVersion = data.Response;
          
          const orders = cloneDeep(responseVersion.orders);
          const attendees = cloneDeep(responseVersion.attendees);
          const attendee = attendees.find(
            (x) => x.responseAttendeeId === variables?.responseAttendeeId
          );
          const attendeeTickets = attendee?.tickets ?? [];

          const purchasedTicketId = variables?.purchasedTicketIds[0] ?? "";

          // Find existing ticket
          const index = attendeeTickets?.findIndex(
            (x) => x.ticketVersionId === variables?.ticketVersionId
            && purchasedTicketId ? x.purchasedTicketIds.includes(purchasedTicketId) : true
          );

          if (attendee && index >= 0 && orders.length > 0) {
            // Check if we are reducing the quantity or removing the entire ticket
            if (
              variables?.purchasedTicketIds &&
              attendeeTickets[index].purchasedTicketIds.length >
                variables?.purchasedTicketIds.length
            ) {
              // Reduce quantity
              const remainingIds = attendeeTickets[index].purchasedTicketIds.filter(
                (id) => !variables?.purchasedTicketIds.includes(id)
              );
              attendeeTickets[index].purchasedTicketIds = remainingIds;
              attendeeTickets[index].quantity = remainingIds.length;
            } else {
              // Remove ticket
              attendeeTickets?.splice(index, 1);
            }
            orders[0].purchasedTickets = attendeeTickets;

            // Recalculate total
            const newTotal = attendees.reduce(
              (acc, a) =>
                (acc += a.tickets?.reduce((acc2, t) => (acc2 += t.price * t.quantity), 0) ?? 0),
              0
            );
            orders[0].subTotal = newTotal;
            orders[0].grandTotal = newTotal;

            const newResponse = {
              ...responseVersion,
              attendees: attendees,
              subTotal: newTotal,
              total: newTotal,
              orders: orders,
            };
  
            return {
              ...data,
              Response: newResponse,
            };
          }
        }
      }
    );
  };

type TicketInventoryMutationUpdater = (
  tickets: Ticket[]
) => MutationUpdaterFunction<
  TicketInventoryQuery,
  TicketInventoryQueryVariables,
  DefaultContext,
  ApolloCache<unknown>
>;

// Adds ticket data to Apollo cache, used exclusively while in preview mode
export const ticketInventoryMutationUpdater: TicketInventoryMutationUpdater =
  (tickets) =>
  (cache, _result, { variables }) => {
    const ticketIds = tickets.map((x) => x.ticketId);

    cache.updateQuery<TicketInventoryQuery>(
      {
        query: TICKET_INVENTORY_QUERY,
        variables: {
          documentId: variables?.documentId,
          countryCode: variables?.countryCode,
          ticketIds,
        },
      },
      () => {
        return _result.data;
      }
    );
  };

export { reserveTicketMutationUpdater, cancelTicketMutationUpdater };
