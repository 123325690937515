import { ApolloCache, DefaultContext, MutationUpdaterFunction } from "@apollo/client";
import {
  ResponseVersion,
  RestartInProcessResponseMutation,
  RestartInProcessResponseMutationVariables,
  RestartResponseMutation,
  RestartResponseMutationVariables,
} from "@src/types";
import { updateResponseQuery } from "./addResponseVersion";

type RestartResponseVersionMutationUpdaterType = () =>
  MutationUpdaterFunction<
    RestartResponseMutation,
    RestartResponseMutationVariables,
    DefaultContext,
    ApolloCache<unknown>
  >;

type RestartInProcessResponseVersionMutationUpdaterType = () =>
  MutationUpdaterFunction<
    RestartInProcessResponseMutation,
    RestartInProcessResponseMutationVariables,
    DefaultContext,
    ApolloCache<unknown>
  >;

const restartResponseVersionMutationUpdater: RestartResponseVersionMutationUpdaterType =
  () =>
    async (cache, { data: mutationResult }) => {
      const response = mutationResult?.RestartResponse as ResponseVersion;
      updateResponseQuery(cache, response);

      return response;
    };


const restartInProcessResponseVersionMutationUpdater: RestartInProcessResponseVersionMutationUpdaterType =
  () =>
    async (cache, { data: mutationResult }) => {
      const response = mutationResult?.RestartInProcessResponse as ResponseVersion;
      updateResponseQuery(cache, response);

      return response;
    };

export { restartInProcessResponseVersionMutationUpdater, restartResponseVersionMutationUpdater };

