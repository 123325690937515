import { SigneeInfoValues } from "@src/localVariables";
import { ResponseVersion, useResponseVersionsQuery, useStaffResponseVersionsQuery } from "@src/types";
import { useUrlParams } from "./useUrlParams";

const useResponseVersions = (
  documentId: string,
  signee: SigneeInfoValues,
  isStaffResponse: boolean,
  isProcessResponse: boolean,
  isRosteredResponse: boolean
) => {
  const { countryCode } = useUrlParams();
  const responseVersionsQuery = useResponseVersionsQuery({
    variables: {
      documentId,
      countryCode,
      email: signee.email,
    },
    fetchPolicy: "cache-first",
    skip:
      !documentId
      || !signee.email
      || isStaffResponse
      || isProcessResponse
      || isRosteredResponse
  });

  const staffResponseVersionsQuery = useStaffResponseVersionsQuery({
    variables: {
      documentId,
      countryCode
    },
    fetchPolicy: "cache-first",
    skip: !documentId || !isStaffResponse
  })

  let responseVersions: ResponseVersion[] = [];

  if (responseVersionsQuery?.data?.ResponseVersions && responseVersionsQuery?.data?.ResponseVersions.length > 0)
    responseVersions = responseVersionsQuery?.data?.ResponseVersions


  if (isStaffResponse && staffResponseVersionsQuery?.data?.StaffResponseVersions && staffResponseVersionsQuery?.data?.StaffResponseVersions.length > 0)
    responseVersions = staffResponseVersionsQuery?.data?.StaffResponseVersions;

  return {
    responseVersions: responseVersions,
    loading: staffResponseVersionsQuery.loading || responseVersionsQuery.loading,
    error: staffResponseVersionsQuery.error || responseVersionsQuery.error,
  };
};

export { useResponseVersions };
