import React, { FC } from "react";
import { useFormikContext } from "formik";

import {
  ErrorNavigatorWrapper,
  ShowNextErrorButton,
  ErrorIcon,
  ErrorNavigationMessage,
} from "./styles";
import { getErrorListFromErrorObjects } from "@src/utils/validation";
import { useTranslation } from "react-i18next";

const ErrorNavigator: FC = () => {
  const { t } = useTranslation();
  const { errors, submitCount } = useFormikContext();

  const errorList = getErrorListFromErrorObjects(errors);

  const selectedError = errorList.length ? errorList[0] : null;

  const shouldShowError = !!submitCount && selectedError;

  const message = t("messages.fieldsHaveErrors", { number: errorList.length });

  const navigateToError = () => {
    if (selectedError) {
      const yOffset = -150;
      const element = document.getElementById(selectedError.key);

      if (element) {
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  };

  return shouldShowError ? (
    <ErrorNavigatorWrapper>
      <ErrorIcon />
      <ErrorNavigationMessage>{message}</ErrorNavigationMessage>
      <ShowNextErrorButton onClick={() => navigateToError()}>
        {t("labels.showNextError")}
      </ShowNextErrorButton>
    </ErrorNavigatorWrapper>
  ) : null;
};

export { ErrorNavigator };
