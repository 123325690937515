import React, { FunctionComponent } from "react";
import styled from "styled-components";

import NormalLogoImg from "@src/assets/logo.png";
import CircleLogoImg from "@src/assets/pc-logo-icon-circle.png";

export type logoStyles = "normal" | "circle";

export type LogoProps = {
  style: logoStyles;
  width: string;
  height: string;
};

const StyledImg = styled.img``;

const Logo: FunctionComponent<LogoProps> = ({ style, width, height }: LogoProps) => {
  return (
    <StyledImg src={GetLogoImage(style)} width={width} height={height} alt="Permission Click" />
  );
};

const GetLogoImage = (style: string) => {
  switch (style) {
    case "normal":
      return NormalLogoImg;
    case "circle":
      return CircleLogoImg;
    default:
      return NormalLogoImg;
  }
};

export { Logo };
