import { Button } from "@src/components/atoms/Button";
import { Icon } from "@src/components/atoms/Icon";
import { Flex } from "@src/components/layout/Page";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  InfoModalBackground,
  InfoModalContent,
  ModalTitle,
  InfoModalTitleContainer,
  InfoModalCloseIconContainer,
  InfoModalMessageContainer,
  InfoModalActionButtonsContainer,
} from "./styles";

export type InfoModalProps = {
  title?: string;
  message?: string;
  confirmButtonText?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
};

const InfoModal: FC<InfoModalProps> = ({
  title,
  message,
  confirmButtonText,
  onCancel,
  onConfirm,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <InfoModalContent>
        <Flex>
          <InfoModalTitleContainer>
            <ModalTitle>{title}</ModalTitle>
          </InfoModalTitleContainer>
          <InfoModalCloseIconContainer>
            <Icon
              style="solid"
              icon="xmark"
              color="#cbcbcb"
              onClick={onCancel}
              testId={"questionModalXButton"}
            />
          </InfoModalCloseIconContainer>
        </Flex>
        <InfoModalMessageContainer>
          <p>{message}</p>
        </InfoModalMessageContainer>
        <InfoModalActionButtonsContainer>
          {onCancel ? (
            <Button
              style={{ border: "1px solid #a2a2a2", color: "#a2a2a2", marginRight: "15px" }}
              onClick={onCancel}
              data-testid={"questionModalCancelButton"}
            >
              {t("labels.cancel")}
            </Button>
          ) : null}

          {onConfirm ? (
            <Button onClick={onConfirm} primary data-testid={"questionModalConfirmButton"}>
              {confirmButtonText || "Confirm"}
            </Button>
          ) : null}
        </InfoModalActionButtonsContainer>
      </InfoModalContent>
      <InfoModalBackground onClick={onCancel} />
    </>
  );
};

export { InfoModal };
