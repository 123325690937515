import { ApolloCache, DefaultContext, MutationUpdaterFunction } from "@apollo/client";
import {
  CreateResponseMutation,
  CreateResponseMutationVariables,
  ResponseVersion,
} from "@src/types";
import { updateResponseQuery } from "./addResponseVersion";

type CreateResponseMutationUpdaterType = () =>
  MutationUpdaterFunction<
  CreateResponseMutation,
  CreateResponseMutationVariables,
  DefaultContext,
  ApolloCache<unknown>
>;

const createResponseMutationUpdater: CreateResponseMutationUpdaterType =
  () =>
  async (cache, { data: mutationResult }) => {
    const response = mutationResult?.CreateResponse as ResponseVersion;
    updateResponseQuery(cache, response);
  };

export { createResponseMutationUpdater };
