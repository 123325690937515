import React from "react";
import { Routes as ReactRoutes, Route } from "react-router-dom";
import { AccessData } from "./pages/AccessData/AccessData";
import { Confirmation } from "./pages/Confirmation/Confirmation";
import { ErrorComponent } from "./pages/Error";
import { Review } from "./pages/Review";
import { RosterLanding } from "./pages/LandingPages/RosterLanding";
import { SelectResponse } from "./pages/SelectResponse";
import { SigneeForm } from "./pages/SigneeForm";
import { StudentInfo } from "./pages/StudentInfo";
import { TicketsAndAttendeeForm } from "./pages/TicketsAndAttendeeForm/TicketsAndAttendeeForm";
import { PublicLanding } from "./pages/LandingPages/PublicLanding";
import { SigneeInfo } from "./pages/SigneeInfo/SigneeInfo";
import { PayNowLanding } from "./pages/LandingPages/PayNowLanding";
import { PDFDownloadLanding } from "./pages/LandingPages/PDFDownloadLanding";

const RosterBase = "/r/:hashedId";
const PayNowBase = "/rp/:hashedId";
const PDFDownloadBase = "/rd/:countryCode/:hashedId";
const RouteBase = "/:hashedId/:countryCode";
const OrgRouteBase = "/:hashedId/:countryCode/:hashedOrgId";
const PreviewBase = `${RouteBase}/preview`;

const AccessDataRoute = "access-data";
const AttendeeInformationRoute = "attendees";
const SigneeFormRoute = "signee-form";
const SelectResponseRoute = "select-response";
const AttendeeFormRoute = "attendee-form";
const ReviewRoute = "review";
const ConfirmationRoute = "confirmation";
const SigneeInfoRoute = "signee-info";

export interface PreviewProps {
  isPreview?: boolean;
}

const Routes = (): JSX.Element => (
  <ReactRoutes>
    {/* Rostered and Staff Form Library */}
    <Route path={RosterBase} element={<RosterLanding />} />
    <Route path={`${RosterBase}/${AccessDataRoute}`} element={<AccessData />} />
    <Route path={`${RosterBase}/${SigneeInfoRoute}`} element={<SigneeInfo />} />
    <Route path={`${RosterBase}/${SelectResponseRoute}`} element={<SelectResponse />} />
    <Route path={`${RosterBase}/${AttendeeInformationRoute}`} element={<StudentInfo />} />
    <Route path={`${RosterBase}/${SigneeFormRoute}`} element={<SigneeForm />} />
    <Route
      path={`${RosterBase}/${AttendeeFormRoute}/:attendeeIndex`}
      element={<TicketsAndAttendeeForm />}
    />
    <Route path={`${RosterBase}/${ReviewRoute}`} element={<Review />} />
    <Route path={`${RosterBase}/${ConfirmationRoute}`} element={<Confirmation />} />

    {/* PDF Download Link */}
    <Route path={PDFDownloadBase} element={<PDFDownloadLanding />} />

    {/* Public Link */}
    <Route path={RouteBase} element={<PublicLanding />} />
    <Route path={`${RouteBase}/${SigneeInfoRoute}`} element={<SigneeInfo />} />
    <Route path={`${RouteBase}/${SelectResponseRoute}`} element={<SelectResponse />} />
    <Route path={`${RouteBase}/${AttendeeInformationRoute}`} element={<StudentInfo />} />
    <Route path={`${RouteBase}/${SigneeFormRoute}`} element={<SigneeForm />} />
    <Route
      path={`${RouteBase}/${AttendeeFormRoute}/:attendeeIndex`}
      element={<TicketsAndAttendeeForm />}
    />
    <Route path={`${RouteBase}/${ReviewRoute}`} element={<Review />} />
    <Route path={`${RouteBase}/${ConfirmationRoute}`} element={<Confirmation />} />

    {/* Pay Now Link */}
    <Route path={PayNowBase} element={<PayNowLanding />} />
    <Route path={`${RouteBase}/${ReviewRoute}`} element={<Review />} />
    <Route path={`${RouteBase}/${ConfirmationRoute}`} element={<Confirmation />} />

    {/* Public link for school via district form */}
    <Route path={OrgRouteBase} element={<PublicLanding />} />
    <Route path={`${OrgRouteBase}/${SigneeInfoRoute}`} element={<SigneeInfo />} />
    <Route path={`${OrgRouteBase}/${SelectResponseRoute}`} element={<SelectResponse />} />
    <Route path={`${OrgRouteBase}/${AttendeeInformationRoute}`} element={<StudentInfo />} />
    <Route path={`${OrgRouteBase}/${SigneeFormRoute}`} element={<SigneeForm />} />
    <Route
      path={`${OrgRouteBase}/${AttendeeFormRoute}/:attendeeIndex`}
      element={<TicketsAndAttendeeForm />}
    />
    <Route path={`${OrgRouteBase}/${ReviewRoute}`} element={<Review />} />
    <Route path={`${OrgRouteBase}/${ConfirmationRoute}`} element={<Confirmation />} />

    {/* Preview */}
    <Route path={`${PreviewBase}`} element={<PublicLanding isPreview />} />
    <Route path={`${PreviewBase}/${SigneeInfoRoute}`} element={<SigneeInfo isPreview />} />
    <Route
      path={`${PreviewBase}/${AttendeeInformationRoute}`}
      element={<StudentInfo isPreview />}
    />
    <Route path={`${PreviewBase}/${SigneeFormRoute}`} element={<SigneeForm isPreview />} />
    <Route
      path={`${PreviewBase}/${AttendeeFormRoute}/:attendeeIndex`}
      element={<TicketsAndAttendeeForm isPreview />}
    />
    <Route path={`${PreviewBase}/${ReviewRoute}`} element={<Review isPreview />} />
    <Route path={`${PreviewBase}/${ConfirmationRoute}`} element={<Confirmation isPreview />} />

    {/* catch */}
    <Route path="/" element={<ErrorComponent error="Incorrect URL" />} />
  </ReactRoutes>
);

export {
  Routes,
  AccessDataRoute,
  AttendeeInformationRoute,
  SigneeFormRoute,
  SelectResponseRoute,
  AttendeeFormRoute,
  ConfirmationRoute,
  SigneeInfoRoute,
  ReviewRoute,
};
