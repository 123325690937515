import React, { ChangeEventHandler, FocusEventHandler } from "react";
import omit from "lodash/omit";

import {
  FieldWrapper,
  MaxLengthMessage,
  StyledRequiredLabel,
  StyledTextInput,
} from "@src/components/styles";
import { TextFieldProps } from "../types";
import { FieldError } from "@src/components/atoms/FieldError";
import { Label } from "@src/components/atoms/Label";
import { useTranslation } from "react-i18next";

const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      label,
      id,
      value,
      isRequired,
      maxLength,
      placeHolderText,
      error,
      touched,
      setValue,
      saveAnswer,
      onBlur,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) =>
      setValue({ ...value, value: event.target.value });

    const handleBlur: FocusEventHandler<HTMLInputElement> = async (event) => {
      const newAnswer = { ...value, value: event.target.value };

      onBlur && onBlur(event);

      const answerId = saveAnswer ? await saveAnswer(omit(newAnswer, "__typename")) : "";

      setValue({
        ...newAnswer,
        answerId,
      });
    };

    return (
      <FieldWrapper id={id}>
        {isRequired ? <StyledRequiredLabel /> : null}
        <Label>{label}</Label>

        {maxLength && maxLength > 0 ? (
          <MaxLengthMessage>{t("labels.characterLimit", { length: maxLength })}</MaxLengthMessage>
        ) : null}
        <StyledTextInput
          hasError={!!error && touched}
          ref={ref}
          data-testid={id}
          name={id}
          value={value?.value || ""}
          maxLength={maxLength && maxLength > 0 ? maxLength : undefined}
          placeholder={placeHolderText ? placeHolderText : undefined}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <FieldError id={id} name={id} />
      </FieldWrapper>
    );
  }
);

// This has to be set here because the component is being created on an anonymous
// function inside the call to React.forwardRef()
TextField.displayName = "TextField";

export { TextField };
