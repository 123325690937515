import { ApolloCache, DefaultContext, MutationUpdaterFunction } from "@apollo/client";
import { RESPONSE_VERSION_QUERY } from "@src/queries/responseVersion";
import {
  AddResponseVersionMutation,
  AddResponseVersionMutationVariables,
  ResponseQuery,
  ResponseVersion,
} from "@src/types";

type AddResponseVersionMutationUpdater = () =>
  MutationUpdaterFunction<
  AddResponseVersionMutation,
  AddResponseVersionMutationVariables,
  DefaultContext,
  ApolloCache<unknown>
>;

const addResponseVersionMutationUpdater: AddResponseVersionMutationUpdater = () =>
  async (cache, { data: mutationResult }) => {
    const newResponse = mutationResult?.AddResponseVersion as ResponseVersion;
    updateResponseQuery(cache, newResponse);

    return newResponse;
  };

const updateResponseQuery = (
  cache: ApolloCache<unknown>,
  responseVersion: ResponseVersion
) =>
  cache.updateQuery<ResponseQuery>(
    {
      query: RESPONSE_VERSION_QUERY,
      variables: {
        hash: responseVersion.hash,
      },
      overwrite: true,
    },
    () => {
      return {
        __typename: "DomainQueries",
        Response: responseVersion,
      };
    }
  );

export {
  addResponseVersionMutationUpdater,
  updateResponseQuery,
};
