import { parse } from "mathjs";
import keyBy from "lodash/keyBy";
import groupBy from "lodash/groupBy";

import { AnswerInput, Field, Question } from "@src/types";
import { getEquationSectionId, getQuestionDataValue, isQuestionRequired } from "./getters";
import { EquationValue, InnerFields } from "./formValuesTypes";

const getEquationQuestionNameForExpression = (question: Question): string =>
  question.name
    ? question.name
        .toLowerCase()
        .replace(/^([^a-z])+/, "") // Remove all leading non-alphabetic characters
        .replace(/\s/g, "_") // Replace all spaces with underscores
        .replace(/[\W]/g, "") // Remove all remaining non-alphanumeric characters
    : "";

const getEquationIdAsNameForExpression = (value: string): string =>
  value
    ? value
        .toLowerCase()
        .replace(/^([^a-z])+/, "") // Remove all leading non-alphabetic characters
        .replace(/\s/g, "_") // Replace all spaces with underscores
        .replace(/[\W]/g, "") // Remove all remaining non-alphanumeric characters
    : "";

const getEquationInputQuestions = (field: Field): Question[] =>
  field.fields && field.fields.length > 0 ? field.fields[0].equationSections || [] : [];

const getEquationCalculatedQuestions = (field: Field): Question[] =>
  field.fields && field.fields.length > 1 ? field.fields[1].equationSections || [] : [];

const getEquationAggregateQuestions = (field: Field): Question[] =>
  field.fields && field.fields.length > 2 ? field.fields[2].equationSections || [] : [];

const getExpressionValues = (answers: AnswerInput[]): Record<string, string[]> =>
  answers.reduce((acc, answer) => {
    if (answer && answer.questionId !== "row") {
      const name = getEquationIdAsNameForExpression(answer.questionId);

      acc[name] = acc[name] ? [...acc[name], answer.value || ""] : [answer.value || ""];
    }

    return acc;
  }, {} as Record<string, string[]>);

type GetCalculatedValue = {
  values: Record<string, string | string[]>;
  question: Question;
};

const getCalculatedValue = ({ question, values }: GetCalculatedValue): string => {
  const expression = getQuestionDataValue(question);

  try {
    return `${parse(expression).evaluate(values)}`;
  } catch (e) {
    return "";
  }
};

type GetInputAnswers = {
  inputQuestions: Question[];
  innerFields: InnerFields;
  rowId: string;
};

type GetInputAnswersReturn = {
  inputAnswers: AnswerInput[];
  expressionValues: Record<string, string | string[]>;
};

const getInputAnswersAndExpressionValues = ({
  inputQuestions,
  innerFields,
  rowId,
}: GetInputAnswers): GetInputAnswersReturn => {
  return inputQuestions.reduce(
    (acc, question) => {
      const expressionName = getEquationQuestionNameForExpression(question);
      const sectionId = getEquationSectionId(question);
      const answer = innerFields[sectionId] as AnswerInput;

      // if there's a default value set, assign it
      if (!answer.value && question.characterLimit) {
        answer.value = question.characterLimit.toString();
      }

      const newAnswer: AnswerInput = {
        ...answer,
        fieldId: rowId,
      };

      acc.inputAnswers.push(newAnswer);

      acc.expressionValues = {
        ...acc.expressionValues,
        [expressionName]: newAnswer.value || "",
      };

      return acc;
    },
    {
      inputAnswers: [],
      expressionValues: {},
    } as GetInputAnswersReturn
  );
};

type GetCalculatedAnswersArgs = {
  calculatedQuestions: Question[];
  rowId: string;
  expressionValues: Record<string, string | string[]>;
};

type GetCalculatedAnswersInitialValues = {
  calculatedAnswers: AnswerInput[];
  calculatedExpressionValues: Record<string, string | string[]>;
};

const getCalculatedAnswers = ({
  calculatedQuestions,
  rowId,
  expressionValues,
}: GetCalculatedAnswersArgs): AnswerInput[] => {
  const { calculatedAnswers } = calculatedQuestions.reduce(
    (acc, question) => {
      const expressionName = getEquationQuestionNameForExpression(question);
      const result = getCalculatedValue({
        question,
        values: acc.calculatedExpressionValues,
      });

      const newAnswer: AnswerInput = {
        fieldId: rowId,
        questionId: question.name || "",
        value: result,
      };

      acc.calculatedAnswers.push(newAnswer);

      acc.calculatedExpressionValues = {
        ...acc.calculatedExpressionValues,
        [expressionName]: result,
      };

      return acc;
    },
    {
      calculatedAnswers: [],
      calculatedExpressionValues: expressionValues,
    } as GetCalculatedAnswersInitialValues
  );

  return calculatedAnswers;
};

type GetAggregateAnswers = {
  aggregateQuestions: Question[];
  fieldId: string;
  allAnswers: AnswerInput[];
};

const getAggregateAnswers = ({
  aggregateQuestions,
  fieldId,
  allAnswers,
}: GetAggregateAnswers): AnswerInput[] => {
  const expressionValues = getExpressionValues(allAnswers);

  const { aggregateAnswers } = aggregateQuestions.reduce(
    (acc, question) => {
      const expressionName = getEquationQuestionNameForExpression(question);
      const result = getCalculatedValue({
        question,
        values: acc.expressionValues,
      });

      const newAnswer: AnswerInput = {
        fieldId,
        questionId: question.name || "",
        value: result,
      };

      acc.aggregateAnswers.push(newAnswer);

      acc.expressionValues = {
        ...acc.expressionValues,
        [expressionName]: result,
      };

      return acc;
    },
    {
      aggregateAnswers: [],
      expressionValues,
    } as {
      aggregateAnswers: AnswerInput[];
      expressionValues: Record<string, string | string[]>;
    }
  );

  return aggregateAnswers;
};

type AnswersArrayToEquationValueArgs = {
  answers: AnswerInput[];
  fieldId?: string | null;
  aggregateQuestions: Question[];
};

const answersArrayToEquationValue = ({
  answers,
  fieldId,
  aggregateQuestions,
}: AnswersArrayToEquationValueArgs): EquationValue => {
  const initialObject: EquationValue = {
    rows: [],
    aggregates: {},
    allAnswers: [],
    isDirty: false,
  };

  const answersDictionary = groupBy(answers, "fieldId");

  const rowsAndAggregates = answersDictionary[fieldId || ""] || [];

  const { rows, aggregates, allAnswers } = rowsAndAggregates.reduce((acc, answer) => {
    if (answer.questionId === "row") {
      const rowPlaceholder = answer;
      const rowAnswers = answersDictionary[rowPlaceholder.answerId || ""] || [];

      acc.rows.push({
        ...rowPlaceholder,
        order: rowPlaceholder.order || 0,
        answers: keyBy(rowAnswers, "questionId"),
      });

      acc.allAnswers = [...acc.allAnswers, ...rowAnswers, rowPlaceholder];
    } else {
      acc.aggregates[answer.questionId] = answer;

      acc.allAnswers.push(answer);
    }

    return acc;
  }, initialObject);

  const aggregateAnswers = aggregateQuestions.reduce((acc, question) => {
    const questionId = question.id;
    const newAnswer: AnswerInput = {
      fieldId: fieldId || "",
      questionId,
      fileType: null,
      order: null,
      value: "",
    };

    acc[questionId] = aggregates[question.name || ""] || newAnswer;
    return acc;
  }, {} as Record<string, AnswerInput>);

  return {
    rows: rows.sort((a, b) => (a.order || 0) - (b.order || 0)),
    aggregates: aggregateAnswers,
    isDirty: false,
    allAnswers,
  };
};

const getValueForEquationInputQuestion = (question: Question): string => {
  const questionType = question.valueType;
  const defaultValue = question.characterLimit;
  const min = question.minEntries ? question.minEntries : 0;

  if (questionType === "text") {
    return "";
  }

  if (questionType === "integer") {
    return `${defaultValue || min}`;
  }

  return defaultValue ? `${defaultValue.toFixed(2)}` : `${min.toFixed(2)}`;
};

type GetEquationInnerFieldValuesArgs = {
  inputQuestions: Question[];
  calculatedQuestions: Question[];
};

const getEquationInnerFieldInitialValues = ({
  inputQuestions,
  calculatedQuestions,
}: GetEquationInnerFieldValuesArgs): InnerFields => {
  const tableRows = [...inputQuestions, ...calculatedQuestions];

  const tableRowAnswers = tableRows.reduce((innerFields, question) => {
    const sectionAnswer: AnswerInput = {
      // Equation answers are created with an empty fieldId because it needs
      // to reference the id of the "row" that will be created when the answer
      // is submitted.
      fieldId: "",
      questionId: question.name || "",
      value: getValueForEquationInputQuestion(question),
    };

    innerFields[question.id] = sectionAnswer;

    return innerFields;
  }, {} as InnerFields);

  return tableRowAnswers;
};

const isEquationRequired = (field: Field): boolean => {
  const mainEquationQuestion =
    field.equationSections && field.equationSections.length ? field.equationSections[0] : null;

  const required = mainEquationQuestion ? isQuestionRequired(mainEquationQuestion) : false;

  return required;
};

export {
  getEquationInputQuestions,
  getEquationCalculatedQuestions,
  getEquationAggregateQuestions,
  answersArrayToEquationValue,
  getEquationInnerFieldInitialValues,
  getExpressionValues,
  getInputAnswersAndExpressionValues,
  getCalculatedAnswers,
  getCalculatedValue,
  getAggregateAnswers,
  isEquationRequired,
  getEquationQuestionNameForExpression,
  getEquationIdAsNameForExpression,

  //testing exports
  getValueForEquationInputQuestion as _getValueForEquationInputQuestion,
};
