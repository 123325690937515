import { Button } from "@src/components/atoms/Button";
import { Flex } from "@src/components/layout/Page";
import styled from "styled-components";

export const FeedbackContainer = styled(Flex)`
  ${({ theme }) => `
    ${Button} {
      min-width: 90px;

      :not(:first-child) {
        margin-left: 10px;
      }

      :not([type=submit]){
        background-color: ${theme.colors.values.red}
      } 
    }

    textarea {
      resize: vertical;
      min-height: 100px;
      max-height: 600px;
    }
  `}
`;

export const StartFeedbackContainer = styled(Flex)`
  margin-top: 10px;
  flex-direction: column;

  .startFeedback {
    align-items: baseline;
    margin-bottom: 15px;

    > *:not(:first-child) {
      margin-left: 20px;
    }
  }
`;

export const StartFeedbackButton = styled(Button)`
  min-width: 60px;
`;

export const SentFeedbackContainer = styled(Flex)`
  ${({ theme }) => `
    margin-top: 10px;
    align-items: baseline;

    i {
      color: ${theme.colors.values.green};
      font-weight: 500;
      margin: -2px 4px 0;
    }
  `}
`;