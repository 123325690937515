import React, { FC } from "react";

import { Flex } from "@src/components/layout/Page";
import { PreviewProps } from "@src/Routes";
import { Ticket, Attendee, Field, FormInfoQuery } from "@src/types";
import { CancelTicketArgs, TicketMode } from "@src/utils/purchasedTickets";
import { AttendeePreview } from "./AttendeePreview";

type AttendeesPreviewProps = PreviewProps & {
  tickets: Ticket[];
  attendees: Attendee[];
  fields?: Field[];
  mode: TicketMode;
  countryCode: string;
  form: NonNullable<FormInfoQuery["Form"]>;
  responseVersionId: string;
  onCancelTicket?: (args: CancelTicketArgs) => void;
};

const AttendeesPreview: FC<AttendeesPreviewProps> = ({
  tickets,
  attendees,
  fields,
  mode,
  countryCode,
  form,
  responseVersionId,
  onCancelTicket,
}) => {
  const attendeesToDisplay =
    mode !== TicketMode.CHECKOUT
      ? attendees.filter((attendee) => attendee?.tickets?.length > 0)
      : attendees;

  return attendees ? (
    <Flex flexDirection="column" style={{ marginBottom: "15px"}}>
      {attendeesToDisplay.map((attendee) => (
        <AttendeePreview
          key={`preview-${attendee.responseAttendeeId}`}
          tickets={tickets}
          attendee={attendee}
          attendees={attendees}
          fields={fields}
          mode={mode}
          countryCode={countryCode}
          form={form}
          responseVersionId={responseVersionId}
          onCancelTicket={onCancelTicket}
        />
      ))}
    </Flex>
  ) : null;
};

export { AttendeesPreview };
