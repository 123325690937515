import React, { FC } from "react";
import { FaStar, FaRegStar } from "react-icons/fa";

import {
  StyledAnswerRow,
  StyledDisplayLabel,
  StyledSelectedStar,
  StyledUnselectedStar,
} from "@src/components/styles";
import { Field } from "@src/types";

type RatingFieldProps = {
  field: Field;
  label: string;
  value: string;
};

const RatingDisplayField: FC<RatingFieldProps> = ({ field, label, value }) => {
  const rating = value ? parseInt(value, 10) : 0;

  const selectedStar = (
    <StyledSelectedStar small={true} data-testid={field.question?.id + "-SELECTED"}>
      <FaStar />
    </StyledSelectedStar>
  );

  const unSelectedStar = (
    <StyledUnselectedStar small={true} data-testid={field.question?.id + "-UNSELECTED"}>
      <FaRegStar />
    </StyledUnselectedStar>
  );

  return (
    <StyledAnswerRow>
      <StyledDisplayLabel>{label}</StyledDisplayLabel> 
      {rating > 0 ? selectedStar : unSelectedStar}
      {rating > 1 ? selectedStar : unSelectedStar}
      {rating > 2 ? selectedStar : unSelectedStar}
      {rating > 3 ? selectedStar : unSelectedStar}
      {rating > 4 ? selectedStar : unSelectedStar}
    </StyledAnswerRow>
  );
};

export { RatingDisplayField };
