import React from "react";
import { i18next } from "@src/translations";
import common_en from "@src/translations/en/common.json";

import { DateOption } from "@src/components/atoms/DateOption";
import { Icon } from "@src/components/atoms/Icon";
import { AnswerInput } from "@src/types";
import { DateFillWrapper, FieldWrapper, StyledRequiredLabel } from "@src/components/styles";
import { ErrorObject } from "../types";
import { FieldError } from "@src/components/atoms/FieldError";
import { Normalize } from "react-i18next";
import { Label } from "@src/components/atoms/Label";

interface Props {
  id: string;
  isRequired?: boolean;
  value: AnswerInput;
  readonly?: boolean;
  hideState?: boolean;
  allowChangeOnInvalid?: boolean;
  disabled?: boolean;
  label: string;
  error?: ErrorObject | string;
  showClearIcon?: boolean;

  setValue?(value: AnswerInput): void;
  saveAnswer?(answer: AnswerInput): Promise<string>;
}

interface State {
  hour?: string | null;
  minute?: string | null;
  amPm?: string | null;
  relativeMode: boolean;
}

export class TimeField extends React.Component<Props, State> {
  private hours = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
  private minutes: string[];
  private amPmOptions: string[] = ["AM", "PM"];

  public constructor(props: Props) {
    super(props);

    const { hour, minute, amPm } = this.getValue();
    this.minutes = [];

    for (let index = 0; index < 60; index++) {
      this.minutes.push(index < 10 ? `0${index}` : `${index}`);
    }

    this.state = {
      hour,
      minute,
      amPm,
      relativeMode: false,
    };
  }

  componentDidUpdate(prevProps: { value: AnswerInput }) {
    if (prevProps.value !== this.props.value) {
      const { hour, minute, amPm } = this.getValue();

      this.setState({
        hour,
        minute,
        amPm,
      });
    }
  }

  public render(): JSX.Element {
    return (
      <FieldWrapper>
        {this.props.isRequired ? <StyledRequiredLabel /> : null}
        <Label>{this.props.label}</Label>

        <DateFillWrapper>
          <div className={"date-selection-wrapper" + (this.props.isRequired ? " required" : "")}>
            {this.props.showClearIcon &&
            (this.state.hour || this.state.minute || this.state.amPm) ? (
              <Icon size="12px" style="solid" icon="trash" onClick={() => this.removeAnswer()} />
            ) : null}

            <DateOption
              id={this.props.id}
              selectedOption={this.state.hour?.toString()}
              options={this.hours}
              disabled={this.props.disabled}
              onChange={(x) => this.onHourChanged(x)}
              label={this.t("labels.hour")}
              placeholder="hh"
            />

            <span className="date-separator">:</span>

            <DateOption
              id={this.props.id}
              selectedOption={this.state.minute?.toString()}
              options={this.minutes}
              disabled={this.props.disabled}
              onChange={(x) => this.onMinuteChanged(x)}
              label={this.t("labels.minute")}
              placeholder="mm"
            />

            <DateOption
              id={this.props.id}
              selectedOption={this.state.amPm?.toString()}
              options={this.amPmOptions}
              disabled={this.props.disabled}
              onChange={(x) => this.onAmPmChanged(x)}
              label={this.t("labels.amPm")}
              placeholder="--"
            />
          </div>

          {!this.props.hideState ? (
            this.isValid() ? (
              <p className="selected-message">
                {this.t("messages.youHaveSelected", { dateTime: this.format() })}
              </p>
            ) : (
              <p className={"selected-message" + (this.props.isRequired ? " required" : "")}>
                {this.t("messages.noTimeSelected")}
              </p>
            )
          ) : null}
        </DateFillWrapper>
        <FieldError id={this.props.id} name={this.props.id} />
      </FieldWrapper>
    );
  }

  private removeAnswer() {
    this.setState(
      {
        hour: null,
        minute: null,
        amPm: null,
      },
      () => {
        const emptyAnswer = {
          ...this.props.value,
          value: "",
        };

        this.props.setValue && this.props.setValue(emptyAnswer);
        this.props.saveAnswer && this.props.saveAnswer(emptyAnswer);
      }
    );
  }

  private getValue() {
    const selectedTime = this.props.value;
    const hour = selectedTime.value ? selectedTime.value.substring(0, 2) : null;
    const minute = selectedTime.value ? selectedTime.value.substring(3, 5) : null;
    const amPm = selectedTime.value ? selectedTime.value.substring(6, 8) : null;

    return { hour, minute, amPm };
  }

  private format() {
    return `${this.state.hour}:${this.state.minute} ${this.state.amPm}`;
  }

  private onHourChanged(hour: string) {
    this.setState({ hour }, () => this.modified());
  }

  private onMinuteChanged(minute: string) {
    this.setState({ minute }, () => this.modified());
  }

  private onAmPmChanged(amPm: string) {
    this.setState({ amPm }, () => this.modified());
  }

  private isValid(): boolean {
    return !!this.state.hour && !!this.state.minute && !!this.state.amPm;
  }

  private async modified() {
    const isValid = this.isValid();

    if (!this.props.readonly && (isValid || this.props.allowChangeOnInvalid)) {
      const newAnswer = {
        ...this.props.value,
        value: this.format(),
      };

      this.props.setValue && this.props.setValue(newAnswer);

      const answerId = this.props.saveAnswer ? await this.props.saveAnswer(newAnswer) : "";

      this.props.setValue &&
        this.props.setValue({
          ...newAnswer,
          answerId,
        });
    }
  }

  private getError() {
    if (typeof this.props.error === "string" || this.props.error instanceof String) {
      return this.props.error as string;
    } else {
      return this.props.error?.value || "";
    }
  }

  private t(text: Normalize<typeof common_en>, options?: Record<string, unknown>) {
    return i18next.t(text, options);
  }
}
