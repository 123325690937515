import { Flex } from "@src/components/layout/Page";
import { StyledLabel } from "@src/components/styles";
import styled from "styled-components";

export const AttendeePreviewWrapper = styled(Flex)`
  flex-direction: column;
  margin-bottom: 10px;
`;

export const PermissionLabel = styled(StyledLabel)<{ isPermitted: boolean }>`
  ${({ theme, isPermitted }) => `
    color: ${isPermitted ? theme.colors.values.green : theme.colors.values.red};
  `}
`;
