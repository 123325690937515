import React from "react";
import { useTranslation } from "react-i18next";

import { ResponseVersion, useFormInfoQuery } from "@src/types";
import { ResumeListItem } from "@src/utils/ResumeListItem";
import { useBreakPoints, useUrlParams } from "@src/customHooks";
import { StyledLabel, StyledResponseCard, StyledResponseCardTitle } from "@src/components/styles";
import { Flex } from "@src/components/layout/Page";
import { ResponseState } from "@src/constants/responseStates";
import { Icon } from "@src/components/atoms/Icon";
import { defaultTheme } from "@src/themes";
import { getReadableDate } from "@src/utils/dateTime";
import { Button, SmallButton } from "@src/components/atoms/Button";
import { isStaff } from "@src/utils/responseVersionGetters";

type ResponseCardProps = {
  responseVersion: ResponseVersion;
  handleSelectResponse: (resumeListItem: ResumeListItem) => () => Promise<void>;
  handlePayNow: (resumeListItem: ResumeListItem) => void;
};

const ResponseCard = ({
  responseVersion,
  handlePayNow,
  handleSelectResponse,
}: ResponseCardProps) => {
  const { t, i18n } = useTranslation();

  const { documentHashedId: hashedId, countryCode, hashedOrgId } = useUrlParams();

  const { data: formInfoQueryData } = useFormInfoQuery({
    variables: { hashedId: hashedId || "", countryCode, hashedOrgId },
  });

  const screenSize = useBreakPoints();

  const listItem = new ResumeListItem(
    responseVersion,
    formInfoQueryData?.Form?.id ?? "",
    !formInfoQueryData?.Form?.document?.disableResponseEditing ?? false
  );

  const state = listItem.state();

  const dateOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  return (
    <StyledResponseCard
      data-testid={"RESPONSE-CARD"}
      key={responseVersion.responseVersionId}
      screenSize={screenSize}
      state={state}
    >
      <Flex flexDirection="column">
        <StyledResponseCardTitle data-testid={"RESPONSE-STATE"} state={state}>
          {listItem.title()}
        </StyledResponseCardTitle>
        <Flex>
          {state === ResponseState.ResponseInProgress ? (
            <Icon style="solid" icon="clock" color={defaultTheme.colors.values.blue} />
          ) : state === ResponseState.ResponsePendingApproval ? (
            <Icon style="solid" icon="hourglass-clock" color={defaultTheme.colors.values.blue} />
          ) : state === ResponseState.ResponseRejected || state === ResponseState.ResponseCancelled ? (
            <Icon style="solid" icon="xmark" color={defaultTheme.colors.values.darkred} />
          ) : state === ResponseState.ResponseComplete ? (
            <Icon style="solid" icon="check" color={defaultTheme.colors.values.darkgreen} />
          ) : (
            <Icon style="solid" icon="clock" color={defaultTheme.colors.values.grey} />
          )}
          <StyledLabel
            style={{
              fontSize: "12px",
              fontWeight: "500",
              paddingLeft: "5px",
            }}
            title={t("labels.dateCreated")}
          >
            {getReadableDate({
              dateString: responseVersion.createdDate,
              options: dateOptions,
              locale: i18n.resolvedLanguage,
            })}
          </StyledLabel>
        </Flex>
        <div
          style={{
            height: "2px",
            backgroundColor: "#c7c7c7",
            marginBottom: "15px",
          }}
        ></div>
      </Flex>

      {responseVersion.attendees != null && responseVersion.attendees.length > 0 ? (
        <Flex flexDirection="column" style={{ flexGrow: 1 }}>
          {responseVersion.attendees.map((attendee, index) => (
            <Flex key={attendee.responseAttendeeId}>
              <Icon style="solid" icon="child" color={defaultTheme.colors.alias.primary} />
              <StyledLabel
                data-testid={"ATTENDEE-NAME-" + index}
                style={{ paddingLeft: "5px" }}
              >{`${attendee.firstName || ""} ${attendee.lastName || ""} ${isStaff(responseVersion) ? " - " + responseVersion.completedByOrganizationName : ""}`}</StyledLabel>
            </Flex>
          ))}
        </Flex>
      ) : null}

      {listItem.body() ? (
        <Flex
          justifyContent="center"
          style={{
            margin: "10px 0px",
          }}
        >
          <StyledLabel
            style={{
              fontSize: "12px",
              padding: "10px",
              textAlign: "center",
              margin: "0px",
              maxWidth: "250px",
            }}
          >
            {listItem.body()}
          </StyledLabel>
        </Flex>
      ) : null}

      <Flex justifyContent="space-evenly">
        {!listItem.canPay() ? (
          listItem.buttonText() ? (
            <Button
              data-testid="RESPONSE-CARD-ACTION-BUTTON"
              primary
              onClick={handleSelectResponse(listItem)}
            >
              {listItem.buttonText()}
            </Button>
          ) : null
        ) : (
          <>
            {listItem.buttonText() ? (
              <SmallButton
                data-testid="RESPONSE-CARD-ACTION-BUTTON"
                primary
                onClick={handleSelectResponse(listItem)}
                screenSize={screenSize}
              >
                {listItem.buttonText()}
              </SmallButton>
            ) : null}
            <SmallButton
              data-testid="RESPONSE-CARD-PAY-NOW-BUTTON"
              primary
              onClick={() => handlePayNow(listItem)}
              screenSize={screenSize}
            >
              {t("messages.payNow")}
            </SmallButton>
          </>
        )}
      </Flex>
    </StyledResponseCard>
  );
};

export { ResponseCard };
