import omit from "lodash/omit";

import { Answer, AnswerInput, AttendeeInput, ResponseSource, ResponseVersion } from "@src/types";

export type FormAttendee = {
  responseAttendeeId: string;
  firstName: string | null;
  lastName: string | null;
  permitted?: "yes" | "no" | null;
  manualPermissionDate: string | null;
  attachmentId: string | null;
  attachmentFileType: string | null;
  notes: string | null;
};

const formAttendeeToAttendeeInput = (attendee: FormAttendee): AttendeeInput =>
  omit(
    {
      ...attendee,
      firstName: attendee.firstName || "",
      lastName: attendee.lastName || "",
      permitted: attendee.permitted === "yes",
      manualPermissionDate: attendee.manualPermissionDate ?? null,
      attachmentId: attendee.attachmentId ?? null,
      notes: attendee.notes ?? null,
    },
    "__typename",
    "attendeeAnswers",
    "attendeeId",
    "attachmentFileType"
  );

const answerToAnswerInput = (answer: Answer | AnswerInput): AnswerInput =>
  omit(answer, "__typename");

function objectToStringifiedArray(object: Record<string, boolean>): string | null {
  const truthyKeys = Object.keys(object).filter((key) => object[key]);

  return truthyKeys.length > 0 ? JSON.stringify(truthyKeys) : null;
}

const parseStringIntoArray = (stringifiedArray: string): string[] => {
  try {
    const parsedArray: string[] = JSON.parse(stringifiedArray);

    if (Array.isArray(parsedArray)) {
      return parsedArray;
    }

    throw "parseStringIntoArray: Stringified object is not an array.";
  } catch (e) {
    console.error(e);

    // give the value back as the expected type
    return [stringifiedArray];
  }
};

const parseStringToNumber = (string: string | undefined): number | undefined => {
  if (!string) {
    return undefined;
  }

  const parsedNumber = Number(string);

  return isNaN(parsedNumber) ? undefined : parsedNumber;
};

/**
 * Check if a {@link ResponseVersion}'s {@link ResponseSource} is the same as the given {@link ResponseSource}.
 * @returns True if the sources match, false if not.
 */
const checkResponseVersionSource = (responseVersion: ResponseVersion | null, responseSource: ResponseSource): boolean =>
  responseVersion?.source != null && responseSource != null ? responseVersion.source === responseSource : false;

export {
  formAttendeeToAttendeeInput,
  objectToStringifiedArray,
  parseStringIntoArray,
  answerToAnswerInput,
  parseStringToNumber,
  checkResponseVersionSource,
};
