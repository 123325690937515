import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import React, { Fragment, ReactElement } from "react";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import jwt from "@src/utils/jwt";
import { loadStripe } from "@stripe/stripe-js";
import { CssReset } from "./components/layout";
import { Routes } from "./Routes";
import TelemetryProvider from "./TelemetryProvider";
import { defaultTheme } from "./themes";
import { defaultMergeFunction } from "./utils/cache";
import { i18next } from "./translations";

export const stripePromise = loadStripe(
  process.env.STRIPE_PUBLISHABLE_KEY ??
    (() => {
      throw new Error("Missing STRIPE_PUBLISHABLE_KEY environment variable!");
    })()
);

const requiresAuth = window.location.hostname === process.env.REQUIRES_AUTH_DOMAIN;

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = await jwt.getToken();
  if (!token) {
    // abort
    if (process.env.AUTH_URL) window.location.replace(process.env.AUTH_URL);
    return null;
  }
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const httpLink = createHttpLink({
  uri: process.env.GRAPHQL_URL,
});

const client = new ApolloClient({
  link: requiresAuth ? authLink.concat(httpLink) : httpLink,
  defaultOptions: {
    mutate: {
      errorPolicy: "all",
    },
    query: {
      errorPolicy: "all",
    },
  },
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          ResponseVersions: {
            merge: defaultMergeFunction,
          },
        },
      },
      ResponseVersion: {
        keyFields: ["responseVersionId"],
        fields: {
          signeeAnswers: {
            merge: defaultMergeFunction,
          },
        },
      },
      Answer: {
        keyFields: ["answerId"],
      },
      Question: {
        keyFields: false
      },
      Attendee: {
        keyFields: ["responseAttendeeId"],
        fields: {
          integratedDataOnFile: {
            merge(existing, incoming) {
              if (incoming && incoming.length !== 0) {
                return incoming;
              } else {
                return existing || [];
              }
            },
          },
        },
      },
    },
  }),
});

const App = (): ReactElement => {
  return (
    <Fragment>
      <CssReset />
      <ApolloProvider client={client}>
        <ThemeProvider theme={defaultTheme}>
          <I18nextProvider i18n={i18next}>
            <BrowserRouter>
              <TelemetryProvider
                connectionString={process.env.APPLICATIONINSIGHTS_CONNECTION_STRING}
                appVersion={process.env.APPVERSION}
              >
                <Routes />
              </TelemetryProvider>
            </BrowserRouter>
          </I18nextProvider>
        </ThemeProvider>
      </ApolloProvider>
    </Fragment>
  );
};

export { App };
