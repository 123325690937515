import React, { FC, useEffect } from "react";
import { Flex } from "@src/components/layout/Page";
import { LoadingMessage, StyledLabel } from "@src/components/styles";
import { PaymentOption } from "./PaymentOption";
import { OnlinePay } from "./Stripe/OnlinePay";
import { Token } from "@stripe/stripe-js";
import { WalletPay, WalletPayProps } from "./Stripe/WalletPay";
import {
  getPaymentMethods,
  getStripeTokenFeeMethod,
  PaymentMethod,
  PaymentMethodType,
} from "@src/utils/purchasedTickets";
import { useBreakPoints, useUrlParams } from "@src/customHooks";
import { useFormikContext } from "formik";
import { PaymentForm, PaymentFormKeys } from "../Review";
import { useTaxesAndFeesQuery } from "@src/types";
import { useReactiveVar } from "@apollo/client";
import { IsPayNow, SelectedPaymentType } from "@src/localVariables";
import { useFormInfo } from "@src/customHooks/useFormInfo";
import { hasSafePay } from "@src/utils/getters";
import { useTranslation } from "react-i18next";
import { useGoogleTranslate } from "@src/utils/translation";

type PaymentSectionProps = {
  passAlongFees: boolean;
  responseOrganizationId: string;
};

const PaymentSection: FC<PaymentSectionProps> = ({ passAlongFees, responseOrganizationId }) => {
  const { t } = useTranslation();
  const googleTranslate = useGoogleTranslate();
  const { countryCode } = useUrlParams();
  const { form } = useFormInfo();
  const { values, setFieldValue } = useFormikContext<PaymentForm>();
  const screenSize = useBreakPoints();
  const orgHasSafePayEnabled = hasSafePay(form);
  const title = form?.name ?? "";
  const allowOnlinePaymentsOnly = form?.allowOnlinePaymentsOnly ?? false;
  const manualPaymentText = form?.manualPaymentText
    ? googleTranslate(form.manualPaymentText)
    : t("pages.tickets.cashCheque");
  const organizationName = form?.document?.organization?.name ?? "";
  const paymentCountryCode = form?.document?.organization?.countryCode;
  const paymentCurrencyCode = form?.document?.organization?.currencyCode;
  const isPayNow = useReactiveVar(IsPayNow) || false;
  const selectedPaymentType = useReactiveVar(SelectedPaymentType);
  const paymentOptions = getPaymentMethods(manualPaymentText).filter(
    (x) =>
      //Remove offline payment options if isOnlinePaymentOnly is true or it's in PayNow mode
      ((isPayNow && x.isOnline) ||
        (!isPayNow && (!allowOnlinePaymentsOnly || (allowOnlinePaymentsOnly && x.isOnline)))) &&
      //Include safepay options if enabled
      (!x.isSafePay || (orgHasSafePayEnabled && x.isSafePay))
  );

  const selectedPaymentMethod = paymentOptions.find((pO) => pO.type === selectedPaymentType);

  const setPaymentFormValue = (fieldName: PaymentFormKeys, value: unknown) =>
    setFieldValue(fieldName, value, false);

  const setPaymentOption = (paymentOption: PaymentMethod | null) => {
    paymentOption && SelectedPaymentType(paymentOption.type);

    setPaymentFormValue("selectedPaymentMethod", paymentOption);
  };

  const taxesAndFeesQuery = useTaxesAndFeesQuery({
    variables: {
      organizationId: responseOrganizationId,
      passFeesToBuyer: passAlongFees,
      amount: values.subtotal,
      currency: paymentCurrencyCode ?? "",
    },
    fetchPolicy: "cache-first",
    skip: !orgHasSafePayEnabled || !passAlongFees,
  });

  const assignFees = (token: Token | null | undefined) => {
    if (!(orgHasSafePayEnabled && passAlongFees)) return;

    if (token) {
      // Get fees based on the payment method
      const tokenFeeMethodType = getStripeTokenFeeMethod(token, countryCode);
      const fees = taxesAndFeesQuery?.data?.TaxesAndFees?.estimates?.find(
        (estimate) => estimate.method === tokenFeeMethodType
      );

      if (fees != null) {
        setPaymentFormValue("total", fees.amountToCharge);
        setPaymentFormValue("serviceFee", fees.providerFee + fees.applicationFeeNet);
        setPaymentFormValue("tax", fees.applicationFeeTax);
        setPaymentFormValue("isServiceFeeAssigned", true);
      }
    } else {
      // Unassign fees
      setPaymentFormValue("total", values.subtotal);
      setPaymentFormValue("serviceFee", 0);
      setPaymentFormValue("tax", { gstLabel: "0.00", pstLabel: "0.00", gst: 0, pst: 0, total: 0 });
      setPaymentFormValue("isServiceFeeAssigned", false);
    }
  };

  const onTokenReceived = (token: Token | null) => {
    // double = here checks for undefined as well
    if (token == null || !token.id) {
      token = null;
    }
    setPaymentFormValue("stripeToken", token);
  };

  useEffect(() => {
    if (values.subtotal > 0) {
      if (orgHasSafePayEnabled && passAlongFees) {
        taxesAndFeesQuery.refetch({ amount: values.subtotal });
        assignFees(values.stripeToken);
      }
    } else {
      // No payment required, set payment option to null
      if (values.selectedPaymentMethod) setPaymentOption(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.subtotal]);

  useEffect(() => {
    assignFees(values.stripeToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.stripeToken]);

  useEffect(() => {
    onTokenReceived(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.selectedPaymentMethod]);

  useDefaultPaymentMethod(
    values.subtotal,
    values.selectedPaymentMethod,
    paymentOptions,
    setPaymentOption
  );

  return (
    <Flex flexDirection="column" style={{ marginTop: "15px" }}>
      {taxesAndFeesQuery.loading && <LoadingMessage />}
      <StyledLabel style={{ fontSize: "18px" }}>{t("pages.tickets.paymentMethods")}</StyledLabel>
      <StyledLabel>{t("pages.tickets.chooseYourPaymentMethod")}</StyledLabel>
      <Flex>
        <Flex
          flexDirection={screenSize === "desktop" ? "row" : "column"}
          justifyContent="space-evenly"
          style={{ width: "100%" }}
        >
          {paymentOptions.map((paymentOption, index) => {
            return (
              <PaymentOption
                key={`option-${paymentOption.label}`}
                {...paymentOption}
                screenSize={screenSize}
                isSelected={values.selectedPaymentMethod?.type === paymentOption.type}
                tabIndex={index}
                onClick={() => {
                  if (values.selectedPaymentMethod?.type !== paymentOption.type) {
                    setPaymentOption(paymentOption);
                  }
                }}
              />
            );
          })}
        </Flex>
      </Flex>
      <SelectedPaymentOption
        option={selectedPaymentMethod || null}
        organizationName={organizationName}
        walletPayProps={{
          title,
          total: values.total ?? 0,
          currency: paymentCurrencyCode,
          country: paymentCountryCode,
        }}
        onTokenReceived={onTokenReceived}
      />
    </Flex>
  );
};

type SelectedPaymentOptionProps = {
  option: PaymentMethod | null;
  organizationName: string;
  walletPayProps?: WalletPayProps;
  onTokenReceived: (token: Token | null) => void;
};

const SelectedPaymentOption: FC<SelectedPaymentOptionProps> = ({
  option,
  organizationName,
  walletPayProps,
  onTokenReceived,
}) => {
  const { t } = useTranslation();

  return (
    option && (
      <Flex justifyContent="center">
        {option.type === PaymentMethodType.CREDITCARD && (
          <OnlinePay onTokenReceived={onTokenReceived} />
        )}
        {option.type === PaymentMethodType.WALLET && walletPayProps && (
          <WalletPay onTokenReceived={onTokenReceived} {...walletPayProps} />
        )}
        {option.type === PaymentMethodType.MANUAL && (
          <div className={"payment-option cash selected"} aria-live="polite">
            <p style={{ margin: 0, marginTop: "25px", fontWeight: "500" }}>
              {t("pages.tickets.yourOrderWillNotBeComplete", {
                method: option.label,
                organizationName,
              })}
            </p>
          </div>
        )}
      </Flex>
    )
  );
};

const useDefaultPaymentMethod = (
  subtotal: number,
  selectedPaymentMethod: PaymentMethod | null,
  paymentOptions: PaymentMethod[],
  setPaymentOption: (PaymentMethod: PaymentMethod) => void
) => {
  useEffect(() => {
    if (subtotal > 0 && !selectedPaymentMethod) {
      const creditCardPaymentOption = paymentOptions.find(
        (option) => option.type === PaymentMethodType.CREDITCARD
      );
      // If creditcard is an option, we'll default to that
      if (creditCardPaymentOption) setPaymentOption(creditCardPaymentOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export { PaymentSection };
