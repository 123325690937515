import gql from "graphql-tag";

import { ResponseVersionFragment } from "./fragments";

const RESPONSE_VERSIONS_QUERY = gql`
  query ResponseVersions($countryCode: String!, $documentId: ID!, $email: String!) {
    ResponseVersions(countryCode: $countryCode, documentId: $documentId, email: $email) {
      ...ResponseVersionFragment
    }
  }
  ${ResponseVersionFragment}
`;

export { RESPONSE_VERSIONS_QUERY };
