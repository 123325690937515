import styled from "styled-components";

const AnswerBackground = styled.div`
  background-color: #e1ecf5;
  padding: 15px;
  border-radius: 5px;
  margin: 10px 0;
`;

const ButtonRow = styled.div`
  display: flex;
  padding: 5px;
  justify-content: center;
  margin-top: 15px;

  > button {

    margin: 0 10px;
  }
`;

export { AnswerBackground, ButtonRow };
