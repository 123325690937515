import React, { FC } from "react";
import { SpinnerBlock, SpinnerContainer } from "./style";

const Spinner: FC = () => {
  return (
    <SpinnerContainer className="container">
      <SpinnerBlock></SpinnerBlock>
      <SpinnerBlock></SpinnerBlock>
      <SpinnerBlock></SpinnerBlock>
      <SpinnerBlock></SpinnerBlock>
      <SpinnerBlock></SpinnerBlock>
      <SpinnerBlock></SpinnerBlock>
      <SpinnerBlock></SpinnerBlock>
      <SpinnerBlock></SpinnerBlock>
      <SpinnerBlock></SpinnerBlock>
      <SpinnerBlock></SpinnerBlock>
      <SpinnerBlock></SpinnerBlock>
      <SpinnerBlock></SpinnerBlock>
      <SpinnerBlock></SpinnerBlock>
      <SpinnerBlock></SpinnerBlock>
      <SpinnerBlock></SpinnerBlock>
      <SpinnerBlock></SpinnerBlock>
    </SpinnerContainer>
  );
};

export { Spinner };
