import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Attendee, PurchasedTicket, Ticket } from "@src/types";
import { Flex } from "@src/components/layout/Page";
import {
  CancelTicketArgs,
  currencyFormatter,
  getPurchasedTicketKey,
} from "@src/utils/purchasedTickets";
import { PurchasedTicketPreview } from "./PurchasedTicketPreview";
import { TicketHeader, TicketMode } from "../TicketSection/TicketSection";
import { PreviewProps } from "@src/Routes";
import { StyledQuantity, SubtotalLabel } from "./styles";
import { TrashButton } from "../TicketSection/TrashButton";
import { TicketTypeCode } from "../TicketSection/TicketPrice";
import { Icon } from "@src/components/atoms/Icon";
import { useBreakPoints } from "@src/customHooks";
import { useFormInfo } from "@src/customHooks/useFormInfo";

type TicketPreviewProps = PreviewProps & {
  ticket: Ticket;
  purchasedTickets: PurchasedTicket[];
  cart: Attendee[];
  attendee: Attendee;
  attendeeIndex: number;
  mode: TicketMode;
  countryCode: string;
  documentId: string;
  responseVersionId: string;
  onCancelTicket?: (args: CancelTicketArgs) => void;
};

const TicketPreview: FC<TicketPreviewProps> = ({
  ticket,
  purchasedTickets,
  cart,
  attendee,
  attendeeIndex,
  mode,
  countryCode,
  documentId,
  responseVersionId,
  onCancelTicket,
  isPreview,
}) => {
  const { t, i18n } = useTranslation();
  const screenSize = useBreakPoints();
  const { form } = useFormInfo();
  const [showDetails, setShowDetails] = useState<boolean>(false);

  const subTotal =
    purchasedTickets?.reduce((a, b) => {
      return a + b.quantity * b.price ?? 0;
    }, 0) ?? 0;

  const hasOptions = (ticket.formQuestions?.length ?? 0) > 0;

  const totalQuantity =
    purchasedTickets?.reduce((a, b) => {
      return a + b.quantity;
    }, 0) ?? 0;

  const purchasedTicketIds =
    !hasOptions && purchasedTickets && purchasedTickets.length > 0
      ? purchasedTickets.flatMap((x) => x.purchasedTicketIds)
      : [];

  const currencyCode = form?.document?.organization?.currencyCode;

  return (
    <Flex style={{ marginBottom: "15px" }} data-testid={ticket.id + "-OVERVIEW"}>
      <Flex style={{ width: "100%" }} flexDirection="column">
        <TicketHeader
          ticket={ticket}
          attendee={attendee}
          attendeeIndex={attendeeIndex}
          purchasedTickets={purchasedTickets}
          mode={mode}
          isPreview={isPreview}
          onCancelTicket={onCancelTicket}
          cart={cart}
        >
          <>
            <Flex alignItems="center" gap={10}>
              <StyledQuantity style={{ margin: "0" }} data-testid={ticket.id + "-TOTAL-QUANTITY"}>
                {t("pages.tickets.quantity")}: {totalQuantity}
              </StyledQuantity>
              <SubtotalLabel
                style={{
                  margin: "0",
                  fontSize:
                    mode === TicketMode.CART_PREVIEW || screenSize === "smallScreen"
                      ? "14px"
                      : "20px",
                }}
              >
                {t("totals.subtotal")}:{" "}
                {currencyFormatter(i18n.resolvedLanguage, currencyCode).format(subTotal)}
              </SubtotalLabel>
              {hasOptions ? (
                <div
                  style={{ cursor: "pointer", padding: "15px", marginLeft: "-10px" }}
                  onClick={() => {
                    setShowDetails(!showDetails);
                  }}
                >
                  <Icon
                    onClick={() => {
                      setShowDetails(!showDetails);
                    }}
                    style="solid"
                    icon={showDetails ? "angle-down" : "angle-right"}
                    color="#0283ff"
                    testId={ticket.id + "-EXPANDER"}
                  />
                </div>
              ) : null}
              {!hasOptions && mode === TicketMode.CART_PREVIEW ? (
                <Flex alignItems="center">
                  {onCancelTicket ? (
                    <TrashButton
                      style={{ marginLeft: "0" }}
                      onClick={() =>
                        onCancelTicket({
                          ticketVersionId: ticket.id,
                          responseAttendeeId: attendee.responseAttendeeId,
                          purchasedTicketIds: purchasedTicketIds,
                        })
                      }
                    />
                  ) : null}
                </Flex>
              ) : null}
            </Flex>
          </>
        </TicketHeader>
        {(hasOptions || Number(ticket.typeCode) === TicketTypeCode.Donation) && showDetails ? (
          <div style={{ marginLeft: "15px" }}>
            {purchasedTickets.map((purchasedTicket) => (
              <PurchasedTicketPreview
                key={getPurchasedTicketKey(purchasedTicket)}
                ticket={ticket}
                purchasedTicket={purchasedTicket}
                responseAttendeeId={attendee.responseAttendeeId}
                onCancelTicket={onCancelTicket}
                mode={mode}
                countryCode={countryCode}
                documentId={documentId}
                responseVersionId={responseVersionId}
              />
            ))}
          </div>
        ) : null}
      </Flex>
    </Flex>
  );
};

export { TicketPreview };
