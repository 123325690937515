import React, { FC } from "react";
import { useField, FieldValidator } from "formik";

import { AnswerInput, Question } from "@src/types";
import { getEquationSectionId } from "@src/utils/getters";
import { NumericInput } from "@src/components/atoms/NumericInput";
import { StyledTableRowLabel, StyledTableRowTextInput } from "@src/components/atoms/Table";
import { currencyFormatter } from "@src/utils/purchasedTickets";
import { ControlType, NumericType } from "@src/components/atoms/NumericInput/types";
import { useGoogleTranslate } from "@src/utils/translation";
import { useTranslation } from "react-i18next";
import { useFormInfo } from "@src/customHooks/useFormInfo";
import { EquationRow } from "@src/utils/formValuesTypes";

type EquationSectionProps = {
  sectionId?: string | null;
  question: Question;
  row?: EquationRow;
  editRowValue?: (newAnswer: AnswerInput) => Promise<void>;
  validate: FieldValidator;
};

const EquationSection: FC<EquationSectionProps> = ({
  sectionId,
  question,
  row,
  editRowValue,
  validate,
}) => {
  const googleTranslate = useGoogleTranslate();
  const { t, i18n } = useTranslation();
  const equationSectionId = sectionId || getEquationSectionId(question);
  const [{ value: answer }, meta, helpers] = useField<AnswerInput | undefined>({
    name: equationSectionId,
    validate,
  });
  const { form } = useFormInfo();

  const currencyCode = form?.document?.organization?.currencyCode;
  const min = question.minEntries ? question.minEntries : 0;
  const max = question.maxEntries ? question.maxEntries : -1;

  const handleChange = (value?: string | null) => {
    const existingAnswer = answer || {
      fieldId: row?.answerId || "",
      fileType: null,
      order: null,
      questionId: sectionId || question.name || "",
      value,
    };

    const newAnswer = {
      ...existingAnswer,
      value,
    };

    helpers.setValue(newAnswer);

    editRowValue && editRowValue(newAnswer);
  };

  const minValue =
    min > 0
      ? question.valueType === "currency"
        ? currencyFormatter(i18n.resolvedLanguage, currencyCode).format(min)
        : question.valueType === "decimal"
        ? min.toFixed(2)
        : min.toString()
      : "";

  const maxValue =
    max > -1
      ? question.valueType === "currency"
        ? currencyFormatter(i18n.resolvedLanguage, currencyCode).format(max)
        : question.valueType === "decimal"
        ? max.toFixed(2)
        : max.toString()
      : "";

  return (
    <>
      {question.valueType === "text" ? (
        <StyledTableRowTextInput
          name={equationSectionId}
          value={answer?.value || ""}
          placeholder={googleTranslate(
            `Enter ${question.name ?? question.placeHolderText ?? "value"}...`
          )}
          onChange={(event) => handleChange(event.target.value)}
          error={!!meta.error}
        />
      ) : (
        <>
          <NumericInput
            value={answer?.value}
            min={min}
            max={max}
            type={question.valueType as NumericType}
            fixedDecimalPlaces={question.valueType === "decimal" ? 2 : undefined}
            step={1}
            disabled={false}
            controlType={ControlType.Horizontal}
            width="100%"
            minWidth="135px"
            onChange={handleChange}
            error={meta.error}
          />

          {min > 0 ? (
            <StyledTableRowLabel style={{ float: "left" }} title={minValue}>
              {t("labels.min")}: {minValue}
            </StyledTableRowLabel>
          ) : null}

          {max > -1 ? (
            <StyledTableRowLabel style={{ float: "right" }} title={maxValue}>
              {t("labels.max")}: {maxValue}
            </StyledTableRowLabel>
          ) : null}
        </>
      )}
    </>
  );
};

export { EquationSection };
