import { Logo } from "@src/components/atoms/Logo";
import {
  StyledCopyright,
  StyledFooterBar,
  StyledLink,
  StyledLinkContainer,
  StyledLogoContainer,
} from "@src/components/layout/Footer";
import { Flex } from "@src/components/layout/Page";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import PCIIcon from "@src/assets/pci-icon.png";
import { Icon } from "@src/components/atoms/Icon";
import ReactTooltip from "react-tooltip";
import { useBreakPoints } from "@src/customHooks";

const PageFooter: FunctionComponent = ({}) => {
  const { t } = useTranslation();
  const screenSize = useBreakPoints();

  const links = (
    <StyledLinkContainer screenSize={screenSize} key="links">
      <StyledLink href="https://permissionclick.com/privacy-policy/" target="_blank">
        {t("links.legal")}
      </StyledLink>
      <StyledLink href="https://ps.powerschool-docs.com/permissionclick/latest/parent-user-guides" target="_blank">
        {t("links.tipsAndFaq")}
      </StyledLink>

      {screenSize !== "smallScreen" ? (
        <Flex
          alignItems="center"
          justifyContent="space-around"
          style={{ width: "80px" }}
          data-tip
          data-for="security"
        >
          <img style={{ height: "30px" }} src={PCIIcon} />
          <Icon style="solid" icon="shield-check" color="#ffffff" size="20px"></Icon>
        </Flex>
      ) : null}

      <ReactTooltip id="security" effect="solid">
        {t("messages.securityInformation")}
      </ReactTooltip>
    </StyledLinkContainer>
  );

  const copyright = (
    <Flex alignItems="center" key="copyright">
      <StyledCopyright screenSize={screenSize}>
        {t("messages.copyright", { year: new Date().getFullYear() })}
      </StyledCopyright>
    </Flex>
  );

  return (
    <StyledFooterBar screenSize={screenSize}>
      <StyledLogoContainer screenSize={screenSize}>
        {screenSize === "smallScreen" ? (
          <Logo style="circle" width="50px" height="50px" />
        ) : (
          <Logo style="normal" width="150px" height="30px" />
        )}
      </StyledLogoContainer>
      {screenSize === "smallScreen" ? (
        <Flex flexDirection="column">{[links, copyright]}</Flex>
      ) : (
        [copyright, links]
      )}
    </StyledFooterBar>
  );
};

export { PageFooter };
