import { cloneDeep } from "@apollo/client/utilities";

import { AnswerInput, Field, FieldType } from "@src/types";
import { getValueOptions } from "./getters";

const isCollapsed = (field: Field, fields: Field[], collapsedHeaders: string[]): boolean => {

  const fieldsThatDontCollapse = [FieldType.Header, FieldType.Divider, FieldType.Section];

  if (field && field.type && !fieldsThatDontCollapse.includes(field.type)) {

    let relatedFields = cloneDeep(fields);

    relatedFields = relatedFields.slice(
      0,
      relatedFields.findIndex((x) => x.id === field.id)
    );

    const relatedHeaderIndex = relatedFields
      .reverse()
      .findIndex((x) => x.type && fieldsThatDontCollapse.includes(x.type));

    if (relatedHeaderIndex < 0) {
      return false;
    }

    const relatedHeaderField = relatedFields[relatedHeaderIndex];

    if (relatedHeaderField.type !== FieldType.Header && relatedHeaderField.type !== FieldType.Section) {
      return false;
    }

    const headerFieldIndex = fields.findIndex((x) => x.id === relatedHeaderField.id);

    return isCollapsed(fields[headerFieldIndex], fields, collapsedHeaders);
  } else if (field?.id) {
    return collapsedHeaders.indexOf(field.id) >= 0;
  }

  return false;
};

const isComplexField = (field: Field): boolean => {
  return (
    field &&
    (field.type === FieldType.Equations ||
      field.type === FieldType.Conditional ||
      field.type === FieldType.SelectConditional ||
      field.type === FieldType.MultiFieldConditional ||
      field.type === FieldType.RepeatableQuestion ||
      field.type === FieldType.Address ||
      field.type === FieldType.IntegratedData ||
      field.type === FieldType.Upload)
  );
};

const isNoInputField = (field: Field): boolean => {
  return (
    field &&
    (field.type === FieldType.Header ||
      field.type === FieldType.Section ||
      field.type === FieldType.Divider ||
      field.type === FieldType.Descriptivetext)
  );
};

const isSimpleField = (field: Field): boolean => {
  return field && !isComplexField(field) && !isNoInputField(field);
};

const collectFieldAnswers = (field: Field, allAnswers: AnswerInput[]): AnswerInput[] => {
  let answers = allAnswers ? allAnswers.filter((x) => x !== null && x.fieldId === field.id) : [];

  if (field.type === FieldType.Equations) {
    if (allAnswers) {
      // row answers reference the row id not the field id
      const rowIds = answers.filter((x) => x.questionId === "row").map((y) => y.answerId);

      answers = answers.concat(allAnswers.filter((x) => rowIds.includes(x.fieldId)));
    }
  }

  if (field.fields && field.fields.length > 0) {
    // if the field contains fields we need those answers as well
    field.fields.map(
      (subField) => (answers = answers.concat(collectFieldAnswers(subField, allAnswers)))
    );
  }

  return answers;
};

/**
 * Get the approriate nested fields of a conditonal. For example,
 * if we have a YesNo condtional with required fields in both yes/no sections,
 * we can't add them all to be checked because the user can't answer them all.
 */
const getConditionalNestedFields = (field: Field, answers: AnswerInput[]): Field[] => {
  let fieldsToCheck: Field[] = [];

  if ((field.type === FieldType.MultiFieldConditional || field.type === FieldType.Conditional) && field.fields?.length === 2) {
    const answer = answers.find((x) => x.fieldId === field.id && x.questionId === field.question?.id);
    if (answer) {
      fieldsToCheck = fieldsToCheck.concat(answer.value === "true" ? field.fields[0].fields ?? []: field.fields[1].fields ?? [])
    }
  }

  if (field.type === FieldType.SelectConditional) {
    const answer = answers.find((x) => x.fieldId === field.id && x.questionId === field.question?.id);
    if (answer) {
      const selectedIndex = getValueOptions(field).findIndex((option) => option === answer.value);
      fieldsToCheck = fieldsToCheck.concat(selectedIndex >= 0 && field.fields && field.fields[selectedIndex] ? field.fields[selectedIndex].fields || [] : []);
    }
  }

  return fieldsToCheck;
}

const isConditional = (field: Field): boolean => {
  return (
    field &&
    (field.type === FieldType.Conditional ||
      field.type === FieldType.SelectConditional ||
      field.type === FieldType.MultiFieldConditional)
  );
}

const canHaveChildFields = (field: Field): boolean => {
  return (
    field &&
    (field.type === FieldType.Equations ||
      field.type === FieldType.Conditional ||
      field.type === FieldType.SelectConditional ||
      field.type === FieldType.MultiFieldConditional ||
      field.type === FieldType.RepeatableQuestion ||
      field.type === FieldType.Address ||
      field.type === FieldType.IntegratedData ||
      field.type === FieldType.FieldContainer)
  );
}


export { isCollapsed, isComplexField, isNoInputField, isSimpleField, isConditional, collectFieldAnswers, canHaveChildFields, getConditionalNestedFields };
