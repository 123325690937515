import { StyledIcon } from "@src/components/atoms/Icon";
import { DefaultTheme } from "@src/themes/default";
import React, { FC } from "react";
import styled, { useTheme } from "styled-components";

const TrashIcon = styled(StyledIcon)`
  padding: 15px;
`;

export type TrashButtonProps = {
  title: string;
  testId?: string;
  style?: React.CSSProperties;
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

const TrashButton: FC<TrashButtonProps> = ({ title, testId, style, onClick }) => {
  const theme = useTheme() as DefaultTheme;
  return (
    <TrashIcon
      size={"18px"}
      hoverEnabled={true}
      hoverColor={theme.colors.values.red}
      clickable
      className={`fa-light fa-trash-can-xmark`}
      onClick={(e) => onClick(e)}
      title={title}
      data-testid={testId}
      style={style}
    />
  );
};

export { TrashButton };
