import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ValidationError } from "yup";

import { Row, Cell, InvisibleCell } from "@src/components/atoms/Table";
import { Question } from "@src/types";
import { EquationSection } from "./EquationSection";
import { AddRowButton, ClearRowButton } from "../styles";
import { getSchemaForEquationQuestion } from "@src/utils/validation";
import { InnerFields } from "@src/utils/formValuesTypes";
import { useFormikContext } from "formik";

type EquationsFormProps = {
  inputQuestions: Question[];
  calculatedQuestions: Question[];
  isEditing: boolean;
  loading: boolean;
  editRow?: (innerFields: InnerFields) => Promise<void>;
  addRow?: (innerFields: InnerFields) => Promise<void>;
  setIsDirty: (isDirty: boolean) => void;
};

const EquationsForm: FC<EquationsFormProps> = ({
  inputQuestions,
  calculatedQuestions,
  isEditing,
  loading,
  editRow,
  addRow,
  setIsDirty,
}) => {
  const { t } = useTranslation();
  const { isValid, values, dirty, validateForm, resetForm } = useFormikContext<InnerFields>();

  useEffect(() => {
    setIsDirty(dirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirty]);

  const validate = (question: Question) => (value: string) => {
    try {
      const schema = getSchemaForEquationQuestion({ question, t });
      schema.validateSync(value);
    } catch (err) {
      const error = err as ValidationError;
      return error.message;
    }
  };

  const handleClick = async (values: InnerFields) => {
    if (isValid) {
      const errors = await validateForm();
      const errorKeys = Object.keys(errors);

      if (!(errorKeys && errorKeys.length)) {
        isEditing && editRow ? editRow(values) : addRow ? addRow(values) : console.log("failure");
        resetForm();
      }
    }
  };

  const clearForm = () => resetForm();

  return (
    <Row style={{ paddingTop: "15px" }}>
      {inputQuestions.map((question, index) => (
        <Cell
          key={question.id || index}
          alignLeft={true}
          style={{ background: "#ececec", padding: "10px 5px" }}
        >
          <EquationSection
            key={question.id || ""}
            question={question}
            validate={validate(question)}
          />
        </Cell>
      ))}
      {calculatedQuestions
        ? calculatedQuestions.map((question, index) => (
            <Cell
              key={question.id || index}
              style={{ background: "#ececec", padding: "10px 5px" }}
            ></Cell>
          ))
        : null}
      {
        <InvisibleCell key="actions">
          <AddRowButton disabled={!isValid || loading} onClick={() => handleClick(values)}>
            {isEditing ? "Edit Row" : t("labels.addRow")}
          </AddRowButton>
          <ClearRowButton disabled={!dirty || loading} onClick={clearForm}>
            {t("labels.clear")}
          </ClearRowButton>
        </InvisibleCell>
      }
    </Row>
  );
};

export { EquationsForm };
