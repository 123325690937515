import React, { FC } from "react";
import styled from "styled-components";

import { StyledOption } from "@src/components/styles";
import { defaultTheme } from "@src/themes";
import { DefaultLanguage, Language, Languages } from "@src/utils/translation";
import { useTranslation } from "react-i18next";
import { useReactiveVar } from "@apollo/client";
import { IsLoadingTranslation } from "@src/localVariables";
import { StyledIcon } from "@src/components/atoms/Icon";

const Wrapper = styled.div`
  &:focus {
    border: 2px solid ${defaultTheme.colors.fields.selected};
  }

  box-sizing: border-box;
  font-size: 12px;
  border: 1px solid ${(props) => props.theme.colors.fields.bordercolor};
  border-radius: 0;
  width: 170px;
  padding: 0 10px;
  margin: 10px 20px 10px 10px;
  height: fit-content;
  position: relative;
`;

const LanguageStyledSelect = styled.select`
  &:focus {
    outline: none !important;
  }

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 12px;
  border: none;
  border-radius: 0;
  width: 150px;
  cursor: pointer;
  height: 37px;
`;

const LoadingIcon = styled(StyledIcon)`
  position: absolute;
  right: 25px;
  top: 10px;
  color: ${(props) => props.theme.colors.fields.text};
`;

type SelectLanguageProps = {
  onLanguageChanged(language: Language): void;
};

const SelectLanguage: FC<SelectLanguageProps> = ({ onLanguageChanged }) => {
  const { i18n } = useTranslation();
  const isLoadingTranslations = useReactiveVar(IsLoadingTranslation);

  return (
    <Wrapper>
      <LanguageStyledSelect
        onChange={(event) =>
          onLanguageChanged(
            Languages.find((x) => x.code === event.currentTarget.value) ?? DefaultLanguage
          )
        }
        value={i18n.resolvedLanguage}
      >
        {Languages.sort((a, b) => (a.display || "").localeCompare(b.display || "")).map(
          (option) => (
            <StyledOption key={option.code} value={option.code}>
              {option.display}
            </StyledOption>
          )
        )}
      </LanguageStyledSelect>
      {isLoadingTranslations ? <LoadingIcon className="fas fa-sync fa-spin" /> : null}
    </Wrapper>
  );
};

export { SelectLanguage };
