import { ApolloCache, DefaultContext, MutationUpdaterFunction } from "@apollo/client";
import cloneDeep from "lodash/cloneDeep";

import {
  Answer,
  DeleteAttendeeAnswerMutation,
  DeleteAttendeeAnswerMutationVariables,
  ResponseQuery,
  SaveAttendeeAnswerMutation,
  SaveAttendeeAnswerMutationVariables,
} from "@src/types";
import { RESPONSE_VERSION_QUERY } from "@src/queries/responseVersion";

type saveAttendeeAnswerForResponseArgs = {
  cache: ApolloCache<unknown>;
  answer?: Answer;
  responseHashedId?: string;
  attendeeId: string;
};

const saveAttendeeAnswerForResponse = ({
  cache,
  responseHashedId,
  answer,
  attendeeId,
}: saveAttendeeAnswerForResponseArgs) => {
  cache.updateQuery<ResponseQuery>(
    {
      query: RESPONSE_VERSION_QUERY,
      variables: {
        hash: responseHashedId,
      },
      overwrite: true,
    },
    (data: ResponseQuery | null) => {
      if (data) {
        const responseVersion = data.Response;

        if (responseVersion) {
          const attendees = cloneDeep(responseVersion.attendees);
          const attendee = attendees.find((x) => x.attendeeId === attendeeId);
          const newAttedeeAnswers = attendee?.attendeeAnswers?.filter(
            (x) => x.answerId !== answer?.answerId
          );

          if (answer && attendee) {
            newAttedeeAnswers?.push(answer);
            attendee.attendeeAnswers = newAttedeeAnswers || [];
          }

          return {
            ...data,
            Response: {
              ...responseVersion,
              attendees,
            },
          };
        }
      }
    }
  );
};

type DeleteAnswerForResponseArgs = {
  cache: ApolloCache<unknown>;
  responseHashedId: string;
  variables?: DeleteAttendeeAnswerMutationVariables;
  attendeeId: string;
};

const deleteAnswerForResponse = ({
  cache,
  responseHashedId,
  attendeeId,
  variables
}: DeleteAnswerForResponseArgs) => {

  cache.updateQuery<ResponseQuery>(
    {
      query: RESPONSE_VERSION_QUERY,
      variables: {
        hash: responseHashedId,
      },
      overwrite: true,
    },
    (data: ResponseQuery | null) => {
      if (data) {
        const responseVersion = data.Response;

        if (responseVersion) {
          const attendees = cloneDeep(responseVersion.attendees);
          const attendee = attendees.find((x) => x.attendeeId === attendeeId);

          if (variables) {
            const newAttendeeAnswers = attendee?.attendeeAnswers?.filter((x) => {
              const answerOrder = x.order || null;
              const variableOrder = variables.answerInput.order || null;

              return !(
                x.fieldId === variables.answerInput.fieldId &&
                x.questionId === variables.answerInput.questionId &&
                answerOrder === variableOrder
              );
            });

            if (attendee)
              attendee.attendeeAnswers = newAttendeeAnswers || [];
          }

          return {
            ...data,
            Response: {
              ...responseVersion,
              attendees,
            },
          };
        }
      }
    }
  );
};

type SaveAttendeeAnswerMutationUpdaterArgs = {
  attendeeId: string;
  responseHashedId: string;
};

type SaveAttendeeAnswerMutationUpdater = (
  args: SaveAttendeeAnswerMutationUpdaterArgs
) => MutationUpdaterFunction<
  SaveAttendeeAnswerMutation,
  SaveAttendeeAnswerMutationVariables,
  DefaultContext,
  ApolloCache<unknown>
>;

// Saves answer to cache, used exclusively for preview
const saveAttendeeAnswerMutationUpdater: SaveAttendeeAnswerMutationUpdater =
  ({ attendeeId, responseHashedId }) =>
  (cache, { data }) => {
    const answer = data?.SaveAttendeeAnswer;
    saveAttendeeAnswerForResponse({
      cache,
      answer,
      responseHashedId,
      attendeeId,
    });
  };

type DeleteAttendeeAnswerMutationUpdaterArgs = {
  attendeeId: string;
  responseHashedId: string;
};

type DeleteAttendeeAnswerMutationUpdater = (
  args: DeleteAttendeeAnswerMutationUpdaterArgs
) => MutationUpdaterFunction<
  DeleteAttendeeAnswerMutation,
  DeleteAttendeeAnswerMutationVariables,
  DefaultContext,
  ApolloCache<unknown>
>;

// Removes answer to cache, used exclusively for preview
const deleteAttendeeAnswerMutationUpdater: DeleteAttendeeAnswerMutationUpdater =
  ({ attendeeId, responseHashedId }) =>
  (cache, { }, { variables }) => {
    deleteAnswerForResponse({
      cache,
      responseHashedId,
      variables,
      attendeeId,
    });
  };

export {
  saveAttendeeAnswerMutationUpdater,
  deleteAttendeeAnswerMutationUpdater
};
