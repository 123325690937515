import { NumericInput } from "@src/components/atoms/NumericInput";
import { ControlType } from "@src/components/atoms/NumericInput/types";
import { Flex } from "@src/components/layout/Page";
import { StyledLabel } from "@src/components/styles";
import { Attendee, Ticket, TicketInventory } from "@src/types";
import { getQuantityInCart } from "@src/utils/purchasedTickets";
import { useField } from "formik";
import inRange from "lodash/inRange";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

export type QuantitySectionProps = {
  name: string;
  ticket: Ticket;
  inventory: TicketInventory;
  attendee: Attendee;
  canBuyTicket: boolean;
  cart?: Attendee[];
};

// &&'s help override any conflicting CSS of the parent Styled Component. Explained more below:
// https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
const SoldOutLabel = styled(StyledLabel)`
  ${({ theme }) => `
  && {
    color: ${theme.colors.values.red};
  }
  `}
`;

const QuantitySection: FC<QuantitySectionProps> = ({
  name,
  ticket,
  inventory,
  attendee,
  canBuyTicket,
  cart,
}) => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [input, meta, helpers] = useField(name);

  const quantityInCart = getQuantityInCart({
    cart,
    attendeeId: null,
    ticketVersionId: ticket.id,
  });

  const quantityInCartForThisAttendee = getQuantityInCart({
    cart,
    attendeeId: attendee.attendeeId,
    ticketVersionId: ticket.id,
  });

  const remaining = inventory.isLimited
    ? (inventory.remaining || 0) - quantityInCart ?? 0
    : Infinity;

  const max = ticket.maximumQuantity ?? 500;

  const maximumQuantity = inventory.isLimited
    ? Math.min(max - quantityInCartForThisAttendee, remaining)
    : max - quantityInCartForThisAttendee;

  const minQuantity = ticket.minimumQuantity || 0;
  const minQuantityForThisAttendee = Math.max(0, minQuantity - quantityInCartForThisAttendee);

  const setQuantity = (value: string | null | undefined) => {
    if (value && maximumQuantity !== 0) {
      const quantity = Math.min(Number(value), remaining);
      if (
        quantity !== 0 &&
        quantity < minQuantityForThisAttendee &&
        minQuantityForThisAttendee <= remaining
      )
        helpers.setValue(minQuantityForThisAttendee);
      else helpers.setValue(quantity);
    }
  };

  const isSoldOut = inventory.isLimited && remaining <= 0;

  return (
    <Flex flexDirection="column">
      {!isSoldOut ? (
        <>
          <NumericInput
            ticketId={ticket.id}
            value={input.value ?? 0}
            min={minQuantityForThisAttendee}
            max={maximumQuantity}
            step={1}
            onChange={(value) => setQuantity(value)}
            disabled={!canBuyTicket || maximumQuantity === 0}
            controlType={ControlType.Horizontal}
            type="integer"
          />
          {minQuantity > 0 ? (
            <StyledLabel style={{ fontSize: "11px", color: "#868686" }}>
              {t("pages.tickets.minQuantityRequired", { minQuantity })}
            </StyledLabel>
          ) : null}
          {maximumQuantity != 0 && inRange(ticket.maximumQuantity ?? 0, 100) ? (
            <StyledLabel style={{ fontSize: "11px", color: "#868686" }}>
              {t("pages.tickets.maxQuantityAllowed", { maximumQuantity })}
            </StyledLabel>
          ) : null}
          {inventory.isLimited && remaining ? (
            <StyledLabel style={{ fontSize: "11px", color: "#868686" }}>
              {t("pages.tickets.quantityRemaining", { remaining })}
            </StyledLabel>
          ) : null}
          {maximumQuantity === 0 ? (
            <StyledLabel style={{ fontSize: "11px", color: "#868686" }}>
              {t("pages.tickets.maximumQuantityInCart")}
            </StyledLabel>
          ) : null}
        </>
      ) : (
        <SoldOutLabel data-testid={ticket.id + "-SOLD-OUT"}>
          {t("pages.tickets.soldOut")}
        </SoldOutLabel>
      )}
    </Flex>
  );
};

export { QuantitySection };
