import React, { useEffect, useRef } from "react";
import { useField } from "formik";
import { useDebounce } from "use-debounce";

import { AnswerInput, Field } from "@src/types";
import { getFieldKey, getLabel } from "@src/utils/getters";
import { useFieldValidation } from "@src/utils/validation";
import { TextField } from "../TextField";
import { useGoogleTranslate } from "@src/utils/translation";
import { useTranslation } from "react-i18next";

type AddressInnerFieldProps = {
  field: Field;
  parentFieldId: string;
  saveAnswer?: (answer: AnswerInput) => Promise<string>;
};

const AddressInnerField = React.forwardRef<HTMLInputElement, AddressInnerFieldProps>(
  ({ field, parentFieldId, saveAnswer }, ref) => {
    const { t } = useTranslation();
    const googleTranslate = useGoogleTranslate();

    const fieldKey = getFieldKey(field, parentFieldId);
    const label = googleTranslate(getLabel(field));
    const isRequired = field.question?.isRequired ?? false;

    const placeHolder = field.question?.placeHolderText
      ? googleTranslate(field.question?.placeHolderText)
      : ref
      ? t("placeholders.enterALocation")
      : "";

    const validate = useFieldValidation(field);

    const [input, meta, helpers] = useField<AnswerInput>({ name: fieldKey, validate });

    const inputValueRef = useRef(input.value);
    inputValueRef.current = input.value;
    
    const [debouncedValue, debounceControls] = useDebounce(input.value, 2000, {
      leading: false,
      trailing: true,
    });

    // Saves the answer whenever the debounced value changes.
    // The value of the field can change due to the user typing on it or selecting an address.
    // This centralizes the saving of the answer in a single place no matter how it changed.
    useEffect(() => {
      save(debouncedValue);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedValue]);

    // Flushes the debounced callback when the component unmounts
    // so values entered right before clicking "Next" are saved.
    useEffect(() => {
      return () => {
        if (debounceControls.isPending()) {
          // Save the absolute latest answer when this component unmounts.
          save(inputValueRef.current);
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const save = async (newAnswer: AnswerInput) => {
      const answerId = saveAnswer ? await saveAnswer(newAnswer) : "";

      const newValue = answerId ? { ...newAnswer, answerId } : newAnswer;

      helpers.setValue(newValue);
    };

    const setValue = async (value: AnswerInput) => {
      value && helpers.setValue(value);
    };

    return (
      <TextField
        ref={ref}
        key={fieldKey}
        id={fieldKey}
        data-testid={fieldKey}
        label={label}
        value={input.value}
        isRequired={isRequired}
        placeHolderText={placeHolder || ""}
        error={meta.error}
        touched={meta.touched}
        setValue={setValue}
      />
    );
  }
);

// This has to be set here because the component is being created on an anonyous
// function inside the call to React.forwardRef()
AddressInnerField.displayName = "AddressInnerField";

export { AddressInnerField };
