import React, { FC } from "react";
import { ContentWrapper, Flex, PageWrapper } from "@src/components/layout/Page";
import { PageFooter } from "@src/components/molecules/pageFooter";
import { useBreakPoints } from "@src/customHooks";
import { FormInfoQuery } from "@src/types";
import { StyledHeader, StyledLabel } from "@src/components/styles";
import { useTranslation } from "react-i18next";
import { Language, useGoogleTranslate } from "@src/utils/translation";
import { SelectLanguage } from "@src/components/molecules/pageHeader/SelectLanguage";

type FormClosedProps = { form: NonNullable<FormInfoQuery["Form"]> };

const FormClosed: FC<FormClosedProps> = ({ form }) => {
  const screenSize = useBreakPoints();
  const googleTranslate = useGoogleTranslate();
  const { t, i18n } = useTranslation();

  const onLanguageChanged = (language: Language) => {
    i18n.changeLanguage(language.code);
  };

  if (form.redirectUrl) {
    window.location.href = form.redirectUrl;
    return null;
  }

  return (
    <>
      <PageWrapper>
        <ContentWrapper
          screenSize={screenSize}
          style={{ marginTop: screenSize === "desktop" ? "25px" : "0px" }}
        >
          <Flex flexDirection="column" gap={25}>
            <Flex justifyContent="space-between">
              <StyledHeader>{googleTranslate(form.name || "")}</StyledHeader>
              <SelectLanguage onLanguageChanged={onLanguageChanged} />
            </Flex>
            <StyledLabel style={{ color: "#7e7e7e" }}>
              {form.redirectMessage
                ? googleTranslate(form.redirectMessage)
                : t("pages.formStatus.closed")}
            </StyledLabel>
          </Flex>
        </ContentWrapper>
      </PageWrapper>
      <PageFooter />
    </>
  );
};

export { FormClosed };
