import gql from "graphql-tag";
import { TicketInventoryFragment } from "./fragments";

const TICKET_INVENTORY_QUERY = gql`
  query TicketInventory($countryCode: String!, $documentId: ID!, $ticketIds: [ID!]!) {
    TicketInventory(countryCode: $countryCode, documentId: $documentId, ticketIds: $ticketIds) {
      ...TicketInventoryFragment
    }
  }
  ${TicketInventoryFragment}
`;

export { TICKET_INVENTORY_QUERY };
