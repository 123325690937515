const defaultTheme = {
  colors: {
    alias: {
      primary: "#0283ff",
      lightPrimary: "#0097e3",
      darkPrimary: "#006496",
      disabled: "#b7b7b7",
    },
    page: {
      background: "#F1F2F4",
    },
    fields: {
      text: "#212B36",
      selected: "#0283FF",
      bordercolor: "#dddfe1",
      tablebordercolor: "#ececec",
      disabledBackground: "#d8d2d2",
    },
    values: {
      white: "#fff",
      lightblack: "#273549",
      grey: "#868686",
      lightgrey: "#f3f3f3",
      darkgrey: "#3d3d3d",
      red: "#ff0000",
      darkred: "#dd0000",
      lightblue: "#3260D3",
      yellow: "#ef9400",
      blue: "#1177d9",
      darkblue: "#006b9e",
      green: "#8dc754",
      darkgreen: "#2f8822",
      lightRed: "#ff6c6c",
    },
  },
  spacing: {
    default: "8px",
    medium: "16px",
  },
  font: {
    default: "'Roboto', sans-serif;",
    size: "14px",
    iconSize: "16px",
  },
  borderRadius: "5px",
  shadows: {
    button: "2px 3px 6px -2px #777",
  },
};

export type DefaultTheme = typeof defaultTheme;

export { defaultTheme };
