import React, { FC, useState } from "react";

import { cloneDeep } from "@apollo/client/utilities";
import ImageIcon from "@src/assets/image.png";
import PdfIcon from "@src/assets/pdf.png";
import { FileViewer } from "@src/components/atoms/FileViewer";
import { HeaderField, ParagraphField } from "@src/components/fields";
import { EquationsDisplayField } from "@src/components/fields/EquationsField/components";
import { RatingDisplayField } from "@src/components/fields/RatingField";
import { AnswerBackground } from "@src/components/fields/RepeatableQuestionField/components/styles";
import { Flex } from "@src/components/layout/Page";
import {
  FileThumbnail,
  PdfThumbnail,
  StyledAnswerLabel,
  StyledAnswerRow,
  StyledDisplayLabel,
  StyledDivider,
} from "@src/components/styles";
import { MimeType } from "@src/constants/mimeTypes";
import { Answer, AnswerInput, Field, FieldType } from "@src/types";
import {
  getEquationAggregateQuestions,
  getEquationCalculatedQuestions,
  getEquationInputQuestions,
} from "@src/utils/equations";
import { collectFieldAnswers, isCollapsed, isNoInputField, isSimpleField } from "@src/utils/field";
import { getEquationValue } from "@src/utils/formValues";
import { getHeaderText, getSubheaderText } from "@src/utils/getters";
import { getLabelValuePair, LabelValuePair } from "@src/utils/repeatables";
import groupBy from "lodash/groupBy";
import { Icon } from "@src/components/atoms/Icon";
import { useGoogleTranslate } from "@src/utils/translation";
import { useTranslation } from "react-i18next";

type DisplayAnswersProps = {
  fields: Field[];
  answers: AnswerInput[];
  integratedDataAnswers?: Answer[];
  countryCode?: string;
  documentId?: string;
  responseVersionId?: string;
  uploadedFileViewBaseUrl?: string;
  shouldTranslateAnswers?: boolean;
};

const DisplayAnswers: FC<DisplayAnswersProps> = ({
  fields,
  answers,
  integratedDataAnswers,
  countryCode,
  documentId,
  responseVersionId,
  uploadedFileViewBaseUrl,
  shouldTranslateAnswers,
}) => {
  const [showFile, setShowFile] = useState<AnswerInput | null>(null);
  const [collapsedHeaders, setCollapsedHeaders] = useState<string[]>([]);
  const googleTranslate = useGoogleTranslate();
  const { t } = useTranslation();

  if (!uploadedFileViewBaseUrl && countryCode && documentId && responseVersionId) {
    uploadedFileViewBaseUrl = `${
      process.env.RESPONSES_URL
    }response/${countryCode.toLowerCase()}/${documentId}/media`;
  }

  const handleToggleCollapse = (fieldId: string) => {
    const existing = collapsedHeaders.find((x) => x === fieldId);

    if (existing) {
      setCollapsedHeaders(collapsedHeaders.filter((x) => x !== existing));
    } else {
      const headers = cloneDeep(collapsedHeaders);
      headers.push(fieldId);

      setCollapsedHeaders(headers);
    }
  };

  const displayAnswer = (
    testId: string,
    labelValue: LabelValuePair,
    direction: "ltr" | "auto" = "auto"
  ) => {
    const questionLabel = googleTranslate(labelValue.label);
    const questionValue = shouldTranslateAnswers
      ? googleTranslate(labelValue.value)
      : labelValue.value;
    return (
      <StyledAnswerRow>
        <StyledDisplayLabel>{questionLabel}</StyledDisplayLabel>{" "}
        <StyledAnswerLabel data-testid={testId + "-ANSWER"} dir={direction}>
          {questionValue}
        </StyledAnswerLabel>
      </StyledAnswerRow>
    );
  };

  const displayPhoneAnswer = (testId: string, labelValue: LabelValuePair) => {
    const questionLabel = googleTranslate(labelValue.label);
    const questionValue = labelValue.value;
    return (
      <StyledAnswerRow data-testid="Phone Answer">
        <StyledDisplayLabel>{questionLabel}</StyledDisplayLabel>{" "}
        <StyledAnswerLabel data-testid={testId + "-ANSWER"} dir="ltr">
          {questionValue}
        </StyledAnswerLabel>
      </StyledAnswerRow>
    );
  };

  return (
    <Flex flexDirection="column" style={{ width: "100%" }}>
      {showFile ? (
        <FileViewer
          viewUrl={`${uploadedFileViewBaseUrl}/${showFile.value}`}
          mimeType={showFile.fileType ?? ""}
          dismiss={() => setShowFile(null)}
        />
      ) : null}
      {fields.map((i, idx) => {
        const field: Field = i;

        const collapsed = isCollapsed(field, fields, collapsedHeaders);

        if (field.type !== FieldType.Header && field.type !== FieldType.Section && collapsed)
          return null;

        if (isNoInputField(field)) {
          if (field.type === FieldType.Descriptivetext) {
            return <ParagraphField key={field.id} text={field.question?.placeHolderText ?? ""} />;
          }

          if (field.type === FieldType.Header || field.type === FieldType.Section) {
            return (
              <HeaderField
                key={field.id}
                fieldId={field?.id ?? ""}
                headerText={googleTranslate(getHeaderText(field))}
                subheaderText={googleTranslate(getSubheaderText(field))}
                collapsible={field.collapsable}
                isCollapsed={collapsed}
                handleToggleCollapse={handleToggleCollapse}
              />
            );
          }

          if (field.type === FieldType.Divider) {
            return <StyledDivider key={field.id} />;
          }
        }

        if (isSimpleField(field)) {
          const labelValue = getLabelValuePair({ field, answers });

          if (field.type === FieldType.Rating) {
            if (isNaN(Number(labelValue.value))) {
              return displayAnswer(field.question?.id ?? "", labelValue);
            } else {
              return (
                <RatingDisplayField
                  key={idx}
                  field={field}
                  label={googleTranslate(labelValue.label)}
                  value={labelValue.value}
                />
              );
            }
          }

          if (field.type === FieldType.Yesno) {
            if (labelValue.value.toLowerCase() === "true") {
              labelValue.value = t("labels.yes");
            }

            if (labelValue.value.toLowerCase() === "false") {
              labelValue.value = t("labels.no");
            }
          }

          if (
            field.type !== FieldType.Singleline &&
            field.type !== FieldType.Multiline &&
            field.type !== FieldType.Phonenumber
          ) {
            labelValue.value = googleTranslate(labelValue.value);
          }

          if (field.type === FieldType.Phonenumber) {
            return <div key={idx}>{displayPhoneAnswer(field.question?.id ?? "", labelValue)}</div>;
          }

          return <div key={idx}>{displayAnswer(field.question?.id ?? "", labelValue)}</div>;
        } else if (field.type === FieldType.Address) {
          return field.fields
            ?.filter((f) => !f.question?.hidden)
            .map((addressField, addressIndex) => {
              const labelValue = getLabelValuePair({
                field: addressField,
                answers,
                overrideFieldId: field.id,
              });

              return (
                <div key={addressIndex}>
                  {displayAnswer(addressField.question?.id ?? "", labelValue)}
                </div>
              );
            });
        } else if (field.type === FieldType.Upload) {
          const answer = answers.find(
            (x) => x.fieldId === field.id && x.questionId === field.question?.id
          );
          const labelValue = getLabelValuePair({ field, answers });
          return (
            <div key={idx}>
              <StyledAnswerRow>
                <StyledDisplayLabel>{googleTranslate(labelValue.label)}</StyledDisplayLabel>{" "}
                {answer && answer.value ? (
                  answer.fileType === MimeType.PDF ? (
                    <PdfThumbnail
                      data-testid={field.question?.id + "-FILE"}
                      onClick={() => setShowFile(answer)}
                      style={{
                        cursor: "pointer",
                        width: "30px",
                        verticalAlign: "middle",
                        marginLeft: "15px",
                      }}
                      src={PdfIcon}
                    />
                  ) : (
                    <FileThumbnail
                      data-testid={field.question?.id + "-FILE"}
                      onClick={() => setShowFile(answer)}
                      style={{
                        cursor: "pointer",
                        width: "30px",
                        verticalAlign: "middle",
                        marginLeft: "15px",
                      }}
                      src={ImageIcon}
                    />
                  )
                ) : (
                  <StyledAnswerLabel>{labelValue.value}</StyledAnswerLabel>
                )}
              </StyledAnswerRow>
            </div>
          );
        } else if (field.type === FieldType.RepeatableQuestion) {
          const labelValue = getLabelValuePair({ field, answers });
          const repeatableAnswers = collectFieldAnswers(field, answers);
          const answersByOrder = groupBy(repeatableAnswers, "order");
          const rows = Object.keys(answersByOrder).map((rowOrder) => ({
            order: rowOrder,
            fields: field.fields?.flatMap((innerField) => {
              if (innerField.type === FieldType.Address) {
                const addressFieldId = innerField.id;
                const addressFields = innerField.fields || [];
                return addressFields
                  .filter((x) => !x.question?.hidden)
                  .map((x) => {
                    return { ...x, id: addressFieldId };
                  });
              } else {
                return innerField;
              }
            }),
            answers: answersByOrder[rowOrder],
          }));

          return (
            <div key={idx}>
              <StyledAnswerRow>
                <StyledDisplayLabel>{googleTranslate(labelValue.label)}</StyledDisplayLabel>{" "}
                {rows && rows.length > 0 ? (
                  rows.map((row, index) => {
                    return (
                      <AnswerBackground key={index}>
                        <DisplayAnswers
                          fields={row.fields ?? []}
                          answers={row.answers}
                          countryCode={countryCode}
                          documentId={documentId}
                          responseVersionId={responseVersionId}
                          uploadedFileViewBaseUrl={uploadedFileViewBaseUrl}
                        />
                      </AnswerBackground>
                    );
                  })
                ) : (
                  <StyledAnswerLabel>{labelValue.value}</StyledAnswerLabel>
                )}
              </StyledAnswerRow>
            </div>
          );
        } else if (field.type === FieldType.Equations) {
          const labelValue = getLabelValuePair({ field, answers });
          const value = getEquationValue({ field, answers: collectFieldAnswers(field, answers) });

          if (value && value.allAnswers && value.allAnswers.length > 0) {
            return (
              <EquationsDisplayField
                key={idx}
                label={googleTranslate(labelValue.label)}
                value={value}
                field={field}
                inputQuestions={getEquationInputQuestions(field)}
                calculatedQuestions={getEquationCalculatedQuestions(field)}
                aggregateQuestions={getEquationAggregateQuestions(field)}
              />
            );
          } else {
            return displayAnswer(field.question?.id ?? "", labelValue);
          }
        } else if (
          field.type === FieldType.MultiFieldConditional ||
          field.type === FieldType.Conditional
        ) {
          const labelValue = getLabelValuePair({ field, answers });

          if (labelValue.value.toLowerCase() === "true") {
            labelValue.value = t("labels.yes");
          }

          if (labelValue.value.toLowerCase() === "false") {
            labelValue.value = t("labels.no");
          }

          return (
            <div key={idx}>
              {displayAnswer(field.question?.id ?? "", labelValue)}
              {labelValue.value === t("labels.yes") && field.fields && field.fields.length > 0 ? (
                <DisplayAnswers
                  fields={field.fields[0].fields ?? []}
                  answers={answers}
                  countryCode={countryCode}
                  documentId={documentId}
                  responseVersionId={responseVersionId}
                  uploadedFileViewBaseUrl={uploadedFileViewBaseUrl}
                />
              ) : null}

              {labelValue.value === t("labels.no") && field.fields && field.fields.length > 1 ? (
                <DisplayAnswers
                  fields={field.fields[1].fields ?? []}
                  answers={answers}
                  countryCode={countryCode}
                  documentId={documentId}
                  responseVersionId={responseVersionId}
                  uploadedFileViewBaseUrl={uploadedFileViewBaseUrl}
                />
              ) : null}
            </div>
          );
        } else if (field.type === FieldType.SelectConditional) {
          const labelValue = getLabelValuePair({ field, answers });
          const selectedIndex = field.question?.valueOptions?.indexOf(labelValue.value) ?? -1;

          labelValue.value = googleTranslate(labelValue.value);

          return (
            <div key={idx}>
              {displayAnswer(field.question?.id ?? "", labelValue)}

              {selectedIndex >= 0 && field.fields && selectedIndex < field.fields?.length ? (
                <DisplayAnswers
                  fields={field.fields[selectedIndex].fields ?? []}
                  answers={answers}
                  countryCode={countryCode}
                  documentId={documentId}
                  responseVersionId={responseVersionId}
                  uploadedFileViewBaseUrl={uploadedFileViewBaseUrl}
                />
              ) : null}
            </div>
          );
        } else if (field.type === FieldType.IntegratedData && field.fields?.length === 1) {
          const labelValue = getLabelValuePair({ field, answers });

          const key = field.question?.dataValue?.split(":")[1] || "";

          const existingValue = integratedDataAnswers?.find(
            (answer) => answer.questionId === key
          )?.value;

          const newValue = answers?.find(
            (answer) =>
              answer.fieldId === field.fields?.[0].id ||
              ("" && answer.questionId === field.fields?.[0].question?.id) ||
              ""
          )?.value;

          const valueChanged = newValue ? newValue !== existingValue : false;
          const valueRemoved =
            (!newValue && existingValue) || (newValue === "" && newValue !== existingValue);

          const existingValueLabel: LabelValuePair = {
            fileType: "",
            label: t("labels.SISValue"),
            value: existingValue || t("labels.noAnswer"),
          };

          const newValueLabel: LabelValuePair = {
            fileType: "",
            label: t("labels.responseValue"),
            value: newValue || existingValue || t("labels.noAnswer"),
          };

          const originalQuestion = field.fields?.[0];
          const direction = originalQuestion?.type === FieldType.Phonenumber ? "ltr" : "auto";

          return (
            <Flex key={idx} flexDirection="column">
              <p style={{ margin: "0px 0px 10px 0px" }}>{googleTranslate(labelValue.label)}</p>
              <div style={{ marginLeft: "20px" }}>
                {displayAnswer(key + "-SIS-VALUE", existingValueLabel, direction)}
                {!field.readonly ? (
                  <StyledAnswerRow>
                    <StyledDisplayLabel>{newValueLabel.label}</StyledDisplayLabel>{" "}
                    {valueChanged ? (
                      <Icon style="solid" icon="pen-to-square" color="#8dc754" />
                    ) : valueRemoved ? (
                      <Icon style="solid" icon="minus-octagon" color="#f2594b" />
                    ) : null}
                    <StyledAnswerLabel
                      style={{
                        color: valueChanged ? "#8dc754" : valueRemoved ? "#f2594b" : "inherit",
                        marginLeft: valueChanged || valueRemoved ? "5px" : "0px",
                      }}
                      data-testid={key + "-RESPONSE-VALUE"}
                      dir={direction}
                    >
                      {valueRemoved ? t("labels.valueRemoved") : newValueLabel.value}
                    </StyledAnswerLabel>
                  </StyledAnswerRow>
                ) : null}
              </div>
            </Flex>
          );
        }
      })}
    </Flex>
  );
};

export { DisplayAnswers };
