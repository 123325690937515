
import { debounce } from "lodash";

const TokenRoute = "oauth/token";
const RefreshTokenRoute = "oauth/refresh-token";

const inMemoryJWTManager = () => {
    let memory_token: string | null = null;
    let memory_expiresAt: Date | null = null;
    let refresh_token: string | null = null;
    const isExpired = () => (memory_token && memory_expiresAt && refresh_token && memory_expiresAt.getTime() < Date.now());

    const getTokenInternal = async (reset = false) => {
        if (!memory_token || isExpired() || reset) {
            // The refresh token itself is on cookies

            if (!(process.env.AUTH_URL)) {
                throw new Error("Missing AUTH_URL environment variable!");
            }

            let url = process.env.AUTH_URL;

            url += isExpired() ? RefreshTokenRoute : TokenRoute;
            const content = (isExpired()) ? new FormData() : null;
            content?.append("refresh_token", refresh_token ?? "");

            memory_token = null;
            memory_expiresAt = null;
            refresh_token = null;

            const response = await fetch(url, {
                method: "POST",
                credentials: "include",
                body: content,
                headers: {
                    "pragma": "no-cache",
                    "cache-control": "no-cache",
                }
            });

            if (response.status === 200) {
                const result = await response.json();
                memory_token = result["access_token"]; // TODO: Get UserId and set on applicationInsights
                memory_expiresAt = new Date(Date.parse(result["expires_at"]));
                refresh_token = result["refresh_token"];
            }
            else if (response.status >= 500) {
                throw new Error(await response.text());
            }
        }

        return memory_token;
    };

    const getTokenDebounced = debounce(() => getTokenInternal(), 1000, { 'leading': true });
    const resetTokenDebounced = debounce(() => getTokenInternal(true), 1000, { 'leading': true });

    const getToken = (reset = false) => {
        if (reset)
            return resetTokenDebounced();
        else
            return getTokenDebounced();
    };

    return {
        getToken,
    }
};

export default inMemoryJWTManager();