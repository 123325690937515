import axios from "axios";
import DataLoader from "dataloader";

import { IsLoadingTranslation } from "@src/localVariables";

const GOOGLE_TRANSLATE_ENDPOINT = `https://translation.googleapis.com/language/translate/v2?key=${process.env.GOOGLE_API_KEY}`;
const GOOGLE_SEGMENT_LIMIT = 128;

export type TranslationLoaderParameters = {
  text: string;
  target: string;
  source: string;
  countryCode: string;
};

const cacheKeyFn = (value: TranslationLoaderParameters) =>
  `${value.text}-${value.target}-${value.source}-${value.countryCode}}`;

const getPcTranslateEndpoint = (countryCode: string, langCode: string) =>
  `${process.env.DOCUMENTS_URL}respond/${countryCode}/${langCode}/translations`;

const translateMultipleTextsFromGoogle = async (
  texts: string[],
  target: string,
  source: string
) => {
  const result = await axios.post(GOOGLE_TRANSLATE_ENDPOINT, {
    q: texts,
    target,
    source,
  });

  const translationDict = texts.reduce((acc, cur, index) => {
    acc[cur] = result.data.data.translations[index]?.translatedText ?? "";
    return acc;
  }, {} as Record<string, string>);

  return translationDict;
};

const translateFromPC = async (
  texts: string[],
  target: string,
  source: string,
  countryCode: string
) => {
  const PC_TRANSLATE_ENDPOINT = getPcTranslateEndpoint(countryCode, target);

  try {
    const result = await axios.post(PC_TRANSLATE_ENDPOINT, {
      text: texts,
      target,
      source,
    });

    return result.data.Translations;
  } catch (e) {
    console.error(`PC Translation Error: ${e}`);
    const result = await translateMultipleTextsFromGoogle(texts, target, source);

    return result;
  }
};

// This DataLoader instance will return an object that contains a `load` method.
// The `load` method will be called various times when the application renders.
// The DataLoader then aggregates all calls and makes a single run of the function below.
const pcTranslationLoader = new DataLoader<TranslationLoaderParameters, string, string>(
  async (values) => {
    IsLoadingTranslation(true);

    const target = values[0]?.target;
    const source = values[0]?.source;
    const countryCode = values[0]?.countryCode;

    const texts = values.map((value) => value.text);
    const textsSet = new Set(texts);
    const uniqueTexts = Array.from(textsSet);

    let finalResults: Record<string, string> = {};

    for (let i = 0; i < uniqueTexts.length; i += GOOGLE_SEGMENT_LIMIT) {
      const chunk = texts.slice(i, i + GOOGLE_SEGMENT_LIMIT);
      const result = await translateFromPC(chunk, target, source, countryCode);

      finalResults = {
        ...finalResults,
        ...result,
      };
    }

    const result = values.map(({ text }) => finalResults[text] ?? "");

    IsLoadingTranslation(false);
    return result;
  },
  { cacheKeyFn }
);

const translateFromGoogle = async (
  texts: string[],
  target: string,
  source: string
): Promise<string[]> => {
  const result = await axios.post(GOOGLE_TRANSLATE_ENDPOINT, {
    q: texts,
    target,
    source,
  });

  return result.data.data.translations.map(
    (translation: { translatedText?: string }) => translation.translatedText ?? ""
  );
};

const googleTranslationLoader = new DataLoader<TranslationLoaderParameters, string, string>(
  async (values) => {
    IsLoadingTranslation(true);

    const target = values[0]?.target;
    const source = values[0]?.source;

    const texts = values.map((value) => value.text);
    const results = [];

    // Iterate trough all texts and call the translation API
    // for 128 of them at a time.
    // This is a limitation of the Google Translation API.

    for (let i = 0; i < texts.length; i += GOOGLE_SEGMENT_LIMIT) {
      const chunk = texts.slice(i, i + GOOGLE_SEGMENT_LIMIT);
      const chunkResults = await translateFromGoogle(chunk, target, source);
      results.push(...chunkResults);
    }

    IsLoadingTranslation(false);
    return results;
  },
  { cacheKeyFn }
);

export { pcTranslationLoader, googleTranslationLoader };
