import { useState } from "react";
import omit from "lodash/omit";

import { Answer, AnswerInput, Field } from "@src/types";
import { getFormInitialValues } from "@src/utils/formValues";
import { InnerFields, RepeatableValue, SelectedFile } from "@src/utils/formValuesTypes";
import { getNextRowOrder } from "@src/utils/getters";
import { getInnerFieldAnswersFromRepeatableValue } from "@src/utils/repeatables";
import axios from "axios";
import { cloneDeep } from "@apollo/client/utilities";

type useRepeatableLogicArgs = {
  value: RepeatableValue;
  fields: Field[];
  setValue: (value: RepeatableValue) => void;
  saveAnswer?: (answer: AnswerInput) => Promise<string>;
  deleteAnswer?: (answer: Answer) => Promise<unknown>;
};

type RepeatableLogic = {
  isEditing: boolean;
  initialValues: InnerFields;
  selectRow: (rowOrder: string) => void;
  addRow: (
    innerFields: InnerFields,
    selectedFiles: SelectedFile[],
    uploadUrl?: string
  ) => Promise<void>;
  editRow: (
    innerFields: InnerFields,
    selectedFiles: SelectedFile[],
    uploadUrl?: string,
    deletedFiles?: SelectedFile[]
  ) => Promise<void>;
  deleteRow: (rowOrder: string) => Promise<void>;
  setIsDirty: (isDirty: boolean) => void;
};

const useRepeatableLogic = ({
  value,
  fields,
  setValue,
  saveAnswer,
  deleteAnswer,
}: useRepeatableLogicArgs): RepeatableLogic => {
  const [editingRow, setEditingRow] = useState<number | null>(null);
  const [originalAnswers, setOriginalAnswers] = useState<AnswerInput[]>([]);
  const [innerFieldValues, setInnerFieldValues] = useState<InnerFields>(
    omit(value, ["answers", "isDirty"])
  );
  const isEditing = editingRow !== null;

  const upload = async (selectedFile: SelectedFile, order: number, uploadUrl: string) => {
    if (selectedFile.file) {
      const form = new FormData();
      form.append("file", selectedFile.file, selectedFile.file.name);
      form.append("fieldId", selectedFile.fieldId);
      form.append("questionId", selectedFile.questionId);
      form.append("order", order.toString());

      const result = await axios.post(uploadUrl, form);

      // explicitly assign only the properties needed for saving an answer
      const newAnswer: Answer = {
        answerId: result.data.answerId,
        fieldId: result.data.fieldId,
        questionId: result.data.questionId,
        value: result.data.value,
        fileType: result.data.fileType,
        order: result.data.order,
      };

      return newAnswer;
    }
  };

  const addRow = async (
    innerFields: InnerFields,
    selectedFiles: SelectedFile[],
    uploadUrl?: string
  ) => {
    let newAnswers = getInnerFieldAnswersFromRepeatableValue(innerFields);

    if (selectedFiles && selectedFiles.length > 0) {
      selectedFiles.map(
        (selectedFile) =>
          (newAnswers = newAnswers.filter(
            (x) => x.fieldId !== selectedFile.fieldId && x.questionId !== selectedFile.questionId
          ))
      );
    }

    newAnswers = newAnswers.filter(
      (x) => typeof x.value !== "undefined" && x.value !== null && x.value !== ""
    );

    const allAnswers = [...value.answers, ...newAnswers];

    const rowOrder = getNextRowOrder(allAnswers);

    if (uploadUrl && selectedFiles && selectedFiles.length > 0) {
      await Promise.allSettled(
        selectedFiles.map(async (x) => {
          const answer = await upload(x, rowOrder, uploadUrl);

          if (answer && saveAnswer) {
            answer.order = rowOrder;
            newAnswers.push(answer);
            allAnswers.push(answer);
            saveAnswer(omit(answer, "__typename"));
          }
        })
      );
    }

    const newInnerFields = getFormInitialValues({
      fields,
      order: rowOrder,
    });

    newAnswers.forEach((answer) => {
      answer.order = rowOrder;
    });

    setInnerFieldValues(newInnerFields);
    setValue({
      ...value,
      answers: allAnswers,
    });

    await Promise.allSettled(
      newAnswers.map(async (answer) => {
        if (
          saveAnswer &&
          typeof answer.value !== "undefined" &&
          answer.value !== null &&
          answer.value !== ""
        ) {
          answer.answerId = await saveAnswer(omit(answer, "__typename"));
        }
      })
    );
  };

  const selectRow = (rowOrder: string) => {
    try {
      const order = parseInt(rowOrder);

      const innerFields = getFormInitialValues({
        fields,
        order,
        answers: value.answers,
      });

      setOriginalAnswers(getInnerFieldAnswersFromRepeatableValue(innerFields));

      setInnerFieldValues(innerFields);

      setEditingRow(order);
    } catch (error) {
      console.error("Error while trying to select a repeatable row to edit.", error);
    }
  };

  const editRow = async (
    innerFields: InnerFields,
    selectedFiles: SelectedFile[],
    uploadUrl?: string,
    deletedFiles?: SelectedFile[]
  ) => {
    let editedAnswers = getInnerFieldAnswersFromRepeatableValue(innerFields);
    const changedAnswers: AnswerInput[] = [];

    originalAnswers.forEach((answer) => {
      const index = editedAnswers.findIndex((x) => x.questionId === answer.questionId);
      const editedAnswer = cloneDeep(editedAnswers[index]);

      if (index > -1 && editedAnswer.value !== answer.value) {
        editedAnswer.order = editingRow;
        changedAnswers.push(editedAnswer);
      }
    });

    if (deletedFiles && deletedFiles.length > 0) {
      deletedFiles.map((selectedFile) => {
        const file = editedAnswers.find(
          (x) => x.fieldId === selectedFile.fieldId && x.questionId === selectedFile.questionId
        );
        if (file) {
          editedAnswers = editedAnswers.filter(
            (x) => x.fieldId !== selectedFile.fieldId && x.questionId !== selectedFile.questionId
          );
          file.value = null;
          file.fileType = null;
          editedAnswers.push(file);
        }
      });
    }

    if (selectedFiles && selectedFiles.length > 0) {
      selectedFiles.map((selectedFile) => {
        editedAnswers = editedAnswers.filter(
          (x) => x.fieldId !== selectedFile.fieldId && x.questionId !== selectedFile.questionId
        );
      });
    }

    const otherAnswers = value.answers.filter((answer) => answer.order !== editingRow);
    const allAnswers = [...otherAnswers, ...editedAnswers];

    if (uploadUrl && selectedFiles && selectedFiles.length > 0) {
      await Promise.allSettled(
        selectedFiles.map(async (x) => {
          const answer = await upload(x, editingRow ?? 0, uploadUrl);

          if (answer && saveAnswer) {
            answer.order = editingRow;
            editedAnswers.push(answer);
            allAnswers.push(answer);
            await saveAnswer(omit(answer, "__typename"));
          }
        })
      );
    }

    const emptyInnerFields = getFormInitialValues({
      fields,
      order: getNextRowOrder(allAnswers),
    });

    setInnerFieldValues(emptyInnerFields);

    setValue({
      ...value,
      answers: allAnswers,
    });

    setEditingRow(null);

    setOriginalAnswers([]);

    await Promise.allSettled(
      editedAnswers.map(async (answer) => {
        if (saveAnswer && changedAnswers.find((x) => x.answerId === answer.answerId)) {
          answer.answerId = await saveAnswer(omit(answer, "__typename"));
        }
      })
    );
  };

  const deleteRow = async (rowOrder: string) => {
    try {
      const order = parseInt(rowOrder);

      if (order === editingRow) {
        setEditingRow(null);
      }

      const { remainingAnswers, answersToDelete } = value.answers.reduce(
        (acc, answer) => {
          if (answer.order === order) {
            acc.answersToDelete.push(answer as Answer);
          } else {
            acc.remainingAnswers.push(answer);
          }
          return acc;
        },
        { remainingAnswers: [], answersToDelete: [] } as {
          remainingAnswers: AnswerInput[];
          answersToDelete: Answer[];
        }
      );

      setValue({
        ...value,
        answers: remainingAnswers,
      });

      answersToDelete.forEach((answer) => deleteAnswer && deleteAnswer(omit(answer, "__typename")));
    } catch (error) {
      console.error("Error while trying to delete a row.", error);
    }
  };

  const setIsDirty = (isDirty: boolean) =>
    setValue({
      ...value,
      isDirty,
    });

  return {
    isEditing,
    initialValues: innerFieldValues,
    selectRow,
    addRow,
    editRow,
    deleteRow,
    setIsDirty,
  };
};

export { useRepeatableLogic };
