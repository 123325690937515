import { useApolloClient, useReactiveVar } from "@apollo/client";
import { cloneDeep } from "@apollo/client/utilities";
import {
  AttendeeFormRoute,
  AttendeeInformationRoute,
  PreviewProps,
  ReviewRoute,
  SigneeFormRoute,
} from "@src/Routes";
import { FieldSelector } from "@src/components/fields";
import { ContentWrapper, PageWrapper } from "@src/components/layout";
import { Flex, Push } from "@src/components/layout/Page";
import { ProgressTracker } from "@src/components/molecules/ProgressTracker";
import { ErrorNavigator } from "@src/components/molecules/errorNavigator";
import { InfoModal } from "@src/components/molecules/infoModal/InfoModal";
import { LoadingModal } from "@src/components/molecules/loadingModal/LoadingModal";
import { NavigationButtons } from "@src/components/molecules/navigationButtons";
import { PageFooter } from "@src/components/molecules/pageFooter";
import { PageHeader } from "@src/components/molecules/pageHeader";
import { StyledAttendeeName, StyledDivider } from "@src/components/styles";
import { useBreakPoints, useUrlParams } from "@src/customHooks";
import { useFormInfo } from "@src/customHooks/useFormInfo";
import { useCachedResponseVersion } from "@src/customHooks/useResponseVersion";
import { useSafeSaveAttendeeAnswerMutation } from "@src/customHooks/useSafeMutations";
import { TranslateFormErrors } from "@src/customHooks/useTranslateFormErrors";
import { AccessDataAnswers, RequestError, ResponseVersionHash } from "@src/localVariables";
import {
  deleteAttendeeAnswerMutationUpdater,
  saveAttendeeAnswerMutationUpdater,
} from "@src/mutations/updaterFunctions/updateAttendeeAnswers";
import { AnswerInput, useDeleteAttendeeAnswerMutation } from "@src/types";
import { checkUnsubmittedAnswers } from "@src/utils/answers";
import { isCollapsed } from "@src/utils/field";
import {
  getIntegratedDataFields,
  hasAttendeeQuestions,
  hasSigneeQuestions,
  hasTickets,
} from "@src/utils/formGetters";
import { getFormInitialValues } from "@src/utils/formValues";
import { InitialValuesObject } from "@src/utils/formValuesTypes";
import { getAttendeeFields, getAttendeesList, getFieldKey } from "@src/utils/getters";
import { getAttendeeAnswer, getDeleteAttendeeAnswerResponseForPreview } from "@src/utils/preview";
import { ticketMaximumsAreNotExceeded, ticketMinimumsAreMet } from "@src/utils/purchasedTickets";
import { isStaff } from "@src/utils/responseVersionGetters";
import { scrollToTop } from "@src/utils/scrollToTop";
import { answerToAnswerInput } from "@src/utils/typeConverters";
import { Form, Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";
import { ErrorComponent } from "../Error/Error";
import { Tickets } from "../Tickets/Tickets";

export const RespondentMustSelectATicket = "RespondentMustSelectATicket";
export const TicketMinimumsNotMet = "TicketMinimumsNotMet";
export const TicketMaximumsExceeded = "TicketMaximumsExceeded";

const TicketsAndAttendeeForm: FC<PreviewProps> = ({ isPreview }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { baseUrl, countryCode, markAsVisited, attendeeIndex } = useUrlParams();
  const screenSize = useBreakPoints();
  const responseVersionHash = useReactiveVar(ResponseVersionHash);
  const requestError = useReactiveVar(RequestError);
  const accessData = useReactiveVar(AccessDataAnswers) || [];
  const [collapsedHeaders, setCollapsedHeaders] = useState<string[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const client = useApolloClient();
  const [disableNextButton, setDisableNextButton] = useState(false);

  useEffect(() => {
    typeof attendeeIndex !== undefined && scrollToTop();
  }, [attendeeIndex]);

  const formInfo = useFormInfo();
  const responseVersionQuery = useCachedResponseVersion(responseVersionHash);
  const attendeesList = getAttendeesList(responseVersionQuery.responseVersion).filter(
    (attendee) => !formInfo.form.requiresPermission || attendee.permitted
  );

  const currentAnswers =
    attendeeIndex !== undefined ? attendeesList[attendeeIndex]?.attendeeAnswers || [] : [];
  const integratedDataOnFile =
    attendeeIndex !== undefined ? attendeesList[attendeeIndex]?.integratedDataOnFile || [] : [];

  const allInitialAnswers = [...currentAnswers, ...integratedDataOnFile];
  const saveAttendeeAnswer = useSafeSaveAttendeeAnswerMutation({ answers: allInitialAnswers });

  const [deleteAttendeeAnswer] = useDeleteAttendeeAnswerMutation();

  if (formInfo.error) return <ErrorComponent error={formInfo.error} />;
  if (formInfo.loading || responseVersionQuery.loading) return <LoadingModal />;
  if (responseVersionQuery.error)
    return <ErrorComponent error={responseVersionQuery.error ?? ""} />;

  const form = formInfo.form;
  const responseVersion = responseVersionQuery.responseVersion;

  if (
    !baseUrl ||
    !responseVersion ||
    typeof attendeeIndex === "undefined" ||
    responseVersionQuery.responseVersion?.isSigned
  ) {
    const currentUrl = window.location.pathname;

    // Remove everything after AttendeeFormRoute
    return (
      <Navigate
        to={`${currentUrl.replace(currentUrl.slice(currentUrl.indexOf(AttendeeFormRoute)), "")}`}
      />
    );
  }

  if (attendeeIndex >= attendeesList.length && !formInfo.form.isGeneralSignup) {
    const index = window.location.pathname.indexOf(AttendeeFormRoute);

    return <Navigate to={`${window.location.pathname.substring(0, index)}`} />;
  }

  markAsVisited("attendee-form/0");

  const selectedAttendee = attendeesList[attendeeIndex];
  const attendeeFullName = `${selectedAttendee.firstName} ${selectedAttendee.lastName}`;

  const fields = getAttendeeFields(formInfo.form);

  const initialValues = getFormInitialValues({
    fields,
    answers: currentAnswers,
    integratedDataOnFile: integratedDataOnFile,
  });

  const saveAnswer = async (answerInput: AnswerInput): Promise<string> => {
    const answer = answerToAnswerInput(answerInput);

    if (isPreview) {
      const previewAnswer = getAttendeeAnswer(answer);
      const updater = saveAttendeeAnswerMutationUpdater({
        attendeeId: selectedAttendee.attendeeId,
        responseHashedId: responseVersion.hash,
      });
      if (updater) {
        updater(
          client.cache,
          { data: { SaveAttendeeAnswer: previewAnswer } },
          {
            variables: {
              attendeeId: selectedAttendee.attendeeId,
              countryCode,
              documentId: form.documentId,
              responseVersionId: responseVersion.responseVersionId,
              answer,
              accessData,
            },
          }
        );
      }
      return previewAnswer.answerId;
    } else {
      const answerId = await saveAttendeeAnswer({
        update: saveAttendeeAnswerMutationUpdater({
          attendeeId: selectedAttendee.attendeeId,
          responseHashedId: responseVersion.hash,
        }),
        variables: {
          countryCode,
          documentId: form.documentId,
          responseVersionId: responseVersion.responseVersionId,
          attendeeId: selectedAttendee.responseAttendeeId,
          answer,
          accessData,
        },
      });

      return answerId;
    }
  };

  const deleteAnswer = async (answer: AnswerInput) => {
    if (isPreview) {
      const response = { DeleteAttendeeAnswer: getDeleteAttendeeAnswerResponseForPreview() };
      const updater = deleteAttendeeAnswerMutationUpdater({
        attendeeId: selectedAttendee.attendeeId,
        responseHashedId: responseVersion.hash,
      });
      if (updater) {
        updater(
          client.cache,
          { data: response },
          {
            variables: {
              countryCode,
              documentId: form.documentId,
              responseVersionId: responseVersion.responseVersionId,
              attendeeId: selectedAttendee.responseAttendeeId,
              accessData,
              answerInput: {
                fieldId: answer.fieldId,
                questionId: answer.questionId,
                order: answer.order,
              },
            },
          }
        );
      }
      return Promise.resolve({ data: response, errors: undefined });
    } else {
      return await deleteAttendeeAnswer({
        update: deleteAttendeeAnswerMutationUpdater({
          attendeeId: selectedAttendee.attendeeId,
          responseHashedId: responseVersion.hash,
        }),
        variables: {
          countryCode,
          documentId: form.documentId,
          responseVersionId: responseVersion.responseVersionId,
          attendeeId: selectedAttendee.responseAttendeeId,
          accessData,
          answerInput: {
            fieldId: answer.fieldId,
            questionId: answer.questionId,
            order: answer.order,
          },
        },
      });
    }
  };

  const nextPage = (
    setFieldError?: (field: string, message: string | undefined) => void,
    continueWithoutTickets?: boolean
  ) => {
    const integratedDataAnswers = Object.keys(initialValues)
      .filter((x) => x.includes("INTEGRATED_DATA"))
      .map((key) => Object.values(initialValues[key])[1]);

    if (integratedDataAnswers) {
      integratedDataAnswers.forEach((answer) => {
        // Save untouched integrated data answers
        if (typeof (answer as AnswerInput).answerId === "undefined") {
          saveAnswer(answer as AnswerInput);
        }
      });
    }

    if (
      formInfo.form?.respondentMustSelectATicket &&
      hasTickets(formInfo.form) &&
      attendeesList[attendeeIndex].tickets.length === 0 &&
      setFieldError
    ) {
      setFieldError(RespondentMustSelectATicket, t("validation.noTicketsWhenRequired"));
      return;
    }

    const ticketMinimumsAreValid = ticketMinimumsAreMet(form.tickets ?? [], responseVersion.orders);
    const ticketMaximumsAreValid = ticketMaximumsAreNotExceeded(
      form.tickets ?? [],
      responseVersion.orders
    );

    if (!ticketMinimumsAreValid && setFieldError) {
      setFieldError(RespondentMustSelectATicket, t("validation.ticketMinimumsAreNotMet"));
      return;
    } else if (!ticketMaximumsAreValid && setFieldError) {
      setFieldError(RespondentMustSelectATicket, t("validation.ticketMaximumsAreExceeded"));
      return;
    }

    if (hasTickets(form) && attendeesList[attendeeIndex].tickets.length === 0 && !showModal) {
      setShowModal(true);
      return;
    }

    if (!showModal || continueWithoutTickets) {
      const nextIndex = attendeeIndex + 1;
      if (!formInfo.form.isGeneralSignup && attendeesList[nextIndex]) {
        navigate(`${baseUrl}/${AttendeeFormRoute}/${nextIndex}`);
      } else {
        navigate(`${baseUrl}/${ReviewRoute}`);
      }
    }
  };

  const back = () => {
    let attendeesList = getAttendeesList(responseVersionQuery.responseVersion);

    attendeesList = attendeesList.filter(
      (attendee) => !formInfo.form.requiresPermission || attendee.permitted
    );

    const previousIndex = attendeeIndex - 1;

    if (attendeesList[previousIndex]) {
      navigate(`${baseUrl}/${AttendeeFormRoute}/${previousIndex}`);
    } else {
      if (hasSigneeQuestions(form)) navigate(`${baseUrl}/${SigneeFormRoute}`);
      else if (!formInfo.form.isGeneralSignup) navigate(`${baseUrl}/${AttendeeInformationRoute}`);
      else navigate(`${baseUrl}`);
    }
  };

  const handleToggleCollapse = (fieldId: string) => {
    const existing = collapsedHeaders.find((x) => x === fieldId);

    if (existing) {
      setCollapsedHeaders(collapsedHeaders.filter((x) => x !== existing));
    } else {
      const headers = cloneDeep(collapsedHeaders);
      headers.push(fieldId);

      setCollapsedHeaders(headers);
    }
  };

  const handleSubmit = async (
    values: InitialValuesObject,
    setFieldError?: (field: string, message: string | undefined) => void
  ) => {
    setDisableNextButton(true);

    //skip integrated data fields
    const integratedDataFields = getIntegratedDataFields(form);
    const omitFieldKeys: string[] = [];

    integratedDataFields.map((x) => omitFieldKeys.push(getFieldKey(x)));

    const needsSaving = checkUnsubmittedAnswers(values, omitFieldKeys);
    await Promise.all(needsSaving.map((x) => saveAnswer(x as AnswerInput)));

    setDisableNextButton(false);
    nextPage(setFieldError, false);
  };

  return (
    <>
      <PageWrapper>
        <PageHeader isPreview={isPreview}>{requestError ? null : <ProgressTracker />}</PageHeader>
        <ContentWrapper screenSize={screenSize}>
          <Flex alignItems="center">
            <StyledAttendeeName>{attendeeFullName}</StyledAttendeeName>
          </Flex>
          {hasAttendeeQuestions(form) && <StyledDivider />}
          <Formik
            key={attendeeIndex}
            initialValues={initialValues}
            onSubmit={(values, { setFieldError }) => handleSubmit(values, setFieldError)}
          >
            <TranslateFormErrors>
              <Form>
                <ErrorNavigator />
                {fields.map((field) => (
                  <FieldSelector
                    key={field.id}
                    field={field}
                    isCollapsed={isCollapsed(field, fields, collapsedHeaders)}
                    uploadUrl={
                      !isPreview
                        ? `${process.env.RESPONSES_URL}response/${countryCode.toLowerCase()}/${
                            form.documentId
                          }/${responseVersion.responseVersionId}/attendee-answer/${
                            selectedAttendee.responseAttendeeId
                          }/upload`
                        : undefined
                    }
                    viewFileUrl={`${
                      process.env.RESPONSES_URL
                    }response/${countryCode.toLowerCase()}/${form.documentId}/media`}
                    saveAnswer={saveAnswer}
                    deleteAnswer={deleteAnswer}
                    handleToggleCollapse={handleToggleCollapse}
                  />
                ))}
                {hasTickets(form) ? (
                  <Tickets
                    attendee={selectedAttendee}
                    attendeeIndex={attendeeIndex}
                    isPreview={isPreview}
                    form={form}
                    responseVersion={responseVersion}
                  />
                ) : null}
                <NavigationButtons
                  displayBackButton={!isStaff(responseVersion) || hasSigneeQuestions(form)}
                  nextButtonDisabled={disableNextButton}
                  backOnClick={back}
                />
              </Form>
            </TranslateFormErrors>
          </Formik>
          {showModal ? (
            <InfoModal
              title={t("validation.noTicketsAdded")}
              message={t("messages.youHaveNoTickets")}
              confirmButtonText={t("labels.proceedWithoutTickets")}
              onCancel={() => {
                setShowModal(false);
              }}
              onConfirm={() => {
                setShowModal(false);
                nextPage(undefined, true);
              }}
            />
          ) : null}
        </ContentWrapper>
        <Push />
      </PageWrapper>
      <PageFooter />
    </>
  );
};

export { TicketsAndAttendeeForm };
