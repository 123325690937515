import React, { FC } from "react";
import { FaStar, FaRegStar } from "react-icons/fa";
import omit from "lodash/omit";

import {
  FieldWrapper,
  StyledRequiredLabel,
  StyledSelectedStar,
  StyledUnselectedStar,
} from "@src/components/styles";
import { CommonFieldProps } from "../types";
import { AnswerInput } from "@src/types";
import { Icon } from "@src/components/atoms/Icon";
import { ClearIconWrapper } from "./style";
import { FieldError } from "@src/components/atoms/FieldError";
import { Label } from "@src/components/atoms/Label";

type RatingFieldProps = CommonFieldProps;

const RatingField: FC<RatingFieldProps> = ({
  id,
  label,
  value,
  isRequired,
  setValue,
  saveAnswer,
}) => {
  const rating = value?.value ? parseInt(value.value, 10) : 0;

  const handleChange = (rating: number) => async () => {
    const newAnswer: AnswerInput = {
      ...value,
      value: rating.toString(),
    };

    setValue(newAnswer);

    const answerId = saveAnswer ? await saveAnswer(omit(newAnswer, "__typename")) : "";

    setValue({
      ...newAnswer,
      answerId,
    });
  };

  const handleClear = () => {
    const newAnswer: AnswerInput = {
      ...value,
      value: "",
    };

    setValue(newAnswer);
    saveAnswer && saveAnswer(omit(newAnswer, "__typename"));
  };

  const selectedStar = (
    <StyledSelectedStar data-testid={id + "-SELECTED"}>
      <FaStar />
    </StyledSelectedStar>
  );

  const unSelectedStar = (
    <StyledUnselectedStar data-testid={id + "-UNSELECTED"}>
      <FaRegStar />
    </StyledUnselectedStar>
  );

  return (
    <FieldWrapper id={id}>
      {isRequired ? <StyledRequiredLabel /> : null}
      <Label>{label}</Label>

      <div style={{ display: "flex" }}>
        <div data-testid={id + "-1"} onClick={handleChange(1)}>
          {rating > 0 ? selectedStar : unSelectedStar}
        </div>
        <div data-testid={id + "-2"} onClick={handleChange(2)}>
          {rating > 1 ? selectedStar : unSelectedStar}
        </div>
        <div data-testid={id + "-3"} onClick={handleChange(3)}>
          {rating > 2 ? selectedStar : unSelectedStar}
        </div>
        <div data-testid={id + "-4"} onClick={handleChange(4)}>
          {rating > 3 ? selectedStar : unSelectedStar}
        </div>
        <div data-testid={id + "-5"} onClick={handleChange(5)}>
          {rating > 4 ? selectedStar : unSelectedStar}
        </div>
        {rating > 0 ? (
          <ClearIconWrapper>
            <Icon
              testId={id + "-REMOVE"}
              size="12px"
              style="solid"
              icon="trash"
              onClick={handleClear}
              color="#bcbcbc"
            />
          </ClearIconWrapper>
        ) : null}
      </div>
      <FieldError id={id} name={id} />
    </FieldWrapper>
  );
};

export { RatingField };
