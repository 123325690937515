import styled from "styled-components";
import { Flex } from "@src/components/layout/Page";

const ModalTitle = styled.h1`
  font-weight: 300;
`;

const InfoModalContent = styled.div`
  position: fixed;
  z-index: 1000;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 490px;
  min-width: 340px;
  height: fit-content;
  background-color: white;
  border-radius: 7px;
`;

const InfoModalBackground = styled.div`
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #838383;
  opacity: 0.6;
  z-index: 999;
`;

const InfoModalTitleContainer = styled(Flex)`
  flex-grow: 1;
  margin-left: 20px;
`;

const InfoModalMessageContainer = styled(Flex)`
  margin-left: 25px;
`;

const InfoModalCloseIconContainer = styled(Flex)`
  margin: 20px 25px 0px 0px;
  height: fit-content;
  align-items: center;
`;

const InfoModalActionButtonsContainer = styled(Flex)`
  justify-content: flex-end;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  padding: 20px;
`;

export {
  ModalTitle,
  InfoModalContent,
  InfoModalBackground,
  InfoModalTitleContainer,
  InfoModalMessageContainer,
  InfoModalCloseIconContainer,
  InfoModalActionButtonsContainer,
};
