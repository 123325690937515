import React, { FC } from "react";
import { EquationsWrapper, FieldWrapper, StyledDisplayLabel } from "@src/components/styles";
import { Table, Row, Header, Cell } from "@src/components/atoms/Table";
import { AggregatesTable, AggregatesWrapper } from "../styles";
import { Field, Question } from "@src/types";
import { EquationValue } from "@src/utils/formValuesTypes";
import { currencyFormatter } from "@src/utils/purchasedTickets";
import { useGoogleTranslate } from "@src/utils/translation";
import { useTranslation } from "react-i18next";
import { useFormInfo } from "@src/customHooks/useFormInfo";

type Header = {
  name: string;
  type: string;
};

type EquationsFieldProps = {
  label: string;
  value: EquationValue;
  field: Field;
  inputQuestions: Question[];
  calculatedQuestions: Question[];
  aggregateQuestions: Question[];
};

const EquationsDisplayField: FC<EquationsFieldProps> = ({
  label,
  value,
  inputQuestions,
  calculatedQuestions,
  aggregateQuestions,
}) => {
  const googleTranslate = useGoogleTranslate();
  const { i18n } = useTranslation();
  const { form } = useFormInfo();

  const currencyCode = form?.document?.organization?.currencyCode;

  const { rows, aggregates } = value;
  const tableHeaders: Header[] = [...inputQuestions, ...calculatedQuestions].map((question) => {
    return { name: question.name || "", type: question.valueType || "text" };
  });
  const hasRows = rows.length > 0;

  const formatValue = (type: string, value: string) => {
    switch (type) {
      case "currency":
        return !isNaN(parseFloat(value))
          ? currencyFormatter(i18n.resolvedLanguage, currencyCode).format(parseFloat(value))
          : value;

      case "decimal":
        return !isNaN(parseFloat(value)) ? parseFloat(value).toFixed(2) : value;

      case "integer":
        return !isNaN(parseInt(value, 10)) ? parseInt(value, 10) : value;

      default:
        return value;
    }
  };

  return (
    <FieldWrapper fullWidth={true}>
      <StyledDisplayLabel>{label}</StyledDisplayLabel>
      <EquationsWrapper>
        <Table>
          <Row>
            {tableHeaders.map((header) => (
              <Header key={header.name}>{googleTranslate(header.name)}</Header>
            ))}
          </Row>

          {hasRows ? (
            <>
              {rows.map((row) => (
                <Row key={row.answerId}>
                  {tableHeaders.map((header) => (
                    <Cell key={header.name}>
                      {row.answers[header.name]
                        ? formatValue(header.type, row.answers[header.name].value ?? "")
                        : null}
                    </Cell>
                  ))}
                </Row>
              ))}
            </>
          ) : (
            <Row>
              {tableHeaders.map((header) => (
                <Cell key={header.name}>&nbsp;</Cell>
              ))}
            </Row>
          )}
        </Table>
      </EquationsWrapper>

      <AggregatesWrapper>
        <AggregatesTable isEditing={false}>
          <tbody>
            {aggregateQuestions.length > 0
              ? aggregateQuestions.map((question, index) => (
                  <Row key={question.name || index}>
                    <Cell>{googleTranslate(question.name || "")}</Cell>
                    <Cell>
                      {question.valueType
                        ? formatValue(question.valueType, aggregates[question.id].value ?? "")
                        : aggregates[question.id].value ?? ""}
                    </Cell>
                  </Row>
                ))
              : null}
          </tbody>
        </AggregatesTable>
      </AggregatesWrapper>
    </FieldWrapper>
  );
};

export { EquationsDisplayField };
