import { AnswerInput, Field } from "@src/types";
import { FieldHelperProps } from "formik";

export type FieldProps = {
  field: Field;
  parentQuestionId?: string;
};

export type FieldAnswer = {
  value: string | null;
  fileType?: string;
  order?: string;
};

export type ErrorObject = {
  value: string;
};

export type CommonFieldProps = {
  label: string;
  id: string;
  value: AnswerInput;
  error?: string;
  touched: boolean;
  isRequired: boolean;
  setValue: FieldHelperProps<AnswerInput>["setValue"];
  saveAnswer?: (answer: AnswerInput) => Promise<string>;
};

export type TextFieldProps = CommonFieldProps & {
  placeHolderText: string;
  maxLength?: number;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

export type MutlipleChoiceProps = CommonFieldProps & {
  options: string[];
  pinnedValueOptions: string[];
  sort: boolean;
};

export const DateFormats = {
  Long: "dddd MMMM DD, YYYY",
  Standard: "MMMM DD, YYYY",
  YearMonthDay: "YYYY-MM-DD",
  MonthDayYear: "MM/DD/YYYY",
};
