import React, { FC, useState } from "react";
import { Formik, FormikHelpers, FormikState } from "formik";

import { FieldWrapper, FieldInformationLabel } from "@src/components/styles";
import { Answer, AnswerInput, Field } from "@src/types";
import { RepeatableAnswers } from "./components";
import { InnerFields, RepeatableValue, SelectedFile } from "@src/utils/formValuesTypes";
import { useRepeatableLogic } from "./hooks";
import { RepeatableForm } from "./components";
import { FieldError } from "@src/components/atoms/FieldError";
import { Flex } from "@src/components/layout/Page";
import { Label } from "@src/components/atoms/Label";
import { useTranslation } from "react-i18next";
import { TranslateFormErrors } from "@src/customHooks/useTranslateFormErrors";

type RepeatableQuestionProps = {
  id: string;
  label: string;
  value: RepeatableValue;
  field: Field;
  error?: string;
  uploadUrl?: string;
  viewFileUrl: string;
  isTicketQuestion?: boolean;
  setValue: (value: RepeatableValue) => void;
  saveAnswer?: (answer: AnswerInput) => Promise<string>;
  deleteAnswer?: (answer: Answer) => Promise<unknown>;
};

const RepeatableQuestionField: FC<RepeatableQuestionProps> = ({
  id,
  label,
  value,
  field,
  uploadUrl,
  viewFileUrl,
  isTicketQuestion,
  setValue,
  saveAnswer,
  deleteAnswer,
}) => {
  const { t } = useTranslation();
  const innerFields = field.fields || [];
  const [selectedFiles, setSelectedFiles] = useState<SelectedFile[]>([]);
  const [deletedFiles, setDeletedFiles] = useState<SelectedFile[]>([]);

  const repeatableLogicArgs = {
    value,
    fields: innerFields,
    isTicketQuestion,
    setValue,
    saveAnswer,
    deleteAnswer,
  };

  const { isEditing, initialValues, selectRow, addRow, editRow, deleteRow, setIsDirty } =
    useRepeatableLogic(repeatableLogicArgs);

  const handleSubmit = (values: InnerFields, { resetForm }: FormikHelpers<InnerFields>) => {
    isEditing
      ? editRow(values, selectedFiles, uploadUrl, deletedFiles)
      : addRow(values, selectedFiles, uploadUrl);

    setSelectedFiles([]);
    resetForm();
  };

  const onSelectFile = (fieldId: string, questionId: string, file: File) => {
    if (isEditing && deletedFiles.length > 0) {
      const deletedFiles = selectedFiles.filter(
        (x) => x.fieldId !== fieldId && x.questionId !== questionId
      );
      setDeletedFiles(deletedFiles);
    }

    const files = selectedFiles.filter((x) => x.fieldId !== fieldId && x.questionId !== questionId);
    files.push({ fieldId, questionId, file });

    setSelectedFiles(files);
  };

  const onRemoveFile = (fieldId: string, questionId: string) => {
    if (!isEditing) {
      const files = selectedFiles.filter(
        (x) => x.fieldId !== fieldId && x.questionId !== questionId
      );
      setSelectedFiles(files);
    } else {
      const files = deletedFiles.filter(
        (x) => x.fieldId !== fieldId && x.questionId !== questionId
      );
      files.push({ fieldId, questionId, file: null });
      setDeletedFiles(files);
    }
  };

  const resetState = (
    resetForm: (nextState?: Partial<FormikState<InnerFields>> | undefined) => void
  ) => {
    setSelectedFiles([]);
    setDeletedFiles([]);
    resetForm();
  };

  return (
    <FieldWrapper id={id}>
      <Label>{label}</Label>

      <Flex gap={15}>
        {field.question?.minEntries !== -1 ? (
          <FieldInformationLabel>
            {`${t("labels.minEntries")} `} {field.question?.minEntries}
          </FieldInformationLabel>
        ) : null}
        {field.question?.maxEntries !== -1 ? (
          <FieldInformationLabel>
            {`${t("labels.maxEntries")} `} {field.question?.maxEntries}
          </FieldInformationLabel>
        ) : null}
      </Flex>

      <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
        {({ isValid, dirty, resetForm, submitForm }) => (
          <TranslateFormErrors>
            <RepeatableForm
              key={
                isEditing
                  ? 99999
                  : value?.answers
                      ?.map((x) => x.order)
                      .sort()
                      .reverse()[0] ?? -1
              }
              id={id}
              innerFields={innerFields}
              isEditing={isEditing}
              isValid={isValid}
              isDirty={dirty}
              viewFileUrl={viewFileUrl}
              setIsDirty={setIsDirty}
              resetForm={() => resetState(resetForm)}
              submitForm={submitForm}
              fileSelected={onSelectFile}
              fileRemoved={onRemoveFile}
            />
          </TranslateFormErrors>
        )}
      </Formik>

      <RepeatableAnswers
        innerFields={innerFields}
        answers={value?.answers ?? []}
        uploadedFileViewBaseUrl={viewFileUrl}
        handleSelectRow={selectRow}
        handleDeleteRow={deleteRow}
      />
      <FieldError id={id} name={id} />
    </FieldWrapper>
  );
};

export { RepeatableQuestionField };
