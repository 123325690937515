import { ApolloError, makeVar } from "@apollo/client";
import { AccessDataInput, ResponseSource } from "@src/types";
import { DefaultLanguage, Language } from "@src/utils/translation";
import { DefaultCountryCode } from "@src/utils/urls";
import { GraphQLError } from "graphql";

export type SigneeInfoValues = {
  firstName: string;
  lastName: string;
  email: string;
  notes?: string;
  attachmentId?: string;
  responseSource: ResponseSource;
  tagId: string | null;
};

export type UrlParts = {
  baseUrl: string;
  responseHashedId?: string;
  documentHashedId?: string;
  countryCode: string;
  hashedOrgId?: string;
  visitedPages: NavSlug[];
};

export type NavSlug = "" | "signee-form" | "attendee-form/0" | "review" | "questions";

const OrderId = makeVar<string | null>(null);
const HasAccess = makeVar<boolean>(false);
const AccessDataAnswers = makeVar<AccessDataInput[]>([]);

const IsPreviewInventoryLoaded = makeVar<boolean>(false);
const IsPayNow = makeVar<boolean>(false);
const SelectedPaymentType = makeVar<number | null>(null);
const UrlParams = makeVar<UrlParts>({
  baseUrl: "",
  countryCode: DefaultCountryCode,
  visitedPages: [],
});
const ResponseVersionHash = makeVar<string>("");
const SelectedLanguage = makeVar<Language>(DefaultLanguage);
const RequestError = makeVar<ApolloError | GraphQLError | null | undefined>(null);
const ResponseAlreadySubmitted = makeVar<boolean>(false);

const IsLoadingTranslation = makeVar<boolean>(false);

const GainsightInitialized = makeVar<boolean>(false);

export {
  HasAccess,
  AccessDataAnswers,
  IsPreviewInventoryLoaded,
  UrlParams,
  IsPayNow,
  SelectedPaymentType,
  ResponseVersionHash,
  OrderId,
  SelectedLanguage,
  RequestError,
  ResponseAlreadySubmitted,
  IsLoadingTranslation,
  GainsightInitialized
};
