import { initReactI18next } from "react-i18next";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { DefaultLanguage } from "@src/utils/translation";

// Translation files
import common_af from "./af/common.json";
import common_ak from "./ak/common.json";
import common_am from "./am/common.json";
import common_ar from "./ar/common.json";
import common_as from "./as/common.json";
import common_ay from "./ay/common.json";
import common_az from "./az/common.json";
import common_be from "./be/common.json";
import common_bg from "./bg/common.json";
import common_bho from "./bho/common.json";
import common_bm from "./bm/common.json";
import common_bn from "./bn/common.json";
import common_bs from "./bs/common.json";
import common_ca from "./ca/common.json";
import common_ceb from "./ceb/common.json";
import common_ckb from "./ckb/common.json";
import common_co from "./co/common.json";
import common_cs from "./cs/common.json";
import common_cy from "./cy/common.json";
import common_da from "./da/common.json";
import common_de from "./de/common.json";
import common_doi from "./doi/common.json";
import common_dv from "./dv/common.json";
import common_ee from "./ee/common.json";
import common_el from "./el/common.json";
import common_en from "./en/common.json";
import common_eo from "./eo/common.json";
import common_es from "./es/common.json";
import common_et from "./et/common.json";
import common_eu from "./eu/common.json";
import common_fa from "./fa/common.json";
import common_fi from "./fi/common.json";
import common_fr from "./fr/common.json";
import common_fy from "./fy/common.json";
import common_ga from "./ga/common.json";
import common_gd from "./gd/common.json";
import common_gl from "./gl/common.json";
import common_gn from "./gn/common.json";
import common_gom from "./gom/common.json";
import common_gu from "./gu/common.json";
import common_ha from "./ha/common.json";
import common_haw from "./haw/common.json";
import common_he from "./he/common.json";
import common_hi from "./hi/common.json";
import common_hmn from "./hmn/common.json";
import common_hr from "./hr/common.json";
import common_ht from "./ht/common.json";
import common_hu from "./hu/common.json";
import common_hy from "./hy/common.json";
import common_id from "./id/common.json";
import common_ig from "./ig/common.json";
import common_ilo from "./ilo/common.json";
import common_is from "./is/common.json";
import common_it from "./it/common.json";
import common_ja from "./ja/common.json";
import common_jv from "./jv/common.json";
import common_ka from "./ka/common.json";
import common_kk from "./kk/common.json";
import common_km from "./km/common.json";
import common_kn from "./kn/common.json";
import common_ko from "./ko/common.json";
import common_kri from "./kri/common.json";
import common_ku from "./ku/common.json";
import common_ky from "./ky/common.json";
import common_la from "./la/common.json";
import common_lb from "./lb/common.json";
import common_lg from "./lg/common.json";
import common_ln from "./ln/common.json";
import common_lo from "./lo/common.json";
import common_lt from "./lt/common.json";
import common_lv from "./lv/common.json";
import common_lus from "./lus/common.json";
import common_mai from "./mai/common.json";
import common_mg from "./mg/common.json";
import common_mi from "./mi/common.json";
import common_mk from "./mk/common.json";
import common_ml from "./ml/common.json";
import common_mn from "./mn/common.json";
import common_mr from "./mr/common.json";
import common_ms from "./ms/common.json";
import common_mt from "./mt/common.json";
import common_my from "./my/common.json";
import common_ne from "./ne/common.json";
import common_nl from "./nl/common.json";
import common_no from "./no/common.json";
import common_nso from "./nso/common.json";
import common_ny from "./ny/common.json";
import common_or from "./or/common.json";
import common_om from "./om/common.json";
import common_pa from "./pa/common.json";
import common_pl from "./pl/common.json";
import common_ps from "./ps/common.json";
import common_pt from "./pt/common.json";
import common_qu from "./qu/common.json";
import common_ro from "./ro/common.json";
import common_ru from "./ru/common.json";
import common_rw from "./rw/common.json";
import common_sa from "./sa/common.json";
import common_sd from "./sd/common.json";
import common_si from "./si/common.json";
import common_sk from "./sk/common.json";
import common_sl from "./sl/common.json";
import common_sm from "./sm/common.json";
import common_sn from "./sn/common.json";
import common_so from "./so/common.json";
import common_sq from "./sq/common.json";
import common_sr from "./sr/common.json";
import common_st from "./st/common.json";
import common_su from "./su/common.json";
import common_sv from "./sv/common.json";
import common_sw from "./sw/common.json";
import common_ta from "./ta/common.json";
import common_te from "./te/common.json";
import common_tg from "./tg/common.json";
import common_th from "./th/common.json";
import common_ti from "./ti/common.json";
import common_tk from "./tk/common.json";
import common_tl from "./tl/common.json";
import common_tr from "./tr/common.json";
import common_ts from "./ts/common.json";
import common_tt from "./tt/common.json";
import common_ug from "./ug/common.json";
import common_uk from "./uk/common.json";
import common_ur from "./ur/common.json";
import common_uz from "./uz/common.json";
import common_vi from "./vi/common.json";
import common_xh from "./xh/common.json";
import common_yi from "./yi/common.json";
import common_yo from "./yo/common.json";
import common_zh_CN from "./zh-CN/common.json";
import common_zh_TW from "./zh-TW/common.json";
import common_zu from "./zu/common.json";

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    interpolation: { escapeValue: false },
    fallbackLng: DefaultLanguage.code,
    resources: {
      af: {
        translation: common_af,
      },
      ak: {
        translation: common_ak,
      },
      am: {
        translation: common_am,
      },
      ar: {
        translation: common_ar,
      },
      as: {
        translation: common_as,
      },
      ay: {
        translation: common_ay,
      },
      az: {
        translation: common_az,
      },
      be: {
        translation: common_be,
      },
      bg: {
        translation: common_bg,
      },
      bho: {
        translation: common_bho,
      },
      bm: {
        translation: common_bm,
      },
      bn: {
        translation: common_bn,
      },
      bs: {
        translation: common_bs,
      },
      ca: {
        translation: common_ca,
      },
      ceb: {
        translation: common_ceb,
      },
      ckb: {
        translation: common_ckb,
      },
      co: {
        translation: common_co,
      },
      cs: {
        translation: common_cs,
      },
      cy: {
        translation: common_cy,
      },
      da: {
        translation: common_da,
      },
      de: {
        translation: common_de,
      },
      doi: {
        translation: common_doi,
      },
      dv: {
        translation: common_dv,
      },
      ee: {
        translation: common_ee,
      },
      el: {
        translation: common_el,
      },
      en: {
        translation: common_en,
      },
      eo: {
        translation: common_eo,
      },
      es: {
        translation: common_es,
      },
      et: {
        translation: common_et,
      },
      eu: {
        translation: common_eu,
      },
      fa: {
        translation: common_fa,
      },
      fi: {
        translation: common_fi,
      },
      fr: {
        translation: common_fr,
      },
      fy: {
        translation: common_fy,
      },
      ga: {
        translation: common_ga,
      },
      gd: {
        translation: common_gd,
      },
      gl: {
        translation: common_gl,
      },
      gn: {
        translation: common_gn,
      },
      gom: {
        translation: common_gom,
      },
      gu: {
        translation: common_gu,
      },
      ha: {
        translation: common_ha,
      },
      haw: {
        translation: common_haw,
      },
      he: {
        translation: common_he,
      },
      hi: {
        translation: common_hi,
      },
      hmn: {
        translation: common_hmn,
      },
      hr: {
        translation: common_hr,
      },
      ht: {
        translation: common_ht,
      },
      hu: {
        translation: common_hu,
      },
      hy: {
        translation: common_hy,
      },
      id: {
        translation: common_id,
      },
      ig: {
        translation: common_ig,
      },
      ilo: {
        translation: common_ilo,
      },
      is: {
        translation: common_is,
      },
      it: {
        translation: common_it,
      },
      ja: {
        translation: common_ja,
      },
      jv: {
        translation: common_jv,
      },
      ka: {
        translation: common_ka,
      },
      kk: {
        translation: common_kk,
      },
      km: {
        translation: common_km,
      },
      kn: {
        translation: common_kn,
      },
      ko: {
        translation: common_ko,
      },
      kri: {
        translation: common_kri,
      },
      ku: {
        translation: common_ku,
      },
      ky: {
        translation: common_ky,
      },
      la: {
        translation: common_la,
      },
      lb: {
        translation: common_lb,
      },
      lg: {
        translation: common_lg,
      },
      ln: {
        translation: common_ln,
      },
      lo: {
        translation: common_lo,
      },
      lt: {
        translation: common_lt,
      },
      lv: {
        translation: common_lv,
      },
      lus: {
        translation: common_lus,
      },
      mai: {
        translation: common_mai,
      },
      mg: {
        translation: common_mg,
      },
      mi: {
        translation: common_mi,
      },
      mk: {
        translation: common_mk,
      },
      ml: {
        translation: common_ml,
      },
      mn: {
        translation: common_mn,
      },
      mr: {
        translation: common_mr,
      },
      ms: {
        translation: common_ms,
      },
      mt: {
        translation: common_mt,
      },
      my: {
        translation: common_my,
      },
      ne: {
        translation: common_ne,
      },
      nl: {
        translation: common_nl,
      },
      no: {
        translation: common_no,
      },
      nso: {
        translation: common_nso,
      },
      ny: {
        translation: common_ny,
      },
      or: {
        translation: common_or,
      },
      om: {
        translation: common_om,
      },
      pa: {
        translation: common_pa,
      },
      pl: {
        translation: common_pl,
      },
      ps: {
        translation: common_ps,
      },
      pt: {
        translation: common_pt,
      },
      qu: {
        translation: common_qu,
      },
      ro: {
        translation: common_ro,
      },
      ru: {
        translation: common_ru,
      },
      rw: {
        translation: common_rw,
      },
      sa: {
        translation: common_sa,
      },
      sd: {
        translation: common_sd,
      },
      si: {
        translation: common_si,
      },
      sk: {
        translation: common_sk,
      },
      sl: {
        translation: common_sl,
      },
      sm: {
        translation: common_sm,
      },
      sn: {
        translation: common_sn,
      },
      so: {
        translation: common_so,
      },
      sq: {
        translation: common_sq,
      },
      sr: {
        translation: common_sr,
      },
      st: {
        translation: common_st,
      },
      su: {
        translation: common_su,
      },
      sv: {
        translation: common_sv,
      },
      sw: {
        translation: common_sw,
      },
      ta: {
        translation: common_ta,
      },
      te: {
        translation: common_te,
      },
      tg: {
        translation: common_tg,
      },
      th: {
        translation: common_th,
      },
      tk: {
        translation: common_tk,
      },
      ti: {
        translation: common_ti,
      },
      tl: {
        translation: common_tl,
      },
      tr: {
        translation: common_tr,
      },
      ts: {
        translation: common_ts,
      },
      tt: {
        translation: common_tt,
      },
      ug: {
        translation: common_ug,
      },
      uk: {
        translation: common_uk,
      },
      ur: {
        translation: common_ur,
      },
      uz: {
        translation: common_uz,
      },
      vi: {
        translation: common_vi,
      },
      xh: {
        translation: common_xh,
      },
      yi: {
        translation: common_yi,
      },
      yo: {
        translation: common_yo,
      },
      "zh-CN": {
        translation: common_zh_CN,
      },
      "zh-TW": {
        translation: common_zh_TW,
      },
      zu: {
        translation: common_zu,
      },
    },
  }, (err, _) => {
    if (err) console.error('i18next initialization error:', err);
    else console.log('i18next initialized successfully');
  });


export { i18next };
