import { Logo } from "@src/components/atoms/Logo";
import { PageWrapper } from "@src/components/layout";
import { Flex } from "@src/components/layout/Page";
import { PageFooter } from "@src/components/molecules/pageFooter";
import { StyledHeader, StyledLabel } from "@src/components/styles";
import { Breakpoints, useBreakPoints } from "@src/customHooks";
import { useParams } from "react-router-dom";
import React, { FC, useEffect } from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";

type ErrorWrapperArgs = { screenSize: Breakpoints };

const StyledErrorWrapper = styled(Flex)<ErrorWrapperArgs>`
  margin-right: ${(props) => (props.screenSize === "desktop" ? `50px` : `25px`)};
  padding-left: 5vw;
`;

const PDFDownloadLanding: FC = () => {
  const screenSize = useBreakPoints();
  const { hashedId, countryCode } = useParams();
  const { t } = useTranslation();

  const logoDimensions = screenSize === "smallScreen" ? "100px" : "200px";

  const downloadUrl = `${process.env.RESPONSES_URL}rd/${countryCode}/${hashedId}`;

  useEffect(() => {
    window.location.href = downloadUrl;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      <Flex
        style={{ flexGrow: "1", width: "100vw", backgroundColor: "white" }}
        flexDirection="row"
        justifyContent="center"
      >
        <StyledErrorWrapper flexDirection="column" justifyContent="center" screenSize={screenSize}>
          <StyledHeader style={{ marginBottom: "10px" }}>
            {t("messages.yourDownloadIsOnItsWay")}
          </StyledHeader>
          <StyledLabel>
            <Trans i18nKey="messages.clickHereIfItDoesntStart">
              Kindly Click{" "}
              <a href={downloadUrl} rel="noreferrer" target="_blank">
                here
              </a>{" "}
              for a redo.
            </Trans>
          </StyledLabel>
        </StyledErrorWrapper>
        <Flex alignItems="center" style={{ paddingRight: "5vw" }}>
          <Logo style="circle" width={logoDimensions} height={logoDimensions} />
        </Flex>
      </Flex>
      <PageFooter />
    </PageWrapper>
  );
};

export { PDFDownloadLanding };
