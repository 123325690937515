import { useApolloClient, useReactiveVar } from "@apollo/client";
import { Flex } from "@src/components/layout/Page";
import { LoadingModal } from "@src/components/molecules/loadingModal/LoadingModal";
import { Totals } from "@src/components/molecules/totals";
import { StyledLabel } from "@src/components/styles";
import { useBreakPoints, useUrlParams } from "@src/customHooks";
import { useFormInfo } from "@src/customHooks/useFormInfo";
import { useCachedResponseVersion } from "@src/customHooks/useResponseVersion";
import { OrderId, ResponseVersionHash } from "@src/localVariables";
import { CancelTicketAnswerResult } from "@src/mutations/mutationTypes";
import { cancelTicketMutationUpdater } from "@src/mutations/updaterFunctions/updateTickets";
import { RESPONSE_VERSIONS_QUERY } from "@src/queries/responseVersions";
import { PreviewProps } from "@src/Routes";
import { Attendee, FormInfoQuery, Order, Ticket, useCancelTicketMutation } from "@src/types";
import { getOrderInfo, getTickets, hasSafePay } from "@src/utils/getters";
import { CancelTicketArgs } from "@src/utils/purchasedTickets";
import { signeeInfo } from "@src/utils/responseVersionGetters";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { TicketsPreview } from "../Preview";
import { TicketMode } from "../TicketSection";
import { CartButton } from "./CartButton";
import {
  CartModalBoundaries,
  StyledCartContainer,
  StyledModal,
  StyledModalContent,
} from "./styles";

const Cart: FC<PreviewProps> = ({ isPreview }) => {
  const { countryCode } = useUrlParams();
  const orderId = useReactiveVar(OrderId) || "";
  const responseVersionHash = useReactiveVar(ResponseVersionHash);
  const client = useApolloClient();

  const formInfo = useFormInfo();
  const responseVersionQuery = useCachedResponseVersion(responseVersionHash);

  const responseVersionQueryVariables = {
    documentId: formInfo.form?.documentId,
    countryCode,
    email: signeeInfo(responseVersionQuery.responseVersion).email,
  };

  const mutationOptions = {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: RESPONSE_VERSIONS_QUERY,
        variables: responseVersionQueryVariables,
      },
    ],
  };

  const [cancelTicket] = useCancelTicketMutation(mutationOptions);

  const onCancelTicket = ({
    ticketVersionId,
    responseAttendeeId,
    purchasedTicketIds,
  }: CancelTicketArgs): Promise<CancelTicketAnswerResult> =>
    cancelTicket({
      variables: {
        countryCode,
        documentId: formInfo.form?.documentId,
        responseVersionId: responseVersionQuery.responseVersion?.responseVersionId,
        responseAttendeeId: responseAttendeeId,
        ticketVersionId: ticketVersionId,
        purchasedTicketIds: purchasedTicketIds,
        orderId,
      },
    });

  const previewOnCancelTicket = ({
    ticketVersionId,
    responseAttendeeId,
    purchasedTicketIds,
  }: CancelTicketArgs): Promise<CancelTicketAnswerResult> => {
    const result = { CancelTicket: true };
    const updater = cancelTicketMutationUpdater(signeeInfo(responseVersionQuery.responseVersion));
    if (updater) {
      updater(
        client.cache,
        { data: result },
        {
          variables: {
            countryCode,
            documentId: formInfo.form?.documentId,
            responseVersionId: responseVersionQuery.responseVersion?.responseVersionId,
            responseAttendeeId: responseAttendeeId,
            ticketVersionId: ticketVersionId,
            purchasedTicketIds: purchasedTicketIds,
            orderId,
          },
        }
      );
    }
    return Promise.resolve({ data: result, errors: undefined });
  };

  const safePay = hasSafePay(formInfo.form);
  const tickets = getTickets(formInfo.form);
  const orderInfo = getOrderInfo(responseVersionQuery.responseVersion, false);

  return (
    <CartContainer
      tickets={tickets}
      cart={orderInfo?.cart || []}
      order={orderInfo?.latestOrder}
      hasSafePay={safePay}
      countryCode={countryCode}
      form={formInfo.form}
      responseVersionId={responseVersionQuery.responseVersion?.responseVersionId}
      onCancelTicket={!isPreview ? onCancelTicket : previewOnCancelTicket}
    />
  );
};

type CartModalProps = {
  children: React.ReactNode[];
  isLoading: boolean;
};

const CartModal: FC<CartModalProps> = ({ children, isLoading }) => {
  const { t } = useTranslation();
  const screenSize = useBreakPoints();

  return (
    <StyledModal screenSize={screenSize}>
      {isLoading ? <LoadingModal message={t("pages.tickets.loadingCart")} /> : children}
    </StyledModal>
  );
};

type CartContainerProps = {
  tickets: Ticket[];
  cart: Attendee[];
  order?: Order;
  hasSafePay?: boolean;
  isLoading?: boolean;
  countryCode: string;
  form: NonNullable<FormInfoQuery["Form"]>;
  responseVersionId: string;
  onCancelTicket?: (args: CancelTicketArgs) => Promise<CancelTicketAnswerResult>;
};

const CartContainer: FC<CartContainerProps> = ({
  tickets,
  cart,
  order,
  isLoading,
  countryCode,
  form,
  responseVersionId,
  onCancelTicket,
}) => {
  const [expandCart, setExpandCart] = useState(false);
  const screenSize = useBreakPoints();
  const totalQuantity = order?.purchasedTickets.length ?? 0;
  const hasTickets = order ? order?.purchasedTickets?.length > 0 : false;

  const closeModal = (e: React.MouseEvent) => {
    const target = e.target as Element;

    if (target?.id == "closeModal") {
      setExpandCart(false);
    }
  };

  return (
    <Flex flexDirection="column">
      <StyledCartContainer screenSize={screenSize}>
        <CartButton
          disabled={!hasTickets}
          onClick={() => setExpandCart(!expandCart)}
          quantity={totalQuantity}
        />
        {expandCart ? (
          <CartModalBoundaries id="closeModal" onClick={(e) => closeModal(e)}>
            <CartModal isLoading={isLoading ?? false}>
              <CartHeader />
              <StyledModalContent>
                {form ? (
                  <TicketsPreview
                    tickets={tickets}
                    cart={cart}
                    mode={TicketMode.CART_PREVIEW}
                    countryCode={countryCode}
                    form={form}
                    responseVersionId={responseVersionId}
                    onCancelTicket={onCancelTicket}
                  />
                ) : null}
              </StyledModalContent>
              <CartFooter>
                <Totals subtotal={order?.grandTotal || 0} showFees={false} showTotal={false} />
              </CartFooter>
            </CartModal>
          </CartModalBoundaries>
        ) : null}
      </StyledCartContainer>
    </Flex>
  );
};

const CartHeader: FC = () => {
  const { t } = useTranslation();

  return (
    <Flex alignItems="baseline" style={{ marginBottom: "10px" }}>
      <StyledLabel style={{ fontSize: "18px" }}>{t("pages.tickets.orderSummary")}</StyledLabel>
    </Flex>
  );
};

type CartFooterProps = {
  children: React.ReactNode;
};

const CartFooter: FC<CartFooterProps> = ({ children }) => {
  return (
    <Flex flexDirection="column" style={{ marginTop: "10px" }}>
      {children}
    </Flex>
  );
};

export { Cart };
