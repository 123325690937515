// Various URLs that are commonly used throughout the app should go here.

const getManualAttachmentUploadUrl = (
  countryCode: string,
  documentId: string,
  responseVersionId: string
): string => {
  return `${
    process.env.RESPONSES_URL
  }response/${countryCode.toLowerCase()}/${documentId}/${responseVersionId}/manual-attachment/upload`;
};

const getViewFileUrl = (countryCode: string, documentId: string): string => {
  return `${process.env.RESPONSES_URL}response/${countryCode.toLowerCase()}/${documentId}/media`;
};

const DefaultCountryCode = "Int";

export { getManualAttachmentUploadUrl, getViewFileUrl, DefaultCountryCode };
