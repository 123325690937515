import { ApolloError } from "@apollo/client";
import { DocumentVersion, FormInfoQuery, useFormInfoQuery } from "@src/types";
import { useUrlParams } from "./useUrlParams";

export type FormInfo = {
  form: DocumentVersion;
  loading: boolean;
  error: ApolloError | undefined;
};

export const useFormInfo = (): FormInfo => {
  const { countryCode, documentHashedId, hashedOrgId } = useUrlParams();

  const formInfoQuery = useFormInfoQuery({
    variables: { hashedId: documentHashedId || "", countryCode, hashedOrgId },
    fetchPolicy: "cache-first",
    skip: !documentHashedId || !countryCode,
  });

  const form = formInfoQuery?.data?.Form;

  return {
    form: form as NonNullable<FormInfoQuery["Form"]> as DocumentVersion,
    loading: formInfoQuery.loading,
    error: formInfoQuery.error,
  };
};
