import React, { FunctionComponent, useState } from "react";
import { PageNumber, PdfControls, PdfControlsButton, PdfViewerContainer } from "./styles";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { pdfjs } from "react-pdf";
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Spinner } from "../Spinner";
import { Icon } from "../Icon";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

type PdfViewerProps = {
  contentUri: string;
  printUri?: string;
  downloadUri?: string;
};

const PdfViewer: FunctionComponent<PdfViewerProps> = ({ contentUri, printUri, downloadUri }) => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = (e: { numPages: number }) => {
    setNumPages(e.numPages);
  };

  const onIncrementPage = () => {
    if (pageNumber < numPages) setPageNumber(pageNumber + 1);
  };

  const onDecrementPage = () => {
    if (pageNumber > 1) setPageNumber(pageNumber - 1);
  };

  return (
    <PdfViewerContainer>
      <Document
        file={contentUri}
        options={{
          renderParameters: {},
        }}
        onLoadError={(e) => console.log(e)}
        onSourceError={(e) => console.log(e)}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={<Spinner />}
      >
        <Page pageNumber={pageNumber} />
      </Document>
      {numPages > 0 ? (
        <PdfControls>
          <PdfControlsButton onClick={onDecrementPage}>
            <i className="fa fa-chevron-left" aria-hidden="true"></i>
          </PdfControlsButton>
          <PageNumber>
            {pageNumber} of {numPages}
          </PageNumber>
          <PdfControlsButton onClick={onIncrementPage}>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </PdfControlsButton>
          {printUri ? (
            <a
              href={printUri}
              target="_blank"
              title="Print"
              rel="noreferrer"
              style={{ margin: "0px 10px" }}
            >
              <Icon style="solid" icon="print" color="#cbcbcb" />
            </a>
          ) : null}

          {downloadUri ? (
            <a href={downloadUri} title="Download" style={{ margin: "0px 10px" }}>
              <Icon style="solid" icon="download" color="#cbcbcb" />
            </a>
          ) : null}
        </PdfControls>
      ) : null}
    </PdfViewerContainer>
  );
};

export { PdfViewer };
