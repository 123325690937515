import styled from "styled-components";
import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";

import { defaultTheme } from "@src/themes";
import { TableWrapper } from "@src/components/atoms/Table";

type PropsType = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const colorOrDisabled =
  (color: string) =>
  (props: PropsType): string =>
    props.disabled ? defaultTheme.colors.alias.disabled : color;

const pointerOrDisabled = (props: PropsType) => (props.disabled ? "auto" : "pointer");

const AggregatesWrapper = styled.div`
  width: 100%;
  text-align: right;
`;

const AggregatesTable = styled(TableWrapper)<{isEditing: boolean}>`
  min-width: unset;
  margin: 0 ${props => props.isEditing ? "60px" : "0"} 0 auto;
  width: auto;
  border-top: 2px solid ${defaultTheme.colors.fields.tablebordercolor};

  td {
    min-width: 105px;
    border-right: solid 2px #ececec;

    &:first-of-type {
      background-color: ${defaultTheme.colors.fields.tablebordercolor};
      font-weight: 500;
    }
  }
`;

const NonSubmittingButton = styled.button.attrs({ type: "button" })``;

const EditRowButton = styled(NonSubmittingButton)`
  background: ${colorOrDisabled(defaultTheme.colors.alias.darkPrimary)};
  color: ${defaultTheme.colors.values.white};
  padding: 5px 7px;
  font-size: 12px;
  border: solid 1px ${colorOrDisabled(defaultTheme.colors.alias.darkPrimary)};
  border-radius: ${defaultTheme.borderRadius};
  margin-right: 5px;
  cursor: ${pointerOrDisabled};
  width: 50px;
`;

const DeleteRowIcon = styled.i`
  color: ${defaultTheme.colors.values.grey};
  display: block;
  margin: 10px auto 0;
  cursor: pointer;
  width: 12px;
  font-size: 14px;
`;

const DeleteRowButton = styled(NonSubmittingButton)`
  background: ${colorOrDisabled(defaultTheme.colors.values.red)};
  color: ${defaultTheme.colors.values.white};
  padding: 5px 7px;
  font-size: 12px;
  border: solid 1px ${colorOrDisabled(defaultTheme.colors.values.red)};
  border-radius: ${defaultTheme.borderRadius};
  cursor: ${pointerOrDisabled};
  width: 50px;
`;

const AddRowButton = styled(NonSubmittingButton)`
  background: ${colorOrDisabled(defaultTheme.colors.alias.primary)};
  color: ${defaultTheme.colors.values.white};
  padding: 5px 7px;
  font-size: 12px;
  border: solid 1px ${colorOrDisabled(defaultTheme.colors.alias.primary)};
  border-radius: ${defaultTheme.borderRadius};
  cursor: ${pointerOrDisabled};
  width: 50px;
  margin-bottom: 5px;
`;

const ClearRowButton = styled(NonSubmittingButton)`
  background: ${colorOrDisabled(defaultTheme.colors.values.red)};
  color: ${defaultTheme.colors.values.white};
  padding: 5px 7px;
  font-size: 12px;
  border: solid 1px ${colorOrDisabled(defaultTheme.colors.values.red)};
  border-radius: ${defaultTheme.borderRadius};
  cursor: ${pointerOrDisabled};
  width: 50px;
`;

export {
  AggregatesWrapper,
  AggregatesTable,
  EditRowButton,
  DeleteRowButton,
  AddRowButton,
  ClearRowButton,
  DeleteRowIcon,
};
