import { defaultTheme } from "@src/themes";
import styled from "styled-components";

const PdfViewerContainer = styled.div`
  box-shadow: 4px 0px 40px 0 rgb(16 36 94 / 20%);
  margin: 25px;
  overflow: auto;
  border-radius: 8px;
  padding: 3px 3px 40px;
  background: ${defaultTheme.colors.values.white};

  canvas {
    margin: auto;
  }
`;

const PdfControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 30px 40px 0 rgb(16 36 94 / 20%);
  margin: 0 auto;
  width: fit-content;
  min-width: 150px;
  border-radius: ${defaultTheme.borderRadius};
  padding: 15px;
  text-align: center;
`;

const PdfControlsButton = styled.span`
  display: inline-block;
  padding: 5px;
  margin: 0 15px;
  border-radius: ${defaultTheme.borderRadius};
  cursor: pointer;
  font-size: 10px;

  &:hover {
    color: ${defaultTheme.colors.values.white};
    background: ${defaultTheme.colors.alias.primary};
  }
`;

const PageNumber = styled.span`
  font-size: 12px;
`;

export { PdfViewerContainer, PdfControls, PdfControlsButton, PageNumber };
