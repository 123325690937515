import { ApolloError } from "@apollo/client";
import { Organization, OrganizationByHashQuery, useOrganizationByHashQuery } from "@src/types";
import { useUrlParams } from "./useUrlParams";

export type OrganizationInfo = {
  organization: Organization;
  loading: boolean;
  error: ApolloError | undefined;
};

export const useOrganizationByHash = (hashedId: string): OrganizationInfo => {
  const { countryCode } = useUrlParams();

  const orgByHash = useOrganizationByHashQuery({
    variables: { hashedId, countryCode },
    fetchPolicy: "cache-first",
    skip: !hashedId || hashedId === "",
  });

  const organization = orgByHash?.data?.OrganizationByHash;

  return {
    organization: organization as NonNullable<OrganizationByHashQuery["OrganizationByHash"]> as Organization,
    loading: orgByHash.loading,
    error: orgByHash.error,
  };
};
