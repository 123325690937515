import React, { FC } from "react";
import NumberFormat from "react-number-format";

import { getNumericInputLogic } from "../getNumericInputLogic";
import { NumericInputWrapper, StyledVerticalInput, VerticalButton } from "../styles";
import { NumericInnerFieldProps } from "../types";

const Vertical: FC<NumericInnerFieldProps> = ({
  ticketId,
  value,
  type,
  min,
  max,
  width,
  minWidth,
  disabled,
  step,
  fixedDecimalPlaces,
  error,
  onChange,
}) => {
  const {
    isDecrementDisabled,
    isIncrementDisabled,
    useDecimalScale,
    decimalScale,
    onDecrease,
    onIncrease,
    handleChange,
  } = getNumericInputLogic({ value, type, disabled, min, max, step, fixedDecimalPlaces, onChange });

  return (
    <NumericInputWrapper
      style={{ display: "flex" }}
      flexDirection="row"
      width={width}
      minWidth={minWidth}
      error={!!error}
    >
      <VerticalButton
        disabled={isDecrementDisabled}
        onClick={onDecrease}
        data-testid={ticketId + "-PRICEDECREMENT"}
      >
        <i className={`fa-solid fa-minus`} style={{ color: "#989898" }} />
      </VerticalButton>
      <NumberFormat
        name={ticketId + "-AMOUNT"}
        disabled={disabled}
        fixedDecimalScale={useDecimalScale}
        decimalScale={decimalScale}
        value={value || ""}
        customInput={StyledVerticalInput}
        allowEmptyFormatting={true}
        prefix={type === "currency" ? "$" : undefined}
        onValueChange={handleChange}
        onKeyPress={
          !disabled
            ? (event: React.KeyboardEvent) => {
                if (event.which === 13) {
                  event.preventDefault();
                }
              }
            : null
        }
      />
      <VerticalButton
        disabled={isIncrementDisabled}
        onClick={onIncrease}
        data-testid={ticketId + "-PRICEINCREMENT"}
      >
        <i className={`fa-solid fa-plus`} style={{ color: "#989898" }} />
      </VerticalButton>
    </NumericInputWrapper>
  );
};

export { Vertical };
