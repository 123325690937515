import React, { FC, useLayoutEffect, useRef, useState } from "react";
import { useReactiveVar } from "@apollo/client";
import { ErrorComponent } from "@src/pages/Error/Error";
import { useLocation, useNavigate } from "react-router-dom";

import { useBreakPoints, useUrlParams } from "@src/customHooks";
import { HasAccess, NavSlug, ResponseVersionHash } from "@src/localVariables";
import {
  HeaderBar,
  HeaderBarContainer,
  InfoBar,
  InfoBarContainer,
  NavBar,
  NavList,
  Spacer,
  StyledLogo,
  StyledOrgName,
  StyledTitle,
  TitleContainer,
} from "../../layout/Header";
import { NavigationItem } from "./NavigationItem";
import { NavigationListItem } from "./NavigationListItem";
import { LoadingModal } from "../loadingModal/LoadingModal";
import { Cart } from "@src/pages/Tickets/Cart/Cart";
import { PreviewBar } from "../previewBar/PreviewBar";
import { Flex } from "@src/components/layout/Page";
import {
  getAttendeeFields,
  getAttendeesList,
  getSigneeFields,
  getTickets,
} from "@src/utils/getters";
import { useFormInfo } from "@src/customHooks/useFormInfo";
import { AttendeeFormRoute, PreviewProps } from "@src/Routes";
import { useCachedResponseVersion } from "@src/customHooks/useResponseVersion";
import { useOrganizationByHash } from "@src/customHooks/useOrganizationByHash";
import { Language, useGoogleTranslate } from "@src/utils/translation";
import { SelectLanguage } from "./SelectLanguage";
import { useTranslation } from "react-i18next";
import { ErrorBar } from "../errorBar/ErrorBar";

type PageHeaderProps = PreviewProps & {
  hideNavigation?: boolean;
  children?: React.ReactNode;
};

const PageHeader: FC<PageHeaderProps> = ({ isPreview, hideNavigation, children }) => {
  const { baseUrl, visitedPages, hashedOrgId } = useUrlParams();
  const { pathname } = useLocation();
  const { form, loading, error: formInfoError } = useFormInfo();
  const navigate = useNavigate();
  const screenSize = useBreakPoints();
  const hasAccess = useReactiveVar(HasAccess) || false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const headerBarRef = useRef<any>();
  const responseVersionHash = useReactiveVar(ResponseVersionHash);
  const responseVersionQuery = useCachedResponseVersion(responseVersionHash);
  const organizationInfoQuery = useOrganizationByHash(hashedOrgId ?? "");
  const { t, i18n } = useTranslation();
  const googleTranslate = useGoogleTranslate();

  const formName = googleTranslate(form?.name || "");

  if (loading || responseVersionQuery.loading || organizationInfoQuery.loading)
    return <LoadingModal />;
  if (formInfoError) return <ErrorComponent error={formInfoError} />;
  if (responseVersionQuery.error)
    return <ErrorComponent error={responseVersionQuery.error ?? ""} />;
  if (organizationInfoQuery.error)
    return <ErrorComponent error={organizationInfoQuery.error ?? ""} />;
  if (!form) return <ErrorComponent error="Form not found." />;

  const responseVersion = responseVersionQuery.responseVersion;
  const brandingOrg =
    form.useOrganizationBranding && hashedOrgId
      ? organizationInfoQuery.organization
      : form.document.organization;
  const requiresAccess = form
    ? form.restrictResponses
      ? (form.document.organization.accessFields &&
          form.document.organization.accessFields.length > 0) ||
        (form.document.organization.parent &&
          form.document.organization.parent.accessFields &&
          form.document.organization.parent.accessFields.length > 0)
      : false
    : false;

  const orgName = brandingOrg.name || "";

  const hasSigneeQuestions = getSigneeFields(form).length > 0;
  const hasAttendeeQuestions = getAttendeeFields(form).length > 0;
  const hasTickets = getTickets(form).length > 0;
  const isGeneralSignup = form.isGeneralSignup;

  const hasPermittedAttendees = getAttendeesList(responseVersion).some(
    (attendee) => !form.requiresPermission || attendee.permitted
  );

  const navItems: NavigationItem[] = [
    {
      displayText: t("navigation.generalInformation"),
      slug: "",
      isCurrent: false,
      showCart: false,
    },
  ];
  const questionSubMenuItems: NavigationItem[] = [];
  const signeeFormSlug = "signee-form";
  const attendeeFormSlug = `${AttendeeFormRoute}/0` as NavSlug;
  const reviewSlug = "review";

  if (hasSigneeQuestions)
    questionSubMenuItems.push({
      displayText: t("navigation.signeeQuestions"),
      slug: signeeFormSlug,
      isCurrent: pathname.indexOf(signeeFormSlug) > -1,
      showCart: true,
    });

  if (!isGeneralSignup && hasAttendeeQuestions && !hasTickets)
    questionSubMenuItems.push({
      displayText: t("navigation.attendeeQuestions"),
      slug: attendeeFormSlug,
      isCurrent: pathname.indexOf(AttendeeFormRoute) > -1,
      showCart: true,
      isDisabled: !hasPermittedAttendees,
    });

  if (hasTickets && !hasAttendeeQuestions)
    questionSubMenuItems.push({
      displayText: t("navigation.tickets"),
      slug: attendeeFormSlug,
      isCurrent: pathname.indexOf(AttendeeFormRoute) > -1,
      showCart: true,
      isDisabled: !hasPermittedAttendees,
    });

  if (!isGeneralSignup && hasTickets && hasAttendeeQuestions)
    questionSubMenuItems.push({
      displayText: t("navigation.attendee&Tickets"),
      slug: attendeeFormSlug,
      isCurrent: pathname.indexOf(AttendeeFormRoute) > -1,
      showCart: true,
      isDisabled: !hasPermittedAttendees,
    });

  if (hasSigneeQuestions && (!hasAttendeeQuestions || isGeneralSignup) && !hasTickets)
    navItems.push({
      displayText: t("navigation.questions"),
      slug: signeeFormSlug,
      isCurrent: pathname.indexOf(signeeFormSlug) > -1,
      showCart: true,
    });

  if (!hasSigneeQuestions && hasAttendeeQuestions && !isGeneralSignup)
    navItems.push({
      displayText: t("navigation.questions"),
      slug: attendeeFormSlug,
      isCurrent: pathname.indexOf(AttendeeFormRoute) > -1,
      showCart: true,
      isDisabled: !hasPermittedAttendees,
    });

  if (!hasSigneeQuestions && !hasAttendeeQuestions && hasTickets)
    navItems.push({
      displayText: "Tickets",
      slug: attendeeFormSlug,
      isCurrent: pathname.indexOf(AttendeeFormRoute) > -1,
      showCart: true,
      isDisabled: !hasPermittedAttendees,
    });

  if (questionSubMenuItems.length > 1)
    navItems.push({
      displayText: t("navigation.questions"),
      slug: "questions",
      subMenuItems: questionSubMenuItems,
      isCurrent: questionSubMenuItems.some((x) => x.isCurrent),
      showCart: true,
    });

  navItems.push({
    displayText: t("navigation.summary&Review"),
    slug: reviewSlug,
    isCurrent: pathname.indexOf(`/${reviewSlug}`) > -1,
    showCart: false,
  });

  // if nothing is current default the first page (the home page) to be current
  navItems[0].isCurrent = !navItems.some((x) => x.isCurrent);

  const onNavigate = (slug?: string) => {
    if (responseVersion) {
      if (slug && pathname.indexOf(`/${slug}`) < 0) navigate(`${baseUrl}/${slug}`);
      else navigate(baseUrl);
    }
  };

  const onLanguageChanged = (language: Language) => {
    i18n.changeLanguage(language.code);
  };

  const singleNavSubItems: NavigationItem[] = [];

  navItems.forEach((navItem) => {
    if (navItem.subMenuItems) {
      navItem.subMenuItems.forEach((subMenuItem) => {
        singleNavSubItems.push(subMenuItem);
      });
    } else {
      singleNavSubItems.push(navItem);
    }
  });

  const singleNav: NavigationItem = {
    displayText: t("navigation.generalInformation"),
    slug: "questions",
    isCurrent: false,
    subMenuItems: singleNavSubItems,
    showCart: false,
  };

  if (screenSize === "smallScreen") {
    navItems.splice(0, navItems.length);
    navItems.push(singleNav);
  }

  const logoOrOrgName = brandingOrg?.logo ? (
    <StyledLogo
      data-testid={"OrgLogo"}
      screenSize={screenSize}
      src={`data:image/jpg;base64,${brandingOrg.logo}`}
      title={orgName ?? ""}
    />
  ) : (
    <StyledOrgName data-testid={"OrgName"} screenSize={screenSize}>
      {orgName ?? ""}
    </StyledOrgName>
  );

  const isCartVisible =
    hasTickets &&
    (navItems.find((navItem) => navItem.isCurrent)?.showCart ||
      (screenSize === "smallScreen" &&
        singleNavSubItems.find((subNavItem) => subNavItem.isCurrent)?.showCart));

  const linkIsActive = (item: NavigationItem) =>
    !!responseVersion && !item.subMenuItems && visitedPages.includes(item.slug);

  return (
    <>
      <HeaderBar>
        <HeaderBarContainer ref={headerBarRef}>
          {isPreview && (
            <PreviewBar guardianRoleLabel={form.document.organization.roleLabels?.guardian ?? ""} />
          )}
          <Flex justifyContent="space-between">
            <InfoBar screenSize={screenSize}>
              {screenSize === "desktop" ? logoOrOrgName : null}
              <InfoBarContainer screenSize={screenSize}>
                <TitleContainer screenSize={screenSize}>
                  {screenSize === "smallScreen" ? logoOrOrgName : null}
                  <StyledTitle data-testid={"FormName"} screenSize={screenSize}>
                    {orgName} - {formName}
                  </StyledTitle>
                </TitleContainer>

                {(hasAccess || !requiresAccess) && !hideNavigation ? (
                  <NavBar screenSize={screenSize}>
                    <NavList>
                      {navItems.map((item, index) => (
                        <NavigationListItem
                          key={index}
                          id={index.toString()}
                          item={item}
                          linkIsActive={linkIsActive}
                          onClick={(slug) => onNavigate(slug)}
                        />
                      ))}
                    </NavList>
                    {screenSize === "smallScreen" ? (
                      <SelectLanguage onLanguageChanged={onLanguageChanged} />
                    ) : null}
                  </NavBar>
                ) : null}
              </InfoBarContainer>
            </InfoBar>
            {screenSize === "desktop" ? (
              <SelectLanguage onLanguageChanged={onLanguageChanged} />
            ) : null}
            {isCartVisible ? <Cart isPreview={isPreview} /> : null}
          </Flex>
        </HeaderBarContainer>
        <ErrorBar />
        {children}
      </HeaderBar>
      <HeaderBarSpacer headerRef={headerBarRef} />
    </>
  );
};

type HeaderBarSpacerProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  headerRef: React.MutableRefObject<any>;
};

// Fills the space underneath the variable-height header so we can better position the page content
const HeaderBarSpacer: FC<HeaderBarSpacerProps> = ({ headerRef }) => {
  const [height, setHeight] = useState(0);

  const headerHeight = headerRef.current ? headerRef.current.clientHeight : 135;

  useLayoutEffect(() => {
    setHeight(headerHeight);
  }, [headerHeight]);

  return <Spacer height={height} />;
};

export { PageHeader };
