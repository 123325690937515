import omit from "lodash/omit";
import { Answer, AnswerInput } from "@src/types";
import {
  ConditionalValue,
  EquationValue,
  InitialValuesObject,
  InnerFields,
  IntegratedDataFieldValue,
  RepeatableValue,
} from "./formValuesTypes";
import { getInnerFieldAnswersFromRepeatableValue } from "./repeatables";

const getAnswersFromInnerFields = (value: InnerFields): AnswerInput[] =>
  Object.values(value) as AnswerInput[];

const getAnswersFromConditionalValue = (value: ConditionalValue): AnswerInput[] => {
  const innerFields = omit(value, ["answer"]);
  const answers = Object.values(innerFields).flatMap((possibleFieldValue) =>
    getAnswersFromPossibleFieldValue(possibleFieldValue)
  );

  return [value.answer, ...answers];
};

const getAnswersFromRepeatableValue = (value: RepeatableValue): AnswerInput[] => {
  const innerFields = omit(value, ["answers", "isDirty"]);
  const innerFieldAnswers = getInnerFieldAnswersFromRepeatableValue(innerFields);

  return [...value.answers, ...innerFieldAnswers];
};

const getAnswersFromEquationValue = (value: EquationValue): AnswerInput[] => value.allAnswers;

const getAnswersFromPossibleFieldValue = (possibleFieldValue: unknown): AnswerInput[] => {
  // Only Conditional and IntegratedData values have an "answer" property
  if ((possibleFieldValue as ConditionalValue | IntegratedDataFieldValue).answer) {
    return getAnswersFromConditionalValue(possibleFieldValue as ConditionalValue);
  }

  // Only Repeatable values have an "answers" property
  if ((possibleFieldValue as RepeatableValue).answers) {
    return getAnswersFromRepeatableValue(possibleFieldValue as RepeatableValue);
  }

  // Only Equation values have an "allAnswers" property
  if ((possibleFieldValue as EquationValue).allAnswers) {
    return getAnswersFromEquationValue(possibleFieldValue as EquationValue);
  }

  // If there's no "questionId" or "fieldId" property, it's an Address value
  if (
    typeof (possibleFieldValue as AnswerInput).questionId === "undefined" &&
    typeof (possibleFieldValue as AnswerInput).fieldId === "undefined"
  ) {
    return getAnswersFromInnerFields(possibleFieldValue as InnerFields);
  }

  // Otherwise, it's a simple AnswerInput
  return [possibleFieldValue] as AnswerInput[];
};

/**
 * Checks Formik state looking for answers that were not submitted.
 *
 * @param {InitialValuesObject} values
 * @param {string[]} omitFieldKeys
 * @returns {AnswerInput[]}
 */
const checkUnsubmittedAnswers = (values: InitialValuesObject, omitFieldKeys?: string[]) => {
  values = omit(values, omitFieldKeys || []);

  const answersArray = Object.values(values).flatMap((value) =>
    getAnswersFromPossibleFieldValue(value)
  );

  return answersArray
    .filter((answer) => !answer.answerId && !!answer.value)
    .map((x) => omit(x, "answerId"));
};

type DeleteNestedAnswersArgs = {
  clearedFields: InitialValuesObject;
  value: ConditionalValue;
  deleteAnswer?: (answer: AnswerInput) => Promise<unknown>;
};

const deleteNestedAnswers = ({ clearedFields, value, deleteAnswer }: DeleteNestedAnswersArgs) => {
  // Delete any nested answers
  for (const key in clearedFields) {
    const nestedAnswer = value[key] as Answer | Record<string, Answer>;
    const fieldId = nestedAnswer.fieldId;

    if (fieldId && nestedAnswer && nestedAnswer.value) {
      deleteAnswer && deleteAnswer(nestedAnswer as Answer);
    } else {
      for (const addressKey in nestedAnswer) {
        const addressAswer = nestedAnswer as Record<string, Answer>;
        const innerAnswer = addressAswer[addressKey] as Answer;

        if (innerAnswer && innerAnswer.value) {
          deleteAnswer && deleteAnswer(innerAnswer);
        }
      }
    }
  }
};

export {
  getAnswersFromPossibleFieldValue,
  checkUnsubmittedAnswers,
  deleteNestedAnswers,

  // Testing exports
  getAnswersFromInnerFields as _getAnswersFromInnerFields,
  getAnswersFromRepeatableValue as _getAnswersFromRepeatableValue,
  getAnswersFromConditionalValue as _getAnswersFromConditionalValue,
  getAnswersFromEquationValue as _getAnswersFromEquationValue,
};
