import React, { FC, useEffect, useState } from "react";
import omit from "lodash/omit";

import { AnswerInput } from "@src/types";
import { parseStringIntoArray } from "@src/utils/typeConverters";
import { FieldWrapper, StyledCheckbox, StyledLabel, StyledRequiredLabel } from "../../styles";
import { MutlipleChoiceProps } from "../types";
import { FieldError } from "@src/components/atoms/FieldError";
import { useGoogleTranslate } from "@src/utils/translation";
import { Label } from "@src/components/atoms/Label";

const CheckboxField: FC<MutlipleChoiceProps> = ({
  id,
  label,
  value,
  isRequired,
  options,
  pinnedValueOptions,
  sort,
  setValue,
  saveAnswer,
}) => {
  const [answerId, setAnswerId] = useState<string>(value.answerId ?? "");

  const googleTranslate = useGoogleTranslate();
  let selectedOptions = value?.value ? parseStringIntoArray(value.value) : [];

  // for fields like integrated fields it's possible the stored answer is no longer
  // an option to select from
  selectedOptions = selectedOptions.filter((x) => options.indexOf(x) > -1);

  if (sort) {
    options.sort();
  }

  if (pinnedValueOptions) {
    options = options.filter((x) => !pinnedValueOptions.includes(x));
    options.unshift(...pinnedValueOptions);
  }

  // Once we get an answerId, add it to the answer.
  // This id will be reused whenever a new answer is created, so this should run only once
  // after render.
  useEffect(() => {
    if (answerId) {
      setValue({ ...value, answerId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerId]);

  const handleChange = (option: string) => async () => {
    const updatedOptions = selectedOptions.includes(option)
      ? selectedOptions.filter((selectedOption) => selectedOption !== option)
      : [...selectedOptions, option];

    const newAnswer: AnswerInput = omit(
      {
        ...value,
        value: JSON.stringify(updatedOptions),
      },
      "__typename"
    );

    setValue(newAnswer);

    const newAnswerId = saveAnswer ? await saveAnswer(newAnswer) : "";

    // Triggers the useEffect that adds the answerId to the answer.
    newAnswerId !== answerId && setAnswerId(newAnswerId);
  };

  return value ? (
    <FieldWrapper id={id}>
      {isRequired ? <StyledRequiredLabel /> : null}
      <Label>{label}</Label>

      {options.map((option) => (
        <StyledLabel title={option} key={option}>
          <StyledCheckbox
            data-testid={id + "-" + option}
            value={option}
            checked={selectedOptions.includes(option)}
            onChange={handleChange(option)}
          />
          {googleTranslate(option)}
        </StyledLabel>
      ))}

      <FieldError id={id} name={id} />
    </FieldWrapper>
  ) : null;
};

export { CheckboxField };
