import React from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { i18next } from "@src/translations";
import common_en from "@src/translations/en/common.json";

import { getYears } from "@src/utils/dateTime";
import { Icon } from "@src/components/atoms/Icon";
import { DateFormats, ErrorObject } from "../types";
import { DateOption } from "../../atoms/DateOption";
import { AnswerInput } from "@src/types";
import { DateFillWrapper, FieldWrapper, StyledRequiredLabel } from "@src/components/styles";
import { FieldError } from "@src/components/atoms/FieldError";
import { Normalize } from "react-i18next";
import { Label } from "@src/components/atoms/Label";
import { useGoogleTranslate } from "@src/utils/translation";

dayjs.extend(customParseFormat);

interface _Props {
  id: string;
  minDate?: dayjs.Dayjs;
  isRequired?: boolean;
  format?: string;
  answer: AnswerInput;
  readonly?: boolean;
  disabled?: boolean;
  years?: string[];
  hideState?: boolean;
  allowChangeOnInvalid?: boolean;
  label: string;
  error?: ErrorObject | string;
  showClearIcon?: boolean;

  setValue?(value: AnswerInput): void;
  saveAnswer?(value: AnswerInput): Promise<string>;
  googleTranslate: (text: string) => string;
}

interface State {
  year: number | null;
  month: number | null;
  day: number | null;
  relativeMode: boolean;
}

export class _DateField extends React.Component<_Props, State> {
  private years: string[];
  private months: string[];

  public constructor(props: _Props) {
    super(props);

    this.years = props.years ? props.years : getYears();
    this.months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

    const value = this.getValue();

    this.state = {
      year: value && value.isValid() ? value.year() : null,
      month: value && value.isValid() ? value.month() + 1 : null,
      day: value && value.isValid() ? value.date() : null,
      relativeMode: false,
    };
  }

  componentDidUpdate(prevProps: { answer: AnswerInput }) {
    if (prevProps.answer !== this.props.answer) {
      const value = this.getValue();

      this.setState({
        year: value && value.isValid() ? value.year() : null,
        month: value && value.isValid() ? value.month() + 1 : null,
        day: value && value.isValid() ? value.date() : null,
        relativeMode: false,
      });
    }
  }

  public render(): JSX.Element {
    const stateValue = this.getValueState();
    const value = this.getValue();
    const days = this.getDays();

    return (
      <FieldWrapper>
        {this.props.isRequired ? <StyledRequiredLabel /> : null}
        <Label>{this.props.label}</Label>

        <DateFillWrapper disabled={this.props.disabled}>
          <div className={"date-selection-wrapper" + (this.props.isRequired ? " required" : "")}>
            {this.props.showClearIcon && (this.state.year || this.state.month || this.state.day) ? (
              <Icon size="12px" style="solid" icon="trash" onClick={() => this.removeAnswer()} />
            ) : null}

            <DateOption
              id={this.props.id}
              class="year"
              selectedOption={this.state.year?.toString()}
              options={this.years}
              disabled={this.props.disabled}
              onChange={(x) => this.onYearChanged(x)}
              label={this.t("labels.year")}
              placeholder="yyyy"
            />

            <span className="date-separator">/</span>

            <DateOption
              id={this.props.id}
              class="month"
              selectedOption={
                this.state.month
                  ? this.state.month < 10
                    ? `0${this.state.month}`
                    : this.state.month?.toString()
                  : null
              }
              options={this.months}
              disabled={this.props.disabled}
              onChange={(x) => this.onMonthChanged(x)}
              label={this.t("labels.month")}
              placeholder="mm"
            />

            <span className="date-separator">/</span>

            <DateOption
              id={this.props.id}
              class="day"
              selectedOption={
                this.state.day
                  ? this.state.day < 10
                    ? `0${this.state.day}`
                    : this.state.day?.toString()
                  : null
              }
              options={days}
              disabled={this.props.disabled}
              onChange={(x) => this.onDayChanged(x)}
              label={this.t("labels.day")}
              placeholder="dd"
            />
          </div>

          {!this.props.hideState ? (
            value && value.isValid() ? (
              <p data-testid={"selected-message"} className="selected-message">
                {this.t("messages.youHaveSelected", {
                  dateTime: this.props.googleTranslate(value.format(this.getFormat())),
                })}
              </p>
            ) : stateValue.isValid() && !this.meetsMinimum(stateValue) ? (
              <p
                data-testid={"selected-message"}
                className={"selected-message" + (this.props.isRequired ? " required" : "")}
              >
                {this.t("messages.selectionDoesNotExceed", {
                  date: stateValue.format(this.getFormat()),
                  minDate: this.props.minDate ? this.props.minDate.format(this.getFormat()) : "",
                })}
              </p>
            ) : (
              <p
                data-testid={"selected-message"}
                className={"selected-message" + (this.props.isRequired ? " required" : "")}
              >
                {this.t("messages.noDateSelected")}
              </p>
            )
          ) : null}
        </DateFillWrapper>
        <FieldError id={this.props.id?.toString()} name={this.props.id?.toString()} />
      </FieldWrapper>
    );
  }

  private removeAnswer() {
    this.setState(
      {
        year: null,
        month: null,
        day: null,
      },
      () => {
        const emptyAnswer = {
          ...this.props.answer,
          value: "",
        };

        this.props.setValue && this.props.setValue(emptyAnswer);
        this.props.saveAnswer && this.props.saveAnswer(emptyAnswer);
      }
    );
  }

  private onYearChanged(year: string) {
    this.setState({ year: parseInt(year, 10) }, () => this.modified());
  }

  private onMonthChanged(month: string) {
    this.setState({ month: parseInt(month, 10) }, () => this.modified());
  }

  private onDayChanged(day: string) {
    this.setState({ day: parseInt(day, 10) }, () => this.modified());
  }

  private isValid(): boolean {
    const invalidStates = [null, undefined, NaN];

    return (
      !invalidStates.includes(this.state.year) &&
      !invalidStates.includes(this.state.month) &&
      !invalidStates.includes(this.state.day)
    );
  }

  private getValue(): dayjs.Dayjs {
    const currentValue = this.props.answer ? this.props.answer.value : "";
    let parsedValue = dayjs(currentValue, this.getFormat());

    if (!parsedValue.isValid()) {
      parsedValue = dayjs(currentValue);
    }

    return parsedValue;
  }

  private getValueState(): dayjs.Dayjs {
    const formattedMonth =
      this.state.month && this.state.month < 10 ? `0${this.state.month}` : this.state.month;
    const formattedDay =
      this.state.day && this.state.day < 10 ? `0${this.state.day}` : this.state.day;

    return dayjs(`${this.state.year}${formattedMonth}${formattedDay}`);
  }

  private getDays() {
    const days: string[] = [];

    if (this.state.year && this.state.month) {
      const daysInMonth = new Date(this.state.year, this.state.month, 0).getDate();

      for (let index = 1; index <= daysInMonth; index++) {
        if (index < 10) {
          days.push(`0${index}`);
        } else {
          days.push(index.toString());
        }
      }
    }

    return days;
  }

  private getFormat() {
    return Object.values(DateFormats).includes(this.props.format ?? "")
      ? this.props.format
      : DateFormats.MonthDayYear;
  }

  private meetsMinimum(currentValue: dayjs.Dayjs): boolean {
    if (this.props.minDate === undefined) {
      return true;
    }

    return this.props.minDate < currentValue;
  }

  private async modified() {
    const isValid = this.isValid();

    if (!this.props.readonly && (isValid || this.props.allowChangeOnInvalid)) {
      const value = this.getValueState();

      if (this.meetsMinimum(value)) {
        const format = this.getFormat();

        const newAnswer = {
          ...this.props.answer,
          value: value.format(format),
        };

        this.props.setValue && this.props.setValue(newAnswer);

        const answerId = this.props.saveAnswer ? await this.props.saveAnswer(newAnswer) : "";
        this.props.setValue && this.props.setValue({ ...newAnswer, answerId });
      }
    }
  }

  private getError() {
    if (typeof this.props.error === "string" || this.props.error instanceof String) {
      return this.props.error as string;
    } else {
      return this.props.error?.value || "";
    }
  }

  private t(translationKey: Normalize<typeof common_en>, variables?: Record<string, unknown>) {
    return i18next.t(translationKey, variables);
  }
}

interface Props {
  id: string;
  minDate?: dayjs.Dayjs;
  isRequired?: boolean;
  format?: string;
  answer: AnswerInput;
  readonly?: boolean;
  disabled?: boolean;
  years?: string[];
  hideState?: boolean;
  allowChangeOnInvalid?: boolean;
  label: string;
  error?: ErrorObject | string;
  showClearIcon?: boolean;

  setValue?(value: AnswerInput): void;
  saveAnswer?(value: AnswerInput): Promise<string>;
}

// Passes a hook down to the class component
export const DateField = (props: Props) => {
  const googleTranslate = useGoogleTranslate();
  return <_DateField {...props} googleTranslate={googleTranslate} />;
};
