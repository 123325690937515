import { ApolloError } from "@apollo/client";
import { ResponseQuery, ResponseVersion, useResponseQuery } from "@src/types";
import { PreviewHash } from "@src/utils/preview";

export type ResponseVersionInfo = {
  responseVersion: NonNullable<ResponseQuery["Response"]>;
  loading: boolean;
  error: ApolloError | undefined;
};

export const useCachedResponseVersion = (hash: string): ResponseVersionInfo => {

  const query = useResponseQuery({
    variables: { hash },
    fetchPolicy: hash === PreviewHash ? "cache-only" : "cache-first",
    skip: !hash
  });

  const responseVersion = query?.data?.Response;

  return {
    responseVersion: responseVersion as NonNullable<ResponseQuery["Response"]> as ResponseVersion,
    loading: query.loading,
    error: query.error,
  };
};
