import { useField } from "formik";
import React, { FC } from "react";

import { ErrorBoundary } from "@src/components/atoms/ErrorBoundary";
import { StyledDivider } from "@src/components/styles";
import { AnswerInput, Field, FieldType } from "@src/types";
import {
  getEquationAggregateQuestions,
  getEquationCalculatedQuestions,
  getEquationInputQuestions,
} from "@src/utils/equations";
import { PossibleFieldValue } from "@src/utils/formValuesTypes";
import {
  getDateFormat,
  getFieldKey,
  getHeaderText,
  getLabel,
  getPhoneFormat,
  getPinnedValueOptions,
  getSubheaderText,
  getValueOptions,
} from "@src/utils/getters";
import { useFieldValidation } from "@src/utils/validation";
import {
  AddressField,
  CheckboxField,
  DateField,
  DropdownConditionalField,
  DropdownField,
  EquationsField,
  HeaderField,
  ParagraphField,
  PhoneField,
  RatingField,
  RepeatableQuestionField,
  TextAreaField,
  TextField,
  TimeField,
  UploadField,
  YesNoConditionalField,
  YesNoField,
} from "..";
import { IntegratedDataField } from "../IntegratedDataField";
import { getAddressInnerFields } from "@src/utils/address";
import { useGoogleTranslate } from "@src/utils/translation";

export type FieldSelectorProps = {
  field: Field;
  containerQuestionId?: string;
  isCollapsed?: boolean;
  uploadUrl?: string;
  viewFileUrl?: string;
  hideLabel?: boolean;
  isTicketQuestion?: boolean;
  saveAnswer?: (answer: AnswerInput) => Promise<string>;
  deleteAnswer?: (answer: AnswerInput) => Promise<unknown>;
  handleToggleCollapse?: (id: string) => void;
  fileSelected?: (fieldId: string, questionId: string, file: File) => void;
  fileRemoved?: (fieldId: string, questionId: string) => void;
};

const FieldSelector: FC<FieldSelectorProps> = ({
  field,
  containerQuestionId,
  isCollapsed,
  uploadUrl,
  viewFileUrl,
  hideLabel,
  isTicketQuestion,
  saveAnswer,
  deleteAnswer,
  handleToggleCollapse,
  fileSelected,
  fileRemoved,
}) => {
  const googleTranslate = useGoogleTranslate();
  const id = getFieldKey(field, containerQuestionId);
  const label = hideLabel ? "" : googleTranslate(getLabel(field));
  const isRequired = field.question?.isRequired ?? false;
  const placeHolder = field.question?.placeHolderText
    ? googleTranslate(field.question?.placeHolderText)
    : "";

  const validate = useFieldValidation(field);
  const [input, meta, helpers] = useField({ name: id, validate });
  const error = meta.error;

  const setValue = async (value: PossibleFieldValue) => {
    value && helpers.setValue(value);
  };

  if (field.type !== FieldType.Header && field.type !== FieldType.Section && isCollapsed) {
    return null;
  }

  switch (field.type) {
    case FieldType.MultiFieldConditional:
    case FieldType.Conditional:
      return (
        <ErrorBoundary name="YesNoConditionalField">
          <YesNoConditionalField
            id={id}
            label={label}
            value={input.value}
            isRequired={isRequired}
            uploadUrl={uploadUrl}
            viewFileUrl={viewFileUrl}
            setValue={setValue}
            fields={field.fields ?? []}
            saveAnswer={saveAnswer}
            deleteAnswer={deleteAnswer}
            error={error}
          />
        </ErrorBoundary>
      );

    case FieldType.SelectConditional:
      return (
        <ErrorBoundary name="DropdownConditionalField">
          <DropdownConditionalField
            id={id}
            label={label}
            value={input.value}
            isRequired={isRequired}
            uploadUrl={uploadUrl}
            viewFileUrl={viewFileUrl}
            setValue={setValue}
            field={field}
            saveAnswer={saveAnswer}
            deleteAnswer={deleteAnswer}
            error={error}
          />
        </ErrorBoundary>
      );

    case FieldType.RepeatableQuestion:
      return (
        <ErrorBoundary name="RepeatableQuestionField">
          <RepeatableQuestionField
            id={id}
            label={label}
            value={input.value}
            field={field}
            uploadUrl={uploadUrl}
            viewFileUrl={viewFileUrl ?? ""}
            isTicketQuestion={isTicketQuestion}
            setValue={setValue}
            saveAnswer={saveAnswer}
            deleteAnswer={deleteAnswer}
            error={error}
          />
        </ErrorBoundary>
      );

    case FieldType.Select:
    case FieldType.Dropdown:
      return (
        <ErrorBoundary name="SelectField">
          <DropdownField
            id={id}
            label={label}
            value={input.value}
            isRequired={isRequired}
            setValue={setValue}
            pinnedValueOptions={getPinnedValueOptions(field)}
            options={getValueOptions(field)}
            sort={field.question?.sortValueOptions ?? false}
            saveAnswer={saveAnswer}
            error={error}
            touched={meta.touched}
          />
        </ErrorBoundary>
      );

    case FieldType.Multiline:
      return (
        <ErrorBoundary name="TextAreaField">
          <TextAreaField
            id={id}
            label={label}
            value={input.value}
            isRequired={isRequired}
            placeHolderText={placeHolder}
            maxLength={field.question?.characterLimit ?? undefined}
            setValue={setValue}
            saveAnswer={saveAnswer}
            error={error}
            touched={meta.touched}
          />
        </ErrorBoundary>
      );

    case FieldType.Multiselect:
    case FieldType.Selectmultiple:
      return (
        <ErrorBoundary name="CheckboxField">
          <CheckboxField
            id={id}
            label={label}
            value={input.value}
            isRequired={isRequired}
            setValue={setValue}
            options={getValueOptions(field)}
            pinnedValueOptions={getPinnedValueOptions(field)}
            sort={field.question?.sortValueOptions ?? false}
            saveAnswer={saveAnswer}
            error={error}
            touched={meta.touched}
          />
        </ErrorBoundary>
      );

    case FieldType.Singleline:
    case FieldType.PostalZip:
      return (
        <ErrorBoundary name="TextField">
          <TextField
            id={id}
            label={label}
            value={input.value}
            isRequired={isRequired}
            placeHolderText={placeHolder}
            maxLength={field.question?.characterLimit ?? undefined}
            setValue={setValue}
            saveAnswer={saveAnswer}
            error={error}
            touched={meta.touched}
          />
        </ErrorBoundary>
      );

    case FieldType.Yesno:
      return (
        <ErrorBoundary name="YesNoField">
          <YesNoField
            id={id}
            label={label}
            value={input.value}
            isRequired={isRequired}
            setValue={setValue}
            saveAnswer={saveAnswer}
            error={error}
            touched={meta.touched}
          />
        </ErrorBoundary>
      );

    case FieldType.Header:
    case FieldType.Section:
      return (
        <ErrorBoundary name="HeaderField">
          <HeaderField
            fieldId={field?.id ?? ""}
            headerText={googleTranslate(getHeaderText(field))}
            subheaderText={googleTranslate(getSubheaderText(field))}
            collapsible={field.collapsable}
            isCollapsed={isCollapsed ?? false}
            handleToggleCollapse={handleToggleCollapse}
          />
        </ErrorBoundary>
      );

    case FieldType.Divider:
      return <StyledDivider />;

    case FieldType.Descriptivetext:
      return (
        <ErrorBoundary name="ParagraphField">
          <ParagraphField text={field.question?.placeHolderText ?? ""} />
        </ErrorBoundary>
      );

    case FieldType.Address:
      return (
        <ErrorBoundary name="AddressField">
          <AddressField
            id={id}
            label={label}
            fields={getAddressInnerFields(field)}
            value={input.value}
            error={error as unknown as Record<string, string>}
            setValue={setValue}
            saveAnswer={saveAnswer}
          />
        </ErrorBoundary>
      );

    case FieldType.Date:
      return (
        <ErrorBoundary name="DateField">
          <DateField
            showClearIcon
            id={id}
            label={label}
            answer={input.value}
            isRequired={isRequired}
            error={error}
            format={getDateFormat(field)}
            setValue={setValue}
            saveAnswer={saveAnswer}
          />
        </ErrorBoundary>
      );

    case FieldType.Phonenumber:
      return (
        <ErrorBoundary name="PhoneField">
          <PhoneField
            id={id}
            label={label}
            value={input.value}
            isRequired={isRequired}
            placeHolderText={placeHolder}
            phoneFormat={getPhoneFormat(field)}
            setValue={setValue}
            saveAnswer={saveAnswer}
            error={error}
            touched={meta.touched}
          />
        </ErrorBoundary>
      );

    case FieldType.Rating:
      return (
        <ErrorBoundary name="RatingField">
          <RatingField
            id={id}
            label={label}
            value={input.value}
            isRequired={isRequired}
            setValue={setValue}
            saveAnswer={saveAnswer}
            error={error}
            touched={meta.touched}
          />
        </ErrorBoundary>
      );

    case FieldType.Time:
      return (
        <ErrorBoundary name="TimeField">
          <TimeField
            showClearIcon
            id={id}
            label={label}
            value={input.value}
            isRequired={isRequired}
            setValue={setValue}
            saveAnswer={saveAnswer}
            error={error}
          />
        </ErrorBoundary>
      );

    case FieldType.Upload:
      return (
        <ErrorBoundary name="UploadField">
          <UploadField
            id={id}
            fieldId={field.id ?? ""}
            questionId={field.question?.id ?? ""}
            label={label}
            answer={input.value}
            isRequired={isRequired}
            uploadUrl={uploadUrl}
            viewFileUrl={viewFileUrl}
            saveAnswer={saveAnswer}
            deleteAnswer={deleteAnswer}
            setValue={setValue}
            error={error}
            fileSelected={fileSelected}
            fileRemoved={fileRemoved}
          />
        </ErrorBoundary>
      );

    case FieldType.Equations:
      return saveAnswer && deleteAnswer ? (
        <ErrorBoundary name="EquationsField">
          <EquationsField
            id={id}
            field={field}
            label={label}
            value={input.value}
            error={error}
            setValue={setValue}
            inputQuestions={getEquationInputQuestions(field)}
            calculatedQuestions={getEquationCalculatedQuestions(field)}
            aggregateQuestions={getEquationAggregateQuestions(field)}
            saveAnswer={saveAnswer}
            deleteAnswer={deleteAnswer}
          />
        </ErrorBoundary>
      ) : null;

    case FieldType.IntegratedData:
      return saveAnswer && field.fields && field.fields.length === 1 ? (
        <ErrorBoundary name="IntegratedDataField">
          <IntegratedDataField
            id={id}
            label={label}
            field={field.fields[0]}
            isRequired={isRequired}
            readOnly={field.readonly}
            originalValue={input.value.answer}
            saveAnswer={saveAnswer}
            error={error}
          />
        </ErrorBoundary>
      ) : null;

    default:
      return null;
  }
};

export { FieldSelector };
