import { TFunction, useTranslation } from "react-i18next";
import * as Yup from "yup";
import { ObjectShape } from "yup/lib/object";

import { MAX_LENGTH, MIN_LENGTH } from "@src/constants/validation";

const textFieldValidation = (t: TFunction) =>
  Yup.string()
    .min(
      MIN_LENGTH,
      t("validation.minCharacters", {
        length: MIN_LENGTH,
      })
    )
    .max(
      MAX_LENGTH,
      t("validation.maxCharacters", {
        length: MAX_LENGTH,
      })
    )
    .required(t("validation.required"));

const useStudentInfoValidation = (): Yup.ObjectSchema<ObjectShape> => {
  const { t } = useTranslation();

  return Yup.object().shape({
    requiresPermission: Yup.boolean(),
    attendees: Yup.array()
      .of(
        Yup.object().shape({
          firstName: textFieldValidation(t),
          lastName: textFieldValidation(t),
          permitted: Yup.string()
            .nullable()
            .when("$requiresPermission", {
              is: true,
              then: (schema) => schema.required(t("validation.required")),
            }),
        })
      )
      .min(1, t("validation.minEntries", { length: 1 })),
  });
};

export { useStudentInfoValidation };
