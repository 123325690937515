import { StyledLabel } from "@src/components/styles";
import { isHTMLlabel } from "@src/utils/htmlCharcodes";
import React, { FC } from "react";
import { Froala } from "../Froala";

type LabelProps = {
  children: string;
};

const Label: FC<LabelProps> = ({ children }) => {
  const isHTML = isHTMLlabel(children);

  return isHTML ? <Froala isFieldLabel text={children} /> : <StyledLabel>{children}</StyledLabel>;
};

export { Label };
