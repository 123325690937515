import { Button } from "@src/components/atoms/Button";
import React, { FC } from "react";
import styled from "styled-components";

type StyledButtonProps = {
  disabled: boolean;
};

const StyledButton = styled(Button)<StyledButtonProps>`
  ${({ disabled, theme }) => `
    min-width: 35px;
    height: 30px;

    border-radius: 7px;
    border-style: none;
    border-color: ${theme.colors.values.lightblue};

    margin-left: auto;
    margin-top: 10px;
    margin-right: 15px;
    padding: 5px;
    position: relative;

    background: ${disabled ? "#b7b7b7" : theme.colors.values.lightblue};

    text-align: center;
    font-size: 12px;
    font-family: ${theme.font.default};
    color: ${theme.colors.values.white};
    cursor: ${disabled ? "default" : "pointer"};

    @media (min-width: 900px) {
      font-size: 14px;
      min-width: 45px;
      min-height: 40px;
      padding: 8px 16px;
      height: 40px;
    }
  `}
`;

export type CartButtonProps = {
  quantity: number;
  disabled: boolean;
  onClick: () => void;
};

const QuantityIcon = styled.div`
  ${({ theme }) => `
    border-radius: 50%;
    position: absolute;
    color: white;
    left: -8px;
    bottom: -6px;
    z-index: 3;
    background-color: ${theme.colors.alias.primary};
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    font-size: 10px;
    line-height: 20px;
    font-family: ${theme.font.default}
  `}
`;

const CartButton: FC<CartButtonProps> = ({ quantity, disabled, onClick }) => {
  return (
    <StyledButton disabled={disabled} onClick={onClick}>
      {!disabled ? <QuantityIcon>{quantity ?? 0}</QuantityIcon> : null}
      <i className={"fa-solid fa-cart-shopping-fast"}></i>
    </StyledButton>
  );
};

export { CartButton };
