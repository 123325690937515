import { Button } from "@src/components/atoms/Button/Button";
import { FieldSelector } from "@src/components/fields";
import { ContentWrapper, PageWrapper, Push } from "@src/components/layout/Page";
import { PageFooter } from "@src/components/molecules/pageFooter";
import { PageHeader } from "@src/components/molecules/pageHeader/PageHeader";
import {
  MatchFailed,
  StyledInlineRequiredLabel,
  StyledRequiredInstructions,
  StyledSectionHeader,
  StyledSectionInstructions,
} from "@src/components/styles";
import { AnswerInput, Field } from "@src/types";
import { InitialValuesObject } from "@src/utils/formValuesTypes";
import { Formik } from "formik";
import React, { Fragment } from "react";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useBreakPoints } from "@src/customHooks";
import { TranslateFormErrors } from "@src/customHooks/useTranslateFormErrors";

type AccessDataFormProps = {
  saveAnswer?: (answer: AnswerInput) => Promise<string>;
  removeAnswer?: (answer: AnswerInput) => Promise<unknown>;
  submit: () => void;
  accessFields: Field[];
  answers: InitialValuesObject;
  hasAccess: boolean;
  showValidation: boolean;
};

const AccessDataForm: FunctionComponent<AccessDataFormProps> = ({
  submit,
  accessFields,
  answers,
  saveAnswer,
  removeAnswer,
  hasAccess,
  showValidation,
}) => {
  const { t } = useTranslation();
  const screenSize = useBreakPoints();

  return (
    <>
      <PageWrapper>
        <PageHeader />
        <ContentWrapper screenSize={screenSize}>
          <StyledSectionHeader>{t("pages.accessData.title")}</StyledSectionHeader>
          <StyledSectionInstructions>{t("pages.accessData.paragraph1")}</StyledSectionInstructions>
          <StyledSectionInstructions>{t("pages.accessData.paragraph2")}</StyledSectionInstructions>
          <StyledSectionInstructions>{t("pages.accessData.paragraph3")}</StyledSectionInstructions>
          <StyledRequiredInstructions>
            <StyledInlineRequiredLabel />
            {t("validation.required", { field: "" })}
          </StyledRequiredInstructions>
          <Formik initialValues={answers} onSubmit={() => console.log()}>
            {() => (
              <TranslateFormErrors>
                <Fragment>
                  {accessFields.map((field) => (
                    <FieldSelector
                      key={field.id}
                      field={field}
                      saveAnswer={saveAnswer}
                      deleteAnswer={removeAnswer}
                    />
                  ))}
                  {!hasAccess && showValidation ? (
                    <MatchFailed>{t("pages.accessData.noMatch")}</MatchFailed>
                  ) : null}
                  <StyledSectionInstructions>
                    {t("pages.accessData.contactSchool")}
                  </StyledSectionInstructions>
                  <Button primary onClick={submit}>
                    {t("labels.validate")}
                  </Button>
                </Fragment>
              </TranslateFormErrors>
            )}
          </Formik>
        </ContentWrapper>
        <Push />
      </PageWrapper>
      <PageFooter />
    </>
  );
};

export { AccessDataForm };
