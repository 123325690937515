import { NumericInput } from "@src/components/atoms/NumericInput";
import { ControlType } from "@src/components/atoms/NumericInput/types";
import { Flex } from "@src/components/layout/Page";
import { StyledLabel } from "@src/components/styles";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

export class TicketTypeCode {
  public static readonly Donation = 0;
  public static readonly Free = 1;
  public static readonly Paid = 2;
}

export const TicketPriceWrapper = styled(Flex)`
  & > * {
    :not(:first-child) {
      margin-left: 20px;
    }
  }

  ${StyledLabel} {
    color: #3260d3;
    margin-bottom: 0px;
    font-size: 20px;
    line-height: 35px;
    margin-right: 5px;
    font-weight: 400;
  }

  & > ${Flex} {
    align-items: center;
  }
`;

export type TicketPriceProps = {
  ticketId: string;
  price: number;
  typeCode: TicketTypeCode;
  canBuyTicket: boolean;
  isPreview: boolean;
  onChange?: (value?: string | null) => void;
  children?: React.ReactNode;
};

const MIN_OPEN_VALUE_PRICE = 1.0;

const TicketPrice: FC<TicketPriceProps> = ({
  ticketId,
  price,
  typeCode,
  canBuyTicket,
  isPreview,
  onChange,
  children,
}) => {
  const { t } = useTranslation();
  const parentPays = price ?? 0;

  const priceLabel =
    typeCode == TicketTypeCode.Free
      ? t("pages.tickets.free")
      : typeCode == TicketTypeCode.Donation
      ? t("pages.tickets.amount")
      : t("pages.tickets.priceEach", { price: parentPays?.toFixed(2) });

  return !isPreview ? (
    <TicketPriceWrapper alignItems={typeCode !== TicketTypeCode.Donation ? "flex-start" : "center"}>
      <Flex>
        <StyledLabel data-testid={ticketId + "-PRICE"}>{priceLabel}</StyledLabel>

        {typeCode == TicketTypeCode.Donation ? (
          <NumericInput
            ticketId={ticketId}
            type="currency"
            value={`${parentPays}`}
            min={MIN_OPEN_VALUE_PRICE}
            step={0.25}
            disabled={!canBuyTicket}
            controlType={ControlType.Vertical}
            onChange={onChange}
          />
        ) : null}
      </Flex>
      {children}
    </TicketPriceWrapper>
  ) : null;
};

export { TicketPrice };
