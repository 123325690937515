import { useReactiveVar } from "@apollo/client";
import { LoadingModal } from "@src/components/molecules/loadingModal/LoadingModal";
import { useCachedResponseVersion } from "@src/customHooks/useResponseVersion";
import { UrlParams, IsPayNow, OrderId, ResponseVersionHash } from "@src/localVariables";
import { ConfirmationRoute, ReviewRoute } from "@src/Routes";
import React from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router-dom";
import { ErrorComponent } from "../Error";
import { DefaultCountryCode } from "@src/utils/urls";

const PayNowLanding: FC = () => {
  const { t } = useTranslation();
  const { hashedId, hashedOrgId } = useParams();
  const { visitedPages } = useReactiveVar(UrlParams);
  const baseUrl = `/rp/${hashedId}${hashedOrgId ? `/${hashedOrgId}` : ""}`;
  const query = useCachedResponseVersion(hashedId || "");

  if (query.loading) return <LoadingModal message={t("messages.loadingYourExperience")} />;
  if (query.error) return <ErrorComponent error={query.error} />;

  // rostered responses include responses created via the Staff Form Library
  const existingResponse = query.responseVersion;

  if (existingResponse) {
    ResponseVersionHash(existingResponse.hash);
    IsPayNow(true);
    OrderId(existingResponse.orders.find((x) => x.isCheckedOut && !x.hasBeenPaid)?.orderId);
    UrlParams({
      baseUrl,
      responseHashedId: hashedId,
      documentHashedId: existingResponse.documentHashId,
      countryCode: hashedId?.substring(0, 2) ?? DefaultCountryCode,
      visitedPages,
    });

    if (existingResponse.orders.every((x) => x.hasBeenPaid))
      return <Navigate to={`${baseUrl}/${ConfirmationRoute}`} />;
    else return <Navigate to={`${baseUrl}/${ReviewRoute}`} />;
  }

  return <ErrorComponent error="Response not found." />;
};

export { PayNowLanding };
