import gql from "graphql-tag";
import { TaxesAndFeesFragment } from "./fragments";

const TAXES_AND_FEES_QUERY = gql`
  query TaxesAndFees($organizationId: ID!, $passFeesToBuyer: Boolean!, $amount: Float!, $currency: String!) {
    TaxesAndFees(organizationId: $organizationId, passFeesToBuyer: $passFeesToBuyer, amount: $amount, currency: $currency) {
      ...TaxesAndFeesFragment
    }
  }
  ${TaxesAndFeesFragment}
`;

export { TAXES_AND_FEES_QUERY };
