import styled from "styled-components";
import { defaultTheme } from "@src/themes";
import { Breakpoints } from "@src/customHooks";
import { Flex } from "./layout/Page";
import { ResponseState } from "@src/constants/responseStates";

const FieldWrapper = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  max-width: ${(props) => (props.fullWidth ? "100%" : "600px")};
  border-radius: ${defaultTheme.borderRadius};
  position: relative;
`;

const StyledSelect = styled.select<{ hasError?: boolean }>`
  &:focus {
    outline: none !important;
    border: 2px solid ${defaultTheme.colors.fields.selected};
  }

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 36px;
  font-size: 14px;
  padding-left: 10px;
  border: 1px solid ${(props) => (props.hasError ? "red" : props.theme.colors.fields.bordercolor)};
  border-radius: 3px;
  background: ${(props) =>
    props.hasError ? "#ffdfdf" : "linear-gradient(180deg, #ffffff 0%, #f9fafb 100%)"};
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
`;

const StyledDateSelectWrapper = styled.div`
  margin: 10px;
  display: flex;
  justify-content: "center";
`;

const StyledDateSelect = styled.select`
  &:focus {
    outline: none !important;
    border: 2px solid ${defaultTheme.colors.fields.selected};
  }
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 36px;
  width: 107px;
  font-size: 14px;
  padding-left: 10px;
  border: 1px solid #c4cdd5;
  border-radius: 3px;
  background: linear-gradient(180deg, #ffffff 0%, #f9fafb 100%);
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  &:first-of-type {
    margin-right: 5px;
  }
`;

const StyledButton = styled.button<{ disabled?: boolean }>`
  border-color: transparent;
  border-radius: 5px;
  color: white;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  background-color: ${(props) => (props.disabled ? "#b7b7b7" : defaultTheme.colors.alias.primary)};
  height: 40px;
  width: 150px;
`;

const StyledOption = styled.option`
  font-family: ${defaultTheme.font.default};
`;

const StyledTextArea = styled.textarea<{ hasError?: boolean }>`
  &:focus {
    outline: none !important;
    border: 2px solid ${defaultTheme.colors.fields.selected};
  }

  box-sizing: border-box;
  border: 1px solid ${(props) => (props.hasError ? "red" : props.theme.colors.fields.bordercolor)};
  border-radius: ${defaultTheme.borderRadius};
  font-family: ${defaultTheme.font.default};
  font-size: 100%;
  padding-left: 10px;
  height: 100px;
  background-color: ${(props) => (props.hasError ? "#ffdfdf" : props.theme.colors.values.white)};
  max-width: 100%;
  min-width: 100%;
  font-weight: 300;
`;

const StyledCheckbox = styled.input.attrs({ type: "checkbox" })`
  &:focus {
    border: 2px solid ${defaultTheme.colors.fields.selected};
  }

  margin-right: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 16px;
  width: 16px;
`;

const StyledTextInput = styled.input.attrs({
  type: "text",
  onKeyPress: (event: React.KeyboardEvent) => {
    if (event.which === 13) {
      event.preventDefault();
    }
  },
})<{ hasError?: boolean }>`
  &:focus {
    outline: none !important;
    border: 2px solid ${defaultTheme.colors.fields.selected};
  }

  &:disabled {
    background: ${defaultTheme.colors.fields.disabledBackground};
  }

  background-color: ${(props) => (props.hasError ? "#ffdfdf" : "inherit")};

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1.25px solid
    ${(props) => (props.hasError ? "red" : defaultTheme.colors.fields.bordercolor)};
  border-radius: ${defaultTheme.borderRadius};
  height: 40px;
  padding-left: 10px;
  font-family: ${defaultTheme.font.default};
  font-size: 100%;
  font-weight: 300;
`;

const StyledRadioButton = styled.input.attrs({
  type: "radio",
  onKeyPress: (event: React.KeyboardEvent) => {
    if (event.which === 13) {
      event.preventDefault();
    }
  },
})`
  margin-right: 7px;
  cursor: pointer;
`;

const StyledRadioButtonLabel = styled.span<{ selected: boolean }>`
  color: ${(props) => (props.selected ? props.theme.colors.alias.primary : "inherit")};
`;

const StyledUploadContainer = styled(Flex)`
  width: 100%;
  height: 250px;
  max-width: 600px;
  border: 1.25px dashed ${defaultTheme.colors.fields.bordercolor};
  padding: 20px;
  box-sizing: border-box;
  border-radius: ${defaultTheme.borderRadius};
  cursor: pointer;

  &:hover {
    border: 1.25px dashed ${defaultTheme.colors.fields.selected};
  }
`;

const StyledUploadField = styled.input.attrs({ type: "file" })`
  display: none;
`;

const FieldInformationLabel = styled.p`
  margin: 0;
  font-size: 12px;
  color: gray;
  margin-top: 5px;
`;

const UploadImage = styled.img`
  margin-top: 75px;
  width: 40px;
  pointer-events: none;
`;

const UploadButton = styled.div<{ completionPercentage?: number }>`
  color: #273549;
  border: 1px solid #273549;
  background: ${(props) =>
    `linear-gradient(to right, ${defaultTheme.colors.values.green}6e, ${defaultTheme.colors.values.green}6e ${props.completionPercentage}%, white ${props.completionPercentage}%, white)`};
  width: 100px;
  padding: 10px;
  font-size: 95%;
  text-align: center;
  border-radius: ${defaultTheme.borderRadius};
  font-weight: 500;
  margin-top: 25px;
`;

const ProgressBar = styled.div`
  background: ${defaultTheme.colors.values.green};
  height: 37px;
  opacity: 0.5;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

const UploadPreview = styled.div`
  position: relative;
  margin: 0 auto;
  width: 170px;
  height: 120px;

  i {
    position: absolute;
    right: -35px;
    top: -5px;
  }

  img {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
  }
`;

const PreviewImage = styled.img`
  max-width: 150px;
  max-height: 100px;
  margin: 0 auto;
  display: block;
`;

const ProgressMessage = styled.div``;

const StyledAttendeeName = styled.h1`
  font-family: ${defaultTheme.font.default};
  font-size: 2em;
  font-weight: 300;
  margin: 0;
`;

const HeaderContainer = styled.div`
  margin: 10px 0 25px;
`;

const StyledHeader = styled.h1`
  font-family: ${defaultTheme.font.default};
  font-size: 1.5em;

  > i {
    margin-left: 15px;
    cursor: pointer;
  }
`;

const StyledSubheader = styled.h2`
  font-family: ${defaultTheme.font.default};
  color: ${defaultTheme.colors.values.grey};
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  margin-top: 3px;
`;

const StyledSectionHeader = styled.h3`
  font-family: ${defaultTheme.font.default};
  font-size: 1.17em;
  margin: 50px 0px 25px 0px;

  &:first-of-type {
    margin-top: 0;
  }
`;

const StyledSectionInstructions = styled.p`
  font-family: ${defaultTheme.font.default};
  font-size: 0.9em;
  margin: 0 0 8px;
`;

const StyledRequiredInstructions = styled.p`
  font-family: ${defaultTheme.font.default};
  font-size: 0.8em;
  margin: 8px 0;
`;

const StyledLabel = styled.label`
  font-family: ${defaultTheme.font.default};
  color: ${defaultTheme.colors.fields.text};
  margin-bottom: 10px;
  font-weight: 500;
  width: fit-content;
`;

const StyledDisplayLabel = styled(StyledLabel)`
  color: #aaabab;
`;

const StyledAnswerLabel = styled.span`
  color: #424242;
  font-weight: 500;
`;

const StyledLine = styled.hr`
  margin: 25px 8px;
  border-top: 2px solid #d8d8d8;
`;

const StyledRequiredLabel = styled.i`
  font-family: ${defaultTheme.font.default};
  position: absolute;
  color: #f03742;
  left: -15px;
  font-weight: 500;

  &::before {
    content: "*";
  }
`;

const StyledInlineRequiredLabel = styled.i`
  font-family: ${defaultTheme.font.default};
  color: #f03742;
  font-weight: 500;
  display: inline-block;
  padding-right: 10px;

  &::before {
    content: "*";
  }
`;

const StyledDivider = styled.div`
  width: 100%;
  border-bottom: 1px solid #d5d5d5;
  margin: 10px 0;
`;

const StyledSelectedStar = styled.i<{ small?: boolean }>`
  font-size: ${(props) => (props.small ? "15px" : "26px")};
  padding: 1px;
  cursor: pointer;
  color: ${defaultTheme.colors.alias.primary};
  vertical-align: middle;
`;

const StyledUnselectedStar = styled.i<{ small?: boolean }>`
  font-size: ${(props) => (props.small ? "15px" : "26px")};
  padding: 1px;
  cursor: pointer;
  color: #bcbcbc;
  vertical-align: middle;
`;

const StyledResponseCard = styled.div<{ screenSize: Breakpoints; state: ResponseState }>`
  ${({ theme, screenSize, state }) => `

    display: flex;
    flex-direction: column;
    border: 1px solid ${
      state === ResponseState.ResponseInProgress
        ? theme.colors.values.blue
        : state === ResponseState.ResponsePendingApproval
        ? theme.colors.values.blue
        : state === ResponseState.ResponseRejected || state === ResponseState.ResponseCancelled
        ? theme.colors.values.darkred
        : state === ResponseState.ResponseComplete
        ? theme.colors.values.darkgreen
        : theme.colors.values.lightgrey
    };
    width: ${screenSize === "desktop" ? `380px` : `220px`};
    min-height: 225px;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    margin-right: 25px;
    margin-bottom: 25px;

    &:hover {
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
      border: 1px solid #0283ff !important;
    }
  `}
`;

const StyledResponseCardTitle = styled(StyledLabel)<{
  state: ResponseState;
}>`
  ${({ theme, state }) => `
    font-size: 18px;
    color: ${
      state === ResponseState.ResponseInProgress
        ? theme.colors.values.blue
        : state === ResponseState.ResponsePendingApproval
        ? theme.colors.values.blue
        : state === ResponseState.ResponseRejected || state === ResponseState.ResponseCancelled
        ? theme.colors.values.darkred
        : state === ResponseState.ResponseComplete
        ? theme.colors.values.darkgreen
        : theme.colors.fields.text
    };
  `}
`;

const StyledInformationContainer = styled(Flex)`
  flex-direction: column;
  margin-top: 50px;
`;

const StyledNavigationButtonsContainer = styled(Flex)<{ screenSize: Breakpoints }>`
  justify-content: space-between;
  margin-top: ${(props) => (props.screenSize === "smallScreen" ? `50px` : `100px`)};
`;

const StyledErrorBar = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  background: ${defaultTheme.colors.values.yellow};
  color: ${defaultTheme.colors.values.darkgrey};
  z-index: 2;
`;

const StyledAnswerRow = styled.div`
  margin: 0 0 8px;
  font-weight: 300;
`;

const ErrorHeader = styled.header`
  margin-bottom: 10px;
  font-weight: 500;
`;

const ErrorMessageHeader = styled.header`
  font-weight: 500;
  margin-right: 5px;
`;

const ErrorMessage = styled.p`
  margin: 0px;
`;

const StyledErrorBarMessage = styled.div`
  width: 80%;
  font-size: 12px;
`;

const StyledReviewHeader = styled(StyledLabel)`
  font-size: 20px;
`;

const StyledReviewMessage = styled.p`
  color: ${defaultTheme.colors.values.grey};
`;

const StyledErrorContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  border-radius: 5px;
  padding: 0px 10px;
  gap: 20px;
  height: 40px;
  width: 100%;
`;

const StyledErrorText = styled.p`
  margin: 0;
  color: #e01313;
`

const StyledTermsMessage = styled.span`
  color: ${defaultTheme.colors.values.grey};
  font-size: 12px;
  margin-top: 20px;
`;

const StyledTermsLink = styled.a`
  color: ${defaultTheme.colors.alias.primary};
  text-decoration: none;
  font-weight: 500;
`;

const ReloadButton = styled.button`
  border-color: transparent;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  background-color: ${defaultTheme.colors.alias.primary};
  padding: 5px 10px;
  font-size: 12px;
  float: right;
`;

const LoadingModalBackground = styled.div`
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${defaultTheme.colors.values.lightgrey};
  opacity: 0.6;
  z-index: 999;
`;

const LoadingModalContent = styled.div`
  position: fixed;
  z-index: 1000;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 200px;
  height: 300px;
`;

const LoadingMessage = styled.div`
  margin: 30px auto 0;
  font-size: 14px;
  text-align: center;
`;

const MaxLengthMessage = styled.div`
  font-size: 80%;
  margin-bottom: 8px;
`;

const StyledParagraph = styled.div`
  margin: 5px 0 25px;
`;

const CurrencyLabel = styled(StyledLabel)`
  color: #273549;
  margin: 0;
`;

const RepeatableFieldsWrapper = styled.div`
  padding: 20px;
  background: #f9fafc;
`;

const RepeatableAddButton = styled.button<{ disabled?: boolean }>`
  background: ${(props) => (props.disabled ? "#b7b7b7" : defaultTheme.colors.alias.darkPrimary)};
  color: ${defaultTheme.colors.values.white};
  font-family: ${defaultTheme.font.default};
  padding: ${defaultTheme.spacing.default} ${defaultTheme.spacing.medium};
  border: none;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  height: 40px;
  width: 120px;
  border-radius: ${defaultTheme.borderRadius};
  margin: 0 0 10px 0;

  &:hover {
    background-color: ${(props) =>
      props.disabled ? "#b7b7b7" : defaultTheme.colors.alias.lightPrimary};
  }

  &:focus,
  &:active {
    outline: none;
  }
`;

const PdfThumbnail = styled.img`
  max-width: 60px;
  margin: 5px 0;
`;

const FileThumbnail = styled.img`
  max-width: 60px;
  margin: 5px 0;
`;

const EquationsWrapper = styled.div`
  overflow-x: auto;
  min-width: 300px;
  margin-bottom: 15px;
  padding-bottom: 5px;
`;

const DateFillWrapper = styled.div<{ disabled?: boolean }>`
  margin: 5px 0 0 0;
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};

  .date-selection-wrapper {
    display: inline-block;
    border: 1px solid #dde3e8;
    padding: 5px;
    border-radius: 3px;
    background: #fff;

    &:focus-within {
      border-color: #0283ff;
    }

    .date-separator {
      float: left;
      margin: 0 8px;
      display: block;
      position: relative;
      top: 28px;
    }

    .date-option {
      float: left;
      font-size: 12px;
      margin: 0;
      padding: 5px;
      border: none;
      background: none;
      cursor: pointer;
      text-align: left;
      position: relative;

      &.year {
        width: 40px;
      }

      &.month {
        width: 45px;
      }

      &.day {
        width: 34px;
      }

      label.date-label {
        font-size: 10px;
        cursor: pointer;
        margin: 0;
        display: block;

        &.disabled {
          color: #dee3e9;
        }
      }

      select,
      .date-fill-input {
        border: none;
        margin: 5px 0 0;
        padding: 5px 0;
        display: block;
        text-align: left;
        min-height: 14px;
        font-size: 14px;
        cursor: pointer;
        border-radius: 0;
        background: none;
        border-bottom: 1px solid #dde3e8;
        font-weight: 300;
        min-width: 0;

        &.placeholder {
          color: #dee3e9;
        }

        &:focus {
          border-bottom: 1px solid #0283ff;
        }
      }

      i {
        display: inline-block;
      }

      &:first-of-type {
        margin-left: 0;
      }

      ul {
        position: absolute;
        margin: 0;
        padding: 0;
        background: #fff;
        border: 1px solid #dde3e8;
        max-height: 150px;
        overflow: auto;
        z-index: 1;

        li {
          list-style: none;
          padding: 5px 10px 0;

          &:hover {
            background: #78bbdb;
          }
        }
      }
    }
  }

  .selected-message {
    font-size: 12px;

    span {
      font-weight: 400;
    }
  }
`;

const ClearIconSpacer = styled.span`
  margin: 0 5px;
`;

const MatchFailed = styled.div`
  margin: 10px 0 20px;
  color: ${defaultTheme.colors.values.red};
`;

export {
  StyledSelect,
  StyledTextArea,
  StyledButton,
  FieldWrapper,
  StyledCheckbox,
  StyledOption,
  StyledTextInput,
  StyledRadioButton,
  StyledRadioButtonLabel,
  StyledAttendeeName,
  StyledHeader,
  StyledSubheader,
  StyledSectionHeader,
  StyledLabel,
  StyledDisplayLabel,
  StyledAnswerLabel,
  StyledDivider,
  StyledUploadField,
  StyledSelectedStar,
  StyledUnselectedStar,
  StyledResponseCard,
  StyledResponseCardTitle,
  StyledInformationContainer,
  StyledNavigationButtonsContainer,
  StyledRequiredLabel,
  StyledInlineRequiredLabel,
  StyledDateSelect,
  StyledSectionInstructions,
  StyledRequiredInstructions,
  StyledErrorBar,
  StyledErrorText,
  StyledErrorContainer,
  ReloadButton,
  StyledErrorBarMessage,
  StyledReviewHeader,
  StyledReviewMessage,
  StyledTermsMessage,
  StyledTermsLink,
  ErrorHeader,
  ErrorMessageHeader,
  ErrorMessage,
  LoadingModalBackground,
  LoadingMessage,
  LoadingModalContent,
  StyledDateSelectWrapper,
  MaxLengthMessage,
  HeaderContainer,
  StyledParagraph,
  CurrencyLabel,
  RepeatableFieldsWrapper,
  RepeatableAddButton,
  PdfThumbnail,
  PreviewImage,
  FileThumbnail,
  StyledUploadContainer,
  FieldInformationLabel,
  UploadButton,
  UploadImage,
  UploadPreview,
  ProgressMessage,
  ProgressBar,
  EquationsWrapper,
  StyledAnswerRow,
  DateFillWrapper,
  ClearIconSpacer,
  StyledLine,
  MatchFailed,
};
