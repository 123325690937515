import React, { FC } from "react";
import { Flex } from "@src/components/layout/Page";
import styled from "styled-components";
import { StyledLabel } from "@src/components/styles";
import { Image } from "@src/utils/purchasedTickets";
import { Breakpoints } from "@src/customHooks";

export type PaymentOptionProps = {
  label: string;
  icons: Image[];
  onClick: () => void;
  tabIndex: number;
  screenSize: Breakpoints;
  isSelected: boolean;
};

const PaymentOptionContainer = styled(Flex)`
  margin-left: 15px;
  cursor: pointer;
`;

const StyledPaymentImage = styled.img`
  width: 94px;
  height: 56px;
`;

const StyledMobilePaymentImage = styled.img`
  width: 47px;
  height: 28px;
`;

const PaymentOption: FC<PaymentOptionProps> = ({
  label,
  icons,
  onClick,
  tabIndex,
  screenSize,
  isSelected,
}) => {
  return screenSize === "desktop" ? (
    <PaymentOptionContainer
      flexDirection="column"
      alignItems="center"
      onClick={onClick}
      tabIndex={tabIndex}
    >
      <StyledLabel>{label}</StyledLabel>
      <Flex gap={10}>
        {icons.map((icon) => (
          <StyledPaymentImage key={icon.alt} {...icon} />
        ))}
      </Flex>
      <i
        style={{ marginTop: "10px" }}
        className={isSelected ? "far fa-dot-circle" : "far fa-circle"}
        data-testid={"PAYMENT-OPTION-" + (tabIndex + 1)}
      ></i>
    </PaymentOptionContainer>
  ) : (
    <PaymentOptionContainer
      style={{ margin: 0, marginBottom: "15px", width: "350px" }}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      onClick={onClick}
      tabIndex={tabIndex}
    >
      <Flex gap={10}>
        <i className={isSelected ? "far fa-dot-circle" : "far fa-circle"}></i>
        <StyledLabel style={{ margin: 0 }}>{label}</StyledLabel>
      </Flex>

      <Flex justifyContent="center" gap={10} style={{ flexGrow: 1, maxWidth: "220px" }}>
        {icons.map((icon) => (
          <StyledMobilePaymentImage key={icon.alt} {...icon} />
        ))}
      </Flex>
    </PaymentOptionContainer>
  );
};

export { PaymentOption };
