import styled from "styled-components";
import { Button } from "../Button";

type StyledIconProps = {
  clickable?: boolean;
  color?: string;
  size?: string;
  hoverEnabled?: boolean;
  hoverColor?: string;
  disabled?: boolean;
};

const StyledIcon = styled.i<StyledIconProps>`
  cursor: ${(props) => (props.clickable && !props.disabled ? "pointer" : "inherit")};
  color: ${(props) => props.color || props.theme.colors.fields.text};
  font-size: ${(props) => props.size || props.theme.font.iconSize};

  &:hover {
    ${(props) =>
      !props.disabled &&
      props.hoverEnabled &&
      `
      color: ${props.hoverColor || props.theme.colors.fields.selected};
    `}
  }

  ${Button} & {
    color: white;
    padding-right: 10px;
  }
`;

export { StyledIcon };
