import { ApolloCache, DefaultContext, MutationUpdaterFunction } from "@apollo/client";

import { RESPONSE_VERSION_QUERY } from "@src/queries/responseVersion";
import {
  ResponseQuery,
  UpdateTagMutation,
  UpdateTagMutationVariables,
} from "@src/types";

type UpdaterArgs = {
  responseHashedId: string;
  tagId: string | null;
};

type UpdateResponseArgs = {
  cache: ApolloCache<unknown>;
  tagId: string | null;
  responseHashedId: string;
};

const updateTag = ({
  cache,
  tagId,
  responseHashedId,
}: UpdateResponseArgs) => {
  cache.updateQuery<ResponseQuery>(
    {
      query: RESPONSE_VERSION_QUERY,
      variables: {
        hash: responseHashedId,
      },
      overwrite: true,
    },
    (data: ResponseQuery | null) => {
      if (data) {
        const responseVersion = data.Response;

        if (responseVersion) {

          return {
            ...data,
            Response: {
              ...responseVersion,
              tagId
            },
          };
        }
      }
    }
  );
};

type UpdateTagMutationUpdater = (
  args: UpdaterArgs
) => MutationUpdaterFunction<
  UpdateTagMutation,
  UpdateTagMutationVariables,
  DefaultContext,
  ApolloCache<unknown>
>;

const updateTagMutationUpdater: UpdateTagMutationUpdater =
  ({ responseHashedId, tagId }) =>
  (cache) => {
    updateTag({ cache, tagId, responseHashedId });
  };

export { updateTagMutationUpdater };
