import { ApolloCache, DefaultContext, MutationUpdaterFunction } from "@apollo/client";
import cloneDeep from "lodash/cloneDeep";
import {
  Attendee,
  EditAttendeesMutation,
  EditAttendeesMutationVariables,
  ResponseQuery,
  ResponseVersion,
} from "@src/types";
import { RESPONSE_VERSION_QUERY } from "@src/queries/responseVersion";

type EditAttendeesMutationUpdater = (
  responseHashedId: string
) => MutationUpdaterFunction<
  EditAttendeesMutation,
  EditAttendeesMutationVariables,
  DefaultContext,
  ApolloCache<unknown>
>;

const editAttendeesMutationUpdater: EditAttendeesMutationUpdater =
  (responseHashedId) =>
  (cache, { data }) => {
    const editedAttendees = data?.EditAttendees as Attendee[] || [];
    updateResponseQuery(cache, responseHashedId, editedAttendees)
  };

const updateResponseQuery = (
  cache: ApolloCache<unknown>,
  responseHashedId: string,
  editedAttendees: Attendee[]
) => {
  cache.updateQuery<ResponseQuery>(
    {
      query: RESPONSE_VERSION_QUERY,
      variables: {
        hash: responseHashedId,
      },
      overwrite: true,
    },
    (cachedData) => {
      if (cachedData) {
        const updatedResponseVersion = updateActiveResponseVersion(cachedData?.Response as ResponseVersion, editedAttendees);

        return {
          ...cachedData,
          Response: updatedResponseVersion,
        };
      }
    }
  );
};

const updateActiveResponseVersion = (
  activeResponseVersion: ResponseVersion,
  editedAttendees: Attendee[],
): ResponseVersion => {
  const cachedAttendees = activeResponseVersion?.attendees || [];

  const newAttendees = editedAttendees.map((editedAttendee) => {
    const currentAttendee = cachedAttendees.find(
      (currAttendee) => currAttendee.responseAttendeeId === editedAttendee.responseAttendeeId
    );

    return currentAttendee
      ? {
          ...currentAttendee,
          firstName: editedAttendee.firstName,
          lastName: editedAttendee.lastName,
          permitted: editedAttendee.permitted,
          manualPermissionDate: editedAttendee.manualPermissionDate,
          attachmentId: editedAttendee.attachmentId,
          attachmentFileType: editedAttendee.attachmentFileType,
          notes: editedAttendee.notes,
        }
      : {
          ...editedAttendee,
          tickets: [],
          attendeeAnswers: [],
          integratedDataOnFile: [],
        };
  });

  let newResponseVersion = {
    ...activeResponseVersion,
    attendees: newAttendees,
  };

  // If we removed an attendee with tickets tied to it, then update the orders & subtotal
  // Optionally we could refetch the response version
  if (!activeResponseVersion?.isCheckedOut && activeResponseVersion?.orders) {
    const newOrders = cloneDeep(activeResponseVersion.orders);
    const latestOrder = newOrders.find((order) => !order.isCheckedOut);

    if (latestOrder) {
      const newTickets = latestOrder.purchasedTickets.filter((x) =>
        newAttendees.some((newAttendee) => newAttendee.attendeeId === x.attendeeId)
      );

      if (newTickets.length !== latestOrder.purchasedTickets.length) {
        const newTotal =
          newTickets?.reduce((a, b) => {
            return a + b.quantity * b.price ?? 0;
          }, 0) ?? 0;
        latestOrder.subTotal = newTotal;
        latestOrder.grandTotal = newTotal;
        latestOrder.purchasedTickets = newTickets;

        newResponseVersion = {
          ...newResponseVersion,
          subTotal: newTotal,
          orders: newOrders,
        };
      }
    }
  }

  return newResponseVersion;
};

export { editAttendeesMutationUpdater };
