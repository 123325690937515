import styled from "styled-components";

const SpinnerContainer = styled.div`
  width: 80px;
  height: 100px;
  margin: 0 auto;
`;

const SpinnerBlock = styled.div`
  position: relative;
  box-sizing: border-box;
  float: left;
  margin: 0 10px 10px 0;
  width: 12px;
  height: 12px;
  border-radius: 3px;
  background: #0283ff;

  @keyframes wave {
    0% {
      top: 0;
      opacity: 1;
    }
    50% {
      top: 30px;
      opacity: 0.2;
    }
    100% {
      top: 0;
      opacity: 1;
    }
  }

  &:nth-child(4n + 1) {
    animation: wave 1.2s ease 0s infinite;
  }
  &:nth-child(4n + 2) {
    animation: wave 1.2s ease 0.2s infinite;
  }
  &:nth-child(4n + 3) {
    animation: wave 1.2s ease 0.4s infinite;
  }
  &:nth-child(4n + 4) {
    animation: wave 1.2s ease 0.6s infinite;
    margin-right: 0;
  }
`;

export { SpinnerContainer, SpinnerBlock };
