import { cloneDeep } from "@apollo/client/utilities";
import { Formik, useFormikContext } from "formik";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Button } from "@src/components/atoms/Button";
import { Thumbnail } from "@src/components/atoms/Thumbnail/Thumbnail";
import { FieldSelector } from "@src/components/fields";
import { Flex } from "@src/components/layout/Page";
import { LoadingModal } from "@src/components/molecules/loadingModal/LoadingModal";
import { StyledLabel } from "@src/components/styles";
import { Breakpoints, useBreakPoints, useUrlParams } from "@src/customHooks";
import { ReserveTicketAnswerResult } from "@src/mutations/mutationTypes";
import { AttendeeFormRoute, PreviewProps } from "@src/Routes";
import { Attendee, FieldType, PurchasedTicket, Ticket, TicketInventory } from "@src/types";
import { isCollapsed } from "@src/utils/field";
import { getFormInitialValues } from "@src/utils/formValues";
import { InitialValuesObject } from "@src/utils/formValuesTypes";
import { CancelTicketArgs, ReserveTicketArgs, TicketMode } from "@src/utils/purchasedTickets";
import { DateNotice } from "./DateNotice";
import { QuantitySection } from "./QuantitySection";
import { TicketPrice, TicketTypeCode } from "./TicketPrice";
import { TrashButton } from "./TrashButton";
import { RespondentMustSelectATicket } from "@src/pages/TicketsAndAttendeeForm";
import { checkTicketSellingDates, getTicketAnswers } from "@src/utils/tickets";
import { useReactiveVar } from "@apollo/client";
import { IsPayNow, OrderId } from "@src/localVariables";
import { useGoogleTranslate } from "@src/utils/translation";
import { TranslateFormErrors } from "@src/customHooks/useTranslateFormErrors";

type TicketContainerProps = {
  unavailable?: boolean;
};

const TicketContainer = styled.div<TicketContainerProps>`
  border: 1px solid #cdcdcd;
  box-shadow: 0 0px 3px rgb(0 0 0 / 5%), 0 0px 3px rgb(0 0 0 / 24%);
  border-radius: 10px;
  ${({ unavailable, theme }) =>
    unavailable &&
    `
      color: ${theme.colors.values.grey};
      opacity: .5;
  `}
`;

const TicketQuestionsHeader = styled(Flex)`
  margin-bottom: 5px;
  & > * {
    :not(:first-child) {
      margin-left: 20px;
    }
  }
`;

const AddToCartButton = styled.button.attrs({ type: "button" })<{ screenSize: Breakpoints }>`
  ${({ theme, screenSize }) => `
    height: 47px;
    width: ${screenSize === "desktop" ? "188px" : "100%"};

    border-radius: 7px;
    border-style: none;
    border-color: ${theme.colors.values.lightblue};

    margin: ${screenSize === "desktop" ? "0px 15px 15px auto" : "20px"};

    background: ${theme.colors.values.lightblue};

    text-align: center;
    font-size: 18px;
    font-family: ${theme.font.default};
    color: ${theme.colors.values.white};
    cursor: pointer;

    &:disabled {
      background: ${theme.colors.alias.disabled};
      cursor: default;
    }
  `}
`;

const TicketQuestionsSection = styled(Flex)`
  padding: 10px 15px;
  box-sizing: border-box;
`;

type TicketHeaderProps = PreviewProps & {
  ticket: Ticket;
  attendee: Attendee;
  attendeeIndex: number;
  purchasedTickets?: PurchasedTicket[];
  cart: Attendee[];
  mode: TicketMode;
  onCancelTicket?: (args: CancelTicketArgs) => void;
  children?: React.ReactNode;
};

const TicketHeader: FC<TicketHeaderProps> = ({
  ticket,
  attendee,
  attendeeIndex,
  purchasedTickets,
  mode,
  onCancelTicket,
  children,
}) => {
  const { t } = useTranslation();
  const googleTranslate = useGoogleTranslate();
  const screenSize = useBreakPoints();
  const { baseUrl, countryCode } = useUrlParams();
  const navigate = useNavigate();
  const isPayNow = useReactiveVar(IsPayNow);
  const thumbnailUrl = ticket.imageId
    ? `${process.env.DOCUMENTS_URL}respond/${
        ticket.imageId
      }/${countryCode.toLowerCase()}/ticket-thumbnail`
    : null;

  const onEditTicket = () => {
    navigate(`${baseUrl}/${AttendeeFormRoute}/${attendeeIndex >= 0 ? attendeeIndex : 0}`);
  };

  const displaySummaryForSimpleTicket =
    (mode === TicketMode.PREVIEW || mode === TicketMode.CHECKOUT) &&
    ticket.formQuestions?.length === 0;

  const purchasedTicketIds =
    displaySummaryForSimpleTicket && purchasedTickets && purchasedTickets.length > 0
      ? purchasedTickets.flatMap((x) => x.purchasedTicketIds)
      : [];

  return (
    <Flex data-testid={displaySummaryForSimpleTicket ? ticket.id + "-TICKET" : null}>
      <Flex flexDirection={"column"} alignItems={"center"} style={{ padding: "15px" }}>
        <Thumbnail
          ticket={ticket}
          mode={mode}
          screenSize={screenSize}
          alt={ticket.name || ""}
          src={thumbnailUrl ?? ""}
        />
        {mode === TicketMode.CHECKOUT && !isPayNow ? (
          <Button primary onClick={() => onEditTicket()}>
            {t("pages.tickets.editTicket")}
          </Button>
        ) : null}
        {mode === TicketMode.SHOPPING && (ticket.startSellingDate || ticket.endSellingDate) ? (
          <DateNotice start={ticket.startSellingDate || ""} end={ticket.endSellingDate || ""} />
        ) : null}
      </Flex>
      <Flex
        justifyContent="center"
        flexDirection={"column"}
        style={{ padding: "15px 15px 15px 0px", width: "100%" }}
      >
        <Flex alignItems="center">
          <StyledLabel
            style={{
              fontSize:
                screenSize === "desktop" && mode !== TicketMode.CART_PREVIEW ? "20px" : "16px",
              margin: "0",
            }}
          >
            {googleTranslate(ticket.name || "")}
          </StyledLabel>
          {mode !== TicketMode.CHECKOUT && displaySummaryForSimpleTicket && onCancelTicket ? (
            <TrashButton
              testId="DELETE-TICKET"
              onClick={() =>
                onCancelTicket({
                  ticketVersionId: ticket.id,
                  responseAttendeeId: attendee.responseAttendeeId,
                  purchasedTicketIds: purchasedTicketIds,
                })
              }
            />
          ) : null}
        </Flex>

        {ticket.description && mode !== TicketMode.CART_PREVIEW ? (
          <StyledLabel
            style={{
              fontSize: screenSize === "desktop" ? "14px" : "12px",
              color: "#868686",
              fontWeight: 400,
              width: "75%",
            }}
          >
            {googleTranslate(ticket.description || "")}
          </StyledLabel>
        ) : null}
        {children}
      </Flex>
    </Flex>
  );
};

export type TicketSectionProps = PreviewProps & {
  ticket: Ticket;
  inventory: TicketInventory;
  attendee: Attendee;
  attendeeIndex: number;
  cart?: Attendee[];
  mode: TicketMode;
  onReserveTicket?: (args: ReserveTicketArgs) => Promise<ReserveTicketAnswerResult>;
};

const TicketSection: FC<TicketSectionProps> = ({
  ticket,
  inventory,
  attendee,
  attendeeIndex,
  cart,
  mode,
  isPreview,
  onReserveTicket,
}) => {
  const { t } = useTranslation();
  const googleTranslate = useGoogleTranslate();
  const screenSize = useBreakPoints();
  const { errors, setFieldError } = useFormikContext<{
    RespondentMustSelectATicket: string | undefined;
  }>();
  const fields = ticket.formQuestions || [];
  const hasOptions = fields?.length > 0;
  const [collapsedHeaders, setCollapsedHeaders] = useState<string[]>([]);
  const requiresATicket = errors[RespondentMustSelectATicket] ?? null;
  const orderId = useReactiveVar(OrderId);

  const filteredFields = fields.filter(
    (x) =>
      !x.type ||
      (x.type !== FieldType.Header &&
        x.type !== FieldType.Section &&
        x.type !== FieldType.Descriptivetext)
  );

  const ticketAnswerValues = getFormInitialValues({
    fields: filteredFields,
    answers: [],
  });

  type TicketInitialValues = {
    quantity: number;
    openValueAmount?: number;
    answers: {
      [id: string]: InitialValuesObject;
    };
  };

  const canBuyTicket = checkTicketSellingDates(ticket);

  const initialValues: TicketInitialValues = {
    answers: { [ticket.ticketId]: ticketAnswerValues },
    quantity: 0,
    openValueAmount: undefined,
  };

  const handleToggleCollapse = (fieldId: string) => {
    const existing = collapsedHeaders.find((x) => x === fieldId);

    if (existing) {
      setCollapsedHeaders(collapsedHeaders.filter((x) => x !== existing));
    } else {
      const headers = cloneDeep(collapsedHeaders);
      headers.push(fieldId);

      setCollapsedHeaders(headers);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async ({ quantity, openValueAmount, answers }, { resetForm }) => {
        // id of ticket, generated field id, answer
        const innerFieldAnswers = Object.values(answers[ticket.ticketId]);

        const newAnswers = getTicketAnswers(innerFieldAnswers).filter((x) => x.value);

        if (Number(ticket.typeCode) === TicketTypeCode.Donation) {
          quantity = Math.max(1, quantity);
        }

        if (onReserveTicket) {
          const result = await onReserveTicket({
            orderId: orderId,
            responseAttendeeId: attendee.responseAttendeeId,
            ticketVersionId: ticket.id,
            answers: newAnswers,
            openValueAmount,
            quantity,
          });

          if (!result.errors) {
            if (requiresATicket) {
              setFieldError(RespondentMustSelectATicket, undefined);
            }
            OrderId(result.data?.ReserveTicket);
            resetForm({ values: initialValues });
          }
        }
      }}
    >
      {({ isSubmitting, dirty, setFieldValue, resetForm, values, submitForm }) => {
        const showOptions =
          Number(ticket.typeCode) !== TicketTypeCode.Donation
            ? values.quantity > 0
            : (values.openValueAmount ?? 0) >= 1;

        const addToCartLabel =
          t("pages.tickets.addToCart") +
          `${values.quantity > 0 ? " (" + values.quantity + ")" : ""}`;

        return (
          <TranslateFormErrors>
            <>
              {isSubmitting && <LoadingModal message={t("pages.tickets.addToCartLoading")} />}
              <TicketContainer>
                <>
                  <TicketHeader
                    ticket={ticket}
                    attendee={attendee}
                    attendeeIndex={attendeeIndex}
                    mode={mode}
                    isPreview={isPreview}
                    cart={cart ?? []}
                  >
                    {mode === TicketMode.SHOPPING ? (
                      <>
                        <TicketPrice
                          ticketId={ticket.id}
                          price={
                            Number(ticket.typeCode) === TicketTypeCode.Donation
                              ? values.openValueAmount ?? 0
                              : ticket.price ?? 0
                          }
                          typeCode={ticket.typeCode}
                          canBuyTicket={canBuyTicket}
                          isPreview={false}
                          onChange={(newAmount) =>
                            setFieldValue("openValueAmount", Number(newAmount))
                          }
                        >
                          {!hasOptions &&
                          mode === TicketMode.SHOPPING &&
                          Number(ticket.typeCode) !== TicketTypeCode.Donation ? (
                            <QuantitySection
                              name="quantity"
                              ticket={ticket}
                              inventory={inventory}
                              attendee={attendee}
                              canBuyTicket={canBuyTicket}
                              cart={cart}
                            />
                          ) : null}
                        </TicketPrice>
                      </>
                    ) : null}
                  </TicketHeader>
                  {!hasOptions ? (
                    <Flex>
                      <AddToCartButton
                        data-testid={ticket.id + "-ADDTOCART"}
                        screenSize={screenSize}
                        disabled={!showOptions || isSubmitting || !canBuyTicket}
                        onClick={submitForm}
                      >
                        {addToCartLabel}
                      </AddToCartButton>
                    </Flex>
                  ) : null}
                  {hasOptions ? (
                    <Flex flexDirection="column">
                      <TicketQuestionsSection flexDirection={"column"}>
                        <TicketQuestionsHeader alignItems="center">
                          <StyledLabel style={{ fontSize: "20px", margin: 0 }}>
                            {googleTranslate(ticket.name || "")}
                          </StyledLabel>
                          <TrashButton
                            testId={ticket.id + "-REMOVE"}
                            style={{ marginTop: 0 }}
                            disabled={!dirty}
                            onClick={() => resetForm({ values: initialValues })}
                          />
                          <QuantitySection
                            name="quantity"
                            ticket={ticket}
                            inventory={inventory}
                            attendee={attendee}
                            canBuyTicket={canBuyTicket}
                            cart={cart}
                          />
                        </TicketQuestionsHeader>
                        {showOptions ? (
                          <Flex flexDirection={"column"}>
                            {fields.map((field, index) => (
                              <FieldSelector
                                key={field.id || index}
                                field={field}
                                containerQuestionId={`answers.${ticket.ticketId}`}
                                isTicketQuestion={true}
                                isCollapsed={isCollapsed(field, fields, collapsedHeaders)}
                                handleToggleCollapse={handleToggleCollapse}
                              />
                            ))}
                          </Flex>
                        ) : null}
                      </TicketQuestionsSection>
                      <Flex>
                        <AddToCartButton
                          data-testid={ticket.id + "-ADDTOCART"}
                          screenSize={screenSize}
                          disabled={!showOptions || isSubmitting || !canBuyTicket}
                          onClick={submitForm}
                        >
                          {addToCartLabel}
                        </AddToCartButton>
                      </Flex>
                    </Flex>
                  ) : null}
                </>
              </TicketContainer>
            </>
          </TranslateFormErrors>
        );
      }}
    </Formik>
  );
};

export { TicketSection, TicketHeader, TicketMode };
